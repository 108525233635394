<template>
    <!-- 外訓顯示 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">
                        {{ $t("page.jobTraining.viewTraining") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="title">{{ $t("misc.type2") }}：</span>
                            <span>{{ kind }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainee") }}：</span>
                            <span>{{ `${createUser.chinese_name} ${departmentName}/${position}` }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseDate") }}：</span>
                            <span>{{ startDate }} ~ {{ endDate }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseTime") }}：</span>
                            <span>{{ startTime }} ~ {{ endTime }} {{ remark }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseHours") }}：</span>
                            <span>{{ courseHours }}小時</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseName") }}：</span>
                            <span>{{ courseName }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingPrice") }}：</span>
                            <span>{{ trainingPrice }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingUnit") }}：</span>
                            <span>{{ trainingUnit }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingLocation") }}：</span>
                            <span>{{ trainingLocation }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseContent") }}：</span>
                            <div class="mt-1 white-space-break-spaces">{{ courseContent }}</div>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingTarget") }}：</span>
                            <div class="mt-1 white-space-break-spaces">{{ trainingTarget }}</div>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.approver") }}：</span>
                            <span>{{ approver }}</span>
                        </li>
                        <li>
                            <div class="flex items-center">
                                <span class="title">{{ $t("page.jobTraining.courseProfile") }}：</span>
                                <span class="flex flex-wrap">
                                    <span v-for="file in files" :key="file.id" class="flex mt-1 mr-2 shrink-0">
                                        <button class="btn btn-success mr-1" type="button" @click="openFile(file.path)">
                                            {{ file.fileName }}
                                        </button>
                                    </span>
                                </span>
                            </div>
                        </li>
                        <template v-if="training.status === '已報告'">
                            <li>
                                <span class="title">課程內容應用工作實務之處：</span>
                                <div class="mt-1 white-space-break-spaces">{{ trainingReportContent }}</div>
                            </li>
                            <li>
                                <span class="title">報告文件：</span>
                                <span>{{ trainingReportKind }}</span>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <span class="title">{{ $t("misc.attech") }}：</span>
                                    <span class="flex flex-wrap">
                                        <span v-for="file in training.TrainingReport.File" :key="file.fileId" class="flex mt-1 mr-2 shrink-0">
                                            <button class="btn btn-success mr-1" type="button" @click="openFile(file.path)">
                                                {{ file.fileName }}
                                            </button>
                                        </span>
                                    </span>
                                </div>
                            </li>
                        </template>
                        <li>
                            <div class="flex">
                                <span class="title">{{ $t("page.jobTraining.remittanceInfo") }}：</span>
                                <div>
                                    <div>{{ `${trainingBudget.bankName} 分行代碼 ${trainingBudget.branch}` }}</div>
                                    <div>{{ `戶名 ${trainingBudget.accountName}` }}</div>
                                    <div>{{ `帳號 ${trainingBudget.accountNumber}` }}</div>
                                </div>
                            </div>
                        </li>
                        <li v-if="isTrainingManager || isBudgetManager" class="bg-gray">
                            <span class="title">{{ $t("page.jobTraining.budget") }}：</span>
                            <div>
                                {{
                                    `${trainingBudget.budget}(核准前累積額) - ${trainingBudget.spent}(核准預算金額) = ${trainingBudget.amount}(核准後累積額)`
                                }}
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button v-if="isAudit" type="button" class="btn btn-primary" data-dismiss="modal" @click="handleApprove">
                        {{ $t("misc.approveAccept") }}
                    </button>
                    <button v-if="isAudit" type="button" class="btn btn-danger" data-dismiss="modal" @click="handleReject">
                        {{ $t("misc.approveReject2") }}
                    </button>
                    <button v-if="canReturn" type="button" class="btn btn-danger" data-dismiss="modal" @click="handleReturn">
                        {{ $t("page.jobTraining.returnToRequest") }}
                    </button>
                    <button v-if="canRejectReport" type="button" class="btn btn-danger" data-dismiss="modal" @click="handleRejectReport">
                        {{ $t("page.jobTraining.returnReport") }}
                    </button>
                    <button v-if="canCancel" type="button" class="btn btn-danger" data-dismiss="modal" @click="handleCancel">
                        {{ $t("page.jobTraining.discard") }}
                    </button>
                    <button v-if="canEdit" type="button" class="btn btn-primary" @click="handleEdit">
                        {{ $t("misc.edit") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal" @click="clear">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import { userIdsToNames } from "@/lib/oa"
import moment from "moment"
export default {
    data() {
        return {
            modalName: "showExternalTraining",
            callback: () => {},
            training: {},
            // params
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            courseHours: "",
            remark: "",
            courseName: "",
            trainingUnit: "",
            trainingLocation: "",
            courseContent: "",
            trainingTarget: "",
            trainees: [],
            kind: "",
            trainingPrice: 0,
            approver: "",
            courseProfile: "",
            files: [],
            trainingBudget: {},
            audit: "",
            trainingId: "",
            trainingReportContent: "",
            certificateId: "",
        }
    },
    computed: {
        ...mapState(["userId", "leaveType"]),
        ...mapGetters(["getUser", "getDepartment", "hasPermission"]),
        position() {
            if (!this.createUser?.UserDepartments[0]) return ""
            return this.createUser.UserDepartments[0].position
        },
        departmentName() {
            if (!this.createUser?.UserDepartments[0]) return ""
            return this.getDepartment(this.createUser.UserDepartments[0].department_id).name
        },
        isInternalTrainingApplicant() {
            return this.hasPermission(this.PERMISSION_INTERNAL_TRAINING_APPLICANT)
        },
        isTrainingManager() {
            return this.hasPermission(this.PERMISSION_TRAINING_MANAGE)
        },
        isAudit() {
            //當前審核人
            return this.audit.includes(this.userId)
        },
        isApprover() {
            return this.training?.approver?.includes(this.userId)
        },
        isBudgetManager() {
            return this.training.budgetManager === this.userId
        },
        canReturn() {
            if (this.approver.length === 0) return false
            if (this.isBudgetManager && this.training.status == "待報告") {
                return true
            }
            if (this.isBudgetManager && this.training.status == "待審核" && this.audit == this.training.budgetManager) {
                return true
            }
            if (
                this.isApprover &&
                this.audit == this.training.budgetManager &&
                this.training.status == "待審核" &&
                this.training.TrainingAudit.map((audit) => audit.userId).includes(this.userId)
            ) {
                return true
            }
            return false
        },
        canCancel() {
            const cancelStatus = ["待審核", "待報告"]
            return this.training.createUser === this.userId && cancelStatus.includes(this.training.status)
        },
        canRejectReport() {
            const rejectReportStatus = ["已報告"]
            return (this.isBudgetManager || this.isApprover) && rejectReportStatus.includes(this.training.status)
        },
        canEdit() {
            const editStatus = ["待審核"]
            return (
                this.training.createUser === this.userId &&
                editStatus.includes(this.training.status) &&
                this.audit.includes(this.training.approver[0])
            )
        },
        createUser() {
            if (!this.training.createUser) return { UserDepartments: [] }
            return this.getUser(this.training.createUser)
        },
        trainingReportKind() {
            const kinds = this.training.TrainingReport?.kind.split(",")
            if (!kinds.includes("證照")) {
                return this.training.TrainingReport?.kind
            }
            const other = kinds.filter((kind) => kind !== "證照")
            const certificateId = this.training.TrainingReport?.certificate
            const certificate = `證照(${certificateId})`
            return [...other, certificate].join(",")
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.callback = e.callback || this.callback
            this.getTraining(e.trainingId)
        },
        close() {
            this.$modal.hide(this.modalName)
            this.clear()
        },
        beforeClose() {},
        //get申請人的 職務代理人
        getTraining(trainingId) {
            if (!trainingId) return
            api.getTraining(trainingId).then((result) => {
                const training = result.data
                this.training = training
                this.startDate = moment(training.startDate).format("YYYY-MM-DD")
                this.startTime = training.startTime
                this.endDate = moment(training.endDate).format("YYYY-MM-DD")
                this.endTime = training.endTime
                this.courseHours = training.hours
                this.remark = training.memo
                this.courseName = training.course
                this.trainingUnit = training.org
                this.trainingLocation = training.address
                this.courseContent = training.content
                this.trainingTarget = training.purpose
                this.trainees = training.TrainingNameList.map((user) => user.userId)
                this.kind = training.kind
                this.trainingPrice = training.TrainingBudget.spent
                this.trainingBudget = training.TrainingBudget
                this.approver =
                    training.TrainingAudit?.map((user) => `${userIdsToNames(user.userId)}(${moment(user.createdAt).format("YYYY-MM-DD")})`).join(
                        ", "
                    ) || ""
                this.files = training.Files
                this.audit = training.audit
                this.trainingId = training.id
                this.trainingReportContent = training.TrainingReport?.content
                this.certificateId = training.TrainingReport?.certificate
            })
        },
        handleEdit() {
            const prop = {
                training: this.training,
                callback: this.callback,
            }
            this.$modal.show("editExternalTraining", prop)
            this.close()
        },
        handleApprove() {
            const params = {
                id: this.trainingId,
                status: "核准",
            }
            api.trainingAudit(params).then(this.callback)
        },
        handleReject() {
            const params = {
                id: this.trainingId,
                status: "否決",
            }
            api.trainingAudit(params).then(this.callback)
        },
        handleReturn() {
            const params = {
                id: this.trainingId,
                status: "退回申請",
            }
            api.trainingAudit(params).then(this.callback)
        },
        handleRejectReport() {
            api.deleteTrainingReport(this.trainingId).then(this.callback)
        },
        handleCancel() {
            const params = {
                id: this.trainingId,
                status: "作廢",
            }
            api.trainingAudit(params).then(this.callback)
        },
        clear() {
            this.startDate = ""
            this.startTime = ""
            this.startMinute = ""
            this.endDate = ""
            this.endTime = ""
            this.courseHours = ""
            this.remark = ""
            this.courseName = ""
            this.trainingUnit = ""
            this.trainingLocation = ""
            this.courseContent = ""
            this.trainingTarget = ""
            this.trainees = []
        },
        openFile(file) {
            window.open(api.imageUrl + file)
        },
    },
}
</script>
<style scoped>
.title {
    width: 6em;
    white-space: nowrap;
}
</style>
