import api from "@/Api/Api"
import * as _ from "lodash"
export default {
    namespaced: true,
    state: {
        districts: [],
        rooms: [],
        categories: [],
    },
    getters: {
        getRoomsOfDistrict: (state) => (districtId) => {
            return state.rooms.filter((room) => room.MeetingDistrict.meetingDistrictId == districtId)
        },
        getAllRooms: (state) => {
            return state.rooms
        },
        getRoomName: (state) => (id) => {
            const room = state.rooms.find((room) => room.meetingPlaceId == id)
            if (!room) {
                return ""
            }
            return `${room.MeetingDistrict.district}-${room.place}`
        },
        getRoomNames:
            (state, getters) =>
            (ids = "") => {
                return ids
                    .split(",")
                    .map((id) => getters.getRoomName(id))
                    .join(", ")
            },
        categoryIdToName: (state, getters) => (id) => {
            if (!id) return ""
            return getters.categoryMap[id]?.name || ""
        },
        categoryMap: (state) => {
            return _.keyBy(state.categories, "meetingCategoryId")
        },
    },
    mutations: {
        updateDistricts(state, payload) {
            state.districts = payload
        },
        updateRooms(state, payload) {
            payload.sort((a, b) => a.meetingDistrictId.localeCompare(b.meetingDistrictId))
            state.rooms = payload
        },
        updateCategories(state, payload) {
            state.categories = payload
        },
    },
    actions: {
        actionGetAreas({ commit }) {
            api.get("/meetingDistrict").then((res) => {
                commit("updateDistricts", res.data)
            })
            api.get("/meetingPlace").then((res) => {
                commit("updateRooms", res.data)
            })
        },
        actionGetMeetingCategories({ commit }) {
            api.getMeetingCategories().then((res) => {
                commit("updateCategories", res.data)
            })
        },
    },
}
