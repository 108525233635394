<template>
    <!-- 內訓顯示 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">
                        {{ $t("page.jobTraining.viewTraining") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="title">{{ $t("misc.type2") }}：</span>
                            <span>{{ kind }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainee") }}：</span>
                            <span>{{ userIdsToNames(trainees) }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseDate") }}：</span>
                            <span>{{ startDate }} ~ {{ endDate }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseTime") }}：</span>
                            <span>{{ startTime }} ~ {{ endTime }} {{ remark }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseHours") }}：</span>
                            <span>{{ courseHours }}小時</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseName") }}：</span>
                            <span>{{ courseName }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingUnit") }}：</span>
                            <span>{{ trainingUnit }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingLocation") }}：</span>
                            <span>{{ trainingLocation }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.courseContent") }}：</span>
                            <div class="mt-1 white-space-break-spaces">{{ courseContent }}</div>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.jobTraining.trainingTarget") }}：</span>
                            <div class="mt-1 white-space-break-spaces">{{ trainingTarget }}</div>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button
                        v-if="isInternalTrainingApplicant"
                        type="button"
                        class="btn btn-primary"
                        @click="handleEdit"
                    >
                        {{ $t("misc.edit") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal" @click="clear">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import moment from "moment"
import { userIdsToNames } from "@/lib/oa"
export default {
    data() {
        return {
            modalName: "showInternalTraining",
            callback: () => {},
            training: {},
            // params
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            courseHours: "",
            remark: "",
            courseName: "",
            trainingUnit: "",
            trainingLocation: "",
            courseContent: "",
            trainingTarget: "",
            trainees: [],
            kind: "",
            trainee: { UserDepartments: [] },
        }
    },
    computed: {
        ...mapState(["userId", "leaveType"]),
        ...mapGetters(["getUser", "getDepartment", "hasPermission"]),
        position() {
            if (!this.trainee?.UserDepartments[0]) return ""
            return this.trainee.UserDepartments[0].position
        },
        departmentName() {
            if (!this.trainee?.UserDepartments[0]) return ""
            return this.getDepartment(this.trainee.UserDepartments[0].department_id).name
        },
        isInternalTrainingApplicant() {
            return this.hasPermission(this.PERMISSION_INTERNAL_TRAINING_APPLICANT)
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.callback = e.callback || this.callback
            this.trainee = this.getUser(e.traineeid)
            this.getTraining(e.trainingId)
        },
        close() {
            this.$modal.hide(this.modalName)
            this.clear()
        },
        beforeClose() {},
        //get申請人的 職務代理人
        getTraining(trainingId) {
            if (!trainingId) return
            api.getTraining(trainingId).then((result) => {
                const training = result.data
                this.training = training
                this.startDate = moment(training.startDate).format("YYYY-MM-DD")
                this.startTime = training.startTime
                this.endDate = moment(training.endDate).format("YYYY-MM-DD")
                this.endTime = training.endTime
                this.courseHours = training.hours
                this.remark = training.memo
                this.courseName = training.course
                this.trainingUnit = training.org
                this.trainingLocation = training.address
                this.courseContent = training.content
                this.trainingTarget = training.purpose
                this.trainees = training.TrainingNameList.map((user) => user.userId)
                this.kind = training.kind
            })
        },
        handleEdit() {
            const prop = {
                training: this.training,
                callback: this.callback,
            }
            this.$modal.show("EditInternalTraining", prop)
            this.close()
        },
        clear() {
            this.startDate = ""
            this.startTime = ""
            this.startMinute = ""
            this.endDate = ""
            this.endTime = ""
            this.courseHours = ""
            this.remark = ""
            this.courseName = ""
            this.trainingUnit = ""
            this.trainingLocation = ""
            this.courseContent = ""
            this.trainingTarget = ""
            this.trainees = []
        },
        userIdsToNames: userIdsToNames,
    },
}
</script>
