import config from "../config";
import axios from "axios";
import loading from "../lib/loading";
import shortid from "shortid";

const url = config.fileApi;

export default {
  async post(api, data) {
    const reqId = shortid.generate();
    loading.start(reqId);
    return axios
      .post(url + api, data)
      .then((res) => {
        loading.stop(reqId);
        return res;
      })
      .catch((error) => {
        loading.stop(reqId);
        console.error(error);
        throw error;
      });
  },
  async get(api, config = {}) {
    const reqId = shortid.generate();
    loading.start(reqId);
    const response = await axios
      .get(url + api, config)
      .then((res) => {
        loading.stop(reqId);
        return res.data;
      })
      .catch((error) => {
        loading.stop(reqId);
        console.error(error);
        throw error;
      });
    return response;
  },
  async put(api, data) {
    const reqId = shortid.generate();
    loading.start(reqId);
    var response = await axios
      .put(url + api, data)
      .then((res) => {
        loading.stop(reqId);
        return res;
      })
      .catch((error) => {
        loading.stop(reqId);
        console.error(error);
        throw error;
      });
    return response;
  },
  async delete(api, config = {}) {
    const reqId = shortid.generate();
    loading.start(reqId);
    await axios
      .delete(url + api, config)
      .then((res) => {
        loading.stop(reqId);
      })
      .catch((error) => {
        loading.stop(reqId);
        console.error(error);
        throw error;
      });
  },
};
