import Vue from "vue"
import Vuex from "vuex"
import doc from "./modules/doc"
import user from "./modules/user"
import meeting from "./modules/meeting"
import calendar from "./modules/calendar"
import tgaDsc from "./modules/tgaDsc"
import ezSales from "./modules/ezSales"
import permission from "./modules/permission"
import api from "@/Api/Api"
import fileapi from "@/Api/FileApi"
import _ from "lodash"
import { app } from "@/main"
import Graph from "@/lib/graph"
import eventBus from "@/lib/eventBus"
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

const printGraph = async (type, graph) => {
    // return
    if (type.startsWith("dep")) {
        console.log("========================")
        // console.log("departmentGraph: ", graph);
        await graph.traverseDown("dep-root", (n, id, path, level) => {
            // console.log(
            //   _.repeat(" ", level * 4) +
            //   id.substr(0, 10) +
            //   (n.type === "department" ? "/" : "")
            // );
        })
    } else if (type.startsWith("doc")) {
        console.log("========================")
        console.log("documentGraph: ")
        await graph.traverseDown("root", (n, id, path, level) => {
            console.log(_.repeat(" ", level * 4) + id.substr(0, 6) + (n.type === "directory" ? "/" : ""))
        })
        console.log("========================")
        console.log("documentGraph: tags")
        await graph.traverseDown("tagRoot", (n, id, path, level) => {
            console.log(_.repeat(" ", level * 2) + id.substr(0, 6) + (n.type === "directory" ? "/" : ""))
        })
        console.log("========================")
    }
}

export default new Vuex.Store({
    state: {
        // state
        user: {},
        userToken: "",
        userId: "",
        departmentId: "",
        locale: "zh-TW",
        ready: false,

        // mass data storage
        departmentTree: {},
        departments: [],
        roles: [],
        usersTree: [],
        departmentGraph: new Graph(),
        departmentNumberMap: {}, // number:department
        users: [],
        userPermissions: [],
        userNumberToIdMap: {},
        visitEvents: [],
        userPermissionLoaded: false,

        initData: {},
        // config data
        permissionList: [
            {
                name: "行事曆",
                path: "schedule",
            },
            {
                name: "公佈欄",
                path: "bulletin",
            },
            {
                name: "重大事件",
                path: "graveAffair",
            },
            {
                name: "出勤管理",
                path: "attendance",
            },
            {
                name: "訪談報告",
                path: "interview",
            },
            {
                name: "交易機會",
                path: "transaction",
            },
            {
                name: "工作管理",
                path: "joblist",
            },
            {
                name: "專案管理",
                path: "project",
            },
            {
                name: "文件管理",
                path: "displayDocumentNew",
            },
            {
                name: "會議管理",
                path: "conference",
            },
            {
                name: "訂單管理",
                path: "orders",
            },
            {
                name: "庫存管理",
                path: "inventory",
            },
            {
                name: "試料管理",
                path: "testMaterial",
            },
            {
                name: "業務專區",
                path: "business",
            },
            // {
            //   name: "採購專區",
            //   path: "purchase",
            // },
            {
                name: "研發專區",
                path: "development",
            },
            // {
            //   name: "生產專區",
            //   path: "production",
            // },
            {
                name: "財務專區",
                path: "finance",
            },
            {
                name: "管理專區",
                path: "administration",
            },
        ],
        timezones: [
            { timezone: "-12" },
            { timezone: "-11" },
            { timezone: "-10" },
            { timezone: "-09" },
            { timezone: "-08" },
            { timezone: "-07" },
            { timezone: "-06" },
            { timezone: "-05" },
            { timezone: "-04" },
            { timezone: "-03" },
            { timezone: "-02" },
            { timezone: "-01" },
            { timezone: "+00" },
            { timezone: "+01" },
            { timezone: "+02" },
            { timezone: "+03" },
            { timezone: "+04" },
            { timezone: "+05" },
            { timezone: "+06" },
            { timezone: "+07" },
            { timezone: "+08" },
            { timezone: "+09" },
            { timezone: "+10" },
            { timezone: "+11" },
            { timezone: "+12" },
            { timezone: "+13" },
        ],
        districtCodes: {
            NT1: "misc.taiwan",
            NT3: "misc.america",
            NT4: "misc.de-yi",
            NT5: "misc.hong-hai",
            NT6: "misc.fu-tian",
            SH1: "misc.shang-hai",
            SKY: "misc.tian-guan",
            DG2: "misc.nai-long",
        },
        departmentNumberDistrict: {
            NT3: "美國區",
            NT1: "台灣區",
            HK1: "華南區",
            SH1: "華東區",
        },
        eventslist: [
            { category: "graveAffair0102", name: "退票" },
            { category: "graveAffair0103", name: "帳款逾期" },
            { category: "graveAffair0104", name: "品質異常" },
            { category: "graveAffair0105", name: "原料異常漲跌" },
            { category: "graveAffair0106", name: "安全庫存" },
        ],
        bulletinImportanceMap: {
            Normal: "一般",
            Urgent: "最高",
            Important: "較高",
            lowest: "最低",
            low: "較低",
        },
        inquiryAction: {
            type: {
                1: "相關問題或建議",
                2: "索取目錄或樣品",
                3: "索取報價",
                4: "服務申訴",
                5: "合作",
                6: "其他",
            },
            productCategories: [
                { key: 6, value: "高溫材料" },
                { key: 7, value: "特種塑膠" },
                { key: 1, value: "高導熱塑膠" },
                { key: 5, value: "防延燒材料" },
                { key: 3, value: "環境友善材料" },
                { key: 4, value: "高性能複合材料" },
                { key: 2, value: "高玻纖含量(高剛性尼龍)" },
            ],
            productCategory: {
                6: "高溫材料",
                7: "特種塑膠",
                1: "高導熱塑膠",
                5: "防延燒材料",
                3: "環境友善材料",
                4: "高性能複合材料",
                2: "高玻纖含量(高剛性尼龍)",
            },
            district: {
                1: "台灣",
                2: "上海",
                3: "華南",
                4: "Nypla",
            },
        },
        jobReportTypes: {
            1: "工作指示",
            2: "暫停工作",
            3: "繼續工作",
            4: "工作修改",
            5: "回報工作進度",
            6: "工作結束",
        },
        currencies: ["RMB", "NTD", "USD", "HKD", "AUD", "XEU", "JPY"],
        industryMap: {
            A: ["ACP", "AEE", "AEV", "AEX", "AIN", "ALI", "AMO", "AUF", "AUO", "AUR"],
            B: ["BCP", "BCO", "BSO"],
            E: ["ECB", "ECC", "ECO", "ECU", "EEO", "EES", "EHV"],
            F: ["FOA", "FOO"],
            G: ["GMA"],
            H: ["HFH", "HFI", "HMT", "HOA"],
            L: ["LHS"],
            M: ["MC1", "MC2"],
            O: ["OB1", "OB2", "OOE"],
            R: ["RSA"],
            S: ["SEC", "SL1", "SLB", "SLU", "STM"],
        },
        locales: [
            { id: "zh-TW", name: "繁體" },
            { id: "zh-CN", name: "简体" },
            { id: "en-US", name: "English" },
        ],
        countryList: [
            { id: "TW", name: "TW" },
            { id: "CN", name: "CN" },
            { id: "US", name: "US" },
            { id: "AE", name: "AE" },
            { id: "AU", name: "AU" },
            { id: "BA", name: "BA" },
            { id: "BD", name: "BD" },
            { id: "BR", name: "BR" },
            { id: "BU", name: "BU" },
            { id: "BV", name: "BV" },
            { id: "CA", name: "CA" },
            { id: "DE", name: "DE" },
            { id: "EG", name: "EG" },
            { id: "FR", name: "FR" },
            { id: "HK", name: "HK" },
            { id: "ID", name: "ID" },
            { id: "IL", name: "IL" },
            { id: "IN", name: "IN" },
            { id: "IR", name: "IR" },
            { id: "IT", name: "IT" },
            { id: "JP", name: "JP" },
            { id: "KH", name: "KH" },
            { id: "KP", name: "KP" },
            { id: "LI", name: "LI" },
            { id: "MM", name: "MM" },
            { id: "MX", name: "MX" },
            { id: "MY", name: "MY" },
            { id: "NL", name: "NL" },
            { id: "OTH1", name: "OTH1" },
            { id: "OTH2", name: "OTH2" },
            { id: "PH", name: "PH" },
            { id: "PK", name: "PK" },
            { id: "PL", name: "PL" },
            { id: "RS", name: "RS" },
            { id: "RU", name: "RU" },
            { id: "SA", name: "SA" },
            { id: "SG", name: "SG" },
            { id: "SP", name: "SP" },
            { id: "SW", name: "SW" },
            { id: "TH", name: "TH" },
            { id: "TR", name: "TR" },
            { id: "UA", name: "UA" },
            { id: "UK", name: "UK" },
            { id: "VN", name: "VN" },
            { id: "ZA", name: "ZA" },
        ],
        outsideType: [
            { id: "拜訪客戶", name: "outsideType.visitClient" },
            { id: "電話訪談", name: "outsideType.telephoneInterview" },
            { id: "出差", name: "outsideType.businessTrip" },
            { id: "內部出差", name: "outsideType.internalBusinessTrip" },
            { id: "內部出差國外", name: "outsideType.internalBusinessTripOversea" },
            { id: "實驗/檢測", name: "outsideType.test" },
            { id: "客戶來訪", name: "outsideType.customerVisit" },
            { id: "客戶試料", name: "outsideType.customerSample" },
            { id: "其他", name: "outsideType.other" },
            { id: "資訊座談會", name: "outsideType.symposium" },
            { id: "面談", name: "outsideType.interview" },
            { id: "資訊展", name: "outsideType.exhibition" },
            { id: "受訓", name: "outsideType.trained" },
            { id: "電腦週邊採買", name: "outsideType.computerPurchase" },
            { id: "設備耗材採買", name: "outsideType.purchase" },
            { id: "產業報告製作", name: "outsideType.report" },
            { id: "BI研討會", name: "outsideType.BISeminar" },
            { id: "E-mail拜訪", name: "outsideType.email" },
        ],
        leaveType: [
            { id: "公假", name: "leaveType.publicHoliday" },
            { id: "病假", name: "leaveType.sickLeave" },
            { id: "事假", name: "leaveType.personalLeave" },
            { id: "婚假", name: "leaveType.weddingLeave" },
            { id: "喪假", name: "leaveType.bereavementLeave" },
            { id: "產假", name: "leaveType.maternityLeave" },
            { id: "產檢假", name: "leaveType.prenatalLeave" },
            { id: "陪產假", name: "leaveType.paternityLeave" },
            { id: "返台假", name: "leaveType.returnLeave" },
            { id: "特休假", name: "leaveType.specialLeave" },
            { id: "生理假", name: "leaveType.menstrualLeave" },
            { id: "公傷病假", name: "leaveType.occupationalSicknessLeave" },
            { id: "家庭照顧假", name: "leaveType.familyCareLeave" },
            { id: "補休", name: "leaveType.compensatoryLeave" },
            { id: "其他", name: "misc.other" },
        ],
        i18nNormal: [
            { id: "進行中", name: "misc.inProgress" },
            { id: "已完成", name: "misc.completed" },
            { id: "已簽核", name: "misc.signed" },
            { id: "未簽核", name: "misc.notSigned" },
            { id: "待簽核", name: "misc.waitSign" },
            { id: "否決", name: "misc.approveReject2" },
            { id: "生產異常", name: "page.graveAffair0101.processError" },
            { id: "進料檢驗", name: "page.graveAffair0101.IQC" },
            { id: "進料檢驗異常", name: "page.graveAffair0101.IQCAbnormal" },
            { id: "進貨異常", name: "page.graveAffair0101.abnormalPurchase" },
            { id: "銷售退貨", name: "page.graveAffair0101.abnormalIncomingMaterials" },
            { id: "列為資料", name: "misc.setToInfo" },
            { id: "即時研發", name: "misc.researchNow" },
            { id: "報價", name: "misc.askPrice" },
            { id: "研發可行性評估", name: "page.attendance0201.researchEvaluation" },
            { id: "馬上試料", name: "page.attendance0201.testNow" },
            { id: 86400000, name: "page.joblist0101.everyDay" },
            { id: 172800000, name: "page.joblist0101.everyTwoDays" },
            { id: 691200000, name: "page.joblist0101.everyMonday" },
            { id: 1296000000, name: "page.joblist0101.mondayEveryTwoWeeks" },
            { id: 99999, name: "page.joblist0101.everyMonthOnThe1st" },
            { id: 88888, name: "page.joblist0101.everyMonthOnThe15th" },
            { id: 77777, name: "page.joblist0101.everyHalfYear" },
            { id: "普通", name: "misc.normal" },
            { id: "重要", name: "misc.important" },
            { id: "極重要", name: "misc.veryImportant" },
            { id: "專案回報", name: "page.project0101.projectReport" },
            { id: "意見指示", name: "page.project0101.comment" },
            { id: "專案完成", name: "page.project0101.projectFinish" },
            { id: "審核通過", name: "page.inquiryAction0101.approval" },
            { id: "審核不通過", name: "page.inquiryAction0101.reject" },
            { id: "系統訊息", name: "page.project0101.systemMessage" },
            { id: "任務完成", name: "page.project0101.taskFinish" },
            { id: "任務回報", name: "page.project0101.report" },
            { id: "NT1", name: "misc.taiwan" },
            { id: "NT3", name: "misc.america" },
            { id: "SKY", name: "misc.sky" },
            { id: "SH1", name: "misc.sh1" },
            { id: "備料中", name: "page.orders0101.prepare" },
            { id: "已出貨", name: "page.orders0101.shipped" },
            { id: "已繳庫", name: "page.orders0101.inStore" },
            { id: "製造中", name: "page.orders0101.product" },
            { id: "Pending", name: "page.orders0101.pending" },
            { id: "Open", name: "page.orders0101.open" },
            { id: "Released", name: "page.orders0101.released" },
            { id: "Shipped", name: "page.orders0101.shipped2" },
            { id: "Preparing Order", name: "page.orders0101.prepare" },
            { id: "部分出貨", name: "page.orders0101.partialShipment" },
            { id: "國內商標", name: "page.trademark0101.domesticTrademark" },
            { id: "國外商標", name: "page.trademark0101.foreignTrademark" },
            { id: "國內專利", name: "page.trademark0101.domesticPatent" },
            { id: "國外專利", name: "page.trademark0101.foreignPatent" },
            { id: "男", name: "misc.male" },
            { id: "女", name: "misc.female" },
            { id: "任務暫停", name: "page.project0101.taskPause" },
            { id: "手機", name: "misc.cellPhone" },
            { id: "電話", name: "misc.phone" },
            { id: "email", name: "misc.email" },
            { id: "其他", name: "misc.other" },
            { id: "汽油", name: "page.fee0101.gasoline" },
            { id: "柴油", name: "page.fee0101.dieselFuel" },
            { id: "高鐵", name: "page.fee0101.highSpeedRail" },
            { id: "火車", name: "page.fee0101.train" },
            { id: "etag", name: "page.fee0101.etag" },
            { id: "機票", name: "page.fee0101.airTickets" },
            { id: "機票-頭等艙", name: "page.fee0101.airTicketsFirst" },
            { id: "機票-商務艙", name: "page.fee0101.airTicketsBusiness" },
            { id: "機票-經濟艙", name: "page.fee0101.airTicketsEconomy" },
            { id: "船運", name: "page.fee0101.shipping" },
            { id: "客運", name: "page.fee0101.bus" },
            { id: "捷運", name: "page.fee0101.mrt" },
            { id: "計程車", name: "page.fee0101.taxi" },
            { id: "停車費", name: "page.fee0101.parking" },
            { id: "交通費", name: "page.fee0101.transportation" },
        ],
        rawMaterialFluctuateOrder: {
            nt1: [
                "RM-100-50BW-2",
                "RM-132-7",
                "KM-21SPC",
                "RN-100-TP4208",
                "RN-221-7",
                "RN-341-V",
                "RA-100-7D120",
                "RC-100-C2800",
                "RP-100-K1011",
                "RS-100-7525N",
                "MA-43",
                "FG-10",
                "KM-21SPC-2",
                "KM-A45L",
            ],
            sh1: [
                "RN-100-C20",
                "RN-100-C22",
                "RN-100-C26",
                "RN-100-M2000",
                "RN-100-YH400",
                "RN-200-LX",
                "RN-221-C6",
                "RM-100-U",
                "RM-100-U3602",
                "RM-131-C25",
                "RM-131-L601B6-R",
                "FG-45-C2",
                "FG-60-C",
                "FG-68-C",
                "FR-07-C9950",
            ],
        },
        transferDepManagers: [],
        customerTypes: [
            { name: "重點客戶", value: 1, i18nKey: "page.joblist0101.importantCustomer" },
            { name: "潛在大客戶", value: 2, i18nKey: "page.joblist0101.potentialBigCustomers" },
            // { name: "主管週報", value: 3, i18nKey:"page.joblist0101.supervisorReport" },
            { name: "技術類", value: 5, i18nKey: "page.joblist0101.technology" },
            { name: "工作週報", value: 4, i18nKey: "page.joblist0101.workWeekly" },
            { name: "其他", value: 6, i18nKey: "page.joblist0101.else" },
        ],
        jobCategories: [],
        interviewGroups: [],
        userApprover: {},
        waitingApiQueue: [],
    },
    mutations: {
        login(state, payload) {
            localStorage.setItem("login_Usertoken", payload.token)
            localStorage.setItem("login_UserId", payload.id)
            Vue.set(state, "userToken", payload.token)
            Vue.set(state, "userId", payload.id)
            if (payload.id) {
                localStorage.setItem("openCount", 1)
            } else {
                localStorage.setItem("openCount", 0)
            }
        },
        updateUserId(state, payload) {
            state.userId = payload
        },
        updateUser(state, payload) {
            state.userId = payload.userID
            state.user = payload
        },
        updateUserAttr(state, payload) {
            Vue.set(state.user, payload.key, payload.value)
        },
        updateDepartmentTree(state, payload) {
            Vue.set(state, "departmentTree", payload)
        },
        updateDepartments(state, payload) {
            Vue.set(state, "departments", payload)
        },
        updateRoles(state, payload) {
            Vue.set(state, "roles", payload)
        },
        updateUserTree(state, payload) {
            Vue.set(state, "usersTree", payload)
        },
        updateLang(state, payload) {
            state.locale = payload
            app.$i18n.locale = payload
        },
        updateDepartmentNumberMap(state, payload) {
            Vue.set(state, "departmentNumberMap", payload)
        },
        updateDepartmentGraph(state, payload) {
            Vue.set(state, "departmentGraph", payload)
        },
        updateReady(state, payload) {
            Vue.set(state, "ready", payload)
        },
        updateUsers(state, payload) {
            Vue.set(state, "users", payload)
        },
        updateUserNumberToIdMap(state, payload) {
            Vue.set(state, "userNumberToIdMap", payload)
        },
        updatePermissions(state, payload) {
            Vue.set(state, "userPermissions", payload)
            state.userPermissionLoaded = true
        },
        updateVisitEvents(state, payload) {
            Vue.set(state, "visitEvents", payload)
        },
        updateInitData(state, payload) {
            state.initData = payload
        },
        updateTransferDepManager(state, payload) {
            state.transferDepManagers = payload
        },
        updateJobCategories(state, payload) {
            state.jobCategories = payload
        },
        updateInterviewGroups(state, payload) {
            state.interviewGroups = payload
        },
        updateUserApprover(state, payload) {
            state.userApprover = payload
        },
        regisitWaitingCalls(state, payload) {
            state.waitingApiQueue.push(payload)
        },
    },
    actions: {
        acionGetUserId({ commit }) {
            const userId = localStorage.getItem("login_UserId")
            if (userId) {
                commit("updateUserId", userId)
            }
        },
        async getUser(context, payload) {
            const userId = payload || localStorage.getItem("login_UserId")
            if (userId) {
                const user = await fileapi.get("/user/" + userId).then((r) => _.get(r, "data", {}))
                context.commit("updateUser", user)
                //語系,簽倒狀態從back拿
                api.get("/user/" + userId).then((res) => {
                    context.dispatch("setLang", res.data.lang || "zh-TW")
                    context.commit("updateUserAttr", { key: "status", value: res.data.status })
                    context.commit("updateUserAttr", { key: "CheckIn", value: res.data.CheckIn })
                    context.commit("updateUserAttr", { key: "CheckOut", value: res.data.CheckOut })
                })
            } else {
                context.commit("updateUser", {})
            }
        },
        async login(context, payload) {
            const res = await api.post("/login", payload)
            if (_.get(res, "data.status") === 0) {
                context.commit("login", {
                    id: res.data.data.userId,
                    token: res.data.data.token,
                })
                await context.dispatch("actionAfterLogin")
                return { status: 0 }
            }
            if (res.data.status === -3) {
                return res.data
            } else {
                return {
                    status: 1,
                    showWarning: true,
                    warning: _.get(res, "data.message", ""),
                    ...(res.data && { data: res.data.data }),
                }
            }
        },
        async logout(context, payload) {
            context.commit("login", {
                id: "",
                token: "",
            })
            context.commit("updateUser", {})
        },
        async getVisitEvents(context, payload) {
            const data = await api.get("/event").then((res) => {
                return res.data
            })
            data.sort((a, b) => {
                return (a.name || "")?.localeCompare(b.name || "", "zh-TW")
            })
            context.commit("updateVisitEvents", data)
        },
        // deprecate: use appInit
        async getDepartmentTree(context, payload) {
            const tree = await fileapi.get("/department/tree").then((r) => _.get(r, "data", {}))
            context.commit("updateDepartmentTree", tree)
        },
        // deprecate: use appInit
        async getDepartments(context, payload) {
            const tree = await fileapi.get("/department").then((r) => _.get(r, "data", {}))
            context.commit("updateDepartments", tree)
        },
        async getRoles(context, payload) {
            const data = await fileapi.get("/role").then((r) => _.get(r, "data", []))
            context.commit("updateRoles", data)
        },
        // deprecate: use appInit
        async getUserTree(context, payload) {
            const data = await fileapi.get("/user/tree").then((r) => _.get(r, "data", {}))
            context.commit("updateUserTree", data)
        },
        async getUserAndDepartments(context) {
            const { departments, users, departmentRelations } = await fileapi.get("/app/init").then((r) => _.get(r, "data", {}))
            //取得在職狀態，後續優化方向直接從oaback取user資料
            const resignUsers = await api.get("/user", { params: { status: "離職" } }).then((r) => _.get(r, "data", []))
            resignUsers.forEach((resignUser) => {
                const u = users.find((user) => user.id == resignUser.userID)
                if (u) u.isResign = true
            })
            //是否為業務部門欄位修正
            departments.forEach((department) => (department.isBusiness = department.is_business === "1"))
            context.commit("updateInitData", { users, departments, departmentRelations })
            const departmentGraph = new Graph()
            // construct department tree
            departmentGraph.addNode("dep-root", {
                department_id: "root",
                name: "root",
                number: "root", // 代號
                type: "department",
            })
            _.each(departments, (d) => {
                departmentGraph.addNode(`dep-${d.department_id}`, {
                    ...d,
                    type: "department",
                })
            })
            _.each(departmentRelations, (r) => {
                if (r.parent_department_id !== r.department_id) {
                    departmentGraph.addEdge(`dep-${r.parent_department_id}`, `dep-${r.department_id}`, r)
                }
            })
            _.each(users, (o) => {
                departmentGraph.addNode(`user-${o.id}`, { ...o, type: "user" })
                departmentGraph.addEdge(`dep-${_.get(o, "UserDepartments.0.department_id")}`, `user-${o.id}`, {
                    ..._.get(o, "UserDepartments.0", {}),
                })
            })

            const departmentNumberMap = {}
            // add from root relation to top level department
            await departmentGraph.forEachNode((node, nodeId) => {
                if (node.type === "department") {
                    departmentNumberMap[node.number] = node
                }
                if (!Object.keys(node._inEdges).length && nodeId !== "root") {
                    departmentGraph.addEdge("dep-root", `dep-${nodeId}`, {})
                }
            })

            context.commit("updateDepartmentNumberMap", departmentNumberMap)
            context.commit("updateDepartmentGraph", departmentGraph)
            context.commit("updateUsers", users)
            context.commit(
                "updateUserNumberToIdMap",
                _(users)
                    .groupBy("number")
                    .mapValues((o) => _.get(o[0], "id"))
                    .value()
            )
            // context.commit('updateDepartments', ?)
            //
            printGraph("dep", departmentGraph)
            context.dispatch("actionUpdateUserApprovers")
        },
        async updateDepartmentGraph({ state, commit, dispatch }, lang) {
            const { departments, users, departmentRelations: initDepartmentRelations } = state.initData
            const departmentRelations = [...initDepartmentRelations]
            const departmentGraph = new Graph()
            // construct department tree
            departmentGraph.addNode("dep-root", {
                department_id: "root",
                name: "root",
                number: "root", // 代號
                type: "department",
            })
            _.each(departments, (d) => {
                departmentGraph.addNode(`dep-${d.department_id}`, {
                    ...d,
                    type: "department",
                })
            })
            //英文版時把美國區提到前面
            console.log(lang)
            if (lang === "en-US") {
                departmentRelations.sort((a, b) => {
                    if (a.department_id == 130 && b.department_id != 130) return -1
                    if (a.department_id != 130 && b.department_id == 130) return 1
                    return 0
                })
            }
            _.each(departmentRelations, (r) => {
                if (r.parent_department_id !== r.department_id) {
                    departmentGraph.addEdge(`dep-${r.parent_department_id}`, `dep-${r.department_id}`, r)
                }
            })
            _.each(users, (o) => {
                departmentGraph.addNode(`user-${o.id}`, { ...o, type: "user" })
                departmentGraph.addEdge(`dep-${_.get(o, "UserDepartments.0.department_id")}`, `user-${o.id}`, {
                    ..._.get(o, "UserDepartments.0", {}),
                })
            })

            const departmentNumberMap = {}
            // add from root relation to top level department
            await departmentGraph.forEachNode((node, nodeId) => {
                if (node.type === "department") {
                    departmentNumberMap[node.number] = node
                }
                if (!Object.keys(node._inEdges).length && nodeId !== "root") {
                    departmentGraph.addEdge("dep-root", `dep-${nodeId}`, {})
                }
            })

            commit("updateDepartmentNumberMap", departmentNumberMap)
            commit("updateDepartmentGraph", departmentGraph)
            commit("updateUsers", users)
            commit(
                "updateUserNumberToIdMap",
                _(users)
                    .groupBy("number")
                    .mapValues((o) => _.get(o[0], "id"))
                    .value()
            )
            dispatch("actionUpdateUserApprovers")
        },
        async actionGetPermissions(context) {
            const userId = context.state.userId
            const permissions = await fileapi
                .get("/permission/me", {
                    params: { user_id: userId },
                })
                .then((r) => {
                    const permissions = _.get(r, "data", []).map((o) => {
                        return {
                            ...o,
                            resource_id: o.resource_id || "",
                            resource_type: o.resource_type || "",
                            condition: o.condition || "",
                        }
                    })
                    return permissions
                })
            context.commit("updatePermissions", permissions)
        },

        async setLang({ commit, dispatch }, payload) {
            if (app.$i18n.locale === payload) return
            dispatch("updateDepartmentGraph", payload)
            commit("updateLang", payload)
        },
        async setReady(context, payload) {
            if (payload) {
                eventBus.$emit("ready")
                context.state.waitingApiQueue.forEach((func) => func())
            }
            context.commit("updateReady", payload)
        },
        actionGetTransferDepManager(context) {
            return api.getTransferDep().then((res) => {
                context.commit("updateTransferDepManager", res.data)
            })
        },
        actionGetJobCategory({ commit }) {
            api.getJobCategories().then((res) => {
                commit("updateJobCategories", res.data)
            })
        },
        actionGetInterviewGroups({ commit }) {
            return api.getInterviewGroup().then((res) => {
                commit("updateInterviewGroups", res.data)
            })
        },
        actionGetInterviewGroupsCountUser({ commit }) {
            return api.getInterviewGroupCountUser().then((res) => {
                commit("updateInterviewGroups", res.data)
            })
        },
        async actionUpdateUserApprovers({ commit, state, getters }) {
            const users = await Promise.all(state.users.map(async (user) => [user.id, await getters.getApprovers(user.id)]))
            commit("updateUserApprover", Object.fromEntries(users))
        },
        actionCallAfterReady({ commit, state, getters }, callback) {
            if (state.ready) {
                callback()
                return
            }
            commit("regisitWaitingCalls", callback)
        },
        actionAfterLogin({ dispatch }) {
            dispatch("actionGetInterviewGroupsCountUser")
            dispatch("actionGetTransferDepManager")
            return Promise.all([
                dispatch("getUserAndDepartments"),
                dispatch("getRoles"),
                dispatch("getUser"),
                dispatch("userStore/actionGetUserSetting"),
            ]).then(async () => {
                await dispatch("actionGetPermissions")
            })
        },
    },
    getters: {
        getDepartmentGraphTreelist: (state) => () => {
            // state.
        },
        getUserToken: (state) => () => {
            return localStorage.getItem("login_Usertoken")
        },
        getIsLogin: (state) => {
            return localStorage.getItem("login_Usertoken") ? true : false
        },
        getDepartment: (state) => (id) => {
            // console.log('getDepartment()', id);
            if (!id) {
                return {}
            }
            if (id && !id.startsWith("dep-")) {
                id = `dep-${id}`
            }
            const node = state.departmentGraph.getNode(id)
            const department = node
            return department
        },
        getDepartmentByNumber: (state) => (number) => {
            console.log("getDepartmentByNumber", number, state.departmentNumberMap)
            return _.get(state.departmentNumberMap, number, {})
        },
        getUser:
            (state, getters) =>
            (userId, { isNumber = false, fallbackAsMe = true } = {}) => {
                if (userId === "") {
                    return {}
                }

                if (isNumber) {
                    userId = state.userNumberToIdMap[userId]
                }

                // console.log('getUser()', userId);
                if (!userId && fallbackAsMe) {
                    userId = state.userId
                }

                if (userId && !userId.startsWith("user-")) {
                    userId = `user-${userId}`
                }
                let node = state.departmentGraph.getNode(userId)
                if (!node) {
                    return {}
                }
                const inEdges = Object.values(node._inEdges)
                const departments = inEdges.map((inEdge) => {
                    const department = state.departmentGraph.getNode(`dep-${inEdge.department_id}`) || {}
                    return {
                        department,
                        position: inEdge.position,
                    }
                })
                return {
                    ...node,
                    departments,
                }
            },
        // TODO: input user id
        getUserRole: (state) => (id) => {
            const role = state.roles.find((role) => state.user.roleId === role.id) || {}
            return {
                ...role,
                isAdmin: role.id === "admin",
            }
        },
        hasPermission:
            (state, getters) =>
            (actions = [], { noAdmin, resource_id, resource_type, effect } = {}) => {
                const isAdmin = getters.getUserRole().isAdmin
                // 管理員return true
                if (isAdmin) {
                    if (noAdmin) {
                        return false
                    }
                    return true
                }
                if (typeof actions === "string") {
                    actions = [actions]
                }
                return actions.some((action) => {
                    return state.userPermissions.some((permission) => {
                        return (
                            permission.action === action &&
                            (resource_id ? permission.resource_id === resource_id : true) &&
                            (resource_type ? permission.resource_type === resource_type : true) &&
                            (effect ? permission.effect === effect : true)
                        )
                    })
                })
            },
        getPermissions:
            (state, getters) =>
            ({ action, resource_id = "" }) => {
                let permissions = state.userPermissions.filter((permission) => {
                    return permission.action === action && permission.resource_id === resource_id
                })
                return permissions
            },
        getRoles: (state, getters) => (direction, role) => {
            if (!role) {
                role = getters.getUserRole()
            }
            if (typeof role === "string") {
                role = state.roles.filter((r) => r.id === role)[0]
            }
            if (!role) {
                throw "!role"
            }
            if (!direction) {
                return state.roles
            } else if (direction === "up") {
                return state.roles.filter((r) => r.level > role.level)
            } else if (direction === "down") {
                return state.roles.filter((r) => r.level < role.level)
            } else if (direction === "down-inclusive") {
                return state.roles.filter((r) => r.level <= role.level)
            }
        },
        getDepartments: (state, getters) => (direction) => {
            return getters.getSubDepartments("root")
        },
        getSubDepartments: (state, getters) => async (id) => {
            if (!id) {
                return []
            }
            const department = state.departmentGraph.getNode(`dep-${id}`)
            if (!department) {
                return []
            }
            let departments = []
            await state.departmentGraph.traverseDown(`dep-${id}`, (node, id, path, level) => {
                if (level > 0 && node.type === "department") {
                    departments.push({ ...node, isBusiness: node.is_business === "1" })
                }
            })
            return departments
        },
        getSubdepartmentUserCount: (state, getters) => async (id) => {
            if (!id) {
                return 0
            }
            const department = state.departmentGraph.getNode(`dep-${id}`)
            if (!department) {
                return 0
            }
            let count = 0
            await state.departmentGraph.traverseDown(`dep-${id}`, (node, id, path, level) => {
                if (level > 0 && node.type === "department") {
                    count += node.getChildren("user").length
                }
            })
            return count
        },

        getDepartmentDistrict: (state, getters) => (number) => {
            if (!number) {
                return ""
            }
            return state.departmentNumberDistrict[number.substr(0, 3)]
        },
        getParentDepartments: (state, getters) => async (id) => {
            if (!id) {
                return []
            }
            const department = state.departmentGraph.getNode(`dep-${id}`)
            if (!department) {
                return []
            }
            let departments = []
            await state.departmentGraph.traverseUp(`dep-${id}`, (node, id, path, level) => {
                if (level > 0 && node.type === "department") {
                    departments.push(node)
                }
            })
            return departments
        },
        getTransferDepManagerIds: (state) => {
            let ids = []
            state.transferDepManagers.forEach((dep) => {
                ids = [...ids, ...dep.user_ids.split(",")]
            })
            return ids
        },
        // return []string userids
        // new get approvers logic, TODO: apply to visit and leave
        getApprovers: (state, getters) => async (id) => {
            // console.log("getApprovers start=====", id)
            const user = getters.getUser(id, { isNumber: false, fallbackAsMe: false })
            if (_.get(user, "UserApprovers", []).length) {
                return user.UserApprovers.map((o) => o.approver_user_id)
            }
            const userDepartmentId = _.get(user, "UserDepartments[0].department_id")
            if (!userDepartmentId) {
                console.error("getApprovers: !user department")
                return []
            }
            const rolesUp = _.reverse(getters.getRoles("up", user.role_id))
            if (!rolesUp.length) {
                console.error("no more roles up")
                return []
            }
            const department = state.departmentGraph.getNode(`dep-${userDepartmentId}`)

            const parentDepartments = await getters.getParentDepartments(userDepartmentId)
            const departments = [department, ...parentDepartments]
            // console.log({ user, rolesUp: rolesUp.map((o) => o.id), departments })
            let approvers = []
            for (const d of departments) {
                for (const nextRole of rolesUp) {
                    const users = d.getChildren("user").filter((u) => u.role_id === nextRole.id)
                    if (users.length) {
                        // console.log("xxx", d, nextRole)
                        approvers = users
                        break
                    } else {
                        // console.log("no fit", d.department_id, nextRole.id)
                    }
                }
                if (approvers.length) {
                    break
                }
            }

            // console.log(
            //     "getApprovers end=====",
            //     id,
            //     approvers.map((o) => o.chinese_name)
            // )
            return approvers.map((o) => o.id)
        },
        getI18nKey: (state) => (id) => {
            if (!id) return
            let i18nKeys = [...state.i18nNormal, ...state.outsideType, ...state.leaveType]
            let key = i18nKeys.find((key) => key.id == id)
            return (key && key.name) || id
        },
        getIsAdmin: (state, getters) => {
            return getters.getUserRole().isAdmin
        },
        getUserNumbersToNames:
            (state, getters) =>
            (numbers, separator = " ") => {
                if (!numbers) {
                    return ""
                }
                if (typeof numbers === "string") {
                    return numbers
                        .split(",")
                        .map((number) => getters.getUser(number, { isNumber: true, fallbackAsMe: false }).chinese_name)
                        .join(separator)
                }
            },
        getCustomerTypeNameI18nKey: (state) => (customerType) => {
            return state.customerTypes.find((type) => type.value == customerType)?.i18nKey || ""
        },
        getInterviewCountUsers: (state) => {
            return state.interviewGroups.map((group) => group.userId)
        },
        getInterviewCountApprovers: (state) => {
            return state.interviewGroups.map((group) => state.userApprover[group.userId]).flat()
        },
    },
    modules: {
        doc,
        userStore: user,
        meeting,
        calendar,
        tgaDsc,
        ezSales,
        permission,
    },

    strict: debug,
    plugins: debug ? [] : [],
})
