import Vue from "vue"
import _ from "lodash"
import axios from "axios"
import config from "@/config"
import uuidv4 from "uuid/v4"
import permissions from "../../domain/permission"
// const api = config.fileApi;
import api from "@/Api/Api"
import moment from "moment"
import { type } from "jquery"

export default {
    namespaced: true,
    state: {
        tree: {
            // [node]:{name,folders,files}
        },
        tags: [],
        path: ["root"],
        selectedFiles: [],
        selectedFolders: [],
        browsingMode: "file", // file, approvingFile, taggedFile, searchFile
        approvingFiles: [],
        taggedFiles: [],
        searchFiles: [],
        selectedTag: {},
        cachedSearchQuery: {},
        documentTree: [],
        workingPath: [{ id: "root", name: "文件" }],
        opennessOptions: [
            { text: "全公司", value: "company" },
            { text: "指定部門", value: "customDepartments" },
            { text: "指定員工", value: "customUsers" },
            { text: "職位層級含以上", value: "roleLevelAbove" },
        ],
        approvingDocuments: [],
    },
    getters: {
        getNode:
            (state) =>
            (path = state.path, mode = false) => {
                if (mode === "approvingFile") {
                    return {
                        files: state.approvingFiles,
                    }
                }
                if (mode === "taggedFile") {
                    return {
                        files: state.taggedFiles,
                    }
                }
                if (mode === "searchFile") {
                    return {
                        files: state.searchFiles,
                    }
                }
                return _.get(state.tree, path.join(".folders."), {})
            },
        getNodeFiles:
            (state, getters) =>
            (path = state.path) => {
                const node = getters.getNode(path)
                const files = node.files || {}
                return Object.values(files)
            },
        hasFilePermission: (state, getters, rootState, rootGetters) => (file, browsingMode) => {
            const user = rootGetters.getUser(rootState.user.userID)

            if (
                user.role_id === "admin" ||
                user.id === file.author ||
                (browsingMode === "approvingFile" &&
                    rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_APPROVE)) ||
                (file.type === "folder" && rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_UPDATE)) ||
                (file.type === "folder" && rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_TYPE_MANAGE)) ||
                rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_LIST, { effect: "allow" })
            ) {
                return true
            }

            const departmentId = _.get(user, "departments[0].department.department_id")
            if (file.openness === "company") {
                return true
            } else if (file.openness === "customDepartments") {
                return file.openness_id.split(",").includes(departmentId)
            } else if (file.openness === "customUsers") {
                return file.openness_id.split(",").includes(user.id)
            } else if (file.openness === "roleLevelAbove") {
                return (
                    rootGetters.getUserRole().level >=
                    _.get(
                        rootState.roles.filter((o) => o.id === file.openness_id),
                        "0.level",
                        0
                    )
                )
            }
            return false
        },
        // getterFolders: (state) => (folderId) => {
        //     // root
        //     console.log("getterFolders", folderId)
        //     if (folderId === undefined) {
        //         return state.documentTree
        //             .filter((document) => document.type === "dir")
        //             .map((document) => {
        //                 return {
        //                     name: document.DocumentDirectories[0].name,
        //                     id: document.id,
        //                 }
        //             })
        //     }
        // },
        getterDocumentTree: (state, getters, rootState, rootGetters) => {
            const documentNumbers = (documents) => {
                let number = 0
                documents.forEach((document) => {
                    if (document.type === "dir") {
                        number += document.number
                    } else if (document.type === "doc") {
                        number += 1
                    }
                })
                return number
            }
            const hasFilePermission = (file) => {
                const user = rootGetters.getUser(rootState.user.userID)
                if (
                    user.role_id === "admin" ||
                    user.id === file.userId ||
                    (file.type === "dir" && rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_UPDATE)) ||
                    (file.type === "dir" && rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_TYPE_MANAGE)) ||
                    rootGetters.hasPermission(permissions.PERMISSION_DOCUMENT_LIST, { effect: "allow" })
                ) {
                    return true
                }

                const departmentId = _.get(user, "departments[0].department.department_id")
                if (file.openness === "company") {
                    return true
                } else if (file.openness === "customDepartments") {
                    return file.opennessId.split(",").includes(departmentId)
                } else if (file.openness === "customUsers") {
                    return file.opennessId.split(",").includes(user.id)
                } else if (file.openness === "roleLevelAbove") {
                    return (
                        rootGetters.getUserRole().level >=
                        _.get(
                            rootState.roles.filter((o) => o.id === file.opennessId),
                            "0.level",
                            0
                        )
                    )
                }
                return false
            }
            const normalization = (documents, path = [{ id: "root", name: "文件" }]) => {
                if (documents === undefined || documents === null) {
                    console.log("error: no folders")
                    return []
                }
                return documents
                    .map((document) => {
                        if (document.type === "dir") {
                            const {
                                name = "",
                                openness = "",
                                opennessId = "",
                                id,
                                confidential,
                            } = document.DocumentDirectories[0]
                            const documents = normalization(document.folders, [...path, { id, name }]).filter(
                                (document) => hasFilePermission(document)
                            )
                            const number = documentNumbers(documents)
                            return {
                                id,
                                type: document.type,
                                name,
                                confidential,
                                openness,
                                opennessId,
                                documents,
                                number,
                                folders: documents.filter((document) => document.type == "dir"),
                                path: [...path, { id, name }],
                                parentId: path[path.length - 1].id,
                            }
                        } else if (document.type === "doc") {
                            const id = document.Documents[0].documentId
                            const { type = "" } = document
                            return {
                                id,
                                type,
                                ...document.Documents[0],
                                path: [...path, { id, name: document?.Documents[0]?.name || "" }],
                                parentId: path[path.length - 1].id,
                            }
                        }
                        return document
                    })
                    .filter((document) => hasFilePermission(document))
            }
            let tree = _.clone(state.documentTree)
            tree = normalization(tree)
            return tree
        },
        getterFolderList: (state, getters) => {
            const arrayToObject = (documents) => {
                const folders = documents.filter((document) => document.type == "dir")
                let obj = {}
                if (folders.length == 0) {
                    return {}
                }
                folders.forEach((document) => {
                    Object.assign(obj, { [document.id]: document, ...arrayToObject(document.documents) })
                })
                return obj
            }
            let obj = {
                root: {
                    id: "root",
                    name: "文件",
                    documents: getters.getterDocumentTree,
                    folders: getters.getterDocumentTree.filter((document) => document.type == "dir"),
                    path: [{ id: "root", name: "文件" }],
                },
            }
            Object.assign(obj, arrayToObject(getters.getterDocumentTree))
            return obj
        },
        getterFolderById: (state, getters) => (id) => {
            return getters.getterFolderList[id]
        },
        getterApprovingDocuments: (state) => {
            return state.approvingDocuments.map((document) => ({
                ...document,
                type: "doc",
            }))
        },
        getterWorkingDirectory: (state, getters, rootState, rootGetters) => {
            return getters.getterFolderList[state.workingPath[state.workingPath.length - 1].id]
        },
        getterWorkingDirectoryId: (state, getters, rootState, rootGetters) => {
            return state.workingPath[state.workingPath.length - 1].id
        },
        getterDocumentSelectTree: (state, getters) => {
            const documentsToOptions = (documents) => {
                if (documents === undefined || documents === null) {
                    console.log("error: no folders")
                    return []
                }
                return documents
                    .map((document) => {
                        const { id, name, type, documents } = document
                        let children = []
                        if (documents) {
                            children = documentsToOptions(documents)
                        }
                        return { id, label: name, type, ...(children.length && { children }) }
                    })
                    .filter((document) => document.type === "dir")
                    .sort((a, b) => {
                        return (a.label || "")?.localeCompare(b.label || "", "zh-TW")
                    })
            }
            return [
                {
                    id: "root",
                    label: "根目錄",
                    children: documentsToOptions(getters.getterDocumentTree),
                },
            ]
        },
    },
    mutations: {
        updatePath(state, payload) {
            state.path = payload
        },
        replaceSelectedFiles(state, payload) {
            state.selectedFiles = payload
        },
        replaceSelectedFolders(state, payload) {
            state.selectedFolders = payload
        },
        updateTree(state, payload) {
            Vue.set(state, "tree", payload.node)
        },
        updateTags(state, payload) {
            Vue.set(state, "tags", payload.tags)
        },
        updateBrowsingMode(state, payload) {
            state.browsingMode = payload
        },
        updateApprovingFiles(state, payload) {
            Vue.set(state, "approvingFiles", payload)
        },
        updateSearchFiles(state, payload) {
            Vue.set(state, "searchFiles", payload)
        },
        cacheSearchQuery(state, payload) {
            Vue.set(state, "cachedSearchQuery", payload)
        },
        updateTaggedFiles(state, payload) {
            Vue.set(state, "taggedFiles", payload)
        },
        selectTag(state, payload) {
            Vue.set(state, "selectedTag", payload)
        },
        SET_DOCUMENT_TREE(state, payload) {
            state.documentTree = payload
        },
        SET_WORKING_PATH(state, payload) {
            state.workingPath = payload
        },
        SET_DOCUMENT_TREE_APPROVING(state, payload) {
            state.approvingDocuments = payload
        },
        SET_TAGS(state, payload) {
            state.tags = payload
        },
    },
    actions: {
        actionSetWorkingPath({ commit }, fullPath) {
            commit("SET_WORKING_PATH", fullPath)
        },
        actionSetWorkingPathId({ getters, dispatch }, folderId) {
            let folder = getters.getterFolderById(folderId)
            if (folder.path) {
                dispatch("actionSetWorkingPath", folder.path)
            }
        },
        async updateFileTags(context, payload) {
            const { tags, docId } = payload
            let fileId
            if (!docId) {
                const file = context.state.selectedFiles[0]
                fileId = file.id
                context.commit("replaceSelectedFiles", [
                    {
                        ...file,
                        tags,
                    },
                ])
            } else {
                fileId = docId
            }
            await axios({
                method: "put",
                url: config.fileApi + "/document/tag",
                data: {
                    tags: tags.map((t) => t.id),
                    docId: fileId,

                    path: context.state.path.join("/"),
                    userId: context.rootState.user.userID,
                    departmentId: context.rootState.departmentId,
                },
            })
        },
        async replaceSelectedFiles(context, payload) {
            context.commit("replaceSelectedFiles", payload)
            if (payload.length === 1) {
                context.dispatch("fetchDocument", payload[0])
            }
        },
        async replaceSelectedFolders(context, payload) {
            context.commit("replaceSelectedFolders", payload)
        },
        async updatePath(context, payload) {
            context.commit("updateBrowsingMode", "file")
            if (payload.path.join(".") === context.state.path.join(".")) {
                return
            }
            context.commit("replaceSelectedFiles", [])
            context.commit("updatePath", payload.path)
        },
        async upsertDirectory(context, payload) {
            await axios({
                method: "post",
                url: config.fileApi + "/directory",
                data: {
                    id: payload.data.id,
                    name: payload.data.name,
                    openness: payload.data.openness,
                    openness_id: payload.data.openness_id,

                    path: context.state.path.join("/"),
                    userId: context.rootState.user.userID,
                    departmentId: context.rootState.departmentId,
                },
            })
            context.commit("replaceSelectedFolders", [])
            context.dispatch("fetchDocumentTree")
        },
        async upsertDocument(context, payload) {
            let formData = new FormData()
            const data = payload.data
            if (!data.id) {
                formData.append("create", "true")
                formData.append("id", uuidv4().replace(/-/g, ""))
            } else {
                formData.append("id", data.id)
            }
            formData.append("name", data.name)
            formData.append("content", data.content)
            formData.append("openness", data.openness)
            formData.append("openness_id", data.openness_id)
            formData.append("path", context.state.path.join("/"))
            formData.append("userId", context.rootState.user.userID)
            const user = context.rootGetters.getUser(context.rootState.user.userID)
            formData.append("departmentId", _.get(user, "departments[0].department.department_id"))

            if (!data.id && !_.get(data, "files.length")) {
                throw "請附檔案"
            }
            for (var i = 0; i < data.files?.length; i++) {
                let file = data.files[i]
                formData.append(`docs[]`, file)
            }

            const res = await axios({
                method: "post",
                url: config.fileApi + "/document",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            }).then((r) => r.data)
            if (_.get(data, "tags.length")) {
                context.dispatch("updateFileTags", {
                    tags: data.tags,
                    docId: res.docId,
                })
            }
            context.commit("replaceSelectedFiles", [])
            context.dispatch("fetchDocumentTree")
        },
        async updateTag(context, payload) {
            await axios({
                method: "post",
                url: config.fileApi + "/tag",
                data: {
                    id: payload.id,
                    name: payload.data.name,
                    color: payload.data.color,

                    path: context.state.path.join("/"),
                    userId: context.rootState.user.userID,
                    departmentId: context.rootState.departmentId,
                },
            })
        },
        async createTag(context, payload) {
            await axios({
                method: "post",
                url: config.fileApi + "/tag",
                data: {
                    name: payload.data.name,
                    color: payload.data.color,

                    path: context.state.path.join("/"),
                    userId: context.rootState.user.userID,
                    departmentId: context.rootState.departmentId,
                },
            })
        },
        async fetchDocument(context, payload) {
            const doc = await axios({
                method: "get",
                url: config.fileApi + "/document/doc/" + payload.id,
                params: {
                    userId: context.rootState.user.userID,
                },
            }).then((r) => r.data)
            const params = {
                id: payload.id,
            }
            const files = await api.get("/file", { params }).then((res) => {
                return res.data
            })
            context.commit("replaceSelectedFiles", [
                {
                    ...payload,
                    tags: doc.meta.tags,
                    files: files,
                },
            ])
        },
        async fetchDocumentTree(context, payload) {
            const treeCountMaker = (node) => {
                let number = 0

                let fileArray = Object.entries(node.files)
                fileArray = fileArray.filter(([key, value]) => context.getters.hasFilePermission(value))
                number = fileArray.length
                node.files = Object.fromEntries(fileArray)

                let folderArray = Object.entries(node.folders)
                folderArray.forEach(([key, folder]) => {
                    number += treeCountMaker(folder)
                })
                // 隱藏沒檔案的資料夾 暫時取消
                // folderArray = folderArray.filter(([key, folder]) => folder.number)
                node.folders = Object.fromEntries(folderArray)
                node.number = number
                return number
            }
            const node = await axios({
                method: "get",
                url: config.fileApi + "/document/tree",
                params: {
                    // path: context.state.path.join('/'),
                    userId: context.rootState.user.userID,
                },
            })
                .then((r) => r.data.data)
                .catch((e) => {
                    alert(e)
                })
            treeCountMaker(node.root)
            context.commit("updateTree", {
                node,
                // path: ['root']
            })
        },
        async fetchTags(context, payload) {
            const tags = await axios({
                method: "get",
                url: config.fileApi + "/tag",
                params: {
                    userId: context.rootState.user.userID,
                },
            }).then((r) => r.data.data)
            context.commit("updateTags", {
                tags,
            })
        },
        async fetchApprovingDocument(context, payload) {
            const docs = await axios({
                method: "get",
                url: config.fileApi + "/document/approving",
                params: {
                    userId: context.rootState.user.userID,
                },
            }).then((r) => r.data.data)
            context.commit("updateApprovingFiles", docs)
        },
        async fetchTaggedDocument(context, payload) {
            const docs = await axios({
                method: "get",
                url: config.fileApi + "/document/tagged",
                params: {
                    userId: context.rootState.user.userID,
                    tagId: payload,
                },
            }).then((r) => r.data.data)
            context.commit("updateTaggedFiles", docs)
        },
        async updateDocumentApprovalStatus(context, payload) {
            const status = payload
            await axios({
                method: "put",
                url: config.fileApi + "/document/approving",
                data: {
                    docId: context.state.selectedFiles[0].id,
                    status,
                    userId: context.rootState.user.userID,
                },
            })

            context.commit("replaceSelectedFiles", [])
            context.dispatch("fetchApprovingDocument")
            context.dispatch("fetchDocumentTree")
        },
        async search(context, payload) {
            let query = false
            if (payload) {
                context.commit("cacheSearchQuery", payload)
                query = payload
            } else {
                query = context.state.cachedSearchQuery
            }
            if (query.data.startTime && !query.data.endTime) {
                query.data.endTime = moment(query.data.startTime).add(1, "days").format("yyyy-MM-DD")
            }
            const docs = await axios({
                method: "get",
                url: config.fileApi + "/document/search",
                params: {
                    ...query.data,
                    userId: context.rootState.user.userID,
                },
            }).then((r) => r.data.data)
            context.commit("updateSearchFiles", docs)
        },
        reloadBrowsingDocuments(context, payload) {
            if (context.state.browsingMode === "file") {
                context.dispatch("fetchDocumentTree")
            } else if (context.state.browsingMode === "approvingFile") {
                context.dispatch("fetchApprovingDocument")
            } else if (context.state.browsingMode === "taggedFile") {
                context.dispatch("fetchTaggedDocument")
            } else if (context.state.browsingMode === "searchFile") {
                context.dispatch("search")
            }
        },
        async deleteFile(context, payload) {
            await axios({
                method: "delete",
                url: config.fileApi + "/document/doc/" + payload.data,
            }).then((r) => r.data)
            context.commit("replaceSelectedFiles", [])
            context.dispatch("reloadBrowsingDocuments")
        },
        async deleteFolder(context, payload) {
            await axios({
                method: "delete",
                url: config.fileApi + "/directory/" + payload.data,
            }).then((r) => r.data)
            context.commit("replaceSelectedFolders", [])
            context.dispatch("reloadBrowsingDocuments")
        },
        async deleteTag(context, payload) {
            await axios({
                method: "delete",
                url: config.fileApi + "/tag/" + payload.id,
            }).then((r) => r.data)
        },
        async populate(context, payload) {
            const { nodeIds, target } = payload
            await axios({
                method: "post",
                url: config.fileApi + "/document/populate",
                data: {
                    nodeIds,
                    target,
                },
            }).then((r) => r.data)
            context.dispatch("reloadBrowsingDocuments")
        },
        actionGetDocumentTree({ commit }) {
            return api.getDocumentTree().then((res) => {
                commit("SET_DOCUMENT_TREE", res.data)
            })
        },
        actionGetDocumentApproving({ commit }) {
            commit("SET_DOCUMENT_TREE_APPROVING", [])
            return api.getDocumentApproving().then((res) => {
                if (res.status == 0) {
                    commit("SET_DOCUMENT_TREE_APPROVING", res.data)
                }
            })
        },
        actionGetTags({ commit }) {
            return api.getTags().then((res) => {
                commit("SET_TAGS", res.data)
            })
        },
    },
}
