<template>
    <!-- 今日重要事項 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ $t("page.home.importantMatters.todayImportantMatters") }}</h2>
                </div>
                <div class="modal-body">
                    <ol>
                        <template v-for="(event, index) in showEvents">
                            <li v-if="event.type === 'BULLETIN'" :key="index">
                                {{ $t("page.home.importantMatters.bulletin", { title: event.content.title }) }}
                                <a @click="() => handleBulletinClick(event.content)">{{
                                    $t("page.home.importantMatters.seeDetail")
                                }}</a>
                            </li>
                            <li v-else-if="event.type === 'ACCOUNT'" :key="index">
                                {{
                                    $t("page.home.importantMatters.account", {
                                        name: event.content.customerShortName,
                                        amount: event.content.amount,
                                        currency: event.content.currency,
                                    })
                                }}
                                <router-link
                                    :to="{
                                        name: 'graveAffair0103event',
                                        params: { id: event.content.id },
                                    }"
                                    data-dismiss="modal"
                                >
                                    {{ $t("page.home.importantMatters.report") }}</router-link
                                >
                            </li>
                            <li v-else-if="event.type === 'ORDER'" :key="index">
                                [{{ $t("page.graveAffair0101.qualityAbnormity") }}-{{
                                    $t(getI18nKey(event.content.title))
                                }}] -
                                {{ event.content.content }}
                                ，
                                <router-link
                                    :to="{
                                        name: getRouterPath(event.content.title),
                                        params: { id: event.content.id },
                                    }"
                                    :data-dismiss="modalName"
                                >
                                    {{ $t("page.home.importantMatters.seeDetail") }}</router-link
                                >
                            </li>
                            <li v-else-if="event.type === 'FLUCTUATION'" :key="index">
                                [{{ $t("page.graveAffair0101.faild35") }}] -
                                <span :class="event.content.color">{{ event.content.content2 }}</span
                                >&nbsp;{{ event.content.content1 }}
                                ，
                                <router-link
                                    :to="`/graveAffair0201/${event.content.dbSerialNumber}/${event.content.materialSerialNumber}`"
                                    target="_blank"
                                >
                                    {{ $t("page.home.importantMatters.seeDetail") }}</router-link
                                >
                            </li>
                            <li v-else-if="event.type === 'STOCK'" :key="index">
                                [{{ $t("page.graveAffair0101.safeStock") }}] {{ event.content.content }}，<router-link
                                    :to="{
                                        name: 'graveAffair0106event',
                                        params: { id: event.content.id },
                                    }"
                                    target="_blank"
                                >
                                    {{ $t("page.home.importantMatters.seeDetail") }}</router-link
                                >
                            </li>
                            <li v-else-if="event.type === 'JOB'" :key="index">
                                {{ $t("page.home.importantMatters.job", { title: event.content.title }) }}
                                <a @click="() => openJobContentModal(event.content)" :data-dismiss="modalName">{{
                                    $t("page.home.importantMatters.report")
                                }}</a>
                            </li>
                            <li v-else-if="event.type === 'PROJECT'" :key="index">
                                {{ $t("page.home.importantMatters.project", { title: event.content.name }) }}
                                <router-link :to="`/project0103/${event.content.taskId}`" target="_blank">
                                    {{ $t("page.home.importantMatters.report") }}</router-link
                                >
                            </li>
                            <li v-else-if="event.type === 'MEETING'" :key="index">
                                {{
                                    $t("page.home.importantMatters.meeting", {
                                        time: moment(event.content.startAt).format("HH:mm"),
                                        topic: event.content.topic,
                                    })
                                }}
                                <a @click="() => showMeetingModal(event.content)" :data-dismiss="modalName">{{
                                    $t("page.home.importantMatters.seeDetail")
                                }}</a>
                            </li>
                        </template>
                    </ol>
                    <div class="flex justify-end">
                        <a @click="handleSetEventOptions">{{ $t("page.home.importantMatters.viewEventsSetting") }}</a>
                    </div>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="handleDontShowAgainToday">
                        {{ $t("page.home.importantMatters.dontShowAgainToday") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
        <today-event-options-modal />
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import moment from "moment"
import TodayEventOptionsModal from "./TodayEventOptions.vue"
export default {
    components: {
        TodayEventOptionsModal,
    },
    data() {
        return {
            modalName: "showTodayEvents",
            callback: () => {},
            events: [],
        }
    },
    computed: {
        ...mapState(["userId", "bulletinImportanceMap"]),
        ...mapState("userStore", ["userSettings"]),
        ...mapGetters(["hasPermission", "getI18nKey"]),
        showEvents() {
            return this.events.filter((event) => this.userSettings.importantMatters.includes(event.type))
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.events = e.events
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        moment: moment,
        openFile(filePath) {
            window.open(api.imageUrl + filePath)
        },
        getRouterPath(title) {
            switch (title) {
                case "生產異常":
                    return "graveAffair0104event1"
                case "進料檢驗":
                case "進料檢驗異常":
                    return "graveAffair0104event3"
                case "進貨異常":
                    return "graveAffair0104event4"
                case "銷售退貨":
                    return "graveAffair0104event2"
                default:
                    return undefined
            }
        },
        handleTakeDown() {
            const params = {
                approvalStatus: "已下架",
            }
            api.putBulletin(this.bulletin.bulletinId, params).then(() => {
                this.callback()
                this.close()
            })
        },
        updateStatus(status) {
            const params = {
                approvalStatus: status,
                approvingUser: this.userId,
            }
            api.putBulletin(this.bulletin.bulletinId, params).then(() => {
                this.callback()
                this.close()
            })
        },
        handleBulletinClick(bulletin) {
            const prop = {
                bulletin: bulletin,
            }
            this.$modal.show("showBulletin", prop)
        },
        openJobContentModal(job) {
            this.$modal.show("jobContent", {
                job: _.cloneDeep(job),
                jobCategoryList: this.jobcategory_list,
                callback: this.callback,
            })
        },
        showMeetingModal(meeting) {
            const prop = {
                meeting,
                callback: this.callback,
            }
            this.$modal.show("showMeeting", prop)
        },
        handleSetEventOptions() {
            const prop = {
                callback: this.callback,
            }
            this.$modal.show("todayEventOptions", prop)
        },
        handleDontShowAgainToday() {
            const todayEventsHideUntil = moment().endOf("day").valueOf()
            window.localStorage.setItem("todayEventsHideUntil", todayEventsHideUntil)
        },
    },
}
</script>

<style scoped>
a.delete {
    position: absolute;
    right: 1em;
    font-size: 1.5em;
    font-weight: bold;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    color: #fff;
    padding: 8px 8px;
    background-color: #0078d7;
    border-radius: 4px;
}
a.delete:hover {
    text-decoration: none;
    background-color: #2badaf;
}
</style>
