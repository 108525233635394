<template>
    <!-- 顯示會議內容 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ $t("page.conferenceList0101.meetingTopic") }} - {{ meeting.topic }}[{{ meeting.importance }}]</h2>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal" role="form">
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingTime") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ meeting.startAt | formatDateUTC }}至{{ meeting.endAt | formatDateUTC }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingLocation") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ getRoomNames(meeting.meetingPlaceId) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingCategory") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ categoryIdToName(meeting.meetingCategory) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingChairman") }}</label>
                            <div class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ userIdsToNames(meeting.chairman) }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">
                                {{ $t("misc.applicantPeople") }}
                            </label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ userIdsToNames(meeting.userId) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.recordPeople") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ userIdsToNames(meeting.record_people) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.participants") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ userIdsToNames(meeting.participants) }}
                            </p>
                        </div>
                        <div v-if="meeting.isReport === '已回報'" class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.viewPoeple") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ userIdsToNames(meeting.viewer) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingContent") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ meeting.content }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("misc.remark") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left" style="overflow-wrap: break-word">
                                {{ meeting.remarks }}
                            </p>
                        </div>
                        <div v-if="meeting.isReport === '已回報'" class="form-group">
                            <label class="col-xs-5 col-sm-2 mt-2 text-right">{{ $t("page.conferenceList0101.meetingRecord") }}</label>
                            <p class="col-xs-7 col-sm-10 mt-2 text-left">
                                {{ meeting.record_meeting }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label v-if="meeting.isReport === '未回報'" class="col-xs-5 col-sm-2 mt-2 text-right">
                                {{ $t("page.conferenceList0101.meetingDownload") }}
                            </label>
                            <label v-else class="col-xs-5 col-sm-2 mt-2 text-right">
                                {{ $t("page.conferenceList0101.meetingFile") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="btn btn-success mr-1" v-for="file in files" :key="file.fileId" @click="openFile(file.path)">
                                    {{ file.fileName }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="handleEdit"
                        v-if="getIsAdmin || (meeting.userId === userId && meeting.isReport === '未回報')"
                    >
                        {{ $t("misc.edit") }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="handleEditRecord" v-if="getIsAdmin && meeting.isReport === '已回報'">
                        {{ $t("page.conferenceList0101.editMeetingRecord") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                        @click="handleDelete"
                        v-if="getIsAdmin || (meeting.userId === userId && meeting.isReport === '未回報')"
                    >
                        {{ $t("misc.delete") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import api from "@/Api/Api"
import { userIdsToNames } from "@/lib/oa"
export default {
    components: {},
    data() {
        return {
            modalName: "showMeeting",
            meeting: {},
            files: [],
            callback: () => {},
        }
    },
    computed: {
        ...mapGetters(["getUser", "getIsAdmin"]),
        ...mapGetters("meeting", ["getRoomNames", "categoryIdToName"]),
        ...mapState(["userId"]),
        ...mapState("meeting", ["rooms"]),
    },
    methods: {
        ...mapActions("meeting", ["actionGetAreas"]),
        beforeOpen(e = {}) {
            this.meeting = e.meeting || {}
            this.callback = e.callback || this.callback
            api.get("/file?" + "id=" + this.meeting.meetingId).then((res) => {
                this.files = res.data
            })
            if (this.rooms.length === 0) {
                this.actionGetAreas()
            }
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        getRecords(id) {
            api.get("/report?id=" + id).then((res) => {
                this.theRecords = res.data
            })
        },
        openFile(file) {
            window.open(api.imageUrl + file)
        },
        userIdsToNames: userIdsToNames,
        handleDelete() {
            if (confirm(this.$t("alert.deleteDoubleCheck"))) {
                api.deleteMeeting(this.meeting.meetingId).then((res) => {
                    this.callback()
                })
            }
        },
        handleEdit() {
            const prop = {
                meeting: this.meeting,
                callback: this.callback,
            }
            this.$modal.show("editMeeting", prop)
            this.close()
        },
        handleEditRecord() {
            const prop = {
                meeting: this.meeting,
                callback: this.callback,
            }
            this.$modal.show("editMeetingRecord", prop)
            this.close()
        },
    },
}
</script>

<style></style>
