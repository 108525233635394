<template>
    <nav
        id="menu"
        :class="{
            hidden: !getUserToken(),
        }"
    >
        <div id="user_name">
            <span class="glyphicon glyphicon-user" aria-hidden="true"></span>
            <span id="name">{{ user.chinese_name }}</span>
        </div>
        <ul>
            <li class="function-01">
                <a>
                    {{ $t("sideMenu.attendance.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/attendance0101">
                            {{ $t("sideMenu.attendance.attendance0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/attendance0201">
                            {{ $t("sideMenu.attendance.attendance0201") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/attendance0301">
                            {{ $t("sideMenu.attendance.attendance0301") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/attendance0701">
                            {{ $t("sideMenu.attendance.attendance0701") }}
                        </router-link>
                    </li>
                    <li v-if="isSalesAttendanceViewer">
                        <router-link to="/attendance0601">
                            {{ $t("sideMenu.attendance.attendance0601") }}
                        </router-link>
                    </li>
                    <li v-if="isInterviewCountViewer">
                        <router-link to="/interviewCount0101">
                            {{ $t("page.interviewCount.interviewCount") }}
                        </router-link>
                    </li>
                    <li v-if="getIsAdmin">
                        <router-link to="/attendance0401">
                            {{ $t("sideMenu.attendance.attendance0401") }}
                        </router-link>
                    </li>
                    <li v-if="isAttendance0501Viewer">
                        <router-link to="/attendance0501">
                            {{ $t("sideMenu.attendance.attendance0500") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-02">
                <a>
                    {{ $t("sideMenu.graveAffair.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/graveAffair0101">
                            {{ $t("sideMenu.graveAffair.graveAffair0101") }}
                        </router-link>
                    </li>
                    <li v-if="$test()">
                        <router-link
                            to="/graveAffair0501"
                            :class="{
                                defaultHide: true,
                                show: $store.getters.hasPermission(PERMISSION_FINANCE_LIST),
                            }"
                        >
                            {{ $t("sideMenu.graveAffair.graveAffair0501") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-03">
                <a>
                    {{ $t("sideMenu.interview.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/interview0101">
                            {{ $t("sideMenu.interview.interview0101") }}
                        </router-link>
                    </li>
                    <li v-if="$test()">
                        <router-link
                            to="/interview0301"
                            :class="{
                                defaultHide: true,
                                show: $store.getters.hasPermission(PERMISSION_INTERVIEW0301_ACCESS),
                            }"
                        >
                            {{ $t("sideMenu.interview.interview0301") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-07">
                <a>
                    {{ $t("sideMenu.joblist.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/joblist0101">
                            {{ $t("sideMenu.joblist.joblist0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/joblist0102?customerType=1">
                            {{ $t("page.joblist0101.customerType") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/joblist0201">
                            {{ $t("sideMenu.joblist.joblist0201") }}
                        </router-link>
                    </li>
                    <li
                        :class="{
                            defaultHide: true,
                            show: $store.getters.hasPermission(PERMISSION_JOBLIST_TYPE_MANAGE),
                        }"
                    >
                        <router-link to="/joblist0301">
                            {{ $t("sideMenu.joblist.joblist0301") }}
                        </router-link>
                    </li>
                    <li
                        :class="{
                            defaultHide: true,
                            show: $store.getters.hasPermission(PERMISSION_JOBLIST_PROGRESS_MANAGE),
                        }"
                    >
                        <router-link to="/joblist0401">
                            {{ $t("sideMenu.joblist.joblist0401") }}
                        </router-link>
                    </li>
                    <!-- <li id="07S05">
              <router-link to="/joblist0501">後台列表管理</router-link>
            </li> -->
                </ul>
            </li>
            <li class="function-14">
                <a>
                    {{ $t("sideMenu.orders.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/orders0101">
                            {{ $t("sideMenu.orders.orders0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/orders0102">
                            {{ $t("page.orders0101.orderError") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li
                class="function-16"
                :class="{
                    defaultHide: true,
                    show:
                        $store.getters.hasPermission(PERMISSION_INVENTORY_LIST) ||
                        $store.getters.hasPermission(PERMISSION_INVENTORY_LIST_EXCEPT_MONEY),
                }"
            >
                <a>
                    {{ $t("sideMenu.material.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/material0101">
                            {{ $t("sideMenu.material.material0101") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="purchase">
                <a>
                    {{ $t("sideMenu.purchase.title") }}
                </a>
                <ul>
                    <li v-if="isGraveViewer">
                        <router-link to="/graveAffair0201/NT1">
                            {{ $t("sideMenu.purchase.graveAffair0201") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/graveAffair0107">
                            {{ $t("page.graveAffair0101.materialsAbnormity") }}
                        </router-link>
                    </li>
                    <li v-if="isPurchasingViewer">
                        <router-link to="/purchasingEstimates0201">
                            {{ $t("sideMenu.purchase.purchasingEstimates0201") }}
                        </router-link>
                    </li>
                    <!-- <li v-if="getIsAdmin">
                        <router-link to="/supplierPlatform0101"> 供應商平台 </router-link>
                    </li> -->
                </ul>
            </li>
            <li class="function-06">
                <a>
                    {{ $t("sideMenu.customer.title") }}
                </a>
                <ul>
                    <li v-if="$store.getters.hasPermission(PERMISSION_COMPANY_SALES_LIST)">
                        <router-link to="/companySales0101">
                            {{ $t("sideMenu.companySales.title") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/weeklyReview0101">
                            {{ $t("sideMenu.weeklyReview.title") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/transaction0101">
                            {{ $t("sideMenu.transaction.title") }}
                        </router-link>
                    </li>
                    <li v-if="isIAViewer">
                        <router-link to="/inquiryAction0101">
                            {{ $t("sideMenu.customer.inquiryAction0101") }}
                        </router-link>
                    </li>
                    <li v-if="isQuestionnaireViewer">
                        <router-link to="/questionnaire0101">
                            {{ $t("sideMenu.questionnaire.title") }}
                        </router-link>
                    </li>
                    <li v-if="$store.getters.hasPermission(PERMISSION_TOP20CUSTOMER0101_LIST)">
                        <router-link to="/top20customer0101">
                            {{ $t("sideMenu.customer.top20customer0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/transaction0201">
                            {{ $t("sideMenu.customer.transaction0201") }}
                        </router-link>
                    </li>
                    <li v-if="$store.getters.hasPermission(BREAKEVEN_LIST)">
                        <router-link to="/breakeven0101">
                            {{ $t("sideMenu.breakeven.title") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-06">
                <a> EZ-Sales </a>
                <ul>
                    <li>
                        <router-link to="/ezSales/clientManagement0101"> 客戶管理 </router-link>
                    </li>
                    <li>
                        <router-link to="/ezSales/characterManagement0101"> 人物管理 </router-link>
                    </li>
                    <li>
                        <router-link to="/ezSales/projectManagement0101"> 專案管理 </router-link>
                    </li>
                    <li v-if="getIsAdmin">
                        <router-link to="/ezSales/permission"> 權限功能設置 </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-15">
                <a>
                    {{ $t("sideMenu.testmaterial.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/testmaterial0201">
                            {{ $t("sideMenu.testmaterial.testmaterial0201") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/testmaterial0302">
                            {{ $t("sideMenu.testmaterial.testmaterial0301") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li
                class="function-04 close-menu"
                v-show="$store.getters.hasPermission(PERMISSION_COMPETITION_LIST) || $store.getters.hasPermission(PERMISSION_COMPETITION_MANAGE)"
            >
                <router-link to="/competition0101">
                    {{ $t("sideMenu.customer.competition0101") }}
                </router-link>
            </li>
            <li class="function-20 close-menu">
                <router-link
                    to="/outsourcingTest0102"
                    :class="{
                        defaultHide: true,
                        show: true || $store.getters.hasPermission([PERMISSION_OUTSOURCING_TEST0102_REPORT, PERMISSION_OUTSOURCING_TEST0102_LIST]),
                    }"
                >
                    {{ $t("sideMenu.rd.outsourcingTest0102") }}
                </router-link>
            </li>
            <li class="clientman close-menu">
                <router-link to="/client0101">
                    {{ $t("sideMenu.client0101") }}
                </router-link>
            </li>
            <li class="userman close-menu">
                <router-link to="/member0101">
                    {{ $t("sideMenu.member0101") }}
                </router-link>
            </li>

            <li class="projectman close-menu">
                <router-link to="/project0101">
                    {{ $t("sideMenu.project0101") }}
                </router-link>
            </li>
            <!-- todo menu開合事件改純vue控制 不透過jquery -->
            <li v-if="!$store.getters.hasPermission(PERMISSION_CONFERENCE_LIST, { noAdmin: true, effect: 'deny' })" class="function-17">
                <a>
                    {{ $t("sideMenu.conferenceList.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/conferenceList0101">
                            {{ $t("sideMenu.conferenceList.conferenceList0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/conferenceList0102">
                            {{ $t("sideMenu.conferenceList.conferenceList0102") }}
                        </router-link>
                    </li>
                    <li v-if="getIsAdmin">
                        <router-link to="/conferenceList0104">
                            {{ $t("sideMenu.conferenceList.conferenceList0104") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li v-if="!$store.getters.hasPermission(PERMISSION_DOCUMENT_LIST, { noAdmin: true, effect: 'deny' })" class="function-18 close-menu">
                <!-- <router-link to="/fileManager">
                    {{ $t("sideMenu.fileManager") }}
                </router-link> -->
                <router-link to="/fileManager0101">
                    {{ $t("sideMenu.fileManager") }}
                </router-link>
            </li>
            <li
                class="function-23"
                :class="{
                    defaultHide: true,
                    show: $store.getters.hasPermission(PERMISSION_MACHINE_MANAGEMENT0101_LIST),
                }"
            >
                <a>
                    {{ $t("sideMenu.machineManagement.title") }}
                </a>
                <ul>
                    <li>
                        <router-link
                            to="/machineManagement0101"
                            :class="{
                                defaultHide: true,
                                show: $store.getters.hasPermission(PERMISSION_MACHINE_MANAGEMENT0101_LIST),
                            }"
                        >
                            {{ $t("sideMenu.machineManagement.machineManagement0101") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li v-if="isRDViewer" class="function-27">
                <a>
                    {{ $t("sideMenu.rd.title") }}
                </a>
                <ul>
                    <li>
                        <router-link v-if="isTGADSCViewer" to="/TGA_DSC0101">
                            {{ $t("sideMenu.rd.TGA_DSC0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link v-if="isTEViewer" to="/technicalEvaluation0101">
                            {{ $t("sideMenu.rd.technicalEvaluation0101") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="function-19">
                <a>
                    {{ $t("sideMenu.manageRule.title") }}
                </a>
                <ul>
                    <li
                        :class="{
                            defaultHide: true,
                            show: $store.getters.hasPermission([
                                PERMISSION_ADMINISTRATION_TRADEMARK_EXPIRY_HANLDER,
                                PERMISSION_ADMINISTRATION_TRADEMARK_MANAGE,
                            ]),
                        }"
                    >
                        <router-link
                            :to="{
                                name: 'trademark0101',
                                params: { category: '國內商標' },
                            }"
                        >
                            {{ $t("sideMenu.manageRule.trademark0101") }}
                        </router-link>
                    </li>
                    <li v-if="isCertificateViewer">
                        <router-link to="/certificate0101">
                            {{ $t("sideMenu.manageRule.certificate0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/contract0101">
                            {{ $t("sideMenu.manageRule.contract0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/regulationList0101">
                            {{ $t("sideMenu.manageRule.regulationList0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/jobRule0101">
                            {{ $t("sideMenu.manageRule.jobRule0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/jobTraining0101">
                            {{ $t("sideMenu.manageRule.jobTraining") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/fee0101">
                            {{ $t("sideMenu.manageRule.fee0101") }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li v-if="getIsAdmin" class="function-08">
                <a>
                    {{ $t("sideMenu.backendFunction.title") }}
                </a>
                <ul>
                    <li>
                        <router-link to="/department0101">
                            {{ $t("sideMenu.backendFunction.department0101") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/permission/schedule">
                            {{ $t("sideMenu.backendFunction.permission-schedule") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/email/email_management">
                            {{ $t("sideMenu.backendFunction.email-setting") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'systemSecurity' }">
                            系統安全
                            <!-- {{ $t("sideMenu.backendFunction.email-setting") }} -->
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>
<script>
import { mapState, mapGetters } from "vuex"

export default {
    data() {
        return {}
    },
    async created() {},
    computed: {
        ...mapState(["user", "userToken"]),
        ...mapGetters([
            "getUser",
            "getUserToken",
            "getIsAdmin",
            "hasPermission",
            "getTransferDepManagerIds",
            "getInterviewCountUsers",
            "getInterviewCountApprovers",
        ]),
        isTGADSCViewer() {
            return this.hasPermission([this.PERMISSION_TGA_DSC0101_MANAGE, this.PERMISSION_TGA_DSC0101_LIST])
        },
        isTEViewer() {
            return this.hasPermission([
                this.PERMISSION_TECHNICAL_EVALUATION0101_CREATE,
                this.PERMISSION_TECHNICAL_EVALUATION0101_LIST,
                this.PERMISSION_TECHNICAL_EVALUATION0101_RATE,
            ])
        },
        isRDViewer() {
            return this.isTGADSCViewer || this.isTEViewer
        },
        isGraveViewer() {
            return this.hasPermission(this.PERMISSION_GRAVE_AFFAIR0201_LIST)
        },
        isSalesAttendanceViewer() {
            return this.hasPermission(this.PERMISSION_SALES_ATTENDANCE_LIST)
        },
        isPurchasingViewer() {
            return this.hasPermission(this.PERMISSION_PURCHASING_ESTIMATES0201_LIST)
        },
        isIAViewer() {
            return (
                this.hasPermission([
                    this.PERMISSION_INQUIRY_ACTION0101_LIST,
                    this.PERMISSION_INQUIRY_ACTION0101_APPROVE,
                    this.PERMISSION_INQUIRY_ACTION0101_MANAGE,
                ]) || this.getTransferDepManagerIds.includes(this.user.id)
            )
        },
        isInterviewCountViewer() {
            return (
                this.getInterviewCountUsers.includes(this.user.id) ||
                this.getInterviewCountApprovers.includes(this.user.id) ||
                this.hasPermission([this.PERMISSION_INTERVIEW_GROUP_LIST, this.PERMISSION_INTERVIEW_GROUP_MANAGE])
            )
        },
        isAttendance0501Viewer() {
            return this.hasPermission(this.PERMISSION_ATTENDANCE0501_LIST)
        },
        isCertificateViewer() {
            return this.hasPermission([this.PERMISSION_CERTIFICATE_LIST, this.PERMISSION_CERTIFICATE_MANAGE])
        },
        isContractViewer() {
            return this.hasPermission([this.PERMISSION_ADMINISTRATION_LEASE_LIST, this.PERMISSION_ADMINISTRATION_LEASE_MANAGE])
        },
        isQuestionnaireViewer() {
            return this.hasPermission(this.PERMISSION_QUESTIONNIRE_LIST)
        },
    },
    methods: {},
}
</script>

<style lang="scss">
@import "@/styles/style.scss";
@import "basscss";
.defaultHide {
    display: none !important;
    &.show {
        display: block !important;
    }
}
.function-01 {
    background-image: url("../assets/images/Function_01.png");
}
.function-02 {
    background-image: url("../assets/images/Function_02.png");
}
.function-03 {
    background-image: url("../assets/images/Function_03.png");
}
.function-04 {
    background-image: url("../assets/images/Function_04.png");
}
.function-06 {
    background-image: url("../assets/images/Function_06.png");
}
.function-07 {
    background-image: url("../assets/images/Function_07.png");
}
.function-08 {
    background-image: url("../assets/images/Function_08.png");
}
.function-14 {
    background-image: url("../assets/images/Function_14.png");
}
.function-15 {
    background-image: url("../assets/images/Function_15.png");
}
.function-16 {
    background-image: url("../assets/images/Function_16.png");
}
.function-17 {
    background-image: url("../assets/images/Function_17.png");
}
.function-18 {
    background-image: url("../assets/images/Function_18.png");
}
.function-19 {
    background-image: url("../assets/images/Function_19.png");
}
.function-20 {
    background-image: url("../assets/images/Function_20.png");
}
.function-23 {
    background-image: url("../assets/images/Function_23.png");
}
.function-27 {
    background-image: url("../assets/images/Function_27.png");
}
.purchase {
    background-image: url("../assets/images/purchase.png");
}
.clientman {
    background-image: url("../assets/images/clientman.png");
}
.userman {
    background-image: url("../assets/images/userman.png");
}
.projectman {
    background-image: url("../assets/images/projectman.png");
}
</style>
