<template>
  <a @click="handleClick">
    <slot></slot>
    <span :class="getSortDirection() ? 'up caret' : 'down caret'"></span>
  </a>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "SortCaret",
  props: {
    field: {
      type: String,
      default: "",
    },
    sort: {
      type: Function,
    },
    sortOrder: {
      default: [],
    },
    direction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    handleClick() {
      this.sortBy(this.field);
    },
    getSortDirection() {
      return (
        _.get(this.sortOrder, "0.field") === this.field &&
        _.get(this.sortOrder, "0.direction") === "asc"
      );
    },
    sortBy(field) {
      let direction = "asc";
      if (
        _.get(this.sortOrder, "0.field") === field &&
        _.get(this.sortOrder, "0.direction") === "asc"
      ) {
        direction = "desc";
      }
      this.sort([{ field, direction }]);
    },
  },
};
</script>

<style lang="scss" scoped></style>
