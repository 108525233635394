import moment from "moment"
import api from "@/Api/Api"
import { userIdsToNames } from "@/lib/oa"
export default {
    namespaced: true,
    state: {
        meetings: [],
        visits: [],
        myVisits: [],
        leaves: [],
        myLeaves: [],
        calendars: ["meeting", "visit", "myVisit", "leave", "myLeave"],
    },
    getters: {
        getterEvents: (state, getters, rootState, rootGetters) => {
            return [
                { id: 10, title: "All day event", date: new Date(), allDay: true },
                // { id: 20, title: "Timed event", start: addDays(new Date(), 1) },
                // { id: 30, title: "Timed event", start: addDays(new Date(), 2) },
            ]
        },
        getterShowCalendar: (state) => (calendarName) => {
            return state.calendars.includes(calendarName)
        },
        getterMeetingEvents: (state, getters, rootState, rootGetters) => {
            if (!getters.getterShowCalendar("meeting")) {
                return []
            }
            let viewMeeting = state.meetings
            if (rootState.userStore.userSettings.calendarHiddenMeetingCategoryIds?.length > 0) {
                viewMeeting = state.meetings.filter(
                    (meeting) => !rootState.userStore.userSettings.calendarHiddenMeetingCategoryIds.includes(meeting.meetingCategory)
                )
            }
            return viewMeeting.map((meeting) => {
                return {
                    id: meeting.meetingId,
                    title: `${meeting.topic}[${rootGetters["meeting/categoryIdToName"](meeting.meetingCategory)}]`,
                    start: moment(meeting.startAt).toISOString(),
                    end: moment(meeting.endAt).toISOString(),
                    type: "meeting",
                }
            })
        },
        getterVisitEvents: (state, getters, rootState, rootGetters) => {
            if (!getters.getterShowCalendar("visit")) {
                return []
            }
            return state.visits.map((visit) => {
                return {
                    id: visit.VisitId,
                    title: `${userIdsToNames(visit.userId)}-${visit.Interview.map((interview) => interview.Client.shortName).join(",")}-${
                        visit.category
                    }`,
                    start: moment(visit.startDate).toISOString(),
                    end: moment(visit.endDate).toISOString(),
                    type: "visit",
                }
            })
        },
        getterMyVisitEvents: (state, getters, rootState, rootGetters) => {
            if (!getters.getterShowCalendar("myVisit")) {
                return []
            }
            return state.myVisits.map((visit) => {
                return {
                    id: visit.VisitId,
                    title: `${userIdsToNames(visit.userId)}-${visit.Interview.map((interview) => interview.Client.shortName).join(",")}-${
                        visit.category
                    }`,
                    start: moment(visit.startDate).toISOString(),
                    end: moment(visit.endDate).toISOString(),
                    type: "myVisit",
                }
            })
        },
        getterLeaveEvents: (state, getters, rootState, rootGetters) => {
            if (!getters.getterShowCalendar("leave")) {
                return []
            }
            return state.leaves.map((leave) => {
                return {
                    id: leave.leaveId,
                    title: `${userIdsToNames(leave.userId)}-${leave.category}`,
                    start: moment(leave.startDate).toISOString(),
                    end: moment(leave.endDate).toISOString(),
                    type: "leave",
                }
            })
        },
        getterMyLeaveEvents: (state, getters, rootState, rootGetters) => {
            if (!getters.getterShowCalendar("myLeave")) {
                return []
            }
            return state.myLeaves.map((leave) => {
                return {
                    id: leave.leaveId,
                    title: `${userIdsToNames(leave.userId)}-${leave.category}`,
                    start: moment(leave.startDate).toISOString(),
                    end: moment(leave.endDate).toISOString(),
                    type: "leave",
                }
            })
        },
        getterEventSources: (state, getters, rootState, rootGetters) => {
            return [
                {
                    events: getters.getterMeetingEvents,
                    color: "rgb(11, 128, 67)",
                },
                {
                    events: getters.getterVisitEvents,
                    color: "rgb(55, 136, 216)",
                },
                {
                    events: getters.getterMyVisitEvents,
                    color: "rgba(55, 136, 216,0.5)",
                },
                {
                    events: getters.getterLeaveEvents,
                    color: "rgb(173, 20, 87)",
                },
                {
                    events: getters.getterMyLeaveEvents,
                    color: "rgba(173, 20, 87,0.5)",
                },
            ]
        },
        getterMeetingById: (state) => (meetingId) => {
            return state.meetings.find((meeting) => meeting.meetingId == meetingId)
        },
        getterVisitById: (state) => (visitId) => {
            return [...state.visits, ...state.myVisits].find((visit) => visit.VisitId == visitId)
        },
        getterLeaveById: (state) => (leaveId) => {
            return [...state.leaves, ...state.myLeaves].find((visit) => visit.leaveId == leaveId)
        },
    },
    mutations: {
        updateMeetings(state, payload) {
            state.meetings = payload
        },
        updateVisits(state, payload) {
            state.visits = payload
        },
        updateMyVisits(state, payload) {
            state.myVisits = payload
        },
        updateLeaves(state, payload) {
            state.leaves = payload
        },
        updateMyLeaves(state, payload) {
            state.myLeaves = payload
        },
        updateCalendars(state, payload) {
            state.calendars = payload
        },
    },
    actions: {
        actionGetEvents({ commit, dispatch }, params) {
            dispatch("actionGetMeetingEvents", params)
            dispatch("actionGetVisitEvents", params)
            dispatch("actionGetMyVisitEvents", params)
            dispatch("actionGetLeaveEvents", params)
            dispatch("actionGetMyLeaveEvents", params)
        },
        actionGetMeetingEvents({ commit, getters }, { start, end }) {
            if (!getters.getterShowCalendar("meeting")) {
                return
            }
            const params = {
                startAt: moment(start).valueOf(),
                endAt: moment(end).valueOf(),
            }
            api.getMeetings(params).then((res) => {
                commit("updateMeetings", res.data)
            })
        },
        actionGetVisitEvents({ commit, getters }, { start, end }) {
            if (!getters.getterShowCalendar("visit")) {
                return
            }
            const params = {
                start: moment(start).valueOf(),
                end: moment(end).valueOf(),
            }
            api.getVisit(params).then((res) => {
                commit("updateVisits", res.data)
            })
        },
        actionGetMyVisitEvents({ commit, getters, rootState }, { start, end }) {
            if (!getters.getterShowCalendar("myVisit")) {
                return
            }
            const params = {
                start: moment(start).valueOf(),
                end: moment(end).valueOf(),
                userId: rootState.userId,
            }
            api.getVisit(params).then((res) => {
                commit("updateMyVisits", res.data)
            })
        },
        actionGetLeaveEvents({ commit, getters, rootGetters, rootState }, { start, end }) {
            if (!getters.getterShowCalendar("leave")) {
                return
            }
            const params = {
                start: moment(start).valueOf(),
                end: moment(end).valueOf(),
            }
            api.getLeave(params).then(async (res) => {
                const leaves = await Promise.all(
                    res.data.map(async (leave) => {
                        const approvers = await rootGetters.getApprovers(leave.userId).catch(() => [])
                        return {
                            ...leave,
                            isApprover: approvers.includes(rootState.userId),
                        }
                    })
                )
                commit("updateLeaves", leaves)
            })
        },
        actionGetMyLeaveEvents({ commit, getters, rootState }, { start, end }) {
            if (!getters.getterShowCalendar("myLeave")) {
                return
            }
            const params = {
                start: moment(start).valueOf(),
                end: moment(end).valueOf(),
                userId: rootState.userId,
            }
            api.getLeave(params).then((res) => {
                commit("updateMyLeaves", res.data)
            })
        },
        actionSetCalendars({ commit, dispatch }, calendars) {
            commit("updateCalendars", calendars)
            const params = {
                calendar: calendars.join(","),
            }
            dispatch("userStore/actionUpdateUserSetting", params, { root: true })
        },
    },
}
