<template>
    <!-- 公事外出申請檢視 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ $t("page.home.approveCategory") }}</h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="col-title">{{ $t("misc.topic") }}：</span>
                            <span>{{ $t(getI18nKey(visit.category)) }}－{{ getEventName(visit.event) }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.date") }}：</span>
                            <span>{{ visit.startDate | formatDateUTC }} ~ {{ visit.endDate | formatDateUTC }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.applicantPeople") }}：</span>
                            <span>{{ $store.getters.getUser(visit.userId).chinese_name }}</span>
                        </li>
                        <li v-if="visit.Interview">
                            <span class="col-title">{{ $t("page.attendance0201.visitClient") }}：</span>
                            <span
                                v-for="target in visit.Interview"
                                :key="target.interviewId"
                                style="margin-right: 10px"
                                >{{ target.Client.name }}</span
                            >
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.contactMethod") }}：</span>
                            <span>{{ $t(getI18nKey(visit.contactMethod)) }}：{{ visit.contact }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.remark") }}：</span>
                            <span>{{ visit.remarks }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("page.attendance0201.useCar") }}：</span>
                            <span v-if="visit.useCar">✓</span>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" data-dismiss="modal" @click="updateStatus">
                        {{ $t("page.home.approve") }}
                    </button>
                    <button type="button" class="btn btn-warning" data-dismiss="modal" @click="updateDeniedStatus">
                        {{ $t("misc.approveReject2") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import api from "@/Api/Api"

export default {
    data() {
        return {
            modalName: "approveVisit",
            visit: {},
            callback: () => {},
        }
    },
    computed: {
        ...mapState(["user", "userId", "visitEvents"]),
        ...mapGetters(["getI18nKey"]),
    },
    beforeDestroy() {},
    methods: {
        beforeOpen(e) {
            this.visit = e.visit
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {
            this.clear()
        },
        getEventName(eventName) {
            if (this.$i18n.locale != "en-US") {
                return eventName
            }
            const event = this.visitEvents.find((event) => event.name == eventName)
            return event?.englishName || eventName
        },
        updateStatus() {
            const params = {
                approvalStatus: "已簽核",
                approvingUser: this.userId,
            }
            api.putVisit(this.visit.VisitId, params).then(() => {
                this.close()
                this.callback()
            })
        },
        updateDeniedStatus() {
            const params = {
                approvalStatus: "否決",
                approvingUser: this.userId,
            }
            api.putVisit(this.visit.VisitId, params).then(() => {
                this.close()
                this.callback()
            })
        },
        clear() {},
    },
}
</script>

<style scoped>
.col-title {
    width: 12em;
    white-space: nowrap;
}
</style>
