<template>
    <!-- 報告內容 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose" dataBackdrop>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" @click="close">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">報告內容</h2>
                </div>
                <form class="form-horizontal" role="form">
                    <div class="modal-body">
                        <div>
                            <div class="form-group" style="margin-bottom: 0px">
                                <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.customerId") }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ $_.get(interview, "Client.clientNumber") }}
                                </p>
                                <label class="col-xs-5 col-sm-2 control-label">{{
                                    $t("misc.companyVisitedTarget")
                                }}</label>
                                <div class="col-xs-7 col-sm-4">
                                    <input type="text" class="form-control" v-model="editInterview.visitTarget" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-xs-5 col-sm-2 control-label">{{
                                    $t("misc.companyVisitedDate")
                                }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ interview.visitDate | formatDate("YYYY-MM-DD") }}
                                </p>
                                <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.phone") }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ $_.get(interview, "Client.phone") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.clientName") }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ $_.get(interview, "Client.name") }}
                                </p>
                                <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.fax") }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ $_.get(interview, "Client.fax") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.contactPerson") }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    <span v-if="clientContactNeedChoose">
                                        <select class="form-control" v-model="clientContact">
                                            <option
                                                v-for="client in interview.Client.ClientContact"
                                                :value="client.name"
                                                :key="client.clientContactId"
                                            >
                                                {{ client.name }}
                                            </option>
                                        </select>
                                    </span>
                                    <span v-else>
                                        {{ clientContact }}
                                    </span>
                                </p>
                                <label class="col-xs-5 col-sm-2 control-label">{{
                                    $t("page.attendance0201.ownBusiness")
                                }}</label>
                                <p class="col-xs-7 col-sm-4 control-label t_l">
                                    {{ $store.getters.getUser(interview.userId).chinese_name }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.attendance0201.notifyParty")
                            }}</label>
                            <div class="col-xs-7 col-sm-6">
                                <userselect v-model="edit_notify_person" />
                            </div>
                            <label class="col-xs-5 col-sm-2 control-label"></label>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.attendance0201.visitTopic")
                            }}</label>
                            <p class="col-xs-7 col-sm-10 control-label t_l">
                                {{ getEventName(interview.visitTopic) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">{{
                                $t("page.attendance0201.visitConclusion")
                            }}</label>
                            <div class="col-sm-10">
                                <textarea class="form-control" row="4" v-model="editInterview.conclusion"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="InputFile" class="col-sm-2 control-label TEXT-CO1">{{
                                $t("misc.uploadFile")
                            }}</label>
                            <div class="col-sm-10">
                                <input
                                    type="file"
                                    name="file[]"
                                    multiple="multiple"
                                    v-if="uploadReady"
                                    id="InputFile"
                                    @change="onFileChanged"
                                />
                                <p class="help-block">{{ $t("placeholder.uploadMulti") }}</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-4 checkbox">
                                <label> <input type="checkbox" v-model="hasChance" />{{ $t("misc.chance") }} </label>
                            </div>
                            <div class="col-sm-2 col-sm-offset-4" v-show="hasChance">
                                <select class="form-control" v-model="editInterview.interviewStatus">
                                    <option value="">{{ $t("placeholder.select") }}</option>
                                    <option value="列為資料">{{ $t(getI18nKey("列為資料")) }}</option>
                                    <option value="即時研發">{{ $t(getI18nKey("即時研發")) }}</option>
                                    <option value="報價">{{ $t(getI18nKey("報價")) }}</option>
                                    <option value="研發可行性評估">{{ $t(getI18nKey("研發可行性評估")) }}</option>
                                    <option value="馬上試料">{{ $t(getI18nKey("馬上試料")) }}</option>
                                </select>
                            </div>
                            <div class="col-sm-2" v-show="hasChance">
                                <label>
                                    <input
                                        type="button"
                                        class="btn btn-info btn-sm"
                                        @click="createChance"
                                        value="新增項目"
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="table-responsive clearfloat">
                            <table class="table table-striped table-hover n-table-head">
                                <thead>
                                    <tr>
                                        <td>{{ $t("page.attendance0201.productId") }}</td>
                                        <td>{{ $t("page.attendance0201.meterialBig") }}</td>
                                        <td>{{ $t("page.attendance0201.meterialSmall") }}</td>
                                        <td>{{ $t("page.attendance0201.productName") }}</td>
                                        <td>{{ $t("page.attendance0201.meterialRequest") }}</td>
                                        <td>{{ $t("page.attendance0201.spec") }}</td>
                                        <td>{{ $t("page.attendance0201.numberKgM") }}</td>
                                        <td>{{ $t("page.attendance0201.priceKg") }}</td>
                                        <td>{{ $t("misc.currency") }}</td>
                                        <td>{{ $t("misc.edit") }}</td>
                                    </tr>
                                </thead>
                                <tbody v-for="(chance, index) in interview.Chance" :key="chance.chanceId">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ chance.materialBig }}</td>
                                        <td>{{ chance.materialSmall }}</td>
                                        <td>{{ chance.productName }}</td>
                                        <td>{{ chance.physicalNeed }}</td>
                                        <td>{{ chance.specification }}</td>
                                        <td>{{ chance.amount }}</td>
                                        <td>{{ chance.price }}</td>
                                        <td>{{ chance.currency }}</td>
                                        <td>
                                            <a @click="editChance(index, chance)">{{ $t("misc.edit") }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- /.modal-body -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="updateInterview">
                            {{ $t("misc.ok") }}
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            {{ $t("misc.close") }}
                        </button>
                    </div>
                    <!-- /.modal-footer -->
                </form>
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import api from "@/Api/Api"
import * as _ from "lodash"

export default {
    data() {
        return {
            modalName: "createInterview",
            interview: {},
            visit: {},
            editInterview: {
                visitTarget: "",
                interviewStatus: "",
            },
            edit_notify_person: [],
            uploadReady: true,
            hasChance: false,
            files: [],
            clientContact: "",
            callback: () => {},
        }
    },
    computed: {
        ...mapState(["user", "userId", "visitEvents"]),
        ...mapGetters(["getI18nKey"]),
        clientContactNeedChoose() {
            if (this.interview?.Client?.ClientContact?.length > 1) return true
            return false
        },
    },
    methods: {
        beforeOpen(e) {
            this.interview = _.cloneDeep(e.interview) || {}
            this.visit = _.cloneDeep(e.visit) || {}
            this.editInterview = {
                visitTarget: "",
                interviewStatus: "",
                conclusion: "",
            }
            this.edit_notify_person = []
            this.hasChance = false
            this.resetUploadFile()
            this.setClientContact()
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {
            this.clear()
        },
        updateInterview(interview) {
            if (this.editInterview.visitTarget == "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.companyVisitedTarget")}`)
                return false
            }
            if (this.editInterview.conclusion == "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("page.attendance0201.visitConclusion")}`)
                return false
            }
            let self = this
            let newChance = this.interview.Chance.filter((c) => !c.chanceId)
            const params = {
                visitTarget: this.editInterview.visitTarget,
                conclusion: this.editInterview.conclusion,
                interviewStatus: this.editInterview.interviewStatus,
                isReport: true,
                notifyPerson: this.edit_notify_person.join(","),
                clientContact: this.clientContact,
                isChance: this.hasChance,
            }
            Promise.all(
                [
                    api.put("/interview/" + this.interview.interviewId, params),
                    this.upload(this.interview.interviewId, "interviews"),
                    api.put("/visit/" + this.visit.VisitId, {
                        isReport: true,
                    }),
                ].concat(newChance.map((c) => api.post("/chance", c))) //new chance update
            ).finally(() => {
                self.$bus.$emit("reloadListData")
                self.close()
                this.callback()
            })
        },
        onFileChanged(event) {
            this.files = event.target.files
        },
        upload(id, type) {
            if (!this.files.length) {
                return
            }
            let formdata = new FormData()
            formdata.append("id", id)
            formdata.append("funcType", type)
            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i]
                formdata.append("file", file)
            }
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data", //之前说的以表单传数据的格式来传递fromdata
                },
            }
            api.post("/file", formdata, config).then((x) => {})
        },
        resetUploadFile() {
            this.uploadReady = false
            this.$nextTick(() => {
                this.uploadReady = true
            })
            this.files = []
        },
        createChance() {
            this.$modal.show("createChance", { interview: this.interview, addChance: this.addChance })
        },
        addChance(chance) {
            this.interview.Chance.push(chance)
        },
        editChance(index, chance) {
            this.$modal.show("editChance", { chance, index, editChance: this.updateChance })
        },
        updateChance(index, chance) {
            this.$set(this.interview.Chance, index, chance)
        },
        setClientContact() {
            if (this.interview?.Client?.ClientContact.length > 0) {
                this.clientContact = this.interview.Client.ClientContact[0].name
            }
        },
        getEventName(eventName) {
            console.log(eventName)
            if (this.$i18n.locale != "en-US") {
                return eventName
            }
            const event = this.visitEvents.find((event) => event.name == eventName)
            return event?.englishName || eventName
        },
        clear() {
            this.clientContact = ""
        },
    },
}
</script>

<style></style>
