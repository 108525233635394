<template>
    <div>
        <span class="multipleSel_Button btn btn-default" @click="toggle">{{
            $_.get(selectedUsers, "length") ? $t("misc.selected") + " " + selectedUsers.length + " " + $t("misc.people") : $t("placeholder.select")
        }}</span>
        <div class="multiselect-section" v-if="open">
            <select class="aselect form-control" v-model="selectedDepartmentId">
                <option value="0">{{ $t("misc.allDepartment") }}</option>
                <option v-for="department in departments" :value="department.department_id" :key="department.id">
                    {{ department.name }}
                </option>
            </select>
            <div class="sol-container my-select" style="width: auto; visibility: initial" :class="{ 'sol-active': openUserList }">
                <div class="sol-inner-container">
                    <div class="sol-input-container" @click="openUserList = true">
                        <input v-model="query" type="text" :placeholder="$t('placeholder.pickSelect')" @focus="openUserList = true" />
                    </div>
                    <div class="sol-caret-container">
                        <b class="sol-caret"></b>
                    </div>
                </div>
                <div class="sol-selection-container" v-if="openUserList">
                    <div class="sol-action-buttons">
                        <a class="sol-select-all" @click="selectAll" v-if="!single">{{ $t("misc.selectAll") }}</a>
                        <a class="sol-deselect-all" @click="deselectAll">{{ $t("misc.cancelSelect") }}</a>
                        <div class="sol-clearfix"></div>
                    </div>
                    <div class="sol-no-results" v-if="$_.isEmpty(matchedUsers)">
                        {{ $t("misc.noResult") }}
                    </div>
                    <div class="sol-selection" style="max-height: 150px" v-else>
                        <div class="sol-option" v-for="user in matchedUsers" :key="user.id">
                            <label class="sol-label">
                                <input
                                    type="checkbox"
                                    class="sol-checkbox"
                                    name="character"
                                    :value="user.id"
                                    :disabled="single && $_.get(selectedUsers, 'length') >= 1 && selectedUsers[0] !== user.id"
                                    v-model="selectedUsers"
                                />
                                <div class="sol-label-text">{{ getNameLabel(user.id) }}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="sol-current-selection">
                    <div class="sol-selected-display-item" v-for="(userId, i) in selectedUsers" :key="userId">
                        <span class="sol-quick-delete" @click="deselectUser(i)">×</span>
                        <span class="sol-selected-display-item-text">{{ getNameLabel(userId) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import _ from "lodash"

export default {
    name: "UserSelect",
    props: ["name", "value", "single", "filterOptions", "opened"],
    computed: {
        ...mapState(["ready", "departmentGraph", "roles"]),
        ...mapGetters(["getUser", "getRoles"]),
    },
    asyncComputed: {
        async departments() {
            let data = []
            await this.departmentGraph.traverseDown("dep-root", (n, id, path, level) => {
                if (n && n.type === "department") {
                    if (n.name !== "root") {
                        if (n._id === "dep-admin" && !this.$test()) {
                            return
                        }
                        data.push(n)
                    }
                }
            })
            // 如果有限制區域，只顯示限制區域的部門
            if (this.filterOptions?.area) {
                data = data.filter((department) => department.number.startsWith(this.filterOptions.area))
            }
            // 新需求不限制同部門
            // if (_.get(this, "filterOptions", []).includes("level")) {
            //     const user = this.getUser()
            //     const departmentId = _.get(user, "departments[0].department.department_id")
            //     data = []
            //     if (departmentId) {
            //         await this.departmentGraph.traverseDown(`dep-${departmentId}`, (n, id, path, level) => {
            //             if (n && n.type === "department") {
            //                 if (n.name !== "root") {
            //                     data.push(n)
            //                 }
            //             }
            //         })
            //     }
            // }
            return data
        },
        async users() {
            let data = []
            // if (_.get(this, "filterOptions", []).includes("level")) {
            // }
            const departments = (this.departments || []).map((o) => o.department_id)
            await this.departmentGraph.traverseDown("dep-root", (n, id, path, level) => {
                if (n && n.type === "user") {
                    const userDepartment = _.get(n, "UserDepartments[0]")
                    if (userDepartment && departments.includes(userDepartment.department_id)) {
                        data.push(n)
                    }
                }
            })
            return data
        },
        async matchedUsers() {
            if (!this.users) {
                return []
            }

            let matchedUsers = this.users.filter((user) => {
                let result = user.chinese_name.toLowerCase().includes(this.query.toLowerCase())
                if (this.selectedDepartmentId !== "0") {
                    result = result && user.UserDepartments[0].department_id === this.selectedDepartmentId
                }
                //離職不顯示
                result = result && !user.isResign
                return result
            })
            // 不知道用途先隱藏
            // if (_.get(this, "filterOptions", []).includes("level")) {
            //     matchedUsers = matchedUsers.filter((user) => {
            //         return this.getRoles("down-inclusive")
            //             .map((o) => o.id)
            //             .includes(user.role_id)
            //     })
            // }
            //依層級排序 先排部門再排人員層級
            matchedUsers.sort((a, b) => {
                if (a.UserDepartments[0].department_id === b.UserDepartments[0].department_id) {
                    return (
                        (this.roles.find((role) => role.id == b.role_id)?.level || 0) - (this.roles.find((role) => role.id == a.role_id)?.level || 0)
                    )
                }
                return (
                    (this.departments.findIndex((department) => department.department_id === a.UserDepartments[0].department_id) ?? 999) -
                    (this.departments.findIndex((department) => department.department_id === b.UserDepartments[0].department_id) ?? 999)
                )
            })
            return matchedUsers
        },
    },
    data() {
        return {
            // inputVal: this.value,
            selectedUsers: this.value || [],
            open: this.opened === undefined ? true : this.opened,
            openUserList: false,
            query: "",
            // selectedUsers: [],
            selectedDepartmentId: "0",
        }
    },
    created() {
        if (this.ready) {
            this.init()
        } else {
            this.$bus.$on("ready", (e) => {
                this.init()
            })
            this.$bus.$on("resetUserSelect", (e) => {
                console.log("resetUserSelect")
                this.init()
            })
        }
    },
    beforeDestroy() {
        this.$bus.$off("ready")
        this.$bus.$off("resetUserSelect")
    },
    methods: {
        async init() {
            this.selectedDepartmentId = "0"
            this.query = ""
            this.openUserList = false
        },
        // tree
        handleChange: function (listName, e) {
            // this.approverList;
            // console.log(listName, e);
        },
        toTreeselectNode: function (node) {
            return {}
        },
        loadOptions({ action, parentNode, callback, instanceId }) {
            callback()
        },
        // end tree
        toggle() {
            this.open = !this.open
        },
        close() {
            this.open = false
        },
        selectAll() {
            this.matchedUsers.map((user) => {
                this.selectedUsers.push(user.id)
            })
            this.selectedUsers = _.uniq(this.selectedUsers)
            this.$emit("change", this.selectedUsers)
        },
        deselectAll() {
            this.selectedUsers = []
        },
        deselectUser(index) {
            this.selectedUsers.splice(index, 1)
            this.$emit("change", this.selectedUsers)
        },
        getNameLabel(userId) {
            // [部門][職稱]中文名字
            const user = this.departmentGraph.getNode(`user-${userId}`)
            if (user) {
                const department = this.departmentGraph.getNode(`dep-${_.get(user, "UserDepartments[0].department_id")}`)
                const position = _.get(user, "UserDepartments[0].position")
                if (position) {
                    return `[${_.get(department, "name", "")}][${position}]-${user.chinese_name}`
                } else {
                    return user.chinese_name
                }
            } else {
                return ""
            }
        },
    },
    watch: {
        selectedUsers(val, oldVal) {
            this.$emit("input", val)
            if (_.isEqual(val, oldVal)) {
                return
            }
            this.$emit("change", val)
        },
        value(v) {
            this.selectedUsers = v
        },
        selectedDepartmentId(id) {
            if (id != "0") {
                this.openUserList = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.multiselect-section {
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 1em;
    margin-top: 0.3em;
}
.sol-quick-delete {
    user-select: none;
}
</style>
