<template>
    <div id="app">
        <template v-if="pageOnly">
            <router-view></router-view>
        </template>
        <template v-else>
            <header
                id="header"
                :class="{
                    hidden: !getUserToken(),
                }"
            >
                <div id="mobile_bt">
                    <span class="bt_line"></span>
                    <span class="bt_line"></span>
                    <span class="bt_line"></span>
                </div>
                <div id="logo">
                    <!--<span style="">NYTEX OA</span>-->
                    <img src="./assets/images/logo.png" id="logo_img" />
                </div>
                <ul id="top_menu">
                    <li id="schedule">
                        <router-link to="/schedule0101" class="topmenu">
                            <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                            {{ $t("header.calendar") }}</router-link
                        >
                    </li>
                    <li id="bulletin">
                        <router-link to="/bulletin0101" class="topmenu"
                            ><span class="glyphicon glyphicon-bullhorn" aria-hidden="true"></span> {{ $t("header.billboard") }}</router-link
                        >
                    </li>
                    <li id="TBIG_M01">
                        <router-link to="/myProfile0101" class="topmenu">
                            <span class="glyphicon glyphicon-user" aria-hidden="true"></span>
                            {{ $t("header.profile") }}
                        </router-link>
                    </li>
                    <li id="TBIG_M02">
                        <router-link to="/home" class="topmenu">
                            <span class="glyphicon glyphicon-home" aria-hidden="true"></span>
                            {{ $t("header.home") }}
                        </router-link>
                    </li>
                    <a id="logout" @click="logout" class="topmenu">
                        <span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>
                        {{ $t("header.logout") }}
                    </a>
                    <div class="systemTime">{{ systemTime }}</div>
                </ul>
                <div v-if="showLoginBtn" style="position: absolute; right: 15px; top: 0px">
                    <button
                        class="btn btn-default arriveBtn"
                        @click="updateUserStatus"
                        id="arriveBtn-pc"
                        v-if="user.status === '未簽到' || user.status === '上班' || user.status === ''"
                    >
                        {{ attendanceText }}
                    </button>
                    <button
                        class="btn btn-default arriveBtn"
                        @click="updateUserStatus"
                        id="arriveBtn-mobile"
                        v-if="user.status === '未簽到' || user.status === ''"
                    >
                        {{ attendanceText }}
                    </button>
                </div>
                <!-- 語系切換 -->
                <div v-if="getIsAdmin" style="right: 100px; position: absolute; top: 20px">
                    <select class="selectpicker" data-width="fit" v-model="locale" @change="setLang">
                        <option v-for="l in locales" :key="l.id" :dataContent="l.id" :value="l.id">
                            {{ l.name }}
                        </option>
                    </select>
                </div>
            </header>

            <div
                id="menu_button"
                :class="{
                    hidden: !getUserToken(),
                }"
            >
                <span class="bt_line"></span>
                <span class="bt_line"></span>
                <span class="bt_line"></span>
            </div>

            <oa-menu />
            <section
                id="content_box"
                :class="{
                    isLogin: !getUserToken(),
                }"
            >
                <div class="systemTime-mobile">{{ systemTime }}</div>
                <router-view></router-view>
            </section>

            <div class="modals">
                <create-visit-modal />
                <create-interview-modal />
                <create-chance-modal />
                <edit-chance-modal />
                <visit-modal />
            </div>
        </template>
        <!--<div class="loading"></div>-->
    </div>
</template>

<style></style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import api from "@/Api/Api"
import moment from "moment"
import OaMenu from "@/components/OaMenu"
export default {
    components: {
        OaMenu,
    },
    data() {
        return {
            locale: "zh-TW",
            // isArrive: "",
            userID: "",
            checkLoginIntervalId: null,
            systemTime: "",
            currentPath: "",
        }
    },
    async created() {
        //關閉視窗5秒自動登出
        const time = window.localStorage.getItem("leaveTime")
        const openCount = parseInt(window.localStorage.getItem("openCount"))
        if (moment().diff(moment(parseInt(time))) > 5000 && !this.$test() && openCount < 1) {
            console.log("logout")
            window.localStorage.setItem("login_Usertoken", "")
        }
        window.localStorage.setItem("openCount", openCount + 1)
        this.init()
        this.$bus.$on("appInit", (e) => {
            this.init()
        })
        this.updateTime()
        window.onunload = () => {
            const openCount = window.localStorage.getItem("openCount")
            window.localStorage.setItem("openCount", openCount - 1)
            const time = moment().format("x")
            window.localStorage.setItem("leaveTime", time)
        }
    },
    computed: {
        ...mapState(["user", "userToken", "departmentGraph", "locales"]),
        ...mapGetters(["getUserToken", "getIsAdmin", "getIsLogin"]),
        attendanceText() {
            if (this.user.status === "上班") return this.$t("misc.doEndWork")
            if (this.user.status === "請假") {
                if (this.user.CheckIn && !this.user.CheckOut) return this.$t("misc.doEndWork")
                return this.$t("misc.doCancelLeave")
            }
            if (this.user.status === "外出") {
                if (this.user.CheckIn && !this.user.CheckOut) return this.$t("misc.doEndWork")
                return this.$t("misc.doCancelOuting")
            }
            return this.$t("misc.doStartWork")
        },
        showLoginBtn() {
            //若時間超過下班時間，不顯示簽到按鈕
            if (!this.user.off_time) {
                return
            }
            const [offHour, offMin] = this.user.off_time.split(":")
            const offTime = moment().hour(offHour).minute(offMin)
            return offTime - moment() > 0 || this.user.status === "上班"
        },
        pageOnly() {
            return this.currentPath.startsWith("/compare") || this.currentPath.startsWith("/carApplication")
        },
    },
    methods: {
        ...mapActions(["actionAfterLogin"]),
        init() {
            // login check
            if (!this.checkLoginIntervalId) {
                this.checkLoginIntervalId = setInterval(() => {
                    if (this.$router.currentRoute.path !== "/" && !localStorage.getItem("login_UserId")) {
                        // window.alert("由於系統禁止從其他裝置登入，因此已從這個視窗")
                        this.logout()
                    }
                }, 3000)
            }
            // load data
            if (this.getIsLogin) {
                this.actionAfterLogin().finally(() => {
                    this.$store.dispatch("setReady", true)
                })
            } else {
                this.$store.dispatch("setReady", true)
            }
            this.$store.dispatch("getDepartmentTree")
        },
        // arrive(){
        //   this.isArrive = !this.isArrive
        // },
        updateUserStatus() {
            this.userId = window.localStorage.getItem("login_UserId")
            let status = "上班"
            if (this.user.status === "上班") status = "簽退"
            if (this.user.status === "請假") {
                if (this.user.CheckIn && !this.user.CheckOut) status = "簽退"
                else status = "銷假"
            }
            if (this.user.status === "外出") {
                if (this.user.CheckIn && !this.user.CheckOut) status = "簽退"
                else status = "銷外出"
            }
            if (status == "簽退") {
                if (!confirm(this.$t("alert.checkSignout"))) {
                    return
                }
            }
            const param = {
                userId: this.userId,
                status: status,
            }
            api.post("/attendance", param).then((res) => {
                //不允許外部簽到
                if (res.data.status === -2) {
                    alert(this.$t("alert.externalSignInError"))
                    return
                }
                this.$store.dispatch("getUser")
                if (["/attendance0101"].includes(this.$router.currentRoute.path)) {
                    window.location.reload()
                }
            })
        },
        async logout() {
            await this.$store.dispatch("logout")
            this.$router.push({
                path: "/",
            })
        },
        updateTime() {
            moment.locale(this.$i18n.locale)
            setInterval(() => {
                this.systemTime = moment().format("LLLL")
            }, 1000)
        },
        setLang() {
            this.$store.dispatch("setLang", this.locale)
        },
    },
    watch: {
        $route(to, from) {
            this.currentPath = to.path
        },
    },
}
</script>
<style lang="scss">
@import "@/styles/style.scss";
@import "basscss";

html,
body {
    background-color: #eef0f2;
}
/* #header, #menu_button, #menu{
  display: none;
} */
#content_box {
    background: #eef0f2;
}

#top_menu {
    margin-left: 15em !important;
}

.arriveBtn {
    margin-top: 1.2em;
    margin-left: 3em;
}
.langSelect {
    float: right;
    margin-top: 1.5em;
    margin-right: 1em;
}
#content_box {
    &.isLogin {
        position: fixed;
        left: 0 !important;
    }
}
.modals {
    .vmodal {
        overflow: auto;
        .vm--modal {
            .modal-dialog {
                margin: 0;
            }
            @media only screen and (max-width: 425px) {
                top: 0 !important;
                overflow: auto;
            }
        }
    }
}
.systemTime {
    color: #eee;
    display: table-cell;
    vertical-align: bottom;
    height: 5em;
    padding-bottom: 0.8em;
    padding-left: 1em;
}
.systemTime-mobile {
    display: none;
}
#arriveBtn-mobile {
    display: none;
}
@media (max-width: 769px) {
    .systemTime {
        display: none;
    }
    .systemTime-mobile {
        padding: 0.5em;
        text-align: center;
        display: block;
    }
    #arriveBtn-mobile {
        display: block;
    }
    #arriveBtn-pc {
        display: none;
    }
}
</style>
