import api from "@/Api/Api"
export default {
    namespaced: true,
    state: {
        categories: {
            customerTitle: [],
            customerPaymentLevel: [],
            customerWindowLevel: [],
            characterCountry: [],
            characterDepartment: [],
            characterTitle: [],
            characterRelation: [],
            characterReligion: [],
            characterPolitical: [],
            characterDegree: [],
            characterNation: [],
        },
        categoryMap: {
            customerTitle: {
                class: "SYS_2_00043",
                label: "客戶分析/股東名單/職稱",
            },
            customerPaymentLevel: {
                class: "SYS_2_00044",
                label: "客戶分析/採購意願/採購我方的現況/採購層級",
            },
            customerWindowLevel: {
                class: "SYS_2_00045",
                label: "客戶分析/採購意願/客戶採購意願/主窗口層級",
            },
            characterCountry: {
                class: "SYS_2_00046",
                label: "人物編輯/基本資料/國籍",
            },
            characterDepartment: {
                class: "SYS_2_00047",
                label: "人物編輯/工作/部門",
            },
            characterTitle: {
                class: "SYS_2_00048",
                label: "人物編輯/工作/職稱",
            },
            characterRelation: {
                class: "SYS_2_00049",
                label: "人物編輯/家庭/關係",
            },
            characterReligion: {
                class: "SYS_2_00050",
                label: "人物編輯/立場/宗教",
            },
            characterPolitical: {
                class: "SYS_2_00051",
                label: "人物編輯/立場/政治立場",
            },
            characterDegree: {
                class: "SYS_2_00052",
                label: "人物編輯/學歷/學位",
            },
            characterNation: {
                class: "SYS_2_00053",
                label: "人物編輯/學歷/國家",
            },
        },
        project: {
            EZAnalysis: {},
        },
        customer: {
            CustomersPotential: {},
            CustomersProcurement: {},
            CustomersPurchasing: {},
            CustomerCompetitiveSituations: {
                salesType: [],
            },
            CustomerOther: {},
        },
        plan: {
            perspective: "",
            type: "",
            demand: "",
            opportunity: "",
            analysis: "",
            name: "",
            action: "",
            issue: "",
            base: "",
            shareholder: "",
            potential: "",
            procurement: "",
            prchasing: "",
            spwRatings: "",
            competitiveSituations: "",
            customerCompetitors: "",
            customerOther: "",
        },
        seedConfig: {
            base: {
                title: "基本資料",
                items: {
                    clientNumber: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "clientNumber",
                        title: "客戶編號",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    name: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "name",
                        title: "客戶名稱",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    founder: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "founder",
                        title: "創立者",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    year: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "year",
                        title: "創立年份",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    month: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "month",
                        title: "創立月份",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    uniteNumber: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "uniteNumber",
                        title: "統一編號",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    stockCode: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "stockCode",
                        title: "股票代號",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    businessPhilosophy: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "businessPhilosophy",
                        title: "經營理念",
                        type: "textarea",
                    },
                    customerAnalysis: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "customerAnalysis",
                        title: "客戶分析",
                        type: "textarea",
                    },
                    staffSize: {
                        mainCategory: "base",
                        secondaryCategory: "基本資料",
                        key: "staffSize",
                        title: "員工規模",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                },
            },
            potential: {
                title: "潛力分析",
                items: {
                    capitalStock: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "capitalStock",
                        title: "股本",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    revenuePastYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "revenuePastYear",
                        title: "近一年營收",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    earningsPerShare: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "earningsPerShare",
                        title: "每股獲利盈餘",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    revenueThisYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "revenueThisYear",
                        title: "今年營收",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    revenueLastYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "revenueLastYear",
                        title: "去年營收",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    revenueBeforeLastYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "revenueBeforeLastYear",
                        title: "前年營收",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    profitThisYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "profitThisYear",
                        title: "今年獲利",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    profitLastYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "profitLastYear",
                        title: "去年獲利",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    profitBeforeLastYear: {
                        mainCategory: "potential",
                        secondaryCategory: "財務資訊",
                        key: "profitBeforeLastYear",
                        title: "前年獲利",
                        type: "text",
                        customerClass: "col-sm-4",
                    },
                    structureProportion: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶潛力描述",
                        key: "structureProportion",
                        title: "產品結構比例近三年趨勢與未來變化",
                        type: "textarea",
                    },
                    cultivate: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶潛力描述",
                        key: "cultivate",
                        title: "客戶銷售區域是否我們想耕耘",
                        type: "textarea",
                    },
                    potential: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶潛力描述",
                        key: "potential",
                        title: "客戶在銷售區域有沒有潛力",
                        type: "textarea",
                    },
                    photo: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶潛力描述",
                        key: "photo",
                        title: "客戶地圖或照片等資料",
                        type: "file",
                    },
                    structureRatio: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "structureRatio",
                        title: "產品結構比例",
                        type: "textarea",
                    },
                    salesArea: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "salesArea",
                        title: "銷售區域",
                        type: "textarea",
                    },
                    industryRanking: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "industryRanking",
                        title: "業界排名",
                        type: "textarea",
                    },
                    financialInformation: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "financialInformation",
                        title: "財務資料",
                        type: "textarea",
                    },
                    valueProposition: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "valueProposition",
                        title: "價值定位",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    productMap: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "productMap",
                        title: "產品地圖完整度",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    pricePositioning: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "pricePositioning",
                        title: "價格定位",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    overallSales: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "overallSales",
                        title: "整體銷售趨勢",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    salesMotivation: {
                        mainCategory: "potential",
                        secondaryCategory: "客戶銷售狀況",
                        key: "salesMotivation",
                        title: "業務銷售動機",
                        type: "textarea",
                    },
                },
            },
            procurement: {
                title: "採購政策",
                items: {
                    newProduct: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "newProduct",
                        title: "新品採購流程",
                        type: "text",
                    },
                    usedProduct: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "usedProduct",
                        title: "舊品採購流程",
                        type: "text",
                    },
                    evaluationElements: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "evaluationElements",
                        title: "採購評估要素",
                        type: "text",
                    },
                    situation: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "situation",
                        title: "採購處境",
                        type: "text",
                    },
                    trends: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "trends",
                        title: "採購趨勢",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    attitude: {
                        mainCategory: "procurement",
                        secondaryCategory: "整體採購策略",
                        key: "attitude",
                        title: "採購態度",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    paymentTerms: {
                        mainCategory: "procurement",
                        secondaryCategory: "客戶整體採購行為",
                        key: "paymentTerms",
                        title: "付款條件",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    profitLevel: {
                        mainCategory: "procurement",
                        secondaryCategory: "客戶整體採購行為",
                        key: "profitLevel",
                        title: "利潤水準",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    salesService: {
                        mainCategory: "procurement",
                        secondaryCategory: "客戶整體採購行為",
                        key: "salesService",
                        title: "售前/售後服務",
                        type: "textarea",
                    },
                    quality: {
                        mainCategory: "procurement",
                        secondaryCategory: "客戶整體採購行為",
                        key: "quality",
                        title: "品質要求",
                        type: "text",
                    },
                    entertainment: {
                        mainCategory: "procurement",
                        secondaryCategory: "客戶整體採購行為",
                        key: "entertainment",
                        title: "應酬要求",
                        type: "text",
                    },
                },
            },
            prchasing: {
                title: "採購意願",
                items: {
                    paymentTerms: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "paymentTerms",
                        title: "付款條件",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    profitLevel: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "profitLevel",
                        title: "利潤水準",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    salesService: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "salesService",
                        title: "售前/售後服務",
                        type: "textarea",
                    },
                    quality: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "quality",
                        title: "品質要求",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    entertainment: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "entertainment",
                        title: "應酬要求",
                        type: "text",
                    },
                    paymentLevel: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "paymentLevel",
                        title: "採購層級",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    annualPurchase: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "annualPurchase",
                        title: "平均年採購額",
                        type: "textarea",
                    },
                    procurementProduct: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "procurementProduct",
                        title: "採購產品機構與趨勢",
                        type: "textarea",
                    },
                    ourProportion: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "ourProportion",
                        title: "我方佔比與趨勢",
                        type: "text",
                    },
                    procurementLevels: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "procurementLevels",
                        title: "採購層級相較於競爭者",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    productQuality: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "productQuality",
                        title: "產品品質相較於競爭者",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    productPrice: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "productPrice",
                        title: "產品價格相較於競爭者",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    reputation: {
                        mainCategory: "prchasing",
                        secondaryCategory: "採購我方的現況",
                        key: "reputation",
                        title: "聲譽相較於競爭者",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    windowLevel: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "windowLevel",
                        title: "主窗口層級",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    procurementInitiative: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "procurementInitiative",
                        title: "採購主動性",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    informationShared: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "informationShared",
                        title: "客戶分享的資訊類別",
                        type: "text",
                    },
                    topics: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "topics",
                        title: "客戶對哪些議題有興趣",
                        type: "textarea",
                    },
                    testingProduct: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "testingProduct",
                        title: "客戶測試產品時間",
                        type: "textarea",
                    },
                    tolerance: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "tolerance",
                        title: "客戶對測試失敗容忍度",
                        type: "textarea",
                    },
                    motivation: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "motivation",
                        title: "客戶主動聯繫的動機",
                        type: "textarea",
                    },
                    salesLevels: {
                        mainCategory: "prchasing",
                        secondaryCategory: "客戶採購意願",
                        key: "salesLevels",
                        title: "評估我方的銷售層級",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                },
            },
            competitiveSituations: {
                title: "競爭態勢",
                items: {
                    salesType: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶的主力客戶",
                        key: "salesType",
                        title: "銷售型態",
                        options: ["B2B", "B2C", "B2B2C", "B2B2B"],
                        type: "checkbox",
                    },
                    bargainingPower: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶的主力客戶",
                        key: "bargainingPower",
                        title: "議價能力",
                        type: "text",
                    },
                    purchasedDirectly: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶的主力客戶",
                        key: "purchasedDirectly",
                        title: "有無向我方直接購買",
                        type: "textarea",
                    },
                    pressure: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶的主力客戶",
                        key: "pressure",
                        title: "給客戶的壓力",
                        type: "textarea",
                    },
                    trends: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶的主力客戶",
                        key: "trends",
                        title: "購買趨勢",
                        type: "textarea",
                    },
                    advantage1: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "advantage1",
                        title: "優勢1",
                        type: "textarea",
                    },
                    advantage2: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "advantage2",
                        title: "優勢2",
                        type: "textarea",
                    },
                    advantage3: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "advantage3",
                        title: "優勢3",
                        type: "textarea",
                    },
                    strong: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "strong",
                        title: "強勢產品",
                        type: "textarea",
                    },
                    potential: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "potential",
                        title: "潛力產品",
                        type: "textarea",
                    },
                    growth: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭優勢",
                        key: "growth",
                        title: "成長產品",
                        type: "textarea",
                    },
                    disadvantage1: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "disadvantage1",
                        title: "劣勢1",
                        type: "textarea",
                    },
                    disadvantage2: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "disadvantage2",
                        title: "劣勢2",
                        type: "textarea",
                    },
                    disadvantage3: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "disadvantage3",
                        title: "劣勢3",
                        type: "textarea",
                    },
                    declining: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "declining",
                        title: "衰退產品",
                        type: "textarea",
                    },
                    obstacles: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "obstacles",
                        title: "客戶遇到的阻礙",
                        type: "textarea",
                    },
                    perspectives: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶競爭劣勢",
                        key: "perspectives",
                        title: "客戶對阻礙的看法",
                        type: "textarea",
                    },
                    costTrends: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "costTrends",
                        title: "成本趨勢",
                        type: "textarea",
                    },
                    regulatory: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "regulatory",
                        title: "法規變動",
                        type: "textarea",
                    },
                    replacement: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "replacement",
                        title: "替代產品",
                        type: "textarea",
                    },
                    demandTrends: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "demandTrends",
                        title: "市場需求趨勢",
                        type: "textarea",
                        customerClass: "col-sm-6",
                    },
                    supplyTrends: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "supplyTrends",
                        title: "市場供給趨勢",
                        type: "textarea",
                        customerClass: "col-sm-6",
                    },
                    marketTrends: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "marketTrends",
                        title: "影響市場趨勢的因素",
                        type: "textarea",
                    },
                    opportunities: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "opportunities",
                        title: "機會",
                        type: "textarea",
                    },
                    summary: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "summary",
                        title: "環境總結",
                        type: "textarea",
                    },
                    expandMarkets: {
                        mainCategory: "competitiveSituations",
                        secondaryCategory: "客戶環境變化",
                        key: "expandMarkets",
                        title: "客戶拓展市場的機會和手段",
                        type: "textarea",
                    },
                },
            },
            spwRatings: {
                title: "SPW評分",
                items: {
                    potentialScore: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "potentialScore",
                        title: "客戶規模潛力",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    potentialNarrative: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "potentialNarrative",
                        title: "規模潛力敘述",
                        type: "textarea",
                    },
                    purchasingScore: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "purchasingScore",
                        title: "客戶採購政策",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    purchasingDesc: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "purchasingDesc",
                        title: "採購政策敘述",
                        type: "textarea",
                    },
                    intentionScore: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "intentionScore",
                        title: "客戶採購意願",
                        type: "text",
                        customerClass: "col-sm-6",
                    },
                    intentionDesc: {
                        mainCategory: "spwRatings",
                        secondaryCategory: "我方視角分析",
                        key: "intentionDesc",
                        title: "採購意願敘述",
                        type: "textarea",
                    },
                },
            },
        },
        scalePotentialOptions: [
            { key: "startupInfo", label: "創立資料(創業時間、創立者、員工人數)" },
            { key: "customerMission", label: "客戶經營理念" },
            { key: "financeData", label: "重要財務資料(股本、每股獲利盈餘)" },
            { key: "revenueTrend", label: "近三年營收趨勢" },
            { key: "profitTrend", label: "近三年獲利趨勢" },
            { key: "productSalesTrend", label: "最近三年產品結構與銷售比例趨勢變化" },
            { key: "marketScope", label: "客戶市場範圍具有潛力？是否為我們想經營？" },
            { key: "salesRegion", label: "客戶本身銷售的產品結構比例，以及所銷售的區域" },
            { key: "industryRank", label: "客戶在業界的排名狀況及其他有關的財務資料" },
            { key: "valuePricing", label: "客戶本身的價值定位及價格定位，以及所提供的產品地圖完整度" },
            { key: "salesTrend", label: "客戶的業務其銷售動機，以及客戶目前整體銷售趨勢" },
        ],
        purchasePolicyOptions: [
            { key: "industryPurchaseConcept", label: "客戶的行業整體採購理念、採購流程" },
            { key: "purchaseCriteria", label: "客戶採購的選擇標準" },
            { key: "purchaseTrend", label: "客戶採購態勢" },
            { key: "paymentTerms", label: "客戶整體的付款條件" },
        ],
        purchaseIntentOptions: [
            { key: "paymentTermsUs", label: "客戶針對我方的付款條件" },
            { key: "purchaseLevelTrend", label: "採購我方的層級及產品趨勢" },
            { key: "comparisonWithCompetitors", label: "相較於競爭者，我方..." },
            { key: "purchaseWindowLevel", label: "客戶採購主窗口層級為何？主動還是被動聯繫？" },
            { key: "sharedInformation", label: "客戶會分享甚麼資訊？" },
            { key: "interestTopics", label: "客戶對那些議題感興趣？" },
            { key: "productTestTime", label: "客戶花多少時間測試產品？失敗容忍度如何？" },
            { key: "currentSalesLevel", label: "我方當前的銷售層級為何？" },
        ],
    },
    mutations: {
        updateCategories(state, { categoryName, payload }) {
            state.categories[categoryName] = payload
        },
        updateProject(state, payload) {
            state.project = payload
        },
        updateCustomer(state, payload) {
            state.customer = payload
        },
        updatePlan(state, payload) {
            state.plan = payload
        },
    },
    actions: {
        actionGetCategories({ state, commit }, categoryName) {
            const params = {
                class: state.categoryMap[categoryName].class,
            }
            api.getSelections(params).then((res) => {
                commit("updateCategories", { categoryName, payload: res.data })
            })
        },
        actionGetProject({ state, commit, dispatch }, projectId) {
            api.getEzProject(projectId).then((res) => {
                commit("updateProject", res.data)
                dispatch("actionGetCustomer", res.data.Customer.id)
            })
        },
        actionGetCustomer({ state, commit }, customerId) {
            api.getCustomer(customerId).then((res) => {
                commit("updateCustomer", res.data)
            })
        },
        actionGetPlan({ state, commit }, planId) {
            return api.getEzPlan(planId).then((res) => {
                commit("updatePlan", res.data)
            })
        },
        actionUpdatePlan({ state, commit }, payload) {
            commit("updatePlan", payload)
        },
        clearPlan({ state, commit }) {
            commit("updatePlan", {
                perspective: "",
                type: "",
                demand: "",
                opportunity: "",
                analysis: "",
                name: "",
                action: "",
                issue: "",
                base: "",
                shareholder: "",
                potential: "",
                procurement: "",
                prchasing: "",
                spwRatings: "",
                competitiveSituations: "",
                customerCompetitors: "",
                customerOther: "",
            })
        },
    },
    getters: {
        getCategories: (state) => (categoryName) => {
            if (state.categories[categoryName].length === 0) return []
            return state.categories[categoryName]
        },
    },
}
