const permissions = {
    PERMISSION_EFFECT_ALLOW: "effect:allow",
    PERMISSION_EFFECT_DENY: "effect:deny",

    // function base
    PERMISSION_ADMIN: "ADMIN",

    // 上方功能項
    PERMISSION_SCHEDULE_APPROVE: "schedule:approve", // 行事曆 審核人
    PERMISSION_BULLETIN_APPROVE: "bulletin:approve", // 公佈欄 審核人
    /// 出勤管理
    PERMISSION_ATTENDANCE0101_STAFF_LIST: "attendance0101:staffList", // 職工出勤總表 瀏覽者
    PERMISSION_ATTENDANCE0101_MANAGE: "attendance0101:manage", // 簽到/簽退維護人 管理者
    PERMISSION_ATTENDANCE0101_GROUP_MANAGE: "attendance0101:groupManage", // 列表群組管理員

    PERMISSION_ATTENDANCE0201_LIST: "attendance0201:list", // 外出訪談 全部瀏覽者

    PERMISSION_ATTENDANCE0101_LIST: "attendance0101:list", // 出勤請假 全部瀏覽者

    PERMISSION_FORGOT_PUNCH_IN_MANAGE: "forgotPunchIn:manage", // 忘刷紀錄管理者

    PERMISSION_SALES_ATTENDANCE_LIST: "salesAttendance:list", // 業務出勤列表 分區瀏覽者
    PERMISSION_SALES_ATTENDANCE_RELATED: "salesAttendance:related", // 業務出勤列表 分區顯示人員

    PERMISSION_INTERVIEW_GROUP_LIST: "interviewGroup:list", // 業務拜訪次數 全部瀏覽者
    PERMISSION_INTERVIEW_GROUP_MANAGE: "interviewGroup:manage", // 業務拜訪次數 名單管理者

    PERMISSION_ATTENDANCE0501_LIST: "attendance0501:list", // 瀏覽請假審核列表 全部瀏覽者

    /// 工作提醒
    PERMISSION_GRAVEAFFAIR_LIST: "graveAffair:list", // 重大事件 全部瀏覽者

    /// 訪談報告
    PERMISSION_INTERVIEW0301_ACCESS: "interview0301:access", // 業務訪談統計 可瀏覽者
    PERMISSION_INTERVIEW0201_ACCESS: "interview0201:access", // 客戶拜訪狀況查詢 可瀏覽者(僅剩交易機會有用) //似乎已棄用

    /// 工作管理
    PERMISSION_JOBLIST_LIST: "joblist:list", // 全部瀏覽者
    PERMISSION_JOBLIST_TYPE_MANAGE: "joblist:typeManage", // 前台工作類別管理者
    PERMISSION_JOBLIST_PROGRESS_MANAGE: "joblist:progressManage", // 前台工作進度管理者
    PERMISSION_JOBLIST_CUSTOMER_TYPE_MANAGE: "joblist:customerTypeManage", // 已棄用

    /// 訂單管理
    PERMISSION_ORDER_LIST: "order:list", // 訂單管理 全部瀏覽人
    PERMISSION_ORDERS0102_LIST: "orders0102:list", // 訂單管理 全部瀏覽人
    PERMISSION_ORDER_AMOUNT_VIEWER: "order:amountViewer", // 訂單管理 可看金額

    /// 料件庫存
    PERMISSION_INVENTORY_LIST: "inventory:list", // 庫存統計 全部瀏覽者
    PERMISSION_INVENTORY_LIST_EXCEPT_MONEY: "inventory:listExceptMoney", // 庫存統計 可檢視除金額外之庫存資料

    /// 採購專區
    PERMISSION_GRAVE_AFFAIR0201_LIST: "graveAffair0201:list", // 原料市價波動 分區瀏覽者
    PERMISSION_PURCHASING_ESTIMATES0201_LIST: "purchasingEstimates0201:list", // 用料預估統計 分區瀏覽者
    // 供應商平台
    PERMISSION_SUPPLIER_PLATFORM_LIST: "supplierPlatform:list", // 供應商平台 可瀏覽者
    PERMISSION_SUPPLIER_PLATFORM_MANAGE: "supplierPlatform:manage", // 供應商平台 供應商維護人

    /// 業務專區 ///
    PERMISSION_COMPANY_SALES_LIST: "companySales:list", // 公司銷售狀況

    PERMISSION_WEEKLY_REVIEW_MANAGE: "weeklyReview:manage", //每週業績進度 分區管理者

    PERMISSION_INQUIRY_ACTION0101_LIST: "inquiryAction0101:list", // 網站客戶詢價 全部瀏覽者
    PERMISSION_INQUIRY_ACTION0101_MANAGE: "inquiryAction0101:manage", // 網站客戶詢價 負責人
    PERMISSION_INQUIRY_ACTION0101_APPROVE: "inquiryAction0101:approve", // 網站客戶詢價 審核人

    PERMISSION_QUESTIONNIRE_LIST: "questionnaire:list", // 顧客滿意度調查 可瀏覽者

    PERMISSION_TOP20CUSTOMER0101_LIST: "top20customer0101:list", // 二十大客戶 全部瀏覽者

    PERMISSION_TRANSACTION0201_LIST: "transaction0201:list", // 久未交易客戶 全部瀏覽者
    // 生產損益平衡
    BREAKEVEN_LIST: "breakeven:list", // 分區瀏覽者

    /// EZ-Sales ///
    PERMISSION_EZ_SALES_ALL_VIEWER: "ezSales:allViewer", // 全部瀏覽者

    /// 試料管理 ///
    PERMISSION_TEST_MATERIAL_LIST: "testMaterial:list", // 試料管理 部分瀏覽者
    PERMISSION_TEST_MATERIAL0302_SUBMIT: "testMaterial0302:submit", // 試料樣本紀錄 分區填表人/委託人
    PERMISSION_TEST_MATERIAL0302_TEST: "testMaterial0302:test", // 試料樣本紀錄 分區試料人/收件人
    PERMISSION_TEST_MATERIAL0302_RELATED: "testMaterial0302:related", // 試料樣本紀錄 分區關係人

    /// 競爭對手規格 ///
    PERMISSION_COMPETITION_LIST: "competition:list", // 競爭對手規格 可瀏覽者
    PERMISSION_COMPETITION_MANAGE: "competition:manage", // 競爭對手規格 維護人員

    /// 委外測試報告 ///
    PERMISSION_OUTSOURCING_TEST0102_LIST: "outsourcingTest0102:list", // 委外測試報告 全部瀏覽者
    PERMISSION_OUTSOURCING_TEST0102_REPORT: "outsourcingTest0102:report", // 委外測試報告 報告人

    /// 客戶管理 ///
    PERMISSION_CLIENT_SALES_MANAGE: "client:salesManage", // 客戶管理 負責業務修改者

    /// 專案管理 ///
    PERMISSION_PROJECT_LIST: "project:list", // 專案管理 全部瀏覽者
    PERMISSION_PROJECT_MANAGE: "project:manage", // 專案管理 專案管理指派者

    /// 會議管理 ///
    PERMISSION_CONFERENCE_LIST: "conference:list", // 會議管理 全部/禁止/部份瀏覽人
    // 全部瀏覽人
    // effect:allow
    // 禁止瀏覽人
    // effect:deny
    // 部份瀏覽人
    // effect:allow resource_id:district/DISTRICT

    /// 文件管理
    PERMISSION_DOCUMENT_LIST: "document:list", // 文件管理 全部/禁止 瀏覽者
    PERMISSION_DOCUMENT_APPROVE: "document:approve", // 文件管理 審核者
    PERMISSION_DOCUMENT_UPDATE: "document:update", // 文件管理 修改刪除者
    PERMISSION_DOCUMENT_TYPE_MANAGE: "document:typeManage", // 文件管理 類別管理者

    ///機台管理
    PERMISSION_MACHINE_MANAGEMENT0101_LIST: "machineManagement0101:list", // 機台管理 各區瀏覽人

    /// 研發專區 ///
    // TGA_DSC
    PERMISSION_TGA_DSC0101_LIST: "TGA_DSC0101:list", // TGA_DSC 瀏覽人
    PERMISSION_TGA_DSC0101_CREATE: "TGA_DSC0101:create", // TGA_DSC 新增者
    PERMISSION_TGA_DSC0101_MANAGE: "TGA_DSC0101:manage", // TGA_DSC 維護人員
    // 新技術開發評鑑
    PERMISSION_TECHNICAL_EVALUATION0101_LIST: "technicalEvaluation0101:list", // 新技術開發評鑑 瀏覽人
    PERMISSION_TECHNICAL_EVALUATION0101_CREATE: "technicalEvaluation0101:create", // 新技術開發評鑑 新增者
    PERMISSION_TECHNICAL_EVALUATION0101_RATE: "technicalEvaluation0101:rate", // 新技術開發評鑑 評鑑人

    /// 管理專區 ///
    // 商標專利管理
    PERMISSION_ADMINISTRATION_TRADEMARK_MANAGE: "administrationTrademark:manage",
    PERMISSION_ADMINISTRATION_TRADEMARK_EXPIRY_HANLDER: "administrationTrademark:expiryHanlder",
    // 證照管理
    PERMISSION_CERTIFICATE_LIST: "certificate:list", // 證照管理 可瀏覽者
    PERMISSION_CERTIFICATE_MANAGE: "certificate:manage", // 證照管理 管理者
    // 合約管理
    PERMISSION_ADMINISTRATION_LEASE_LIST: "lease:list", // 合約管理 分區可瀏覽者
    PERMISSION_ADMINISTRATION_LEASE_MANAGE: "lease:manage", // 合約管理 分區管理者
    // 公司規章教育訓練
    PERMISSION_ADMINISTRATION_REGULATION_LIST_MANAGE: "administrationRegulationList:manage", // 公司規章教育訓練 管理者
    // 工作準則
    PERMISSION_ADMINISTRATION_JOB_RULE_LIST: "administrationJobRule:list", // 工作準則 全部瀏覽者
    PERMISSION_ADMINISTRATION_JOB_RULE_MANAGE: "administrationJobRule:manage", // 工作準則 管理者
    // 教育訓練申請
    PERMISSION_TRAINING_LIST: "training:list", // 教育訓練申請 全部瀏覽者
    PERMISSION_INTERNAL_TRAINING_APPLICANT: "internalTraining:applicant", // 內訓申請人
    PERMISSION_TRAINING_MANAGE: "training:manage", // 人資
    // 費用登錄表
    FEE_ALL_VIEWER_1: "administrationFee01:allViewer", // 費用登入表 全部瀏覽者
    FEE_MANAGE: "administrationFee01:manage", // 費用登入表 費用審核者
    // 碳排放查核表
    FEE_ALL_VIEWER_2: "administrationFee02:allViewer", // 碳排放查核表 全部瀏覽者

    /// 重大事件關係人 ///
    PERMISSION_GRAVEAFFAIR_RELATED: "graveAffair:related", // 重大事件 關係人

    /// 外出訪談紀錄
    PERMISSION_ATTENDANCE0201_OBSERVE: "attendance0201:observe", // 外出訪談紀錄 額外觀察對象

    ///
    ///

    // PERMISSION_GRAVEAFFAIR_EXECUTE : 'graveAffair:execute' // wrong? //沒用

    PERMISSION_TRANSACTION0201_ACCESS: "transaction0201:list", // 久未交易客戶 全部瀏覽者

    // 試料(這兩個目前沒有使用)
    PERMISSION_TEST_MATERIAL_APPROVE: "testMaterial:approve", // 試料 審核人
    PERMISSION_TEST_MATERIAL0102_LIST: "testMaterial0102:list", // 試料 各區瀏覽人

    // testMaterial0302:submit resource_id:district/DISTRICT

    //   resource_id -> productCateogry:productCateogry/district:district

    // PERMISSION_OUTSOURCING_TEST0102_ : 'TGA_DSC0101:manage'

    // effect:allow resource_id:district/DISTRICT

    // 財務
    PERMISSION_FINANCE_LIST: "graveAffair0501:list",

    //可外部簽到人員
    PERMISSION_EXTERNAL_SIGN_IN_ACCESS: "externalSignIn:access", // 可外部簽到人員

    // 業務每週進度檢討
    PERMISSION_WEEKLY_REVIEW_REPORTER: "weeklyReview:reporter", // 報告者
}

export default permissions
