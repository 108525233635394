<template>
    <div id="content" class="boxx">
        <div class="d-flex align-center justify-space-between">
            <ul id="TAB" class="nav nav-tabs">
                <li role="presentation" class="active">
                    <router-link to="/home">{{ $t("page.home.notifi") }}</router-link>
                </li>
                <!-- <li role="presentation"><router-link to="/">已審核列表</router-link></li> -->
            </ul>
            <div class="login-times">
                <button type="button" class="btn btn-info" @click="handleTodayEventsClick">
                    {{ $t("page.home.importantMatters.todayImportantMatters") }}
                </button>
                <div class="btn btn-info mx-2" @click="showLogingTimes = !showLogingTimes">
                    {{ $t("page.home.last3LoginTime") }}
                    <span class="ml-2 down caret"></span>
                </div>
                <ul v-if="showLogingTimes">
                    <li v-for="date in loginLogs" :key="date.CreatedAt">
                        {{ date.CreatedAt | formatDateUTC("YYYY-MM-DD HH:mm:ss") }}
                    </li>
                    <li v-if="loginLogs.length === 0">
                        {{ $t("misc.emptyData") }}
                    </li>
                </ul>
            </div>
        </div>

        <!--Modal -->
        <div id="accountModal" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header color1">
                        <button type="button" class="close" data-dismiss="modal">
                            <span class="close_COLOR" aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h2 class="modal-title">
                            {{ $t("sideMenu.graveAffair.title") }} -
                            {{ $t("page.graveAffair0101.accountOverdue") }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <ul class="modal_ul">
                            <li>
                                <span class="span_name">{{ $t("page.graveAffair0101.executor") }}</span>
                                {{
                                    getUser(theoverdueAccountlist.employeeSerialNumber, {
                                        isNumber: true,
                                        fallbackAsMe: false,
                                    }).chinese_name ||
                                    getUser(theoverdueAccountlist.employeeSerialNumber, {
                                        isNumber: false,
                                        fallbackAsMe: false,
                                    }).chinese_name ||
                                    theoverdueAccountlist.employeeSerialNumber
                                }}
                            </li>
                            <li>
                                <span class="span_name">{{ $t("page.graveAffair0101.reportTime") }}：</span>
                                <span v-if="new Date().getMonth() < 9">{{ new Date().getFullYear() }}/0{{ new Date().getMonth() + 1 }}/</span>
                                <span v-else>{{ new Date().getFullYear() }}/{{ new Date().getMonth() + 1 }}/</span>
                                <span v-if="new Date().getDate() < 10">0{{ new Date().getDate() }}</span>
                                <span v-else>{{ new Date().getDate() }}</span>
                            </li>
                            <li>
                                <span class="span_name">{{ $t("page.graveAffair0101.reportContent") }}：</span>
                                <textarea class="form-control" row="4" id="CONT" v-model="accountModal_report.content"></textarea>
                            </li>
                        </ul>
                    </div>
                    <!-- /.modal-body -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal" @click="posttheReport">
                            {{ $t("misc.report") }}
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            {{ $t("misc.close") }}
                        </button>
                    </div>
                    <!-- /.modal-footer -->
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->

        <!--Modal -->
        <div id="schedualeModal" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header color1">
                        <button type="button" class="close" data-dismiss="modal">
                            <span class="close_COLOR" aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h2 class="modal-title">{{ $t("page.home.calReview") }}-{{ Approvaldetail.title }}</h2>
                        {{ userId }}
                    </div>
                    <div class="modal-body">
                        <ul class="modal_ul">
                            <li>
                                <span class="span_name">{{ $t("misc.topic") }}：</span>
                                <span>{{ Approvaldetail.title }}</span>
                            </li>
                            <li>
                                <span class="span_name">{{ $t("misc.date") }}：</span>
                                <span>{{ Approvaldetail.date | formatDate("YYYY-MM-DD") }}</span>
                            </li>
                            <li>
                                <span class="span_name">{{ $t("misc.time") }}：</span>
                                <span>{{ Approvaldetail.startTime }}</span>
                            </li>
                            <li>
                                <span class="span_name">{{ $t("misc.content") }}：</span>
                                <span>{{ Approvaldetail.content }}</span>
                            </li>
                        </ul>
                    </div>
                    <!-- /.modal-body -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal" @click="updateStatusschedule(Approvaldetail.scheduleId)">
                            {{ $t("page.home.approve") }}
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            {{ $t("misc.close") }}
                        </button>
                    </div>
                    <!-- /.modal-footer -->
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="box">
            <div v-if="$i18n.locale != 'en-US'" class="table-responsive clearfloat">
                <h2>{{ $t("page.home.latestAnno") }}</h2>
                <table class="table table-striped table-hover n-table-head TGA_DSC">
                    <thead>
                        <tr>
                            <td>{{ $t("page.home.startTime") }}</td>
                            <td>{{ $t("misc.priority") }}</td>
                            <td>{{ $t("misc.title") }}</td>
                            <td>{{ $t("page.home.annoPeople") }}</td>
                            <td>{{ $t("misc.department") }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="bulletin in bulletincontent" :key="bulletin.bulletinId">
                            <td>
                                <span>{{ bulletin.startDate | formatDateUTC("YYYY-MM-DD") }}</span>
                            </td>
                            <td>{{ bulletinImportanceMap[bulletin.importance] }}</td>
                            <td>
                                <a @click="handleShowBulletinClick(bulletin)">{{ bulletin.title }}</a>
                            </td>
                            <td>
                                {{ getUser(bulletin.userId).chinese_name }}
                            </td>
                            <td v-if="bulletin.userId">
                                {{ getUser(bulletin.userId).departments[0].department.name }}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2">
                                <span style="float: right">
                                    <router-link :to="{ name: 'bulletin0101' }">{{ $t("misc.more") }} >> </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr v-if="$i18n.locale != 'en-US'" />
            <div v-if="$i18n.locale != 'en-US'" class="table-responsive clearfloat">
                <h2>{{ $t("sideMenu.graveAffair.title") }}-{{ $t("permissionList.graveAffair") }}</h2>
                <table class="table table-striped table-hover n-table-head TGA_DSC">
                    <thead>
                        <tr>
                            <td>{{ $t("misc.date") }}</td>
                            <td>{{ $t("misc.title") }}</td>
                            <td>{{ $t("misc.customerName") }}</td>
                            <td>{{ $t("page.graveAffair0101.executor") }}</td>
                            <td>{{ $t("misc.price") }}</td>
                            <td>{{ $t("misc.currency") }}</td>
                            <td>{{ $t("misc.process") }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="account in overdueAccounts" :key="account.id">
                            <td>
                                <span>{{ account.graveAffairDate | formatDate("YYYY-MM-DD") }}</span>
                            </td>
                            <td>{{ $t("page.graveAffair0101.accountOverdue") }}</td>
                            <td>{{ account.customerShortName }}</td>
                            <td>
                                <a>
                                    <router-link
                                        :to="{
                                            name: 'graveAffair0103event',
                                            params: { id: account.id },
                                        }"
                                    >
                                        {{
                                            getUser(account.employeeSerialNumber, {
                                                isNumber: true,
                                                fallbackAsMe: false,
                                            }).chinese_name ||
                                            getUser(account.employeeSerialNumber, {
                                                isNumber: false,
                                                fallbackAsMe: false,
                                            }).chinese_name ||
                                            account.employeeSerialNumber
                                        }}</router-link
                                    >
                                </a>
                            </td>
                            <td>{{ account.amount | currency }}</td>
                            <td>{{ account.currency }}</td>
                            <td>
                                <a @click="getOverdueAccountDetail(account)" data-toggle="modal" data-target="#accountModal">{{
                                    $t("misc.report")
                                }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2">
                                <span style="float: right">
                                    <router-link :to="{ name: 'graveAffair0101' }">{{ $t("misc.more") }} >> </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr v-if="$i18n.locale != 'en-US'" />
            <div
                class="table-responsive clearfloat"
                :class="{
                    defaultHide: true,
                    show: hasPermission([PERMISSION_INVENTORY_LIST, PERMISSION_INVENTORY_LIST_EXCEPT_MONEY]),
                }"
            >
                <h2>{{ $t("sideMenu.material.title") }}</h2>
                <p class="text-red font-bold">
                    {{ $t("page.material0101.storageTotal") }}： {{ aggrStockObj["kg"] | currency }} (KG) ;
                    <span v-if="isInventoryCashViewer">
                        {{ $t("page.material0101.storagePriceTotal") }}：{{ totalMoney | currency }}
                        (NTD)
                    </span>
                </p>
                <table class="table table-striped table-hover n-table-head TGA_DSC font-bold">
                    <thead>
                        <tr>
                            <td>{{ $t("misc.region3") }}</td>
                            <td>{{ $t("page.material0101.currentNumber") }}(KG)</td>
                            <td v-if="isInventoryCashViewer" class="text-right">
                                {{ $t("page.material0101.cPrice") }}
                            </td>
                            <td v-if="isInventoryCashViewer">{{ $t("misc.currency") }}</td>
                            <td v-if="isInventoryCashViewer" class="text-right">{{ $t("page.material0101.twdPrice") }}(NTD)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="d in kgStocks" :key="d.id">
                            <td>{{ $t($store.state.districtCodes[d.dbSerialNumber]) }}</td>
                            <td>{{ d.existingStock | currency }} ({{ d.unit.toLowerCase() }})</td>
                            <td v-if="isInventoryCashViewer" class="text-right">{{ d.cost | currency }}</td>
                            <td v-if="isInventoryCashViewer">{{ d.currency }}</td>
                            <td v-if="isInventoryCashViewer" class="text-right">{{ d.costInNtd | currency }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" v-if="isInventoryCashViewer"></td>
                            <td colspan="2" class="text-right">
                                <router-link :to="{ name: 'material0101' }">{{ $t("misc.more") }} >> </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <div class="table-responsive clearfloat">
                <h2>{{ $t("page.home.latestJob") }}</h2>
                <table class="table table-striped table-hover n-table-head TGA_DSC">
                    <thead>
                        <tr>
                            <td>{{ $t("misc.startTime") }}</td>
                            <td>{{ $t("page.joblist0101.jobType") }}</td>
                            <td>{{ $t("page.joblist0101.assignTarget") }}</td>
                            <td>{{ $t("misc.topic") }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(job, index) in jobs" :key="job.JobId" v-show="index <= 10">
                            <td>{{ job.startDate | formatDate("YYYY-MM-DD") }}</td>
                            <td>{{ getJobCategoryName(job.jobCategory) }}</td>
                            <td>{{ getUser(job.assignPerson).chinese_name }}</td>
                            <td>
                                <a @click="openJobContentModal(job)">{{ job.title }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colspan="2">
                                <span style="float: right">
                                    <router-link :to="{ name: 'joblist0101' }">{{ $t("misc.more") }} >> </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <div class="table-responsive clearfloat">
                <h2>{{ $t("page.home.unapprove") }}</h2>
                <table class="table table-striped table-hover n-table-head TGA_DSC">
                    <thead>
                        <tr>
                            <td>{{ $t("misc.type2") }}</td>
                            <td>{{ $t("page.home.title") }}</td>
                            <td>{{ $t("misc.applicantPeople") }}</td>
                            <td>{{ $t("misc.date") }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="s in scheduleList" :key="s.scheduleId">
                            <td>{{ $t("permissionList.schedule") }}</td>
                            <td>
                                <a data-toggle="modal" data-target="#schedualeModal" @click="getDetails(s)">{{ s.title }}</a>
                            </td>
                            <td>{{ getUser(s.userId).chinese_name }}</td>
                            <td>{{ s.date | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="bulletin in bulletinList" :key="bulletin.bulletinId">
                            <td>{{ $t("page.home.bulletin") }}</td>
                            <td>
                                <a @click="handleBulletinClick(bulletin)">{{ bulletin.title }}</a>
                            </td>
                            <td>{{ getUser(bulletin.userId).chinese_name }}</td>
                            <td>{{ bulletin.startDate | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="visit in approveVisits" :key="visit.VisitId">
                            <td>{{ $t("page.home.visitTicket") }}</td>
                            <td>
                                <a @click="handleApproveVisit(visit)">
                                    <span v-for="interview in visit.Interview" :key="interview.interviewId" class="mr-1">
                                        {{ interview.Client.shortName }}
                                    </span>
                                </a>
                            </td>
                            <td>{{ getUser(visit.userId).chinese_name }}</td>
                            <td>{{ visit.startDate | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="leave in approveLeaves" :key="leave.leaveId">
                            <td>{{ $t("page.home.leaveTicket") }}</td>
                            <td>
                                <a @click="handleApproveLeave(leave)">{{ leave.category }}</a>
                            </td>
                            <td>{{ getUser(leave.userId).chinese_name }}</td>
                            <td>{{ leave.startDate | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="item in getterApprovingDocuments" :key="item.documentId">
                            <td>{{ $t("page.home.approveFile") }}</td>
                            <td>
                                <a @click="handleShowDocument(item.documentId)">{{ item.name || "-" }}</a>
                            </td>
                            <td>{{ getUser(item.userId).chinese_name }}</td>
                            <td>{{ item.CreatedAt | formatDateUTC("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="data in webContactDatas" :key="data.id">
                            <td>{{ $t("sideMenu.customer.inquiryAction0101") }}</td>
                            <td>
                                <a @click="handleApproveWebContact(data)">{{ `${data.status}${$t("page.project0101.approval")}` }}</a>
                            </td>
                            <td>{{ data.reporter }}</td>
                            <td>{{ data.dateTime | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="training in approveTrainings" :key="training.leaveId">
                            <td>{{ $t("sideMenu.manageRule.jobTraining") }}</td>
                            <td>
                                <a @click="handleShowTraining(training)">{{ training.course }}</a>
                            </td>
                            <td>{{ getUser(training.createUser).chinese_name }}</td>
                            <td>{{ training.startDate | formatDate("YYYY-MM-DD") }}</td>
                        </tr>
                        <tr v-for="punchIn in approveforgotPunchIns" :key="punchIn.leaveId">
                            <td>忘刷紀錄</td>
                            <td>
                                <a @click="handleShowpunchIn(punchIn)">忘刷申請</a>
                            </td>
                            <td>{{ getUser(punchIn.user_id).chinese_name }}</td>
                            <td>{{ punchIn.date }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <today-events-modal />
            <show-bulletin-modal />
            <approve-visit-modal />
            <approve-leave-modal />
            <show-leave-modal />
            <show-web-contact-modal />
            <show-document-modal />
            <job-content-modal />
            <show-meeting-modal />
            <show-internal-training-modal />
            <show-external-training-modal />
            <show-forgot-punchin-form-modal />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex"
import moment from "moment"
import api from "../Api/Api"
import * as _ from "lodash"
import { userIdsToNames } from "@/lib/oa"
import JobContentModal from "@/components/modal/JobContent.vue"
import TodayEventsModal from "@/components/modal/TodayEvents.vue"
import ShowBulletinModal from "@/views/bulletin/modal/ShowBulletin"
import ApproveVisitModal from "@/components/modal/ApproveVisit.vue"
import ApproveLeaveModal from "@/components/modal/ApproveLeave.vue"
import ShowWebContactModal from "@/views/inquiryAction/modal/ShowWebContact"
import ShowDocumentModal from "@/views/fileManager/modals/ShowDocument"
import ShowLeaveModal from "@/views/attendance/modal/ShowLeave"
import ShowForgotPunchinFormModal from "@/views/attendance/modal/ShowForgotPunchinForm"
import ShowMeetingModal from "@/views/conferenceList/modal/ShowMeeting.vue"
import ShowInternalTrainingModal from "@/views/jobTraining/modal/ShowInternalTraining.vue"
import ShowExternalTrainingModal from "@/views/jobTraining/modal/ShowExternalTraining.vue"
import { Promise } from "q"
export default {
    name: "home",
    components: {
        JobContentModal,
        TodayEventsModal,
        ShowBulletinModal,
        ApproveVisitModal,
        ApproveLeaveModal,
        ShowWebContactModal,
        ShowDocumentModal,
        ShowLeaveModal,
        ShowForgotPunchinFormModal,
        ShowMeetingModal,
        ShowInternalTrainingModal,
        ShowExternalTrainingModal,
    },
    data() {
        return {
            userId: "",
            bulletincontent: [], //最新公告
            allOverdueAccounts: [], //工作提醒-帳款逾期
            theoverdueAccountlist: [], //工作提醒-重大事件
            accountModal_report: {
                //回報重大事件 .post
                content: "", //內容
                id: "", //事件Id
                name: "", //執行者
            },
            MachineEarlyWarning: [], //料件庫存
            jobs: [], //最新工作
            notReportJobs: [], //未回報工作
            schedule: [], //待簽審區－行事曆
            reviewBulletins: [], //待簽審區－公布欄
            approveVisits: [], //待簽審區－外出
            approveLeaves: [], //待簽審區－請假
            invalid_docs: [], //待簽審區－文件
            webContactDatas: [], //待簽審區－網站客戶詢價
            approveTrainings: [], //待簽審區－培訓
            approveforgotPunchIns: [], //待簽審區－忘刷卡
            Approvaldetail: {},
            abnormalOrders: [], //品質異常
            abnormalFluctuations: [], //原料異常漲跌3.5%
            filelist: [],
            files: {},
            File: {
                id: "",
                fileName: "",
                fileContent: "",
            },
            stockDistricts: [
                { id: "NT1", country: "臺灣" },
                { id: "SH1", country: "上海" },
                { id: "NT3", country: "美國" },
                { id: "SKY", country: "天冠" },
                { id: "NT4", country: "得意VMI" },
                { id: "NT5", country: "鴻海VMI" },
                { id: "NT6", country: "福田VMI" },
            ],
            stockData: [],
            stockSummaries: [],
            safetyStocks: [],
            projects: [],
            meetings: [],
            aggrStockObj: {
                kg: 0,
                pcs: 0,
            },
            totalMoney: 0,
            showLogingTimes: false,
        }
    },
    computed: {
        // ...mapState(["userId", "userToken"]),
        ...mapState(["userToken", "bulletinImportanceMap", "user", "jobCategories"]),
        ...mapState("userStore", ["userSettings"]),
        ...mapGetters(["getUser", "getApprovers", "hasPermission"]),
        ...mapGetters("doc", ["getterApprovingDocuments"]),
        scheduleList() {
            return this.hasPermission(this.PERMISSION_SCHEDULE_APPROVE) ? this.schedule : []
        },
        bulletinList() {
            return this.hasPermission(this.PERMISSION_BULLETIN_APPROVE) ? this.reviewBulletins : []
        },
        loginLogs() {
            return this.userProfile?.LoginLog || []
        },
        kgStocks() {
            return this.stockData.filter((stock) => stock.unit == "KG")
        },
        isInventoryCashViewer() {
            return this.hasPermission(this.PERMISSION_INVENTORY_LIST)
        },
        overdueAccounts() {
            return this.allOverdueAccounts.slice(0, 10)
        },
        bulletinEvents() {
            const todayBulletins = this.bulletincontent.filter((bulletin) => moment(bulletin.startDate).isSame(moment(), "day"))
            const events = todayBulletins.map((bulletin) => ({ type: "BULLETIN", content: bulletin }))
            return events
        },
        overdueAccountEvents() {
            const todayOverdues = this.allOverdueAccounts.filter((overdue) => overdue.employeeSerialNumber === this.user.number)
            const events = todayOverdues.map((overdue) => ({ type: "ACCOUNT", content: overdue }))
            return events
        },
        abnormalOrderEvents() {
            const todayAbnormalOrders = this.abnormalOrders.filter(
                (order) => order.serialNumber === this.user.number && moment(order.date).isSame(moment(), "day")
            )
            const events = todayAbnormalOrders.map((overdue) => ({ type: "ORDER", content: overdue }))
            return events
        },
        abnormalFluctuationEvents() {
            // const todayAbnormalFluctuations = this.abnormalFluctuations
            const todayAbnormalFluctuations = this.abnormalFluctuations.filter(
                (fluctuation) => fluctuation.executor === this.user.number && moment().diff(moment(fluctuation.importDate), "days") === 1
            )
            const events = todayAbnormalFluctuations.map((overdue) => ({
                type: "FLUCTUATION",
                content: overdue,
            }))
            return events
        },
        safetyStockEvents() {
            return this.safetyStocks.map((stock) => ({ type: "STOCK", content: stock }))
        },
        jobEvents() {
            return this.notReportJobs.map((job) => ({ type: "JOB", content: job }))
        },
        taskEvents() {
            const tasks = this.projects
                .map((project) => project.Task)
                .flat()
                .filter((task) => {
                    return task.executor.includes(this.userId) && task.status === "進行中" && !task.isReport
                })
            return tasks.map((task) => ({ type: "PROJECT", content: task }))
        },
        meetingEvents() {
            return this.meetings.map((meeting) => ({ type: "MEETING", content: meeting }))
        },
        showEvents() {
            return [
                ...this.bulletinEvents,
                ...this.overdueAccountEvents,
                ...this.abnormalOrderEvents,
                ...this.abnormalFluctuationEvents,
                ...this.safetyStockEvents,
                ...this.jobEvents,
                ...this.taskEvents,
                ...this.meetingEvents,
            ].filter((event) => this.userSettings.importantMatters.includes(event.type))
        },
    },
    asyncComputed: {},
    created() {
        this.userId = window.localStorage.getItem("login_UserId")
        if (!this.jobCategories.length) {
            this.actionGetJobCategory()
        }
        this.getbulletin() //最新公告
        this.getoverdueAccount() //工作提醒-重大事件
        this.getStockSummary()
        this.getJobs() //最新工作
        this.getuserProfiles()
        this.getSchedule() //待簽審區－行事曆
        this.getBulletinReview() //待簽審區－公布欄
        this.getApproveVisits() //待簽審區－外出
        this.getApproveLeaves() //待簽審區－請假
        this.actionGetDocumentApproving()
        this.getWebContactDatas() //待簽審區－網站客戶詢價
        this.getWaitApproveTrainings() //待簽審區－培訓
        this.getWaitApproveforgotPunchIns() //待簽審區－忘刷卡
        this.getVisitEvents()
        this.actionCallAfterReady(() => {
            this.getTodayImportantMatters().then(() => {
                const todayEventsHideUntil = window.localStorage.getItem("todayEventsHideUntil")
                let showTodayModal = true
                if (todayEventsHideUntil !== null) {
                    showTodayModal = moment(Number(todayEventsHideUntil)).valueOf() < moment().valueOf()
                }
                if (this.showEvents.length && showTodayModal) {
                    this.handleTodayEventsClick()
                }
            })
        })
    },
    methods: {
        ...mapActions("doc", ["actionGetDocumentApproving"]),
        ...mapActions(["actionGetJobCategory", "getVisitEvents", "actionCallAfterReady"]),
        getTodayImportantMatters() {
            // TODO 優化:有設定要顯示的再拉資料
            return Promise.all([
                this.getAbnormalOrders(), //品質異常
                this.getabRawMaterialFluctuations(), // 原料異常漲跌
                this.getSafetyStocks(), // 安全庫存
                this.getNotReportJobs(), // 未回報job
                this.getProjects(), // 專案管理
                this.getMeetings(), // 會議管理
            ])
        },
        handleShowDocument(documentId) {
            const prop = {
                documentId,
                callback: this.actionGetDocumentApproving,
            }
            this.$modal.show("showDocument", prop)
        },
        //最新公告
        getbulletin() {
            let params = {
                offset: 0,
                limit: 10,
                kind: "new",
            }
            api.getBulletin(params).then((res) => {
                this.bulletincontent = res.data
            })
        },
        handleShowBulletinClick(bulletin) {
            const prop = {
                bulletin: bulletin,
            }
            this.$modal.show("showBulletin", prop)
        },
        //工作提醒-重大事件
        getoverdueAccount() {
            const params = {
                limit: 100,
                offset: 0,
            }
            api.getOverdueAccountReceivables(params).then((res) => {
                this.allOverdueAccounts = res.data
            })
        },
        getOverdueAccountDetail(account) {
            this.theoverdueAccountlist = account
        },
        getAbnormalOrders() {
            return api
                .get("/abnormalOrders", {
                    params: {
                        offset: 0,
                        limit: 10,
                    },
                })
                .then((res) => {
                    this.abnormalOrders = res.data
                })
        },
        //原料異常漲跌3.5%
        getabRawMaterialFluctuations() {
            return api
                .get("/abnormalRawMaterialFluctuations", {
                    params: {
                        offset: 0,
                        limit: 10,
                    },
                })
                .then((res) => {
                    this.abnormalFluctuations = res.data.map((fluctuation) => {
                        const content = fluctuation.content
                        let content1 = "",
                            content2 = "",
                            color = ""
                        if (content.includes("跌")) {
                            content1 = content.split("跌")[0]
                            content2 = `跌${content.split("跌")[1]}`
                            color = "text-red"
                        } else if (content.includes("漲")) {
                            content1 = content.split("漲")[0]
                            content2 = `漲${content.split("漲")[1]}`
                            color = "text-blue"
                        } else {
                            content1 = content.split("持平")[0]
                            content2 = `持平${content.split("持平")[1]}`
                        }
                        return {
                            ...fluctuation,
                            content1,
                            content2,
                            color,
                        }
                    })
                })
        },
        // 安全庫存
        getSafetyStocks() {
            const params = {
                offset: 0,
                limit: 100,
                date: moment().format("YYYY-MM-DD"),
                warehouseKeeper: this.user.number,
            }
            return api.getSafetyStocks(params).then((res) => {
                this.safetyStocks = res.data
            })
        },
        //專案
        getProjects() {
            return api.getProjects().then((res) => {
                this.projects = res.data
            })
        },
        //會議
        getMeetings() {
            const params = {
                offset: 0,
                limit: 20,
                startAt: moment().startOf("day").valueOf(),
                endAt: moment().endOf("day").valueOf(),
                isHistory: false,
            }
            return api.getMeetings(params).then((res) => {
                this.meetings = res.data
            })
        },
        //回報重大事件
        posttheReport() {
            this.accountModal_report.id = `overdue_account_receivables:${this.theoverdueAccountlist.customerSerialNumber}`.toUpperCase()
            // this.accountModal_report.id = this.theoverdueAccountlist.id;
            this.accountModal_report.name = this.getUser().chinese_name
            api.post("/report", this.accountModal_report).then((res) => {
                this.accountModal_report.content = ""
            })
        },
        //料件庫存
        getStockSummary() {
            api.get("/stocks/summary").then((res) => {
                const stockSummaries = res.data
                this.stockSummaries = stockSummaries
                this.totalMoney = _.round(_.sumBy(stockSummaries, "costInNtd"), 2)
                this.stockData = _.sortBy(stockSummaries, [(d) => this.stockDistricts.map((o) => o.id).indexOf(d.dbSerialNumber)])
                this.aggrStockObj = _.reduce(
                    stockSummaries,
                    (a, c) => {
                        a[c.unit.toLowerCase()] += c.existingStock
                        return a
                    },
                    { kg: 0, pcs: 0 }
                )
            })
        },
        //最新工作
        getJobs() {
            const params = {
                assignee: this.userId,
                limit: 10,
                offset: 0,
            }
            api.getJobs(params).then((res) => {
                this.jobs = res.data
            })
        },
        //未回報工作
        getNotReportJobs() {
            const params = {
                assignee: this.userId,
                limit: 100,
                offset: 0,
                isReport: false,
            }
            api.getJobList(params).then((res) => {
                this.notReportJobs = res.data
            })
        },
        //get the user data
        getuserProfiles() {
            api.get("/user/" + this.userId).then((res) => {
                this.userProfile = res.data
            })
        },
        //待簽審區－行事曆
        getSchedule() {
            api.get("/schedules?" + "&status=未簽核").then((res) => {
                this.schedule = res.data
            })
        },
        //待簽審區－公布欄
        getBulletinReview() {
            let params = {
                kind: "audit",
            }
            api.getBulletin(params).then((res) => {
                this.reviewBulletins = res.data
            })
        },
        //待簽審區－外出
        getApproveVisits() {
            api.getWaitApproveVisits().then(async (res) => {
                this.approveVisits = res.data
            })
            // this.getpairvisitid(this.interviewlist)
        },
        //待簽審區－請假
        getApproveLeaves() {
            api.getWaitApproveLeaves().then(async (res) => {
                this.approveLeaves = res.data
            })
        },
        //待簽審區－網站客戶詢價
        getWebContactDatas() {
            api.getWebContactDatasApproving().then((res) => {
                Promise.all(
                    // 從report中取出申請人姓名
                    res.data.map(async (data) => {
                        const response = await api.getReports(data.id)
                        const report = response.data.filter((report) => report.type === "申請結案" || report.type === "申請成交")[0]
                        return {
                            ...data,
                            reporter: report?.name || "",
                        }
                    })
                ).then((datas) => {
                    this.webContactDatas = datas
                })
            })
        },
        //待簽審區－教育訓練
        getWaitApproveTrainings() {
            api.getWaitApproveTrainings().then(async (res) => {
                this.approveTrainings = res.data
            })
        },
        //待簽審區－教育訓練
        getWaitApproveforgotPunchIns() {
            api.getWaitApproveforgotPunchIns().then(async (res) => {
                this.approveforgotPunchIns = res.data
            })
        },
        getDetails(detail) {
            this.Approvaldetail = detail
        },
        handleBulletinClick(bulletin) {
            const prop = {
                bulletin: bulletin,
                callback: () => {
                    this.getbulletin()
                    this.getBulletinReview()
                },
            }
            this.$modal.show("showBulletin", prop)
        },
        updateStatusschedule(id) {
            var a = confirm("確定要批核嗎？")
            if (a) {
                api.put("/schedule/" + id, { status: "已簽核" }).then((res) => {
                    this.getSchedule()
                })
            }
        },
        getJobCategoryName(id) {
            return _.get(
                this.jobCategories.filter((c) => c.jobCategoryId === id),
                "0.name"
            )
        },
        openJobContentModal(job) {
            this.$modal.show("jobContent", {
                job: _.cloneDeep(job),
                callback: this.getJobs,
            })
        },
        handleApproveVisit(visit) {
            const prop = {
                visit: visit,
                callback: this.getApproveVisits,
            }
            this.$modal.show("approveVisit", prop)
        },
        handleApproveLeave(leave) {
            const prop = {
                leave: { ...leave, isApprover: true },
                callback: this.getApproveLeaves,
            }
            this.$modal.show("showLeave", prop)
        },
        userIdsToNames: userIdsToNames,
        handleApproveWebContact(webContact) {
            const prop = {
                data: webContact,
                callback: this.getWebContactDatas,
            }
            this.$modal.show("showWebContact", prop)
        },
        handleTodayEventsClick() {
            const prop = {
                events: [
                    ...this.bulletinEvents,
                    ...this.overdueAccountEvents,
                    ...this.abnormalOrderEvents,
                    ...this.abnormalFluctuationEvents,
                    ...this.safetyStockEvents,
                    ...this.jobEvents,
                    ...this.taskEvents,
                    ...this.meetingEvents,
                ],
                callback: () => {},
            }
            this.$modal.show("showTodayEvents", prop)
        },
        handleShowTraining(training) {
            const prop = {
                trainingId: training.id,
                traineeid: training.userId,
                callback: this.getWaitApproveTrainings,
            }
            if (training.kind == "內訓") {
                this.$modal.show("showInternalTraining", prop)
            } else {
                this.$modal.show("showExternalTraining", prop)
            }
        },
        handleShowpunchIn(punchIn) {
            const prop = {
                record: punchIn,
                callback: this.getWaitApproveforgotPunchIns,
            }
            this.$modal.show("showForgotPunchinForm", prop)
        },
    },
}
</script>
<style scoped>
#switcher_left {
    text-decoration: none;
    border-radius: 4px 0px 0px 4px;
}
#switcher_mid {
    text-decoration: none;
    border-radius: 0px 0px 0px 0px;
}
#switcher_right {
    text-decoration: none;
    border-radius: 0px 4px 4px 0px;
}
.login-times {
    position: relative;
    margin-right: 8px;
}
.login-times ul {
    width: 100%;
    margin-top: 4px;
    position: absolute;
    background-color: white;
    list-style-type: none;
    padding-inline-start: 0px;
    border: 1px gray solid;
    z-index: 10;
}
.login-times ul li {
    margin: 4px 8px;
}
</style>
