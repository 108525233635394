<template>
  <nav id="pages">
    <paginate
      v-model="page"
      :page-count="pageCount"
      :click-handler="pageChange"
      :prev-text="$t('misc.previous')"
      :next-text="$t('misc.next')"
      :container-class="'pagination'"
    ></paginate>
    <div class="pages_showing">
      <span v-if="local == 'en-US'">{{ countStart }}-{{ countEnd }} of {{ count }} ，Page</span>
      <span v-else>第 {{ countStart }}-{{ countEnd }} 筆 / 共 {{ count }} 筆資料，頁次</span>
      <select clss="form-control" v-model="page" @change="handleOnSelect">
        <option v-for="i in pageCount" :key="i" :value="i">{{ i }}</option>
      </select>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Pager",
  props: {
    value: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    pageChange: {
      type: Function,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  created() {
    this.page = this.value;
  },
  methods: {
    handleOnSelect(e) {
      this.pageChange(this.page);
    },
  },
  computed: {
    countStart: function() {
      return Math.max(1, (this.page - 1) * this.limit + 1);
    },
    countEnd: function() {
      return Math.min(this.page * this.limit, this.count);
    },
    local(){
      return this.$i18n.locale
    }
  },
  watch: {
    page: function(val) {
      this.$emit("input", val);
    },
    value: function(val) {
      this.page = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
