import zhtw from "./zh-TW.json";
import zhcn from "./zh-CN.json";
import enus from "./en-US.json";

const messages = {
  "en-US": enus,
  "zh-TW": zhtw,
  "zh-CN": zhcn,
};
export default messages;
