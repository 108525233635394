<template>
    <!-- 顯示文件 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ oaDocument.name }}</h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="span_name">文件位置：</span>
                            <a @click="handleChangePath(oaDocument.parentId)">{{ oaDocument.path }}</a>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("page.fileManager.createDate") }}：</span>
                            <span>{{ oaDocument.CreatedAt | formatDateUTC("YYYY-MM-DD") }}</span>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("page.fileManager.public") }}：</span>
                            <span>{{ opennessName[oaDocument.openness] }}</span>
                        </li>
                        <li>
                            <div class="flex">
                                <span class="span_name">{{ $t("page.fileManager.fileDescription") }}：</span>
                                <p class="">{{ oaDocument.content }}</p>
                            </div>
                        </li>
                        <li>
                            <div class="flex">
                                <span class="span_name shrink-0">{{ $t("page.fileManager.download") }}：</span>
                                <div class="flex flex-wrap">
                                    <div v-for="(file, i) in oaDocument.files" :key="i" class="flex mt-1 mr-2 shrink-0">
                                        <button
                                            class="btn btn-success mr-1"
                                            type="button"
                                            @click="openFile(file.path, file.fileName)"
                                        >
                                            {{ file.fileName }}
                                        </button>
                                        <vue-icon
                                            name="file_download"
                                            :size="24"
                                            class="clickable"
                                            color="#337ab7"
                                            @click="downloadDocument(file.path, file.fileName)"
                                            v-tooltip.bottom="$t('page.fileManager.download')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flex">
                                <span class="span_name">{{ $t("misc.tag") }}：</span>
                                <p
                                    class="_tag"
                                    v-for="tagRelation in oaDocument.documentTagsRelations"
                                    :key="tagRelation.id"
                                >
                                    {{ tagRelation.tag.name }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-success mx-1"
                        v-if="isApprover && oaDocument.approvalStatus == '未審核'"
                        @click="updateStatus('已簽核')"
                    >
                        {{ $t("page.inquiryAction0101.approval") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger mx-1"
                        v-if="isApprover && oaDocument.approvalStatus == '未審核'"
                        @click="updateStatus('否決')"
                    >
                        {{ $t("page.inquiryAction0101.reject") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters, mapActions } from "vuex"
import moment from "moment"
export default {
    data() {
        return {
            opennessName: {
                company: "全公司",
                customDepartments: "指定部門",
                customUsers: "指定員工",
                roleLevelAbove: "職位層級含以上",
            },
            modalName: "showDocument",
            callback: () => {},
            documentId: "",
            oaDocument: {
                documentTagsRelations: [],
            },
            changePath: () => {},
            folder: {},
        }
    },
    computed: {
        ...mapState(["userId", "bulletinImportanceMap"]),
        ...mapGetters(["hasPermission", "getUserToken"]),
        ...mapGetters("doc", ["getterFolderById"]),
        isApprover() {
            return this.hasPermission(this.PERMISSION_DOCUMENT_APPROVE)
        },
    },
    methods: {
        ...mapActions("doc", ["actionSetWorkingPathId"]),
        beforeOpen(e = {}) {
            this.documentId = e.documentId
            this.callback = e.callback || this.callback
            this.changePath = e.handleChangePath || this.changePath
            this.getDocumentInfo()
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        openFile(filePath, fileName) {
            if (this.folder.confidential && !/.pdf$/i.test(fileName)) {
                alert(this.$t("page.fileManager.notPdfDownloadAlert"))
                return
            }
            if (this.folder.confidential) {
                alert("為了保護公司文件安全，請避免將文件內容截圖、錄影或其他類似行為，也請勿將資料傳送給其他人。")
            }
            window.open(`${api.url}${filePath}?t=${this.getUserToken()}`)
        },
        downloadDocument(filePath, fileName) {
            if (this.folder.confidential && !/.pdf$/i.test(fileName)) {
                alert(this.$t("page.fileManager.notPdfDownloadAlert"))
                return
            }
            if (this.folder.confidential) {
                alert("為了保護公司文件安全，請避免將文件內容截圖、錄影或其他類似行為，也請勿將資料傳送給其他人。")
            }
            api.downloadDocument(filePath, fileName)
        },
        getDocumentInfo() {
            api.getDocument(this.documentId).then((res) => {
                this.oaDocument = res.data
                this.folder = this.getterFolderById(this.oaDocument.parentId)
            })
        },
        handleTakeDown() {
            const params = {
                approvalStatus: "已下架",
            }
            api.putBulletin(this.bulletin.bulletinId, params).then(() => {
                this.callback()
                this.close()
            })
        },
        updateStatus(status) {
            const params = {
                approvalStatus: status,
            }
            api.putDocument(this.oaDocument.documentId, params).then(() => {
                this.callback()
                this.close()
            })
        },
        handleChangePath(id) {
            this.changePath(id)
            this.close()
        },
    },
}
</script>

<style scoped>
.modal_ul > li {
    border-bottom: 0px;
}
._tag {
    position: relative;
    display: inline-block;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #41b883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
</style>
