<template>
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" @click="close">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ $t("page.joblist0101.jobContent") }}</h2>
                    {{ $debug(job.jobId) }}
                </div>
                <form class="form-horizontal" role="form">
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.currentJobProgress")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ getProcessName(job) }}
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.priority") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ $t(getI18nKey(job.importance)) }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("page.joblist0101.jobType") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ getJobCategoryName(job.jobCategory) }}
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.jobStartDate")
                            }}</label>

                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ job.startDate | formatDateUTC("YYYY-MM-DD") }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.assignTarget")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                <span
                                    v-for="(o, i) in job.assignPerson.split(',')"
                                    :key="i"
                                    style="margin-right: 12px"
                                    >{{ $store.getters.getUser(o).chinese_name }}</span
                                >
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.beAssignTarget")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                <span
                                    v-for="(o, i) in job.assignedPerson.split(',')"
                                    :key="i"
                                    style="margin-right: 12px"
                                    >{{ $store.getters.getUser(o).chinese_name }}</span
                                >
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.jobReportTime")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                <span>{{ $t(getI18nKey(job.reportTime)) }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.attendance0201.notifyParty")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                <span
                                    v-for="(o, i) in job.notifyPerson.split(',')"
                                    :key="i"
                                    style="margin-right: 12px"
                                    >{{ $store.getters.getUser(o).chinese_name }}</span
                                >
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.joblist0101.assignTime")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ job.CreatedAt | formatDate() }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.topic") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ job.title }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">{{ $t("page.joblist0101.jobShortDescript") }}</label>
                            <p class="col-sm-10 control-label t_l">
                                {{ job.content }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">{{ $t("misc.attech") }}</label>
                            <p class="col-sm-10 control-label t_l">
                                <button
                                    class="btn btn-success"
                                    v-for="(file, i) in job.files"
                                    :key="i"
                                    type="button"
                                    @click="openFile(file.path)"
                                >
                                    {{ file.fileName }}
                                </button>
                            </p>
                        </div>
                        <template v-if="!isFinished">
                            <hr />
                            <div v-if="isAssignee" class="form-group">
                                <label class="col-sm-2 control-label">{{ $t("page.joblist0101.jobProgress") }}</label>
                                <div class="col-sm-3">
                                    <select class="form-control" v-model="newReport.process">
                                        <option :value="''">{{ $t("placeholder.select") }}</option>
                                        <option
                                            v-for="process in reportProcessList"
                                            :value="process.jobProcessId"
                                            :key="process.jobProcessId"
                                        >
                                            {{ $i18n.locale == "en-US" ? process.englishName : process.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <template v-if="!(isCustomerType3 && isAssignee)">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">
                                        <span v-if="isAssignee">{{ $t("page.graveAffair0101.reportContent") }} </span>
                                        <span v-if="isNotify">{{ $t("page.joblist0101.aboutContent") }} </span>
                                        <span v-if="isAssigner">{{ $t("page.joblist0101.assignContent") }} </span>
                                    </label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" row="4" v-model="newReport.content"></textarea>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label TEXT-CO1">
                                        {{ $t("page.joblist0101.workLastWeek") }}
                                    </label>
                                    <div class="col-sm-10">
                                        <textarea
                                            class="form-control"
                                            row="4"
                                            v-model="newReport.workLastWeek"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label TEXT-CO1">
                                        {{ $t("page.joblist0101.workThisWeek") }}
                                    </label>
                                    <div class="col-sm-10">
                                        <textarea
                                            class="form-control"
                                            row="4"
                                            v-model="newReport.workThisWeek"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label TEXT-CO1">
                                        {{ $t("page.joblist0101.problems") }}
                                    </label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" row="4" v-model="newReport.problems"></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label TEXT-CO1">
                                        {{ $t("page.joblist0101.nextStep") }}
                                    </label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" row="4" v-model="newReport.nextStep"></textarea>
                                    </div>
                                </div>
                            </template>
                            <div class="form-group">
                                <label for="CATA" class="col-sm-2 control-label TEXT-CO1">{{
                                    $t("misc.uploadFile")
                                }}</label>
                                <div class="col-sm-10">
                                    <input
                                        type="file"
                                        name="file[]"
                                        v-if="uploadReady"
                                        multiple="multiple"
                                        id="InputFile_report"
                                        @change="onFileChanged"
                                    />
                                    <p class="help-block">{{ $t("placeholder.uploadMulti") }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </form>
                <div class="modal-footer">
                    <button
                        v-if="!isFinished && isAssignee"
                        type="button"
                        class="btn btn-primary"
                        @click="addJobReport(JOBREPORTTYPE_REPORTWORK)"
                    >
                        <span>{{ $t("page.graveAffair0101.reportContent") }}</span>
                    </button>
                    <button
                        v-if="!isFinished && isNotify"
                        type="button"
                        class="btn btn-primary"
                        @click="addJobReport('相關意見')"
                    >
                        <span>{{ $t("page.joblist0101.aboutContent") }}</span>
                    </button>
                    <button
                        v-if="!isFinished && isAssigner"
                        type="button"
                        class="btn btn-primary"
                        @click="addJobReport(JOBREPORTTYPE_WORKSHOW)"
                    >
                        <span>{{ $t("misc.confirm") }}</span>
                    </button>
                    <section style="display: inline" class="mx1" v-if="isAssigner">
                        <button
                            type="button"
                            class="btn btn-info"
                            @click="toggleJob()"
                            v-if="job.process !== processMap[JOBREPORTTYPE_PAUSEWORK]"
                        >
                            {{ $t("page.joblist0101.pauseJob") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-warning"
                            @click="editJobModal"
                            v-if="job.process !== processMap[JOBREPORTTYPE_PAUSEWORK]"
                        >
                            {{ $t("page.joblist0101.editJob") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="toggleJob()"
                            v-if="job.process === processMap[JOBREPORTTYPE_PAUSEWORK]"
                        >
                            {{ $t("page.joblist0101.jobResume") }}
                        </button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="finishJob()">
                            {{ $t("page.joblist0101.endJob") }}
                        </button>
                    </section>
                    <button type="button" class="btn btn-default" @click="close">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal" role="form">
                        <div v-for="report in jobReports" :key="report.jobReportId">
                            <hr />
                            <div class="form-group">
                                <label class="col-sm-2 text-right mt-2 TEXT-CO3">
                                    {{ getReportUserDisplayName(report.userId) || report.userId }}
                                </label>
                                <p class="col-sm-9 t_l text-black mt-2">
                                    <span class="text-info">
                                        {{ getReportType(report) }}
                                        {{
                                            getProcessName(report.process) ? ` (${getProcessName(report.process)})` : ""
                                        }}</span
                                    >
                                    <br />
                                    {{ report.UpdatedAt | formatDateUTC }}
                                    <br />
                                    {{ report.content }}
                                    <br />
                                    <button
                                        class="btn btn-success"
                                        v-for="(file, i) in report.File"
                                        :key="i"
                                        type="button"
                                        @click="openFile(file.path)"
                                    >
                                        {{ file.fileName }}
                                    </button>
                                </p>
                                <p class="col-sm-1">
                                    <button
                                        type="button"
                                        v-if="$store.getters.hasPermission() && !isFinished"
                                        class="btn btn-danger"
                                        @click="deleteReport(report.jobReportId)"
                                    >
                                        x
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="flex justify-center">
                            <button
                                v-if="pager.showPage < pager.totalPage"
                                type="button"
                                class="btn btn-info navbar-btn"
                                @click="turnPage(pager.showPage + 1)"
                            >
                                載入更多
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </oa-modal>
</template>

<script>
import api from "../../Api/Api"
import { mapState, mapGetters, mapActions } from "vuex"
import { oaPager } from "@/lib/oa"
export default {
    data() {
        return {
            modalName: "jobContent",
            job: {
                assignedPerson: "",
                assignPerson: "",
                notifyPerson: "",
            },
            processList: [],
            processMap: {},
            newReport: {
                jobId: "",
                process: "",
                content: "",
                userName: "",
                userId: "",
                type: "",
                workLastWeek: "",
                workThisWeek: "",
                problems: "",
                nextStep: "",
            },
            uploadReady: true,
            files: [],
            callback: null,
            readonly: false,
            jobReports: [],
            pager: new oaPager({ rowsPerPage: 10 }),
            path: "",
        }
    },
    computed: {
        ...mapState(["userId", "jobCategories"]),
        ...mapGetters(["getI18nKey", "getIsAdmin"]),
        reportProcessList() {
            return this.processList.filter((jobProcess) => {
                return !["2", "4e7c32cb8b5c90575f492a18fc3d55e7", "4e7c32cb8b5c90575f492a18fc3d55e8"].includes(
                    jobProcess.jobProcessId
                )
            })
        },
        isAssigner() {
            return this.job.assignPerson.split(",").includes(this.userId) || this.getIsAdmin
        },
        isNotify() {
            return this.job.notifyPerson.split(",").includes(this.userId) || this.getIsAdmin
        },
        isAssignee() {
            return this.job.assignedPerson.split(",").includes(this.userId)
        },
        isFinished() {
            return this.job.process == "4e7c32cb8b5c90575f492a18fc3d55e8"
        },
        isCustomerType3() {
            return this.job.customerType === 3
        },
    },
    created() {
        this.getallProcess()
    },
    methods: {
        ...mapActions(["actionGetJobCategory"]),
        beforeOpen(e) {
            this.job = e.job
            this.callback = e.callback
            this.getJobReports({ page: 1 })
            this.path = this.$router.currentRoute.path
            if (!this.jobCategories.length) {
                this.actionGetJobCategory()
            }
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {
            if (this.callback) this.callback()
            this.jobReports = []
        },
        getProcessName(v) {
            let process = ""
            if (typeof v === "string") {
                process = v
            }
            if (typeof v.process === "string" && v.process) {
                process = v.process
            }
            if (process === "") {
                if (!_.isEmpty(this.jobReports)) {
                    process = _(this.jobReports)
                        .map((o) => o.process)
                        .filter((o) => o)
                        .first()
                }
            }
            return _.get(
                this.processList.filter((c) => c.jobProcessId === process),
                this.$i18n.locale == "en-US" ? "0.englishName" : "0.name"
            )
        },
        getJobCategoryName(id) {
            return _.get(
                this.jobCategories.filter((c) => c.jobCategoryId === id),
                this.$i18n.locale == "en-US" ? "0.englishName" : "0.name"
            )
        },
        onFileChanged(event) {
            this.files = event.target.files
        },
        async addJobReport(type = "") {
            if (this.isCustomerType3 && this.isAssignee) {
                //主管週報4項目至少填一項
                if (
                    this.newReport.workLastWeek === "" &&
                    this.newReport.workThisWeek === "" &&
                    this.newReport.problems === "" &&
                    this.newReport.nextStep === ""
                ) {
                    alert("至少填一項回報項目")
                    return false
                }
                let content = ""
                if (this.newReport.workLastWeek) {
                    content += `【${this.$t("page.joblist0101.workLastWeek")}】\n${this.newReport.workLastWeek}\n`
                }
                if (this.newReport.workThisWeek) {
                    content += `【${this.$t("page.joblist0101.workThisWeek")}】\n${this.newReport.workThisWeek}\n`
                }
                if (this.newReport.problems) {
                    content += `【${this.$t("page.joblist0101.problems")}】\n${this.newReport.problems}\n`
                }
                if (this.newReport.nextStep) {
                    content += `【${this.$t("page.joblist0101.nextStep")}】\n${this.newReport.nextStep}\n`
                }
                this.newReport.content = content
            }

            if (this.newReport.content == "") {
                let showWord = type
                if (type == this.JOBREPORTTYPE_REPORTWORK) {
                    showWord = this.$t("page.graveAffair0101.reportContent")
                } else if (type == this.JOBREPORTTYPE_WORKSHOW) {
                    showWord = this.$t("page.joblist0101.assignContent")
                }
                alert(`${this.$t("alert.fillAllRequired")}:${showWord}`)
                return false
            }
            if (type == this.JOBREPORTTYPE_REPORTWORK && this.newReport.process == "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("page.joblist0101.jobProgress")}`)
                return false
            }
            this.newReport.jobId = this.job.jobId
            this.newReport.userId = this.userId
            this.newReport.type = type
            if (this.job.process == this.processMap[this.JOBREPORTTYPE_PAUSEWORK]) {
                return alert("工作暫停無法回報")
            }
            const params = {
                jobId: this.job.jobId,
                userId: this.userId,
                type,
                process: this.newReport.process,
                content: this.newReport.content,
            }
            const reportId = await api.postJobReport(params).then((res) => res.data.data)
            await api.uploadFiles(reportId, this.files, "jobreport")
            await this.updateJobProcess(this.newReport.process)
            this.clear()
            this.close()
        },
        clear() {
            this.newReport.process = ""
            this.newReport.content = ""
            this.newReport.workLastWeek = ""
            this.newReport.workThisWeek = ""
            this.newReport.problems = ""
            this.newReport.nextStep = ""
            document.getElementById("InputFile_report").value = ""
        },
        getReportUserDisplayName(reportUserId) {
            const reportUserNode = this.$store.state.departmentGraph.getNode(`user-${reportUserId}`)
            if (reportUserNode) {
                // if (report.userId === this.userId) {
                // } else
                if (reportUserNode.role_id === "admin") {
                    return "系統管理員"
                }
                return reportUserNode.chinese_name
            }
        },
        getReportType(report) {
            if (report.type) {
                return report.type
            }
            if (this.job.assignPerson.split(",").includes(report.userId)) {
                return "意見指示"
            }
            if (this.job.notifyPerson.split(",").includes(report.userId)) {
                return "相關意見"
            }
            if (this.job.assignedPerson.split(",").includes(report.userId)) {
                return "回報工作進度"
            }
            // TODO:
            return "回報工作進度"
        },
        getallProcess() {
            api.getJobProcess().then((res) => {
                this.processList = res.data
                this.processMap = _(this.processList)
                    .groupBy((o) => o.name)
                    .mapValues((o) => o[0].jobProcessId)
                    .value()
            })
        },
        async deleteReport(jobReportId) {
            if (window.confirm("確定刪除？")) {
                await api.delete("/jobreport/" + jobReportId)
                this.jobReports = this.jobReports.filter((r) => r.jobReportId != jobReportId)
            }
        },
        async reporttheJob(content, process, type = this.JOBREPORTTYPE_JOBMODIFY) {
            const jobreport = {
                jobId: this.job.jobId,
                userName: this.$store.getters.getUser().chinese_name,
                userId: this.userId,
                type: type,
                process,
            }
            await api.post("/jobreport", {
                ...jobreport,
                content,
            })
        },
        async toggleJob() {
            let process
            if (this.job.process === this.processMap[this.JOBREPORTTYPE_PAUSEWORK]) {
                process = this.processMap[this.JOBREPORTTYPE_RESTOREWORK]
                await this.reporttheJob("工作狀態已恢復", process, this.JOBREPORTTYPE_CONTINUEJOB)
            } else {
                process = this.processMap[this.JOBREPORTTYPE_PAUSEWORK]
                await this.reporttheJob("工作狀態已暫停", process, this.JOBREPORTTYPE_PAUSEWORK)
            }
            this.updateJobProcess(process)
            this.close()
        },
        async updateJobProcess(process) {
            this.job.process = process
            return api.put("/job/" + this.job.jobId, {
                lastReport: new Date().getTime(),
                process: this.job.process,
            })
        },
        upload(id, type) {
            if (!this.files.length) {
                return
            }
            let formdata = new FormData()
            formdata.append("id", id)
            formdata.append("funcType", type)
            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i]
                formdata.append("file", file)
            }
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data", //之前说的以表单传数据的格式来传递fromdata
                },
            }
            return api.post("/file", formdata, config)
        },
        async finishJob() {
            this.reporttheJob("工作已結束", this.processMap[this.JOBREPORTTYPE_WORKOVER], this.JOBREPORTTYPE_WORKOVER)
            await this.updateJobProcess(this.processMap[this.JOBREPORTTYPE_WORKOVER])
            this.close()
        },
        openFile(file) {
            window.open(api.imageUrl + file)
        },
        getJobReports({ page } = {}) {
            const params = {
                offset: this.pager.offset(page),
                limit: this.pager.limit,
                jobId: this.job.jobId,
            }
            return api
                .get("/jobreport", {
                    params,
                })
                .then((res) => {
                    this.jobReports = [...this.jobReports, ...res.data]
                    this.pager.total = res.total
                })
        },
        turnPage(i) {
            this.getJobReports({ page: i })
        },
        editJobModal() {
            const params = {
                job: this.job,
                jobCategories: this.jobCategories,
                callback: this.getJob,
            }
            this.$modal.show("editJob", params)
        },
        getJob() {
            const params = {
                id: this.job.jobId,
            }
            api.getJobList(params).then((res) => {
                this.job = res.data
            })
            this.jobReports = []
            this.getJobReports({ page: 1 })
        },
    },
}
</script>

<style></style>
