import store from "@/store"

export class oaPager {
    constructor({ rowsPerPage = 10 } = {}) {
        this.rowsPerPage = rowsPerPage
        this.total = 0
        this.showPage = 1
    }
    offset(page = this.showPage) {
        this.showPage = page
        return (page - 1) * this.rowsPerPage
    }
    get totalPage() {
        return Math.ceil(this.total / this.rowsPerPage)
    }
    get limit() {
        return this.rowsPerPage
    }
}

export const userIdsToNames = (ids, separator = " ") => {
    if (!ids) {
        return ""
    }
    if (typeof ids === "string") {
        return ids
            .split(",")
            .map((id) => store.getters.getUser(id).chinese_name)
            .join(separator)
    }
    if (typeof ids === "object") {
        return ids.map((id) => store.getters.getUser(id).chinese_name).join(separator)
    }
}

export const userIdToNameLabel = (id) => {
    if (!id) {
        return ""
    }
    const user = store.getters.getUser(id)
    if (Object.keys(user).length === 0) {
        return `[無此人員]-${id}`
    }
    if (!user.departments || !user.departments.length) {
        return `[無部門]-${user.chinese_name}-${id}`
    }
    return `[${user.departments[0]?.department.name}][${user.departments[0]?.position}]-${user.chinese_name}`
}

export const userIdsToDepartmentNames = (ids, separator = " ") => {
    if (!ids) {
        return ""
    }
    if (typeof ids === "string") {
        const departmentNames = ids
            .split(",")
            .filter((id) => id)
            .map((id) => store.getters.getUser(id).departments[0].department.name)
        return [...new Set(departmentNames)].join(separator)
    }
}

export const departmentIdsToDepartmentNames = (ids, separator = " ") => {
    if (!ids) {
        return ""
    }
    if (typeof ids === "string") {
        const departmentNames = ids
            .split(",")
            .filter((id) => id)
            .map((id) => store.getters.getDepartment(id).name)
        return [...new Set(departmentNames)].join(separator)
    }
    if (typeof ids === "object") {
        return ids.map((id) => store.getters.getDepartment(id).name).join(separator)
    }
}
export const orderStatuses = [
    { id: "PRE2_00011_18", status: "尚未回報" },
    { id: "PRE2_00011_7", status: "價格談判中" },
    { id: "PRE2_00011_13", status: "品保QC完成" },
    { id: "PRE2_00011_5", status: "品質不符" },
    { id: "PRE2_00011_11", status: "客戶試料中" },
    { id: "PRE2_00011_8", status: "客戶試料完成" },
    { id: "PRE2_00011_14", status: "已成交" },
    { id: "PRE2_00011_4", status: "採購委託資料完成" },
    { id: "PRE2_00011_12", status: "生管資料完成" },
    { id: "PRE2_00011_20", status: "開發案中斷" },
]
//原物料漲跌
export const hasMaterialGraph = (material) => {
    return [
        "RM-100-50BW-2",
        "RM-132-7",
        "KM-21SPC",
        "RN-100-TP4208",
        "RN-100-B250AE",
        "RN-100-NP2400",
        "RN-221-7",
        "RN-341-V",
        "RC-100-C2800",
        "RA-100-7D120",
        "RP-100-K1011",
        "RS-100-7525N",
        "MA-43",
        "FG-10",
        "RN-100-C20",
        "RN-100-C22",
        "RN-100-C26",
        "RN-100-M2000",
        "RN-100-YH400",
        "RN-200-LX",
        "RN-221-C6",
        "RM-100-U",
        "RM-100-U3602",
        "RM-131-C25",
        "RM-131-L601B6-R",
        "FG-45-C2",
        "FG-60-C",
        "FG-68-C",
        "FR-07-C9950",
    ].includes(material)
}

export const mailSetting = {
    人員管理: [
        { name: "人員管理", triggers: ["新增人員"], discription: "額外通知對象" },
        {
            name: "員工屆滿65歲通知信",
            triggers: ["每月一日固定寄送"],
            discription: "每月一日寄當月年齡滿64歲的員工名單,員工離職後不再通知,年齡滿64歲後每滿半年也要納入寄送名單。發送給設定的人資",
        },
    ],
    出勤管理: [
        {
            name: "出勤管理-台灣",
            triggers: ["請假申請", "請假申請簽核", "請假申請否決", "外出申請", "外出申請簽核", "外出申請否決"],
            discription: "額外通知對象(員編前兩碼為數字)",
        },
        {
            name: "出勤管理-中國大陸",
            triggers: ["請假申請", "請假申請簽核", "請假申請否決", "外出申請", "外出申請簽核", "外出申請否決"],
            discription: "額外通知對象(員編前兩碼為sh、hk、sz)",
        },
        {
            name: "出勤管理-美國",
            triggers: ["請假申請", "請假申請簽核", "請假申請否決", "外出申請", "外出申請簽核", "外出申請否決"],
            discription: "額外通知對象(員編前兩碼為us)",
        },
        {
            name: "客戶拜訪次數未達標",
            triggers: ["每月20號發送"],
            discription: "額外通知對象",
        },
        {
            name: "上月業務簽到狀況",
            triggers: ["每月5號發送"],
            discription: "額外通知對象",
        },
    ],
    工作提醒: [
        {
            name: "帳款逾期指定金額通知人",
            triggers: ["資料匯入", "超過三天未回報"],
            discription: "金額大於等於指定金額時通知(1000000台幣,250000人民幣,30000美金)",
        },
    ],
    訂單管理: [
        {
            name: "訂單出貨異常",
            triggers: ["訂單出貨異常數據倒入"],
            discription: "額外通知對象",
        },
    ],
    生產專區: [
        {
            name: "生產專區-機台預警",
            triggers: ["機台預警數據倒入"],
            discription: "通知信發送對象",
        },
    ],
    業務專區: [
        {
            name: "網站客戶詢價",
            triggers: ["主管發表意見指示", "申請結案或成交", "結案或成交通過"],
            discription: "額外通知對象",
        },
        {
            name: "久未交易客戶",
            triggers: ["數據倒入", "意見指示", "進度回報"],
            discription: "額外通知對象",
        },
        {
            name: "業務外出報表統計對象",
            triggers: [],
            discription: "每週、每月拜訪記錄通知信的統計對象",
        },
        {
            name: "業務外出報表發送對象",
            triggers: ["每週及每月5號發送"],
            discription: "通知信發送對象",
        },
    ],
    委外測試報告: [
        {
            name: "委外測試報告",
            triggers: ["新增委外測試報告", "回報委外測試報告"],
            discription: "額外通知對象",
        },
    ],
    研發專區: [
        {
            name: "新技術開發評鑑",
            triggers: ["新增研發項目", "修改研發項目", "評鑑研發項目"],
            discription: "額外通知對象",
        },
    ],
    管理專區: [
        {
            name: "商標專利管理",
            triggers: ["商標到期日8個月內通知,每月通知一次"],
            discription: "額外通知對象",
        },
        {
            name: "租約管理",
            triggers: ["租約到期日一年內通知,每月通知一次"],
            discription: "額外通知對象",
        },
        {
            name: "工作準則",
            triggers: ["新增工作準則"],
            discription: "額外通知對象",
        },
    ],
}

export const feeKinds = [
    "汽油",
    "柴油",
    "高鐵",
    "火車",
    "etag",
    "機票-頭等艙",
    "機票-商務艙",
    "機票-經濟艙",
    "船運",
    "客運",
    "捷運",
    "公務車",
    "自用車",
    "計程車",
    "停車費",
]
