<template>
    <!-- 顯示網站客戶詢價 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ title }}</h2>
                </div>
                <form class="form-horizontal" role="form">
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.inquiryAction0101.productType")
                            }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ inquiryAction.productCategory[contactData.productCategory] }}
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.region") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ inquiryAction.district[contactData.district] }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.clientName2") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ contactData.customerName }}
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.clientConpany") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ contactData.customerCompany }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.phone") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ contactData.tel }}
                            </p>
                            <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.email") }}</label>
                            <p class="col-xs-7 col-sm-4 control-label t_l">
                                {{ contactData.email }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.inquiryAction0101.askPriceTime")
                            }}</label>
                            <p class="col-xs-7 col-sm-10 control-label t_l">
                                {{ contactData.dateTime | formatDate }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-5 col-sm-2 control-label">{{
                                $t("page.inquiryAction0101.commentContact")
                            }}</label>
                            <p class="col-xs-7 col-sm-10 control-label t_l">
                                {{ contactData.opinion }}
                            </p>
                        </div>

                        <hr />
                        <div v-if="contactData.approvalStatus !== 'ok'">
                            <div>
                                <!-- report area -->
                                <div class="form-group">
                                    <label class="col-sm-2 control-label" v-if="isApprover || isAllViewer">
                                        {{ $t("page.project0101.comment") }}
                                    </label>
                                    <label class="col-sm-2 control-label" v-else-if="isAssignee">{{
                                        $t("page.joblist0202.reportProgress")
                                    }}</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" row="4" v-model="content"></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-5 col-sm-2 control-label">{{ $t("misc.uploadFile") }}</label>
                                    <div class="col-sm-10">
                                        <input
                                            type="file"
                                            name="file[]"
                                            multiple="multiple"
                                            id="InputFile"
                                            @change="onFileChanged"
                                        />
                                        <p class="help-block">
                                            {{ $t("placeholder.uploadMulti") }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- buttons area -->
                            <div class="form-group">
                                <label class="col-xs-5 col-sm-2 control-label"></label>
                                <div class="col-sm-10">
                                    <br />
                                    <div class="flex justify-end">
                                        <button
                                            type="button"
                                            class="btn btn-success mx-1"
                                            v-if="isAssignee"
                                            @click="updateAndPostReport('申請結案')"
                                        >
                                            {{ $t("page.inquiryAction0101.endT") }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-success mx-1"
                                            v-if="isAssignee"
                                            @click="updateAndPostReport('申請成交')"
                                        >
                                            {{ $t("page.testmaterial0201.commissionDone") }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-success mx-1"
                                            v-if="isApprover && contactData.approvalStatus == 'pending'"
                                            @click="updateAndPostReport('審核通過')"
                                        >
                                            {{ $t("page.inquiryAction0101.approval") }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger mx-1"
                                            v-if="isApprover && contactData.approvalStatus == 'pending'"
                                            @click="updateAndPostReport('審核不通過')"
                                        >
                                            {{ $t("page.inquiryAction0101.reject") }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-primary mx-1"
                                            v-if="isApprover || isAllViewer"
                                            @click="report('意見指示')"
                                        >
                                            {{ $t("misc.submit") }}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-primary mx-1"
                                            v-else-if="isAssignee"
                                            @click="updateAndPostReport('回報進度')"
                                        >
                                            {{ $t("misc.submit") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div
                            class="table-responsive clearfloat"
                            v-for="report in webContactReports"
                            :key="report.reportId"
                        >
                            <table class="table table-striped table-hover n-table-head">
                                <thead>
                                    <tr>
                                        <td class="col-md-1">
                                            {{ report.type }}
                                        </td>
                                        <td class="col-md-2">
                                            {{ $t("page.inquiryAction0101.dateTime") }}
                                        </td>
                                        <td class="col-md-8">
                                            {{ $t("page.project0101.descriptContent") }}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="col-md-1">
                                            {{ getUser(report.userId).chinese_name }}
                                        </td>
                                        <td class="col-md-2">
                                            <span v-if="report.CreatedAt">{{ report.CreatedAt.substring(0, 10) }}</span>
                                        </td>
                                        <td class="col-md-8">
                                            <div>{{ report.content }}</div>
                                            <div>
                                                <button
                                                    type="button"
                                                    class="btn btn-success"
                                                    v-for="filee in report.files"
                                                    :key="filee.fileId"
                                                    :value="filee.fileName"
                                                    @click="openFile(filee.id + '/' + filee.fileName)"
                                                >
                                                    {{ filee.fileName }}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-primary" data-dismiss="modal" >確定</button> -->
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            {{ $t("misc.close") }}
                        </button>
                    </div>
                    <!-- /.modal-footer -->
                </form>

                <!-- /.modal-body -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import api from "@/Api/Api"
import { title } from "process"
export default {
    components: {},
    data() {
        return {
            modalName: "showWebContact",
            files: [],
            callback: () => {},
            contactData: {},
            content: "",
            webContactReports: [],
        }
    },
    computed: {
        ...mapState(["userId", "inquiryAction"]),
        ...mapGetters(["getUser", "hasPermission"]),
        isAssignee() {
            return this.hasPermission([this.PERMISSION_INQUIRY_ACTION0101_MANAGE], {
                resource_id: `productCategory/${this.contactData.productCategory}:district/${this.contactData.district}`,
            })
        },
        isApprover() {
            return this.hasPermission([this.PERMISSION_INQUIRY_ACTION0101_APPROVE], {
                resource_id: `productCategory/${this.contactData.productCategory}:district/${this.contactData.district}`,
            })
        },
        isAllViewer() {
            return this.hasPermission([this.PERMISSION_INQUIRY_ACTION0101_LIST])
        },
        title() {
            if (this.contactData.approvalStatus == "pending" && this.isApprover) {
                return `${this.contactData.status}${this.$t("page.project0101.approval")}`
            }
            return this.$t("misc.content")
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.contactData = e.data
            this.callback = e.callback || this.callback
            this.getReports()
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        openFile(file) {
            window.open(api.imageUrl + file)
        },
        onFileChanged(event) {
            this.files = event.target.files
        },
        getReports() {
            api.getReports(this.contactData.id).then((res) => {
                this.webContactReports = res.data
            })
        },
        async report(type) {
            if (!this.validator(type)) {
                return
            }
            const param = {
                type: type,
                content: this.content,
                id: this.contactData.id,
                name: this.getUser().chinese_name,
                category: "webContactDatas",
            }
            const res = await api.post("/report", param)
            await this.upload(res.data.data.id)
            this.callback()
            this.close()
            this.clear()
        },
        upload(id) {
            return new Promise((resolve, reject) => {
                if (!this.files.length) {
                    resolve()
                } else {
                    let formdata = new FormData()
                    formdata.append("id", id)
                    for (var i = 0; i < this.files.length; i++) {
                        let file = this.files[i]
                        formdata.append("file", file)
                    }
                    let config = {
                        headers: {
                            "Content-Type": "multipart/form-data", //之前说的以表单传数据的格式来传递fromdata
                        },
                    }
                    api.post("/file", formdata, config).then((x) => {
                        resolve()
                    })
                }
            })
        },
        updateAndPostReport(type) {
            if (!this.validator(type)) {
                return
            }
            let param
            if (type === "申請結案") {
                param = {
                    status: "結案",
                    approvalStatus: "pending",
                }
            } else if (type === "申請成交") {
                param = {
                    status: "已成交",
                    approvalStatus: "pending",
                }
            } else if (type === "審核通過") {
                param = {
                    approvalStatus: "ok",
                }
            } else if (type === "審核不通過") {
                param = {
                    status: "進行中",
                    approvalStatus: " ",
                }
            } else if (type === "回報進度") {
                param = {
                    status: "進行中",
                    approvalStatus: " ",
                }
            }
            this.update(param)
            this.report(type)
        },
        update({ status, approvalStatus } = {}) {
            api.put("/webContactDatas?id=" + this.contactData.id, {
                ...(status && { status }),
                ...{ approvalStatus },
            })
        },
        clear() {
            this.content = ""
        },
        validator(type) {
            if (this.content == "" && type != "審核通過" && type != "審核不通過") {
                const comment =
                    type == "回報進度"
                        ? this.$t("page.joblist0202.reportProgress")
                        : this.$t("page.project0101.comment")
                alert(`${this.$t("alert.fillAllRequired")}:${comment}`)
                return false
            }
            return true
        },
    },
}
</script>

<style></style>
