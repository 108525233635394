<template>
    <!-- 公事外出申請檢視 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">
                        {{ $t("page.attendance0201.outsideRequest") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="col-title"> {{ $t("misc.startDate") }}： </span>
                            <span>{{ visit.startDate | formatDateUTC }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.endDate") }}：</span>
                            <span>{{ visit.endDate | formatDateUTC }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("page.attendance0201.visitMethon") }}：</span>
                            <span>{{ $t(getI18nKey(visit.category)) }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.companyVisitedTarget") }}：</span>
                            <span v-for="p in visit.Interview" :key="p.interviewId">{{ p.Client.name }}&nbsp;</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.contactMethod") }}：</span>
                            <span v-if="visit.contactMethod == '手機'">{{ $t("misc.cellPhone") }}</span>
                            <span v-if="visit.contactMethod == '電話'">{{ $t("misc.phone") }}</span>
                            <span v-if="visit.contactMethod == 'email'">{{ $t("misc.email") }}</span>
                            <span v-if="visit.contactMethod == '其他'">{{ $t("misc.other") }}</span>
                            <span>{{ visit.contact }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("page.attendance0201.event") }}：</span>
                            <span>{{ getEventName(visit.event) }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("page.attendance0201.notifyParty") }}：</span>
                            <span v-if="visit.notifyPerson">
                                <span v-for="(o, i) in visit.notifyPerson.split(',')" :key="i" style="margin-right: 12px">{{
                                    getUser(o).chinese_name
                                }}</span>
                            </span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("sideMenu.attendance.attendance0401") }}：</span>
                            <span v-if="visit.agent">{{ agentsString }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("misc.remark") }}：</span>
                            <span>{{ visit.remarks }}</span>
                        </li>
                        <li>
                            <span class="col-title">{{ $t("page.attendance0201.useCar") }}：</span>
                            <span v-if="visit.useCar">✓</span>
                        </li>
                        <li v-if="visit.approvalStatus == '已簽核' && visit.useCar">
                            <span class="col-title">{{ $t("page.attendance0201.carApplication") }}：</span>
                            <a @click="handlePrint(visit)">{{ $t("page.attendance0201.carApplicationForm") }}</a>
                        </li>
                        <li style="border-bottom: 0">
                            <span class="col-title">{{ $t("page.attendance0201.approvalStatus") }}：</span>
                            <span v-if="visit.approvalStatus == '已簽核'">{{ $t("misc.signed") }}</span>
                            <span v-if="visit.approvalStatus == '未簽核'">{{ $t("misc.notSigned") }}</span>
                            <span v-if="visit.approvalStatus == '否決'">{{ $t("misc.approveReject2") }}</span>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                        v-if="isOwner && visit.approvalStatus == '未簽核'"
                        @click="deleteVisit()"
                        style="float: left"
                    >
                        {{ $t("misc.delete") }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        v-if="isOwner && visit.approvalStatus == '未簽核'"
                        @click="showEditVisitModal()"
                        style="float: left"
                    >
                        {{ $t("misc.edit") }}
                    </button>

                    <button
                        v-if="isApprover && visit.approvalStatus === '未簽核'"
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        @click="updateStatus('已簽核')"
                        style="float: left"
                    >
                        {{ $t("misc.approveAccept") }}
                    </button>
                    <button
                        v-if="isApprover && visit.approvalStatus === '未簽核'"
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                        @click="updateStatus('否決')"
                        style="float: left"
                    >
                        {{ $t("misc.approveReject") }}
                    </button>

                    <button
                        v-if="(isApprover || getIsAdmin) && (visit.approvalStatus === '已簽核' || visit.approvalStatus === '否決')"
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                        @click="updateStatus('未簽核')"
                    >
                        {{ $t("misc.cancelSign") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { userIdsToNames } from "@/lib/oa"
import api from "@/Api/Api"
import moment from "moment"
export default {
    data() {
        return {
            modalName: "showVisit",
            role: "",
            visit: {},
            jobagent: {},
            callback: () => {},
        }
    },
    computed: {
        ...mapState(["ready", "user", "userId", "currencies", "outsideType", "visitEvents"]),
        ...mapGetters(["getI18nKey", "getUser", "getApprovers", "getIsAdmin"]),
        isOwner() {
            return this.userId === this.visit.userId
        },
        agentsString() {
            if (!this.visit.agent) return ""
            const agents = this.visit.agent.split(",")
            let agentsString = ""
            agentsString = `${userIdsToNames(agents[0])}(${this.$t("misc.primary")})`
            if (agents.length > 1) {
                agentsString += `, ${userIdsToNames(agents[1])}(${this.$t("misc.secondary")})`
            }
            return agentsString
        },
    },
    asyncComputed: {
        async isApprover() {
            const approvers = await this.getApprovers(this.visit.userId).catch(() => [])
            return approvers.includes(this.userId)
        },
    },
    beforeDestroy() {
        this.$bus.$off("ready")
    },
    methods: {
        beforeOpen(e) {
            this.role = e.role || ""
            this.visit = _.cloneDeep(e.visit) || {}
            this.callback = e.callback || this.callback
            this.getJobAgent()
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {
            this.clear()
        },
        getJobAgent() {
            const params = {
                userId: this.visit.userId,
            }
            api.getJobAgent(params).then((res) => {
                if (res.status === 0 && res.data.length) {
                    this.jobagent = res.data[0]
                }
            })
        },
        updateStatus(status) {
            api.put("/visit/" + this.visit.VisitId, {
                approvalStatus: status,
                approvingUser: this.userId,
            }).then((res) => {
                this.close()
                this.callback()
            })
        },
        deleteVisit() {
            if (window.confirm(this.$t("page.attendance0201.alert.deleteOutsideRequest"))) {
                api.deleteVisit(this.visit.VisitId).then(() => {
                    this.close()
                    this.callback()
                })
            }
        },
        getEventName(eventName) {
            if (this.$i18n.locale != "en-US") {
                return eventName
            }
            const event = this.visitEvents.find((event) => event.name == eventName)
            return event?.englishName || eventName
        },
        showEditVisitModal() {
            const prop = {
                visit: this.visit,
                callback: this.callback,
            }
            this.$modal.show("editVisit", prop)
            this.close()
        },
        clear() {
            this.jobagent = {}
        },
        handlePrint(visit) {
            const user = this.getUser(visit.User.userID)
            const approvingUser = this.getUser(visit.approvingUser)
            const query = {
                name: user.chinese_name,
                department: user.departments[0].department.name,
                title: user.departments[0].position,
                approvingUser: approvingUser.chinese_name,
                category: visit.category,
                clients: visit.Interview.map((interview) => interview.Client.shortName).join(", "),
                event: visit.event,
                createdAt: moment(visit.CreatedAt).format("YYYY-MM-DD"),
                startTime: moment(visit.startDate).format("YYYY-MM-DD hh:mm"),
                endTime: moment(visit.endDate).format("YYYY-MM-DD hh:mm"),
            }
            const routeData = this.$router.resolve({
                name: "carApplication",
                query,
            })
            window.open(routeData.href, "_blank")
        },
        userIdsToNames: userIdsToNames,
    },
}
</script>

<style scoped>
.col-title {
    width: 12em;
}
</style>
