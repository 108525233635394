import api from "@/Api/Api"
import permissions from "@/domain/permission"
import _, { has } from "lodash"
import { mailSetting } from "@/lib/oa"
const checkPermission = ({ userPermissions, action, resourceType, resourceId, effect, hasPermission }) => {
    if (hasPermission !== undefined) {
        return hasPermission
    }
    return !!userPermissions.find((userPermission) => {
        const actionMatches = userPermission.action === action
        const resourceTypeMatches = resourceType ? userPermission.resource_type === resourceType : true
        const resourceMatches = resourceId ? userPermission.resource_id === resourceId : true
        const effectMatches = effect ? userPermission.effect === effect : true
        return actionMatches && resourceTypeMatches && resourceMatches && effectMatches
    })
}
export default {
    namespaced: true,
    state: {
        searchUserId: null,
        permissionConfig: [
            { category: "上方功能項", type: "行事曆", permission: permissions.PERMISSION_SCHEDULE_APPROVE, name: "審核者" },
            { category: "上方功能項", type: "公佈欄", permission: permissions.PERMISSION_BULLETIN_APPROVE, name: "審核者" },
            { category: "出勤管理", type: "出勤查詢", permission: permissions.PERMISSION_ATTENDANCE0101_STAFF_LIST, name: "職員出勤查詢者" },
            { category: "出勤管理", type: "出勤查詢", permission: permissions.PERMISSION_ATTENDANCE0101_MANAGE, name: "簽到/簽退維護人" },
            { category: "出勤管理", type: "出勤查詢", permission: permissions.PERMISSION_ATTENDANCE0101_GROUP_MANAGE, name: "特殊篩選維護人" },
            { category: "出勤管理", type: "外出訪談紀錄", permission: permissions.PERMISSION_ATTENDANCE0201_LIST, name: "全部瀏覽者" },
            { category: "出勤管理", type: "請假紀錄", permission: permissions.PERMISSION_ATTENDANCE0101_LIST, name: "全部瀏覽者" },
            { category: "出勤管理", type: "忘刷紀錄", permission: permissions.PERMISSION_FORGOT_PUNCH_IN_MANAGE, name: "管理者" },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_RELATED,
                resource_id: "tw",
                name: "台灣出勤列表顯示人員",
            },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_LIST,
                resource_id: "tw",
                name: "台灣可瀏覽者",
            },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_RELATED,
                resource_id: "sh",
                name: "上海出勤列表顯示人員",
            },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_LIST,
                resource_id: "sh",
                name: "上海可瀏覽者",
            },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_RELATED,
                resource_id: "us",
                name: "NYPLA出勤列表顯示人員",
            },
            {
                category: "出勤管理",
                type: "當日業務出勤",
                permission: permissions.PERMISSION_SALES_ATTENDANCE_LIST,
                resource_id: "us",
                name: "NYPLA可瀏覽者",
            },
            { category: "出勤管理", type: "客戶拜訪次數", permission: permissions.PERMISSION_INTERVIEW_GROUP_LIST, name: "全部瀏覽者" },
            { category: "出勤管理", type: "客戶拜訪次數", permission: permissions.PERMISSION_INTERVIEW_GROUP_MANAGE, name: "名單管理者" },
            { category: "出勤管理", type: "瀏覽請假審核列表", permission: permissions.PERMISSION_ATTENDANCE0501_LIST, name: "全部瀏覽者" },
            { category: "工作提醒", type: "重大事件", permission: permissions.PERMISSION_GRAVEAFFAIR_LIST, name: "全部瀏覽者" },
            { category: "訪談報告", type: "訪談報告", permission: permissions.PERMISSION_ATTENDANCE0201_LIST, name: "全部瀏覽者" },
            { category: "訪談報告", type: "業務訪談統計", permission: permissions.PERMISSION_INTERVIEW0301_ACCESS, name: "可瀏覽者" },
            { category: "工作管理", type: "工作管理", permission: permissions.PERMISSION_JOBLIST_LIST, name: "全部瀏覽者" },
            { category: "工作管理", type: "工作管理", permission: permissions.PERMISSION_JOBLIST_TYPE_MANAGE, name: "前台工作類別管理者" },
            { category: "工作管理", type: "工作管理", permission: permissions.PERMISSION_JOBLIST_PROGRESS_MANAGE, name: "前台工作進度管理者" },
            { category: "訂單管理", type: "訂單列表", permission: permissions.PERMISSION_ORDER_LIST, name: "全部瀏覽者" },
            { category: "訂單管理", type: "訂單列表", permission: permissions.PERMISSION_ORDERS0102_LIST, name: "金額瀏覽者" },
            { category: "訂單管理", type: "訂單出貨異常", permission: permissions.PERMISSION_ORDER_AMOUNT_VIEWER, name: "全部瀏覽者" },
            { category: "料件庫存", type: "庫存統計", permission: permissions.PERMISSION_INVENTORY_LIST, name: "全部瀏覽者" },
            {
                category: "料件庫存",
                type: "庫存統計",
                permission: permissions.PERMISSION_INVENTORY_LIST_EXCEPT_MONEY,
                name: "可檢視除金額外之庫存資料",
            },
            {
                category: "採購專區",
                type: "原料市價波動",
                permission: permissions.PERMISSION_GRAVE_AFFAIR0201_LIST,
                resource_id: "district/NT1",
                name: "台灣區瀏覽者",
            },
            {
                category: "採購專區",
                type: "原料市價波動",
                permission: permissions.PERMISSION_GRAVE_AFFAIR0201_LIST,
                resource_id: "district/SH1",
                name: "上海區瀏覽者",
            },
            {
                category: "採購專區",
                type: "用料預估統計",
                permission: permissions.PERMISSION_PURCHASING_ESTIMATES0201_LIST,
                resource_id: "district/NT1",
                name: "台灣區瀏覽者",
            },
            {
                category: "採購專區",
                type: "用料預估統計",
                permission: permissions.PERMISSION_PURCHASING_ESTIMATES0201_LIST,
                resource_id: "district/SH1",
                name: "上海區瀏覽者",
            },
            {
                category: "採購專區",
                type: "供應商平台",
                permission: permissions.PERMISSION_SUPPLIER_PLATFORM_LIST,
                name: "可瀏覽者",
            },
            {
                category: "採購專區",
                type: "供應商平台",
                permission: permissions.PERMISSION_SUPPLIER_PLATFORM_MANAGE,
                name: "供應商維護人",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "ALL",
                name: "全部瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/各區營收表",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0101",
                resource_id: "thisMonth",
                name: "當月營收瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/各區營收表",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0101",
                resource_id: "3Year",
                name: "近三年營收瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/各區營收表",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0101",
                resource_id: "twPeriod",
                name: "臺灣同期比較瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/各區營收表",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0101",
                resource_id: "shPeriod",
                name: "上海同期比較瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/各區營收表",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0101",
                resource_id: "usPeriod",
                name: "Nypla同期比較瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/客戶月銷統計",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0102",
                resource_id: "tw",
                name: "臺灣分區瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/客戶月銷統計",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0102",
                resource_id: "sh",
                name: "上海分區瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/客戶月銷統計",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0102",
                resource_id: "sky",
                name: "華南分區瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/客戶月銷統計",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0102",
                resource_id: "us",
                name: "Nypla分區瀏覽者",
            },
            {
                category: "業務專區",
                type: "公司銷售狀況/營運達成率",
                permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                resource_type: "0104",
                resource_id: "ALL",
                name: "全部瀏覽者",
            },
            {
                category: "業務專區",
                type: "每週業績進度&銷售漏斗",
                permission: permissions.PERMISSION_WEEKLY_REVIEW_MANAGE,
                resource_id: "tw",
                name: "台灣管理者",
            },
            {
                category: "業務專區",
                type: "每週業績進度&銷售漏斗",
                permission: permissions.PERMISSION_WEEKLY_REVIEW_MANAGE,
                resource_id: "sh",
                name: "上海管理者",
            },
            {
                category: "業務專區",
                type: "每週業績進度&銷售漏斗",
                permission: permissions.PERMISSION_WEEKLY_REVIEW_MANAGE,
                resource_id: "nypla",
                name: "Nypla管理者",
            },
            {
                category: "業務專區",
                type: "網站客戶詢價",
                permission: permissions.PERMISSION_INQUIRY_ACTION0101_LIST,
                name: "全部瀏覽者",
            },
            {
                category: "業務專區",
                type: "顧客滿意度調查",
                permission: permissions.PERMISSION_QUESTIONNIRE_LIST,
                name: "可瀏覽者",
            },
            {
                category: "業務專區",
                type: "二十大客戶",
                permission: permissions.PERMISSION_TOP20CUSTOMER0101_LIST,
                name: "全部瀏覽者",
            },
            {
                category: "業務專區",
                type: "久未交易客戶",
                permission: permissions.PERMISSION_TRANSACTION0201_LIST,
                effect: "allow",
                name: "全部瀏覽者",
            },
            {
                category: "業務專區",
                type: "久未交易客戶",
                permission: permissions.PERMISSION_TRANSACTION0201_LIST,
                effect: "deny",
                name: "禁止瀏覽者",
            },
            {
                category: "業務專區",
                type: "生產損益平衡",
                permission: permissions.BREAKEVEN_LIST,
                resource_id: "tw",
                name: "台灣分區瀏覽者",
            },
            {
                category: "業務專區",
                type: "生產損益平衡",
                permission: permissions.BREAKEVEN_LIST,
                resource_id: "tw",
                name: "上海分區瀏覽者",
            },
            {
                category: "EZ-Sales",
                type: "EZ-Sales",
                permission: permissions.PERMISSION_EZ_SALES_ALL_VIEWER,
                name: "全部瀏覽者",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "ALL",
                name: "全部瀏覽者",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TB1",
                name: "部分瀏覽者: TB1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TC1",
                name: "部分瀏覽者: TC1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TC2",
                name: "部分瀏覽者: TC2",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TC3",
                name: "部分瀏覽者: TC3",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TD1",
                name: "部分瀏覽者: TD1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TE1",
                name: "部分瀏覽者: TE1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TF1",
                name: "部分瀏覽者: TF1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TK1",
                name: "部分瀏覽者: TK1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TP1",
                name: "部分瀏覽者: TP1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TX1",
                name: "部分瀏覽者: TX1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "TY1",
                name: "部分瀏覽者: TY1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "SB1",
                name: "部分瀏覽者: SB1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "SC1",
                name: "部分瀏覽者: SC1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "SD1",
                name: "部分瀏覽者: SD1",
            },
            {
                category: "試料管理",
                type: "客戶試料管理",
                permission: permissions.PERMISSION_TEST_MATERIAL_LIST,
                resource_id: "SE1",
                name: "部分瀏覽者: SE1",
            },
            {
                category: "競爭對手規格",
                type: "競爭對手規格",
                permission: permissions.PERMISSION_COMPETITION_LIST,
                name: "可瀏覽者",
            },
            {
                category: "競爭對手規格",
                type: "競爭對手規格",
                permission: permissions.PERMISSION_COMPETITION_MANAGE,
                name: "維護人員",
            },
            {
                category: "委外測試報告",
                type: "委外測試報告",
                permission: permissions.PERMISSION_OUTSOURCING_TEST0102_LIST,
                name: "全部瀏覽者",
            },
            {
                category: "委外測試報告",
                type: "委外測試報告",
                permission: permissions.PERMISSION_OUTSOURCING_TEST0102_REPORT,
                name: "報告人",
            },
            {
                category: "客戶管理",
                type: "客戶管理",
                permission: permissions.PERMISSION_CLIENT_SALES_MANAGE,
                name: "負責業務修改者",
            },
            {
                category: "專案管理",
                type: "專案管理",
                permission: permissions.PERMISSION_PROJECT_LIST,
                name: "全部瀏覽者",
            },
            {
                category: "專案管理",
                type: "專案管理",
                permission: permissions.PERMISSION_PROJECT_MANAGE,
                name: "專案管理指派者",
            },
            {
                category: "會議管理",
                type: "會議管理",
                permission: permissions.PERMISSION_CONFERENCE_LIST,
                effect: "allow",
                resource_id: "ALL",
                name: "全部瀏覽者",
            },
            {
                category: "文件管理",
                type: "文件管理",
                permission: permissions.PERMISSION_DOCUMENT_LIST,
                effect: "allow",
                name: "全部瀏覽者",
            },
            {
                category: "文件管理",
                type: "文件管理",
                permission: permissions.PERMISSION_DOCUMENT_LIST,
                effect: "deny",
                name: "禁止瀏覽者",
            },
            {
                category: "文件管理",
                type: "文件管理",
                permission: permissions.PERMISSION_DOCUMENT_APPROVE,
                name: "審核者",
            },
            {
                category: "文件管理",
                type: "文件管理",
                permission: permissions.PERMISSION_DOCUMENT_UPDATE,
                name: "修改刪除者",
            },
            {
                category: "文件管理",
                type: "文件管理",
                permission: permissions.PERMISSION_DOCUMENT_TYPE_MANAGE,
                name: "類別管理者",
            },
            {
                category: "生產專區",
                type: "機台管理",
                permission: permissions.PERMISSION_MACHINE_MANAGEMENT0101_LIST,
                resource_id: "district/NT1",
                name: "台灣區瀏覽者",
            },
            {
                category: "生產專區",
                type: "機台管理",
                permission: permissions.PERMISSION_MACHINE_MANAGEMENT0101_LIST,
                resource_id: "district/SH1",
                name: "上海區瀏覽者",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (台灣)",
                permission: permissions.PERMISSION_TGA_DSC0101_LIST,
                resource_id: "district/NT1",
                name: "瀏覽人",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (台灣)",
                permission: permissions.PERMISSION_TGA_DSC0101_CREATE,
                resource_id: "district/NT1",
                name: "新增者",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (台灣)",
                permission: permissions.PERMISSION_TGA_DSC0101_MANAGE,
                resource_id: "district/NT1",
                name: "維護人員",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (上海)",
                permission: permissions.PERMISSION_TGA_DSC0101_LIST,
                resource_id: "district/SH1",
                name: "瀏覽人",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (上海)",
                permission: permissions.PERMISSION_TGA_DSC0101_CREATE,
                resource_id: "district/SH1",
                name: "新增者",
            },
            {
                category: "研發專區",
                type: "TGA/DSC圖件 (上海)",
                permission: permissions.PERMISSION_TGA_DSC0101_MANAGE,
                resource_id: "district/SH1",
                name: "維護人員",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (台灣)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_LIST,
                resource_id: "district/NT1",
                name: "瀏覽人",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (台灣)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_CREATE,
                resource_id: "district/NT1",
                name: "新增者",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (台灣)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_RATE,
                resource_id: "district/NT1",
                name: "評鑑人",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (上海)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_LIST,
                resource_id: "district/SH1",
                name: "瀏覽人",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (上海)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_CREATE,
                resource_id: "district/SH1",
                name: "新增者",
            },
            {
                category: "研發專區",
                type: "新技術開發評鑑 (上海)",
                permission: permissions.PERMISSION_TECHNICAL_EVALUATION0101_RATE,
                resource_id: "district/SH1",
                name: "評鑑人",
            },
            {
                category: "管理專區",
                type: "商標專利管理",
                permission: permissions.PERMISSION_ADMINISTRATION_TRADEMARK_MANAGE,
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "商標專利管理",
                permission: permissions.PERMISSION_ADMINISTRATION_TRADEMARK_EXPIRY_HANLDER,
                name: "到期處理人",
            },
            {
                category: "管理專區",
                type: "證照管理",
                permission: permissions.PERMISSION_CERTIFICATE_LIST,
                name: "可瀏覽者",
            },
            {
                category: "管理專區",
                type: "證照管理",
                permission: permissions.PERMISSION_CERTIFICATE_MANAGE,
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "合約管理 (台灣)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_LIST,
                resource_id: "NT1",
                name: "可瀏覽者",
            },
            {
                category: "管理專區",
                type: "合約管理 (台灣)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_MANAGE,
                resource_id: "NT1",
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "合約管理 (上海)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_LIST,
                resource_id: "SH1",
                name: "可瀏覽者",
            },
            {
                category: "管理專區",
                type: "合約管理 (上海)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_MANAGE,
                resource_id: "SH1",
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "合約管理 (Nypla)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_LIST,
                resource_id: "US1",
                name: "可瀏覽者",
            },
            {
                category: "管理專區",
                type: "合約管理 (Nypla)",
                permission: permissions.PERMISSION_ADMINISTRATION_LEASE_MANAGE,
                resource_id: "US1",
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "公司規章教育訓練",
                permission: permissions.PERMISSION_ADMINISTRATION_REGULATION_LIST_MANAGE,
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "工作準則",
                permission: permissions.PERMISSION_ADMINISTRATION_JOB_RULE_LIST,
                name: "全部瀏覽者",
            },
            {
                category: "管理專區",
                type: "工作準則",
                permission: permissions.PERMISSION_ADMINISTRATION_JOB_RULE_MANAGE,
                name: "管理者",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (台灣)",
                permission: permissions.PERMISSION_TRAINING_LIST,
                resource_id: "NT1",
                name: "全部瀏覽者",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (台灣)",
                permission: permissions.PERMISSION_INTERNAL_TRAINING_APPLICANT,
                resource_id: "NT1",
                name: "內訓申請人",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (台灣)",
                permission: permissions.PERMISSION_TRAINING_MANAGE,
                resource_id: "NT1",
                name: "人資",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (上海)",
                permission: permissions.PERMISSION_TRAINING_LIST,
                resource_id: "SH1",
                name: "全部瀏覽者",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (上海)",
                permission: permissions.PERMISSION_INTERNAL_TRAINING_APPLICANT,
                resource_id: "SH1",
                name: "內訓申請人",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (上海)",
                permission: permissions.PERMISSION_TRAINING_MANAGE,
                resource_id: "SH1",
                name: "人資",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (Nypla)",
                permission: permissions.PERMISSION_TRAINING_LIST,
                resource_id: "NT3",
                name: "全部瀏覽者",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (Nypla)",
                permission: permissions.PERMISSION_INTERNAL_TRAINING_APPLICANT,
                resource_id: "NT3",
                name: "內訓申請人",
            },
            {
                category: "管理專區",
                type: "教育訓練申請 (Nypla)",
                permission: permissions.PERMISSION_TRAINING_MANAGE,
                resource_id: "NT3",
                name: "人資",
            },
            {
                category: "管理專區",
                type: "費用登入表",
                permission: permissions.FEE_ALL_VIEWER_1,
                name: "全部瀏覽者",
            },
            {
                category: "管理專區",
                type: "費用登入表",
                permission: permissions.FEE_MANAGE,
                name: "費用審核者",
            },
            {
                category: "管理專區",
                type: "碳排放查核表",
                permission: permissions.FEE_ALL_VIEWER_2,
                name: "全部瀏覽者",
            },
        ],
        revenuesStatisticsPermissions: [],
        userPermissions: [],
        districtName: {
            sh: "上海",
            tw: "台灣",
            us: "NYPLA",
        },
        sampleDistricts: [],
        extraTargets: [],
        affairsExecutorPermissions: { 退票: [], 帳款逾期: [], 品質異常: [], 原料異常漲跌: [], 安全庫存: [] },
        observePermissions: [],
    },
    getters: {
        getPermissionsView: (state, getters) => {
            const groupByCategory = _(getters.getPermissionsWithHasPermission).groupBy("category")
            const groupByTypeAfterGroupByCategory = groupByCategory.mapValues((type) => _.groupBy(type, "type"))
            return groupByTypeAfterGroupByCategory.value()
        },
        getPermissionsWithHasPermission: (state, getters) => {
            const normalPermissions = getters.getSortedPermissions.map((permission) => {
                const hasPermission = checkPermission({
                    userPermissions: state.userPermissions,
                    action: permission.permission,
                    resourceType: permission.resource_type,
                    resourceId: permission.resource_id,
                    effect: permission.effect,
                    hasPermission: permission.hasPermission,
                })
                return {
                    ...permission,
                    hasPermission,
                }
            })
            return [...normalPermissions, ...getters.getMailPermissionsWithHasPermission]
        },
        getSortedPermissions: (state, getters) => {
            // 網站客戶詢價插入點
            const inquiryPermissionIndex = state.permissionConfig.findIndex((permission) => permission.type === "網站客戶詢價")
            const permissions = [...state.permissionConfig]
            permissions.splice(inquiryPermissionIndex + 1, 0, ...getters.getInquiryPermissions)
            return [...permissions, ...state.revenuesStatisticsPermissions, ...getters.getTestMaterialPermissions, ...getters.getMeetingPermissions]
        },
        getInquiryPermissions: (state, getters, rootState, rootGetters) => {
            const districts = rootState.inquiryAction.district
            const productCategories = rootState.inquiryAction.productCategories
            const transferDepManagers = rootState.transferDepManagers
            const inquiryPermissions = Object.entries(districts)
                .map(([district, districtName]) => {
                    return productCategories
                        .map((productCategory) => {
                            return [
                                {
                                    category: "業務專區",
                                    type: `網站客戶詢價(${districtName})`,
                                    permission: permissions.PERMISSION_INQUIRY_ACTION0101_MANAGE,
                                    resource_id: `productCategory/${productCategory.key}:district/${district}`,
                                    name: `${productCategory.value}(負責人)`,
                                },
                                {
                                    category: "業務專區",
                                    type: `網站客戶詢價(${districtName})`,
                                    permission: permissions.PERMISSION_INQUIRY_ACTION0101_APPROVE,
                                    resource_id: `productCategory/${productCategory.key}:district/${district}`,
                                    name: `${productCategory.value}(審核人)`,
                                },
                            ]
                        })
                        .flat()
                })
                .flat()
            const transferDepManagerPermissions = transferDepManagers.map((transferDepManager) => {
                return {
                    category: "業務專區",
                    type: `網站客戶詢價/轉移部門`,
                    hasPermission: transferDepManager.user_ids.includes(state.searchUserId),
                    name: `${transferDepManager.name}(負責人)`,
                }
            })
            return [...inquiryPermissions, ...transferDepManagerPermissions]
        },
        getTestMaterialPermissions: (state, getters, rootState, rootGetters) => {
            const sampleDistricts = state.sampleDistricts
            const testMaterialPermissions = sampleDistricts
                .map((sampleDistrict) => {
                    return [
                        {
                            category: "試料管理",
                            type: `試料樣本紀錄(${sampleDistrict.name})`,
                            permission: permissions.PERMISSION_TEST_MATERIAL0302_SUBMIT,
                            resource_id: `district/${sampleDistrict.sampleDistrictId}`,
                            name: `填表人/委託人`,
                        },
                        {
                            category: "試料管理",
                            type: `試料樣本紀錄(${sampleDistrict.name})`,
                            permission: permissions.PERMISSION_TEST_MATERIAL0302_TEST,
                            resource_id: `district/${sampleDistrict.sampleDistrictId}`,
                            name: `試料人/收件人`,
                        },
                        {
                            category: "試料管理",
                            type: `試料樣本紀錄(${sampleDistrict.name})`,
                            permission: permissions.PERMISSION_TEST_MATERIAL0302_RELATED,
                            resource_id: `district/${sampleDistrict.sampleDistrictId}`,
                            name: `關係人`,
                        },
                    ]
                })
                .flat()
            return testMaterialPermissions
        },
        getMeetingPermissions: (state, getters, rootState, rootGetters) => {
            const districts = rootState.meeting.districts
            return [
                ...districts.map((district) => ({
                    category: "會議管理",
                    type: `會議管理`,
                    permission: permissions.PERMISSION_CONFERENCE_LIST,
                    resource_id: `district/${district.meetingDistrictId}`,
                    name: `${district.district}瀏覽者`,
                })),
                {
                    category: "會議管理",
                    type: "會議管理",
                    permission: permissions.PERMISSION_CONFERENCE_LIST,
                    effect: "deny",
                    name: "禁止瀏覽者",
                },
            ]
        },
        getMailPermissions: (state, getters, rootState, rootGetters) => {
            return Object.entries(mailSetting)
                .map(([type, typePermissions]) => {
                    return typePermissions.map((value) => {
                        return {
                            category: "E-mail管理 (額外通知對象)",
                            type,
                            name: value.name,
                            hasPermission: true,
                        }
                    })
                })
                .flat()
        },
        getMailPermissionsWithHasPermission: (state, getters) => {
            return getters.getMailPermissions.map((permission) => {
                const hasPermission = state.extraTargets.find((target) => target.functionName === permission.name) ? true : false
                return {
                    ...permission,
                    hasPermission,
                }
            })
        },
        getAffairsRelatedPermissions: (state, getters, rootState, rootGetters) => {
            const myAffairsRelatedPermissions = state.userPermissions.filter((permission) => permission.action === "graveAffair:related")
            const groupedPermissions = Object.fromEntries(
                rootState.eventslist
                    .map((event) => {
                        return [
                            event.name,
                            myAffairsRelatedPermissions
                                .filter((permission) => permission.resource_id.startsWith(`type/${event.name}:`))
                                .map((permission) => ({
                                    ...permission,
                                    executor: permission.resource_id.split(":")[1].split("/")[1],
                                })),
                        ]
                    })
                    .filter(([_, permissions]) => permissions.length > 0) // 只顯示有資料的類別
            )
            return groupedPermissions
        },
        getAffairsExecutorPermissions: (state) => {
            return Object.fromEntries(Object.entries(state.affairsExecutorPermissions).filter(([_, permissions]) => permissions.length > 0))
        },
        getAttendanceObservepermissions: (state, getters, rootState, rootGetters) => {
            const myAttendanceObservepermissions = state.userPermissions.filter((permission) => permission.action === "attendance0201:observe")
            const groupedPermissions = Object.fromEntries([
                [
                    "額外觀察者(被誰觀察)",
                    state.observePermissions.map((permission) => ({
                        ...permission,
                        user: permission.grantee_id,
                    })),
                ],
                [
                    "額外觀察對象",
                    myAttendanceObservepermissions.map((permission) => ({
                        ...permission,
                        user: permission.resource_id.split("/")[1],
                    })),
                ],
            ])

            return groupedPermissions
        },
    },
    mutations: {
        updateDistricts(state, payload) {
            state.districts = payload
        },
        updateRooms(state, payload) {
            payload.sort((a, b) => a.meetingDistrictId.localeCompare(b.meetingDistrictId))
            state.rooms = payload
        },
        updateUserPermissions(state, payload) {
            state.userPermissions = payload
        },
        updateRevenuesStatisticsPermissions(state, payload) {
            state.revenuesStatisticsPermissions = payload
        },
        updateUserId(state, payload) {
            state.searchUserId = payload
        },
        updateSampleDistricts(state, payload) {
            state.sampleDistricts = payload
        },
        updateExtraTargets(state, payload) {
            state.extraTargets = payload
        },
        updateExecutorPermissions(state, payload) {
            state.affairsExecutorPermissions[payload.name] = payload.permissions
        },
        updateObservePermissions(state, payload) {
            state.observePermissions = payload
        },
    },
    actions: {
        actionGetUserPermissions({ commit, state, dispatch, rootState }, userId) {
            api.getPermissionsByUser(userId).then((res) => {
                commit("updateUserPermissions", res.data)
            })
            // 取得營運達成率分區資料
            api.getRevenuesStatisticsDepartments().then((res) => {
                const revenuesStatisticsDepartments = res.data
                const revenuesStatisticsPermissions = revenuesStatisticsDepartments.map((revenuesStatisticsDepartment) => ({
                    category: "業務專區",
                    type: "公司銷售狀況/營運達成率",
                    permission: permissions.PERMISSION_COMPANY_SALES_LIST,
                    resource_type: "0104",
                    resource_id: revenuesStatisticsDepartment.id,
                    name: `${state.districtName[revenuesStatisticsDepartment.district]}-${revenuesStatisticsDepartment.name}分區瀏覽者`,
                }))
                commit("updateRevenuesStatisticsPermissions", revenuesStatisticsPermissions)
            })
            // 取得試料管理分區資料
            api.getSampleDistricts().then((res) => {
                commit("updateSampleDistricts", res.data)
            })
            dispatch("meeting/actionGetAreas", null, { root: true })
            //取得額外通知對象
            api.getExtraTargets(userId).then((res) => {
                commit("updateExtraTargets", res.data)
            })
            //取得重大事件相關權限
            rootState.eventslist.forEach((event) => {
                const params = {
                    limit: 1000,
                    offset: 0,
                    action: "graveAffair:related",
                    resourceId: `type/${event.name}:executor/${userId}`,
                }
                api.getPermissions(params).then((res) => {
                    commit("updateExecutorPermissions", { name: event.name, permissions: res.data })
                })
            })
            const params = {
                limit: 1000,
                offset: 0,
                action: "attendance0201:observe",
                resourceId: `user/${userId}`,
            }
            api.getPermissions(params).then((res) => {
                commit("updateObservePermissions", res.data)
            })
        },
        setUserId({ commit }, userId) {
            commit("updateUserId", userId)
        },
    },
}
