<template>
    <!-- 外出申請 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose" dataBackdrop>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" @click="close">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">
                        {{ $t("page.attendance0201.outsideRequestBtn") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal" role="form">
                        <div class="form-group">
                            <label for="STARTDATA" class="col-xs-12 col-sm-2 control-label">{{ $t("misc.startTime") }}</label>
                            <div class="col-xs-4 col-sm-5">
                                <input type="date" class="form-control datepicker" id="STARTDATA" v-model="start" />
                            </div>
                            <div class="col-xs-4 col-sm-2">
                                <select name="startHour" class="form-control" v-model="startHour">
                                    <option value="">{{ $t("misc.hh") }}</option>
                                    <option v-for="(n, i) in 24" :value="i" :key="i">
                                        {{ $_.padStart(i, 2, "0") }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-4 col-sm-2">
                                <select name="startMinute" class="form-control" v-model="startMinute">
                                    <option value="">{{ $t("misc.mm") }}</option>
                                    <option :value="0">0</option>
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option>
                                    <option :value="30">30</option>
                                    <option :value="35">35</option>
                                    <option :value="40">40</option>
                                    <option :value="45">45</option>
                                    <option :value="50">50</option>
                                    <option :value="55">55</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="ENDDATA" class="col-xs-12 col-sm-2 control-label">{{ $t("misc.endTime") }}</label>
                            <div class="col-xs-4 col-sm-5">
                                <input type="date" class="form-control datepicker" id="ENDDATA" v-model="end" />
                            </div>
                            <div class="col-xs-4 col-sm-2">
                                <select name="endHour" class="form-control" v-model="endHour">
                                    <option value="">{{ $t("misc.hh") }}</option>
                                    <option v-for="(n, i) in 24" :value="i" :key="i">
                                        {{ $_.padStart(i, 2, "0") }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-4 col-sm-2">
                                <select name="endMinute" class="form-control" v-model="endMinute">
                                    <option value="">{{ $t("misc.mm") }}</option>
                                    <option :value="0">0</option>
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option>
                                    <option :value="30">30</option>
                                    <option :value="35">35</option>
                                    <option :value="40">40</option>
                                    <option :value="45">45</option>
                                    <option :value="50">50</option>
                                    <option :value="55">55</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="CATA2" class="col-sm-2 control-label">{{ $t("page.attendance0201.visitMethon") }}</label>
                            <div class="col-sm-9">
                                <select class="form-control" id="CATA2" v-model="editVisit.category">
                                    <option value="">{{ $t("misc.selectType") }}</option>
                                    <option v-for="e in outsideType" :key="e.id" :value="e.id">
                                        {{ $t(e.name) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">
                                {{ $t("page.attendance0201.visitClient") }}
                            </label>
                            <div class="col-sm-7">
                                <client-select v-model="clientTargets" multiple :allClients="allClients" @input="handleClientsChange" />
                            </div>
                            <div class="col-sm-3">
                                <button type="button" class="mt-1 btn btn-primary" @click="handleToCustomer">
                                    {{ $t("page.client0101.addNewCustomer") }}
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="CONTACT" class="col-xs-12 col-sm-2 control-label">
                                {{ $t("misc.contactMethod") }}
                            </label>
                            <div class="col-xs-5 col-sm-4">
                                <select class="form-control" id="CONTACT" v-model="editVisit.contactMethod">
                                    <option value="">{{ $t("placeholder.select") }}</option>
                                    <option value="手機">{{ $t("misc.cellPhone") }}</option>
                                    <option value="電話">{{ $t("misc.phone") }}</option>
                                    <option value="email">{{ $t("misc.email") }}</option>
                                    <option value="其他">{{ $t("misc.other") }}</option>
                                </select>
                            </div>
                            <div class="col-xs-7 col-sm-5">
                                <input type="text" class="form-control" id="CONTACT-OTHER" v-model="editVisit.contact" />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="CONTACT" class="col-xs-12 col-sm-2 control-label">
                                {{ $t("page.attendance0201.visitTopic") }}
                            </label>
                            <div class="col-xs-5 col-sm-4">
                                <select class="form-control" id="reason" v-model="editVisit.event">
                                    <option value="">{{ $t("placeholder.select") }}</option>
                                    <option v-for="e in visitEvents" :key="e.name" :value="e.name">
                                        {{ eventName(e) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="CONT" class="col-sm-2 control-label" :class="{ 'TEXT-CO1': !needDestination }">
                                {{ $t("misc.remark") }}
                            </label>
                            <div class="col-sm-10">
                                <textarea
                                    class="form-control"
                                    row="4"
                                    id="CONT"
                                    v-model="editVisit.remarks"
                                    :placeholder="remarkPlaceholder"
                                ></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 col-sm-2 control-label TEXT-CO1">{{ $t("page.attendance0201.notifyParty") }}</label>
                            <div class="col-sm-10">
                                <userselect v-model="edit_notify_person" />
                            </div>
                        </div>
                        <div class="form-group" style="margin-bottom: -5px">
                            <label class="col-xs-12 col-sm-2 control-label TEXT-CO1">{{ $t("page.attendance0201.useCar") }}</label>
                            <div class="col-xs-5 col-sm-4 mt-2 flex align-center">
                                <input type="checkbox" v-model="useCar" />
                                <span class="ml-1 text-blue"> ({{ $t("page.attendance0201.shOnly") }}) </span>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="addNewVisit">
                        {{ $t("misc.add") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import api from "@/Api/Api"
import * as moment from "moment"
import * as _ from "lodash"
import ClientSelect from "@/components/ClientSelect.vue"
export default {
    components: {
        ClientSelect,
    },
    data() {
        return {
            modalName: "createVisit",
            start: "",
            startHour: "",
            startMinute: "",
            end: "",
            endHour: "",
            endMinute: "",
            useCar: false,
            editVisit: {
                category: "",
                contactMethod: "",
                event: "",
                remarks: "",
            },
            clientTargets: [],
            edit_notify_person: [],
            jobAgents: [],
            handleAfterCreate: () => {},
        }
    },
    computed: {
        ...mapState(["user", "userId", "visitEvents", "outsideType"]),
        needDestination() {
            //若是國外出差,必須填寫目的地
            return this.editVisit.category === "出差" || this.editVisit.category === "內部出差國外"
        },
        remarkPlaceholder() {
            if (this.needDestination) {
                return this.$t("page.attendance0201.fillDestination")
            }
            return ""
        },
        allClients() {
            return !this.user?.number?.startsWith("US")
        },
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(["getVisitEvents"]),
        beforeOpen(e) {
            this.start = ""
            this.startHour = ""
            this.startMinute = ""
            if (e.start) {
                this.start = moment(e.start).format("YYYY-MM-DD")
                this.startHour = moment(e.start).hour()
                this.startMinute = moment(e.start).minute()
            }
            this.end = ""
            this.endHour = ""
            this.endMinute = ""
            this.editVisit = {
                category: "",
                contactMethod: "",
                event: "",
                remarks: "",
            }
            this.clientTargets = []
            this.edit_notify_person = []
            this.jobAgents = []
            this.handleAfterCreate = e.handleAfterCreate
            if (this.visitEvents.length === 0) {
                this.getVisitEvents()
            }
            this.getJobAgent()
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        getJobAgent() {
            const params = {
                userId: this.userId,
            }
            api.getJobAgent(params).then((res) => {
                if (res.status === 0 && res.data.length) {
                    const jobAgentData = res.data[0]
                    if (jobAgentData.mainAgent) {
                        this.jobAgents.push(jobAgentData.mainAgent)
                    }
                    if (jobAgentData.secondAgent) {
                        this.jobAgents.push(jobAgentData.secondAgent)
                    }
                }
            })
        },
        async addNewVisit() {
            if (!this.validator()) return
            const startDate = +moment(this.start).set({ hour: this.startHour, minute: this.startMinute }).format("x")
            const endDate = +moment(this.end).set({ hour: this.endHour, minute: this.endMinute }).format("x")
            const visitTarget = this.clientTargets.map((o) => o.clientId)
            const data = await api
                .post("/visit", {
                    startDate,
                    endDate,
                    category: this.editVisit.category,
                    contactMethod: this.editVisit.contactMethod,
                    contact: this.editVisit.contact,
                    event: this.editVisit.event,
                    remarks: this.editVisit.remarks,
                    visitTarget,
                    userId: this.userId,
                    notifyPerson: this.edit_notify_person.join(","),
                    isReport: false,
                    useCar: this.useCar,
                    agent: this.jobAgents.join(","),
                })
                .then((o) => o.data)
                .catch((e) => {
                    console.error(e)
                    throw e
                })
            // error message
            if (data.status == -2) {
                let message = data.message
                if (data?.data?.startDate) {
                    message += "\n" + "日期：" + moment(data.data.startDate).format("YYYY-MM-DD")
                }
                return alert(message)
            }
            if (data.status === 0) {
                this.handleAfterCreate()
                this.close()
            }
        },
        validator() {
            if (this.start === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.startTime")}`)
                return false
            }
            if (this.startHour === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.startTime")}(${this.$t("misc.hour")})`)
                return false
            }
            if (this.startMinute === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.startTime")}(${this.$t("misc.minute")})`)
                return false
            }
            if (this.end === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.endTime")}`)
                return false
            }
            if (this.endHour === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.endTime")}(${this.$t("misc.hour")})`)
                return false
            }
            if (this.endMinute === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.endTime")}(${this.$t("misc.minute")})`)
                return false
            }
            const startDate = moment(this.start).set({ hour: this.startHour, minute: this.startMinute }).valueOf()
            const endDate = moment(this.end).set({ hour: this.endHour, minute: this.endMinute }).valueOf()
            if (endDate <= startDate) {
                alert(this.$t("alert.timeRangeError"))
                return false
            }
            if (this.editVisit.category === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.allType")}`)
                return false
            }
            if (this.needDestination && this.editVisit.remarks === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.remark")}`)
                return false
            }
            if (this.clientTargets.length === 0) {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("page.attendance0201.visitClient")}`)
                return false
            }
            if (this.editVisit.contactMethod === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("misc.contactMethod")}`)
                return false
            }
            if (this.editVisit.event === "") {
                alert(`${this.$t("alert.fillAllRequired")}:${this.$t("page.attendance0201.visitTopic")}`)
                return false
            }
            return true
        },
        eventName(event) {
            return this.$i18n.locale == "en-US" ? event.englishName : event.name
        },
        handleClientsChange() {
            //若客戶的地區或產業別沒填，不給選擇，需更新資料後才能選
            let removeId = ""
            this.clientTargets.forEach((client) => {
                if (client.district == "" || client.country == "" || client.industry == "") {
                    alert(`${this.$t("page.attendance0201.alert.unableSelect")}: ${client.name}`)
                    removeId = client.clientId
                }
            })
            if (removeId) {
                this.clientTargets = this.clientTargets.filter((client) => client.clientId != removeId)
            }
        },
        handleToCustomer() {
            this.$router.push("/client0101")
            this.close()
        },
    },
}
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
    margin: 0;
}
</style>
