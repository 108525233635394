import Vue from "vue";

Vue.filter("currency", function(value, format) {
  if (value === 0 || value) {
    // if (Number.isInteger(value) || String(value).split(".").length === 1) {
    //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // } else 
    // if (String(value).split(".").length === 2) {
      let value2 = parseFloat(value).toFixed(2)
      let sp = String(value2).split(".");

      sp[0] = sp[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return sp.join(".");
    // }
  }
});
