import api from "@/Api/Api"
export default {
    namespaced: true,
    state: {
        tgaCategories: [],
        dscCategories: [],
    },
    mutations: {
        updateTgaCategories(state, payload) {
            state.tgaCategories = payload
        },
        updateDscCategories(state, payload) {
            state.dscCategories = payload
        },
    },
    actions: {
        actionGetTgaCategories({ commit }) {
            const params = {
                class: "SYS_2_00041",
            }
            api.getSelections(params).then((res) => {
                commit("updateTgaCategories", res.data)
            })
        },
        actionGetDscCategories({ commit }) {
            const params = {
                class: "SYS_2_00042",
            }
            api.getSelections(params).then((res) => {
                commit("updateDscCategories", res.data)
            })
        },
    },
}
