import Vue from "vue";
import dateFns from "date-fns";
import * as moment from "moment";
Vue.filter("formatDate", function(value, format) {
  if (value) {
    // if (typeof value === 'string') {
    //   value = new Date(value)
    // }
    return moment(value).subtract(8, 'h').format(format || "YYYY-MM-DD HH:mm")
    // return dateFns.format(value, format || "YYYY-MM-DD HH:mm", {timeZone:'Asia/Taipei'});
  }
});
Vue.filter("formatDateUTC", function(value, format) {
  if (value) {
    // if (typeof value === 'string') {
    //   value = new Date(value)
    // }
    return moment(value).format(format || "YYYY-MM-DD HH:mm")
    // return dateFns.format(value, format || "YYYY-MM-DD HH:mm", {timeZone:'Asia/Taipei'});
  }
});
