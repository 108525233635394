import _ from "lodash";
/*
  Graph implemented as a modified incidence list. O(1) for every typical
  operation except `removeNode()` at O(E) where E is the number of edges.
  ## Overview example:
  ```js
  const graph = new Graph;
  graph.addNode('A'); // => a node object. For more info, log the output or check
                      // the documentation for addNode
  graph.addNode('B');
  graph.addNode('C');
  graph.addEdge('A', 'C'); // => an edge object
  graph.addEdge('A', 'B');
  graph.getEdge('B', 'A'); // => undefined. Directed edge!
  graph.getEdge('A', 'B'); // => the edge object previously added
  graph.getEdge('A', 'B').weight = 2 // weight is the only built-in handy property
                                     // of an edge object. Feel free to attach
                                     // other properties
  graph.getInEdgesOf('B'); // => array of edge objects, in this case only one;
                           // connecting A to B
  graph.getOutEdgesOf('A'); // => array of edge objects, one to B and one to C
  graph.getAllEdgesOf('A'); // => all the in and out edges. Edge directed toward
                            // the node itself are only counted once
  forEachNode(function(nodeObject) {
    console.log(node);
  });
  forEachEdge(function(edgeObject) {
    console.log(edgeObject);
  });
  graph.removeNode('C'); // => 'C'. The edge between A and C also removed
  graph.removeEdge('A', 'B'); // => the edge object removed
  ```
  ## Properties:
  - nodeSize: total number of nodes.
  - edgeSize: total number of edges.
   */

const hasProp = {}.hasOwnProperty;
const Node = class Node {
  constructor(id, d) {
    Object.assign(this, d);
    // outEdges is a collection of (toId, edge) pair, where the toId key is
    // the node id toward which the edge's directed. The value edge is itself
    // an object which, by default, only contains a weight property defaulted
    // to 1. Using objects to represent nodes and edges allow additional
    // attributes to be attached. Same applies to inEdges.
    this._id = id;
    this._graph = d._graph;
    this._outEdges = {};
    this._inEdges = {};
  }

  getData() {
    return _.pickBy(this, (o, k) => {
      return !k.startsWith("_") || k === "_id";
    });
  }

  // get direct children
  getChildren(type) {
    return _.reduce(
      this._outEdges,
      (a, edge) => {
        const node = this._graph.getNode(edge._toId);
        if (!type || node.type === type) {
          a.push(node);
        }
        return a;
      },
      []
    );
  }

  getParents(type) {
    return _.reduce(
      this._inEdges,
      (a, edge) => {
        const node = this._graph.getNode(edge._fromId);
        if (!type || node.type === type) {
          a.push(node);
        }
        return a;
      },
      []
    );
  }

  getPath(path = []) {
    path.unshift(this._id);
    const parentEdges = Object.keys(this._inEdges);
    if (!parentEdges.length) {
      return path;
    } else {
      return this._graph
        .getNode(this._inEdges[parentEdges[0]]._fromId)
        .getPath(path);
    }
  }
};
const Edge = class Edge {
  constructor(fromId, toId, d) {
    d.weight = d.weight || 1;
    Object.assign(this, d);
    this._id = `${fromId}_${toId}`;
    this._graph = d._graph;
    this._fromId = fromId;
    this._toId = toId;
  }
};
const Graph = class Graph {
  constructor() {
    this._nodes = {};
    this.nodeSize = 0;
    this.edgeSize = 0;
  }

  addNode(id, data = {}) {
    // console.log('addNode()', id)

    /*
    The `id` is a unique identifier for the node, and should **not** change
    after it's added. It will be used for adding, retrieving and deleting
    related edges too.
    **Note** that, internally, the ids are kept in an object. JavaScript's
    object hashes the id `'2'` and `2` to the same key, so please stick to a
    simple id data type such as number or string.
    _Returns:_ the node object. Feel free to attach additional custom properties
    on it for graph algorithms' needs. **Undefined if node id already exists**,
    as to avoid accidental overrides.
     */
    if (!id) {
      // console.error('id must not be null', { id })
      return;
    }
    if (!this._nodes[id]) {
      this.nodeSize++;
      this._nodes[id] = new Node(id, { ...data, _graph: this });
      return this._nodes[id];
    }
  }

  updateNode(id, data = {}) {
    if (this._nodes[id]) {
      Object.assign(this._nodes[id], data);
      return this._nodes[id];
    }
  }

  getNode(id) {
    /*
    _Returns:_ the node object. Feel free to attach additional custom properties
    on it for graph algorithms' needs.
    */
    return this._nodes[id];
  }

  removeNode(id) {
    /*
    _Returns:_ the node object removed, or undefined if it didn't exist in the
    first place.
    */
    let inEdgeId, outEdgeId, ref, ref1;
    const nodeToRemove = this._nodes[id];
    if (!nodeToRemove) {
      return;
    } else {
      ref = nodeToRemove._outEdges;
      for (outEdgeId in ref) {
        if (!hasProp.call(ref, outEdgeId)) continue;
        this.removeEdge(id, outEdgeId);
      }
      ref1 = nodeToRemove._inEdges;
      for (inEdgeId in ref1) {
        if (!hasProp.call(ref1, inEdgeId)) continue;
        this.removeEdge(inEdgeId, id);
      }
      this.nodeSize--;
      delete this._nodes[id];
    }
    return nodeToRemove;
  }

  addEdge(fromId, toId, data = {}) {
    // console.log('addEdge()', fromId, toId)

    /*
    `fromId` and `toId` are the node id specified when it was created using
    `addNode()`. `weight` is optional and defaults to 1. Ignoring it effectively
    makes this an unweighted graph. Under the hood, `weight` is just a normal
    property of the edge object.
    _Returns:_ the edge object created. Feel free to attach additional custom
    properties on it for graph algorithms' needs. **Or undefined** if the nodes
    of id `fromId` or `toId` aren't found, or if an edge already exists between
    the two nodes.
    */
    if (this.getEdge(fromId, toId)) {
      return;
    }
    const fromNode = this._nodes[fromId];
    const toNode = this._nodes[toId];
    if (!fromNode || !toNode) {
      // console.error('fromId, toId must not be null', { fromNode, toNode })
      return;
    }
    const edgeToAdd = new Edge(fromId, toId, { ...data, _graph: this });
    fromNode._outEdges[toId] = edgeToAdd;
    toNode._inEdges[fromId] = edgeToAdd;
    this.edgeSize++;
    return edgeToAdd;
  }

  getEdge(fromId, toId) {
    /*
    _Returns:_ the edge object, or undefined if the nodes of id `fromId` or
    `toId` aren't found.
    */
    const fromNode = this._nodes[fromId];
    const toNode = this._nodes[toId];
    if (!fromNode || !toNode) {
    } else {
      return fromNode._outEdges[toId];
    }
  }

  removeEdge(fromId, toId) {
    /*
    _Returns:_ the edge object removed, or undefined of edge wasn't found.
    */
    const fromNode = this._nodes[fromId];
    const toNode = this._nodes[toId];
    const edgeToDelete = this.getEdge(fromId, toId);
    if (!edgeToDelete) {
      return;
    }
    delete fromNode._outEdges[toId];
    delete toNode._inEdges[fromId];
    this.edgeSize--;
    return edgeToDelete;
  }

  getInEdgesOf(nodeId) {
    /*
    _Returns:_ an array of edge objects that are directed toward the node, or
    empty array if no such edge or node exists.
    */
    let fromId;
    const toNode = this._nodes[nodeId];
    const inEdges = [];
    const ref = toNode != null ? toNode._inEdges : {};
    for (fromId in ref) {
      if (!hasProp.call(ref, fromId)) continue;
      inEdges.push(this.getEdge(fromId, nodeId));
    }
    return inEdges;
  }

  getOutEdgesOf(nodeId) {
    /*
    _Returns:_ an array of edge objects that go out of the node, or empty array
    if no such edge or node exists.
    */
    let toId;
    const fromNode = this._nodes[nodeId];
    const outEdges = [];
    const ref = fromNode != null ? fromNode._outEdges : 0;
    for (toId in ref) {
      if (!hasProp.call(ref, toId)) continue;
      outEdges.push(this.getEdge(nodeId, toId));
    }
    return outEdges;
  }

  getAllEdgesOf(nodeId) {
    /*
    **Note:** not the same as concatenating `getInEdgesOf()` and
    `getOutEdgesOf()`. Some nodes might have an edge pointing toward itself.
    This method solves that duplication.
    _Returns:_ an array of edge objects linked to the node, no matter if they're
    outgoing or coming. Duplicate edge created by self-pointing nodes are
    removed. Only one copy stays. Empty array if node has no edge.
     */
    let i, j, ref;
    const inEdges = this.getInEdgesOf(nodeId);
    const outEdges = this.getOutEdgesOf(nodeId);
    if (inEdges.length === 0) {
      return outEdges;
    }
    const selfEdge = this.getEdge(nodeId, nodeId);
    for (
      i = j = 0, ref = inEdges.length;
      ref >= 0 ? j < ref : j > ref;
      i = ref >= 0 ? ++j : --j
    ) {
      if (inEdges[i] === selfEdge) {
        // Place that repleated in edge at the end and pop it.
        [inEdges[i], inEdges[inEdges.length - 1]] = [
          inEdges[inEdges.length - 1],
          inEdges[i],
        ];
        inEdges.pop();
        break;
      }
    }
    return inEdges.concat(outEdges);
  }

  async forEachNode(operation) {
    let nodeId, nodeObject;
    const ref = this._nodes;
    for (nodeId in ref) {
      if (!hasProp.call(ref, nodeId)) continue;
      nodeObject = ref[nodeId];
      await operation(nodeObject, nodeId);
    }
    return 0;
  }

  // Manually return. This is to avoid CoffeeScript's nature of returning an
  // expression, unneeded and wastful (array) in this case.
  async forEachEdge(operation) {
    let edgeObject, nodeId, nodeObject, ref1, toId;
    const ref = this._nodes;
    for (nodeId in ref) {
      if (!hasProp.call(ref, nodeId)) continue;
      nodeObject = ref[nodeId];
      ref1 = nodeObject._outEdges;
      for (toId in ref1) {
        if (!hasProp.call(ref1, toId)) continue;
        edgeObject = ref1[toId];
        await operation(edgeObject);
      }
    }
    return 0;
  }

  async traverse(
    direction,
    nodeId,
    operation,
    accumulator = {},
    path = [nodeId],
    level = 0
  ) {
    if (nodeId in accumulator) {
      return;
    }
    if (!this._nodes[nodeId]) {
      return
    }
    const getEdgesFn = direction === "up" ? "getInEdgesOf" : "getOutEdgesOf";
    const directionId = direction === "up" ? "_fromId" : "_toId";
    await operation(this._nodes[nodeId], nodeId, path, level);

    accumulator[nodeId] = true;
    const edges = this[getEdgesFn](nodeId);

    for (const edge of edges) {
      await this.traverse(
        direction,
        edge[directionId],
        operation,
        accumulator,
        [...path, edge[directionId]],
        level + 1
      );
    }
  }

  async traverseUp(nodeId, operation) {
    await this.traverse("up", nodeId, operation);
  }

  async traverseDown(nodeId, operation) {
    await this.traverse("down", nodeId, operation);
  }
};

// Manual return, check forEachNode for reason.
export default Graph;
