<template>
    <div>
        <span class="multipleSel_Button btn btn-default" @click="toggle">{{
            $_.get(selectedDepartments, "length") ? `已選擇${selectedDepartments.length}名` : "選擇部門"
        }}</span>
        <div class="multiselect-section" v-if="open">
            <!-- <select
        class="aselect form-control"
        v-model="selectedDepartmentId"
      >
        <option value="0">所有部門</option>
        <option
          v-for="(department) in departments"
          :value="department._id"
          :key="department._id"
        >{{department.name}}</option>
      </select> -->
            <div class="sol-container my-select" style="width: auto; visibility: initial" :class="{ 'sol-active': openList }">
                <div class="sol-inner-container">
                    <div class="sol-input-container" @click="openList = true">
                        <input v-model="query" type="text" :placeholder="$t('placeholder.pickSelect')" @focus="openList = true" />
                    </div>
                    <div class="sol-caret-container"><b class="sol-caret"></b></div>
                </div>
                <div class="sol-selection-container" v-if="openList">
                    <div class="sol-action-buttons">
                        <a class="sol-select-all" @click="selectAll" v-if="!single">{{ $t("misc.selectAll") }}</a>
                        <a class="sol-deselect-all" @click="deselectAll">{{ $t("misc.cancelSelect") }}</a>
                        <div class="sol-clearfix"></div>
                    </div>
                    <div class="sol-no-results" v-if="$_.isEmpty(matched)">
                        {{ $t("misc.noResult") }}
                    </div>
                    <div class="sol-selection" style="max-height: 150px" v-else>
                        <div class="sol-option" v-for="o in matched" :key="o._id">
                            <label class="sol-label">
                                <input
                                    type="checkbox"
                                    class="sol-checkbox"
                                    name="character"
                                    :value="o._id"
                                    :disabled="single && $_.get(selectedDepartments, 'length') >= 1 && selectedDepartments[0] !== o._id"
                                    v-model="selectedDepartments"
                                />
                                <div class="sol-label-text">
                                    {{ getNameLabel(o._id) }}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="sol-current-selection">
                    <div class="sol-selected-display-item" v-for="(id, i) in selectedDepartments" :key="id">
                        <span class="sol-quick-delete" @click="deselect(i)"> × </span>
                        <span class="sol-selected-display-item-text">
                            {{ getNameLabel(id) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import _ from "lodash"

export default {
    name: "UserSelect",
    props: ["name", "value", "single", "opened", "filterOptions"],
    computed: {
        // ...mapState(["usersTree"]),
        // tree: function() {
        //   return this.toTreeselectNode(this.usersTree.root).children;
        // }
        ...mapState(["departmentGraph"]),
        ...mapGetters(["getDepartment"]),
    },
    asyncComputed: {
        async departments() {
            let data = []
            await this.departmentGraph.traverseDown("dep-root", (n, id, path, level) => {
                if (n && n.type === "department") {
                    if (n.name !== "root") {
                        data.push(n)
                    }
                }
            })
            // 如果有限制區域，只顯示限制區域的部門
            if (this.filterOptions?.area) {
                data = data.filter((department) => department.number.startsWith(this.filterOptions.area))
            }
            return data
        },
        async matched() {
            if (!this.departments) {
                return []
            }

            return this.departments.filter((department) => {
                let result = department.name.toLowerCase().includes(this.query.toLowerCase())
                return result
            })
        },
    },
    data() {
        return {
            // inputVal: this.value,
            selectedDepartments: this.value || [],
            open: "opened" in this ? this.opened : true,
            openList: false,
            query: "",
            // selectedDepartments: [],
            selectedDepartmentId: "0",
        }
    },
    mounted() {},
    methods: {
        loadOptions({ action, parentNode, callback, instanceId }) {
            callback()
        },
        // end tree
        toggle() {
            this.open = !this.open
        },
        selectAll() {
            this.matched.map((o) => {
                this.selectedDepartments.push(o._id)
            })
            this.selectedDepartments = _.uniq(this.selectedDepartments)
        },
        deselectAll() {
            this.selectedDepartments = []
        },
        deselect(index) {
            this.selectedDepartments.splice(index, 1)
        },
        getNameLabel(id) {
            return _.get(this.getDepartment(id), "name")
        },
    },
    watch: {
        selectedDepartments(val) {
            this.$emit("input", val)
        },
        value(v) {
            this.selectedDepartments = v
        },
    },
}
</script>

<style lang="scss" scoped>
.multiselect-section {
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 1em;
    margin-top: 0.3em;
}
.sol-quick-delete {
    user-select: none;
}
</style>
