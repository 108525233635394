<template functional>
  <div
    :class="['tag-icon', props.iconClass]"
    :style="{
      backgroundColor: `${
        props.color.startsWith('#')
          ? props.color
          : `var(--${props.color})` || '#b4b4b9'
      }`,
    }"
  ></div>
</template>

<script>
export default {
  name: "TagIcon",
};
</script>

<style lang="scss" scoped>
.tag-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $red;
  border-radius: 50%;
}
</style>
