<template>
    <!-- 忘刷提醒 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">忘刷單詳細資料</h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="span_name">{{ $t("misc.name") }}：</span>
                            <span>{{ getUser(record.user_id).chinese_name }}</span>
                        </li>
                        <li>
                            <span class="span_name">忘刷日期：</span>
                            <span>{{ date }}</span>
                        </li>
                        <li>
                            <span class="span_name">當日簽到時間：</span>
                            <span>{{ start || "無紀錄" }}</span>
                        </li>
                        <li>
                            <span class="span_name">當日簽退時間：</span>
                            <span>{{ end || "無紀錄" }}</span>
                        </li>
                        <li>
                            <span class="span_name">當日外出時間：</span>
                            <span>
                                <div v-for="visit in visits">{{ visit.label }}</div>
                            </span>
                        </li>
                        <li>
                            <span class="span_name">當日請假時間：</span>
                            <span>
                                <div v-for="leave in leaves">{{ leave.label }}</div>
                            </span>
                        </li>
                        <li v-if="reasonHr">
                            <span class="span_name">異常說明：</span>
                            <span>{{ reasonHr }}</span>
                        </li>
                        <li>
                            <span class="span_name">實際簽到時間：</span>
                            <span>{{ actualInTime }}</span>
                        </li>
                        <li>
                            <span class="span_name">實際簽退時間：</span>
                            <span>{{ actualOutTime }}</span>
                        </li>
                        <li>
                            <span class="span_name">原因說明：</span>
                            <span>{{ reason }}</span>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button v-if="canApprove" type="button" class="btn btn-primary" @click="handleAccept">
                        {{ $t("misc.approveAccept") }}
                    </button>
                    <button v-if="canApprove" type="button" class="btn btn-danger" @click="handleReject">
                        {{ $t("misc.approveReject2") }}
                    </button>
                    <button v-if="canEdit" type="button" class="btn btn-danger" @click="handleEdit">
                        {{ $t("misc.edit") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import moment from "moment"
export default {
    data() {
        return {
            modalName: "showForgotPunchinForm",
            callback: () => {},
            record: {},
            mode: "view",
            remarks: "",
            date: "",
            start: "",
            end: "",
            signInAbnormal: false,
            signOutAbnormal: false,
            actualInTime: "",
            actualOutTime: "",
            reasonHr: "",
            reason: "",
        }
    },
    computed: {
        ...mapState(["userId"]),
        ...mapGetters(["getUser", "getApprovers"]),
        editMode() {
            return this.mode === "edit"
        },
        leaves() {
            if (!this.record.leaves) return []
            return this.record.leaves.map((leave) => {
                const leaveDate = moment(leave.start_date).format("YYYY-MM-DD")
                const startTime = moment(leave.start_date).format("HH:mm")
                const endDate = moment(leave.end_date).format("YYYY-MM-DD")
                const endTime = moment(leave.end_date).format("HH:mm")
                if (leaveDate == endDate) return { label: `${leaveDate} ${startTime}~${endTime} ${leave.name}`, id: leave.Id }
                return { label: `${leaveDate} ${startTime}~${endDate} ${endTime} ${leave.name}`, id: leave.Id }
            })
        },
        visits() {
            if (!this.record.visits) return []
            return this.record.visits.map((visit) => {
                const visitDate = moment(visit.start_date).format("YYYY-MM-DD")
                const startTime = moment(visit.start_date).format("HH:mm")
                const endDate = moment(visit.end_date).format("YYYY-MM-DD")
                const endTime = moment(visit.end_date).format("HH:mm")
                if (visitDate == endDate) return { label: `${visitDate} ${startTime}~${endTime} ${visit.name}`, id: visit.Id }
                return { label: `${visitDate} ${startTime}~${endDate} ${endTime} ${visit.name}`, id: visit.Id }
            })
        },
        canInputActualInTime() {
            return this.signInAbnormal
        },
        canInputActualOutTime() {
            return this.signOutAbnormal
        },
        canApprove() {
            return this.isApprover && this.record.status === "待審核"
        },
        canEdit() {
            return this.record.user_id === this.userId && this.record.status === "待審核"
        },
    },
    asyncComputed: {
        async isApprover() {
            const approvers = await this.getApprovers(this.record.user_id)
            return approvers.includes(this.userId)
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.recordId = e.record.id
            this.mode = e.mode
            this.callback = e.callback || this.callback
            this.getRecord()
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {
            this.clear()
        },
        getRecord() {
            api.getForgotPunchIn(this.recordId).then((res) => {
                this.record = res.data
                this.date = this.record.date
                this.record = this.record
                this.signInAbnormal = this.record.sign_in === "Y"
                this.signOutAbnormal = this.record.sign_out === "Y"
                this.start = this.record.punch_in_time
                this.end = this.record.punch_out_time
                this.actualInTime = this.record.actual_in_time
                this.actualOutTime = this.record.actual_out_time
                this.reasonHr = this.record.reason_hr
                this.reason = this.record.reason
            })
        },
        async handleAccept() {
            const params = {
                status: "已簽核",
            }
            await api.patchForgotPunchIn(this.recordId, params)
            this.callback()
            this.close()
            this.clear()
        },
        async handleReject() {
            const params = {
                status: "已否決",
            }
            await api.patchForgotPunchIn(this.recordId, params)
            this.callback()
            this.close()
            this.clear()
        },
        handleEdit() {
            const prop = {
                record: this.record,
                callback: this.callback,
            }
            this.$modal.show("editForgotPunchinForm", prop)
            this.close()
            this.clear()
        },
        clear() {
            this.date = ""
            this.start = ""
            this.end = ""
            this.signInAbnormal = false
            this.signOutAbnormal = false
            this.reasonHr = ""
        },
        validator() {
            if (!this.signInAbnormal && !this.signOutAbnormal) {
                alert(`${this.$t("alert.fillAllRequired")}:異常類別`)
                return false
            }
            if (this.reason_hr === "") {
                alert(`${this.$t("alert.fillAllRequired")}:原因說明`)
                return false
            }
            return true
        },
    },
}
</script>
