<template>
    <!-- 顯示請假 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">
                        {{ $t("page.attendance0301.viewLeaveRequest") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="title">{{ $t("misc.startDate") }}：</span>
                            <span>{{ leave.startDate | formatDateUTC }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.endDate") }}：</span>
                            <span>{{ leave.endDate | formatDateUTC }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.leaveType") }}：</span>
                            <span>{{ $t(getI18nKey(leave.category)) }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.contactMethod") }}：</span>
                            <span>{{ $t(getI18nKey(leave.contactMethod)) }} {{ leave.contact }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.applicantPeople") }}：</span>
                            <span v-if="leave.User">{{ leave.User.chineseName }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("page.attendance0201.notifyParty") }}：</span>
                            <span>{{ userIdsToNames(leave.notifyPerson) }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("sideMenu.attendance.attendance0401") }}：</span>
                            <span v-if="leave.agent">{{ agentsString }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.remark") }}：</span>
                            <span>{{ leave.remarks }}</span>
                        </li>
                        <li>
                            <span class="title">{{ $t("misc.attech") }}：</span>
                            <span class="btn btn-success mr-1" v-for="file in leave.files" :key="file.fileId" @click="openFile(file.path)">
                                {{ file.fileName }}</span
                            >
                        </li>
                        <li style="border-bottom: 0">
                            <span class="title">{{ $t("page.attendance0201.approvalStatus") }}：</span>
                            <span>{{ $t(getI18nKey(leave.approvalStatus)) }}</span>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <p v-if="canApprove && cantApprove" class="text-red">
                        {{ $t("page.attendance0301.approveNeedSupportingDocuments") }}
                    </p>
                    <button v-if="canDelete" type="button" class="btn btn-danger" @click="deleteLeave()">
                        {{ $t("misc.delete") }}
                    </button>
                    <button v-if="canEdit" type="button" class="btn btn-primary" @click="handleEditClick()">
                        {{ $t("misc.edit") }}
                    </button>
                    <button v-if="canApprove" :disabled="cantApprove" type="button" class="btn btn-primary" @click="updateStatus('已簽核')">
                        {{ $t("misc.approveAccept2") }}
                    </button>
                    <button v-if="canApprove" type="button" class="btn btn-danger" @click="updateStatus('否決')">
                        {{ $t("misc.approveReject2") }}
                    </button>
                    <button v-if="canCancel" type="button" class="btn btn-danger" @click="updateStatus('未簽核')">
                        {{ $t("misc.cancelSign") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import moment from "moment"
import { userIdsToNames } from "@/lib/oa"
export default {
    data() {
        return {
            modalName: "showLeave",
            callback: () => {},
            leave: {},
        }
    },
    computed: {
        ...mapState(["userId", "leaveType"]),
        ...mapGetters(["getIsAdmin", "getI18nKey", "getApprovers"]),
        canDelete() {
            if (this.getIsAdmin) {
                return true
            }
            return this.leave.userId == this.userId && this.leave.approvalStatus == "未簽核"
        },
        canEdit() {
            return this.leave.userId == this.userId && this.leave.approvalStatus == "未簽核"
        },
        canApprove() {
            return this.isApprover && this.leave.approvalStatus == "未簽核"
        },
        canCancel() {
            return (this.isApprover || this.getIsAdmin) && (this.leave.approvalStatus == "已簽核" || this.leave.approvalStatus == "否決")
        },
        needDocuments() {
            if (this.leave.category === "病假") {
                const start = moment(this.leave.startDate).format("YYYY-MM-DD")
                const end = moment(this.leave.endDate).format("YYYY-MM-DD")
                return start != end
            }
            return ["婚假", "喪假", "公傷病假", "公假", "產假", "產檢假", "陪產假", "陪產檢"].includes(this.leave.category)
        },
        cantApprove() {
            if (!this.needDocuments) return false
            if (this.leave.files.length) return false
            return true
        },
        agentsString() {
            if (!this.leave.agent) return ""
            const agents = this.leave.agent.split(",")
            let agentsString = ""
            agentsString = `${userIdsToNames(agents[0])}(${this.$t("misc.primary")})`
            if (agents.length > 1) {
                agentsString += `, ${userIdsToNames(agents[1])}(${this.$t("misc.secondary")})`
            }
            return agentsString
        },
    },
    asyncComputed: {
        async isApprover() {
            const approvers = await this.getApprovers(this.leave.userId)
            return approvers.includes(this.userId)
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.leave = e.leave
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        clear() {},
        deleteLeave() {
            if (confirm(this.$t("alert.deleteDoubleCheck"))) {
                api.deleteLeave(this.leave.leaveId).then((res) => {
                    this.callback()
                    this.close()
                })
            }
        },
        handleEditClick() {
            const prop = {
                leave: this.leave,
                callback: this.callback,
            }
            this.$modal.show("editLeave", prop)
            this.close()
        },
        userIdsToNames: userIdsToNames,
        updateStatus(approvalStatus) {
            const params = {
                approvalStatus,
                approvingUser: this.userId,
            }
            api.putLeave(this.leave.leaveId, params).then((res) => {
                this.callback()
                this.close()
            })
        },
        openFile(file) {
            window.open(api.imageUrl + file)
        },
    },
}
</script>

<style scoped>
a.delete {
    position: absolute;
    right: 1em;
    font-size: 1.5em;
    font-weight: bold;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    color: #fff;
    padding: 8px 8px;
    background-color: #0078d7;
    border-radius: 4px;
}
a.delete:hover {
    text-decoration: none;
    background-color: #2badaf;
}
.title {
    width: 10em;
    white-space: nowrap;
}
</style>
