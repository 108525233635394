<template>
    <div :id="id" class="vmodal modal fade" :data-backdrop="dataBackdrop ? 'static' : false">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "OaModal",
    props: {
        id: {
            type: String,
            default: "",
        },
        dataBackdrop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    mounted() {
        this.$bus.$on("show.bs.modal." + this.id, (e) => {
            this.$emit("before-open", e)
        })
        this.$bus.$on("hide.bs.modal." + this.id, (e) => {
            this.$emit("before-close", e)
        })
    },
    beforeDestroy() {
        this.$bus.$off("show.bs.modal." + this.id)
        this.$bus.$off("hide.bs.modal." + this.id)
    },
    methods: {},
    computed: {},
    watch: {},
}
</script>

<style lang="scss" scoped></style>
