var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('oa-modal',{attrs:{"id":_vm.modalName},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header color1"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_c('span',{staticClass:"close_COLOR",attrs:{"aria-hidden":"true"}},[_vm._v("×")]),_c('span',{staticClass:"sr-only"},[_vm._v("Close")])]),_c('h2',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("page.home.importantMatters.todayImportantMatters")))])]),_c('div',{staticClass:"modal-body"},[_c('ol',[_vm._l((_vm.showEvents),function(event,index){return [(event.type === 'BULLETIN')?_c('li',{key:index},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.bulletin", { title: event.content.title }))+" "),_c('a',{on:{"click":function () { return _vm.handleBulletinClick(event.content); }}},[_vm._v(_vm._s(_vm.$t("page.home.importantMatters.seeDetail")))])]):(event.type === 'ACCOUNT')?_c('li',{key:index},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.account", { name: event.content.customerShortName, amount: event.content.amount, currency: event.content.currency, }))+" "),_c('router-link',{attrs:{"to":{
                                    name: 'graveAffair0103event',
                                    params: { id: event.content.id },
                                },"data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.report")))])],1):(event.type === 'ORDER')?_c('li',{key:index},[_vm._v(" ["+_vm._s(_vm.$t("page.graveAffair0101.qualityAbnormity"))+"-"+_vm._s(_vm.$t(_vm.getI18nKey(event.content.title)))+"] - "+_vm._s(event.content.content)+" ， "),_c('router-link',{attrs:{"to":{
                                    name: _vm.getRouterPath(event.content.title),
                                    params: { id: event.content.id },
                                },"data-dismiss":_vm.modalName}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.seeDetail")))])],1):(event.type === 'FLUCTUATION')?_c('li',{key:index},[_vm._v(" ["+_vm._s(_vm.$t("page.graveAffair0101.faild35"))+"] - "),_c('span',{class:event.content.color},[_vm._v(_vm._s(event.content.content2))]),_vm._v(" "+_vm._s(event.content.content1)+" ， "),_c('router-link',{attrs:{"to":("/graveAffair0201/" + (event.content.dbSerialNumber) + "/" + (event.content.materialSerialNumber)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.seeDetail")))])],1):(event.type === 'STOCK')?_c('li',{key:index},[_vm._v(" ["+_vm._s(_vm.$t("page.graveAffair0101.safeStock"))+"] "+_vm._s(event.content.content)+"，"),_c('router-link',{attrs:{"to":{
                                    name: 'graveAffair0106event',
                                    params: { id: event.content.id },
                                },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.seeDetail")))])],1):(event.type === 'JOB')?_c('li',{key:index},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.job", { title: event.content.title }))+" "),_c('a',{attrs:{"data-dismiss":_vm.modalName},on:{"click":function () { return _vm.openJobContentModal(event.content); }}},[_vm._v(_vm._s(_vm.$t("page.home.importantMatters.report")))])]):(event.type === 'PROJECT')?_c('li',{key:index},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.project", { title: event.content.name }))+" "),_c('router-link',{attrs:{"to":("/project0103/" + (event.content.taskId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.report")))])],1):(event.type === 'MEETING')?_c('li',{key:index},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.meeting", { time: _vm.moment(event.content.startAt).format("HH:mm"), topic: event.content.topic, }))+" "),_c('a',{attrs:{"data-dismiss":_vm.modalName},on:{"click":function () { return _vm.showMeetingModal(event.content); }}},[_vm._v(_vm._s(_vm.$t("page.home.importantMatters.seeDetail")))])]):_vm._e()]})],2),_c('div',{staticClass:"flex justify-end"},[_c('a',{on:{"click":_vm.handleSetEventOptions}},[_vm._v(_vm._s(_vm.$t("page.home.importantMatters.viewEventsSetting")))])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("misc.close"))+" ")]),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.handleDontShowAgainToday}},[_vm._v(" "+_vm._s(_vm.$t("page.home.importantMatters.dontShowAgainToday"))+" ")])])])]),_c('today-event-options-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }