import Vue from "vue";
let loader;
const loading = {
  // [id]:true|false
};
const startLoading = (id) => {
  if (!Object.keys(loading).length) {
    loader = Vue.$loading.show({
      // Optional parameters
      container: null,
      canCancel: false,
    });
  }
  loading[id] = true;
};
const stopLoading = (id) => {
  delete loading[id];
  if (!Object.keys(loading).length) {
    loader.hide();
  }
};

export default {
  start: startLoading,
  stop: stopLoading,
};
