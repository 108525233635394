<template>
  <div>
    <div>change to modal</div>
    <!-- <treeselect
    @input="handleChange(name,$event)"
    :options="usersTree"
    :searchable="true"
    :show-count="true"
    :multiple="false"
    :disable-branch-nodes="true"
    :default-expand-level="1"
    placeholder
    v-model="inputVal"
    search-nested
  >
    <label
      slot="option-label"
      slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
      :class="labelClassName"
    >
      {{ node.label }}
      <span
        v-if="shouldShowCount"
        :class="countClassName"
      >({{ count }})</span>
    </label>
  </treeselect> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UserSelectSingleValue",
  props: ["name", "value"],
  computed: {
    ...mapState(["usersTree"]),
  },
  methods: {
    handleChange: function(listName, e) {
      // this.approverList;
      // console.log(listName, e);
    },
  },
  data() {
    return { inputVal: this.value };
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
    },
    value(v) {
      this.inputVal = v;
    },
  },
};
</script>

<style></style>
