import api from "@/Api/Api"
export default {
    namespaced: true,
    state: {
        userSettings: {},
    },
    getters: {
        getNumbersToNames:
            (state, getters, rootState, rootGetters) =>
            (numbers, separator = " ") => {
                if (!numbers) {
                    return ""
                }
                if (typeof numbers === "string") {
                    return numbers
                        .split(",")
                        .map(
                            (number) =>
                                rootGetters.getUser(number, { isNumber: true, fallbackAsMe: false }).chinese_name
                        )
                        .join(separator)
                }
            },
        getRoleOptions: (state, getters, rootState) => {
            return rootState.roles.map((role) => {
                return {
                    text: role.name,
                    value: role.id,
                }
            })
        },
    },
    mutations: {
        updateUserSettings(state, payload) {
            if (payload.importantMatters == "") {
                // 預設全選
                payload.importantMatters = [
                    "BULLETIN",
                    "ACCOUNT",
                    "ORDER",
                    "FLUCTUATION",
                    "STOCK",
                    "JOB",
                    "PROJECT",
                    "MEETING",
                ].join(",")
            }
            if (payload.calendar == "") {
                // 預設全選
                payload.calendar = ["meeting", "visit", "myVisit", "leave", "myLeave"].join(",")
            }
            state.userSettings = payload
        },
    },
    actions: {
        actionGetUserSetting({ commit }) {
            api.getUserSetting().then((res) => {
                commit("updateUserSettings", res.data)
                commit("calendar/updateCalendars", res.data.calendar.split(","), { root: true })
            })
        },
        actionUpdateUserSetting({ dispatch }, params) {
            return new Promise((resolve, reject) => {
                api.updateUserSetting(params).then((res) => {
                    dispatch("actionGetUserSetting")
                    resolve()
                })
            })
        },
    },
}
