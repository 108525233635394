<template>
    <div
        :class="['material-icon', name, iconClass]"
        :style="{ fontSize: `${size || 24}px`, color: `${color || inherit}` }"
        v-if="iconSet === 'material' && name"
        @click="emitClick"
    >
        {{ name }}
    </div>
    <div v-else-if="iconSet === 'semantic' && name">
        <sui-icon :name="name" :color="color"></sui-icon>
    </div>
</template>

<script>
export default {
    name: "VueMaterialIcon",
    props: ["name", "iconClass", "size", "color", "iconset"],
    data() {
        return {
            iconSet: this.$props.iconset || "material",
        }
    },
    methods: {
        emitClick() {
            this.$emit("click")
        },
    },
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"),
        url("../assets/fonts/material-icons-regular.woff2") format("woff2"),
        url("../assets/fonts/material-icons-regular.woff") format("woff");
}

/**
   * Based on Google guideline:
   * https://google.github.io/material-design-icons/#icon-font-for-the-web
   */
.material-icon {
    color: inherit;
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    &.clickable {
        padding: 8px;
        border-radius: 50%;
        user-select: none;

        &:hover {
            background-color: rgba(60, 64, 67, 0.08);
            cursor: pointer;
        }

        &:active {
            background-color: rgba(60, 64, 67, 0.12);
        }
    }
}
</style>
