import config from "../config"
import axios from "axios"
import loading from "../lib/loading"
import shortid from "shortid"
import store from "../store"
import Router from "../router"
import _ from "lodash"

const url = config.api
const handleApiError = (error) => {
    if (_.get(error, "response.status") === 401) {
        if (localStorage.getItem("login_Usertoken")) {
            alert("登入逾時")
        }
        localStorage.setItem("login_Usertoken", "")
        if (window.location.pathname !== "/") {
            window.location.href = "/"
        }
        return
    } else if (_.get(error, "response.status") === 400) {
        console.error(error)
        alert(error.response.data.message)
    } else {
        alert(error.message)
    }
    throw error
}

export default {
    imageUrl: url + "/filesystem/",
    url: url,
    async post(api, data, config = {}) {
        const reqId = shortid.generate()
        loading.start(reqId)
        const needAuth = !api.startsWith("/login")
        if (needAuth) {
            api = "/_" + api
            _.set(config, "headers.token", store.getters.getUserToken())
        }
        return axios
            .post(url + api, data, config)
            .then((res) => {
                loading.stop(reqId)
                return res
            })
            .catch((error) => {
                loading.stop(reqId)
                handleApiError(error)
            })
    },
    async get(api, config = {}) {
        const reqId = shortid.generate()
        loading.start(reqId)
        const needAuth = !api.startsWith("/login")
        if (needAuth) {
            api = "/_" + api
            _.set(config, "headers.token", store.getters.getUserToken())
        }
        const response = await axios
            .get(url + api, config)
            .then((res) => {
                loading.stop(reqId)
                if (res.data.status === 0) {
                    return res.data
                } else {
                    if (res.status === 200) {
                        console.error("data status error: ", res.data)
                        return res.data
                    }
                    throw res.data
                }
            })
            .catch((error) => {
                loading.stop(reqId)
                handleApiError(error)
            })
        return response
    },
    async put(api, data, config = {}) {
        const reqId = shortid.generate()
        if (!config.silent) {
            loading.start(reqId)
        }
        const needAuth = !api.startsWith("/login")
        if (needAuth) {
            api = "/_" + api
            _.set(config, "headers.token", store.getters.getUserToken())
        }
        var response = await axios
            .put(url + api, data, config)
            .then((res) => {
                if (!config.silent) {
                    loading.stop(reqId)
                }
                return res
            })
            .catch((error) => {
                if (!config.silent) {
                    loading.stop(reqId)
                }
                handleApiError(error)
            })
        return response
    },
    async patch(api, data, config = { headers: {} }) {
        const reqId = shortid.generate()
        if (!config.silent) {
            loading.start(reqId)
        }
        const needAuth = !api.startsWith("/login")
        if (needAuth) {
            api = "/_" + api
            _.set(config, "headers.token", config.headers.token || store.getters.getUserToken())
        }
        var response = await axios
            .patch(url + api, data, config)
            .then((res) => {
                if (!config.silent) {
                    loading.stop(reqId)
                }
                if (res.status === 200) {
                    return res
                } else {
                    throw res
                }
            })
            .catch((error) => {
                if (!config.silent) {
                    loading.stop(reqId)
                }
                handleApiError(error)
            })
        return response
    },
    async delete(api, config = {}) {
        const reqId = shortid.generate()
        loading.start(reqId)
        const needAuth = !api.startsWith("/login")
        if (needAuth) {
            api = "/_" + api
            _.set(config, "headers.token", store.getters.getUserToken())
        }

        const response = await axios
            .delete(url + api, config)
            .then((res) => {
                loading.stop(reqId)
                console.log(res)
                return res
            })
            .catch((error) => {
                loading.stop(reqId)
                handleApiError(error)
                return res
            })
        return response
    },
    //共用
    getReports(id) {
        const params = { id }
        return this.get("/report", { params })
    },
    postReport(params) {
        return this.post("/report", params)
    },
    getFiles(id) {
        const params = { id }
        return this.get("/file", { params })
    },
    uploadFiles(id, files, type = "") {
        if (!files.length) {
            return Promise.resolve()
        }
        let formdata = new FormData()
        formdata.append("id", id)
        if (type) {
            formdata.append("funcType", type)
        }
        files.forEach((file) => {
            formdata.append("file", file)
        })
        let config = {
            headers: {
                "Content-Type": "multipart/form-data", //之前说的以表单传数据的格式来传递fromdata
            },
        }
        return this.post("/file", formdata, config)
    },
    postInterviewComment(params) {
        return new Promise((resolve, reject) => {
            this.post("/interviewcomment", params).then(resolve).catch(reject)
        })
    },
    getLockUser(params) {
        return new Promise((resolve, reject) => {
            this.get("/lockUser", { params }).then(resolve).catch(reject)
        })
    },
    getLockIP(params) {
        return new Promise((resolve, reject) => {
            this.get("/lockIP", { params }).then(resolve).catch(reject)
        })
    },
    postLockIP(params) {
        return new Promise((resolve, reject) => {
            this.post("/lockIP", params).then(resolve).catch(reject)
        })
    },
    patchLockIP(id, params) {
        return new Promise((resolve, reject) => {
            this.patch(`/lockIP/${id}`, params).then(resolve).catch(reject)
        })
    },
    deleteLockIP(id) {
        return new Promise((resolve, reject) => {
            this.delete(`/lockIP/${id}`).then(resolve).catch(reject)
        })
    },
    getOverdueAccountReceivables(params) {
        return this.get("/overdueAccountReceivables", { params })
    },
    getObserver(params) {
        return new Promise((resolve, reject) => {
            this.get("/observer", { params }).then(resolve).catch(reject)
        })
    },
    postObserver(params) {
        return new Promise((resolve, reject) => {
            this.post("/observer", params).then(resolve).catch(reject)
        })
    },
    putObserver(id, params) {
        return new Promise((resolve, reject) => {
            this.put(`/observer/${id}`, params).then(resolve).catch(reject)
        })
    },
    deleteObserver(id) {
        return new Promise((resolve, reject) => {
            this.delete(`/observer/${id}`).then(resolve).catch(reject)
        })
    },
    patchLockUserRelease(id) {
        return new Promise((resolve, reject) => {
            let param = {
                status: -1,
            }
            this.patch(`/lockUser/${id}`, param).then(resolve).catch(reject)
        })
    },
    //訪談
    getInterview(params) {
        return this.get("/interview", { params })
    },
    //客戶
    putClient(id, params) {
        return this.put(`/client/${id}`, params)
    },
    patchClient(id, params) {
        return this.patch(`/client/${id}`, params)
    },
    deleteClient(id, params) {
        return this.delete(`/client/${id}`, params)
    },
    putClientcontact(id, params) {
        return this.put(`/clientcontact/${id}`, params)
    },
    deleteClientcontact(id, params) {
        return this.delete(`/clientcontact/${id}`, params)
    },
    //合併客戶
    clientMerge(params) {
        return this.post("/clientMerge", params)
    },
    //二十大客戶地區
    getClientArea(params) {
        return this.get("/clientArea", { params })
    },
    postClientArea(params) {
        return this.post("/clientArea", params)
    },
    putClientArea(id, params) {
        return this.put(`/clientArea/${id}`, params)
    },
    deleteClientArea(id) {
        return this.delete(`/clientArea/${id}`)
    },
    //二十大客戶
    getClientTop20(params) {
        return this.get("/clientTop20", { params })
    },
    postClientTop20(params) {
        return this.post("/clientTop20", params)
    },
    putClientTop20(id, params) {
        return this.put(`/clientTop20/${id}`, params)
    },
    deleteClientTop20(id) {
        return this.delete(`/clientTop20/${id}`)
    },
    //拜訪客戶地區
    getInterviewClientArea(params) {
        return this.get("/interviewClientArea", { params })
    },
    postInterviewClientArea(params) {
        return this.post("/interviewClientArea", params)
    },
    putInterviewClientArea(id, params) {
        return this.put(`/interviewClientArea/${id}`, params)
    },
    deleteInterviewClientArea(id) {
        return this.delete(`/interviewClientArea/${id}`)
    },
    //拜訪客戶群組
    getInterviewGroup(params) {
        return this.get("/interviewGroup", { params })
    },
    //只拿人員
    getInterviewGroupCountUser(params) {
        return this.get("/interviewGroupCountUser", { params })
    },
    postInterviewGroup(params) {
        return this.post("/interviewGroup", params)
    },
    putInterviewGroup(id, params) {
        return this.put(`/interviewGroup/${id}`, params)
    },
    deleteInterviewGroup(id) {
        return this.delete(`/interviewGroup/${id}`)
    },
    //試料管理
    getSampleAppointmentDone(id) {
        return this.get(`/sampleAppointmentDones/${id}`)
    },
    putSampleAppointmentDones(id, params) {
        return this.put(`/sampleAppointmentDones/${id}`, params)
    },
    //競爭公司
    getCompetitionCompany(params) {
        return this.get("/competitionCompany", { params })
    },
    getCompetitionCompanyById(id) {
        return this.get(`/competitionCompany/${id}`)
    },
    postCompetitionCompany(params) {
        return this.post("/competitionCompany", params)
    },
    putCompetitionCompany(id, params) {
        return this.put(`/competitionCompany/${id}`, params)
    },
    deleteCompetitionCompany(id) {
        return this.delete(`/competitionCompany/${id}`)
    },
    putCompetitionDisplay(id, params) {
        return this.put(`/competitionRecall/${id}`, params)
    },
    //新增競爭對手規格group時使用
    getCompetitionGroup(params) {
        return this.get("/competitionGroup")
    },
    //競爭對手規格
    getCompetition(params) {
        return this.get("/competition", { params })
    },
    postCompetition(params) {
        const formdata = new FormData()
        Object.entries(params).forEach(([key, value]) => {
            formdata.append(key, value)
        })
        return this.post("/competition", formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    putCompetition(id, params) {
        const formdata = new FormData()
        Object.entries(params).forEach(([key, value]) => {
            formdata.append(key, value)
        })
        return this.put(`/competition/${id}`, formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    //公佈欄
    getBulletin(params) {
        return this.get("/bulletin", { params })
    },
    postBulletin(params) {
        return this.post("/bulletin", params)
    },
    putBulletin(id, params) {
        return this.put(`/bulletin/${id}`, params)
    },
    deleteBulletin(id) {
        return this.delete(`/bulletin/${id}`)
    },
    //試料樣本紀錄
    postSampleRecord(params) {
        return this.post("/sampleRecord", params)
    },
    //試料樣本紀錄回報
    postSampleRecordReport(params) {
        return this.post("/sampleRecordReport", params)
    },
    //請假
    getLeave(params) {
        return this.get("/leave", { params })
    },
    postLeave(params) {
        return this.post("/leave", params)
    },
    putLeave(id, params) {
        return this.put(`/leave/${id}`, params)
    },
    deleteLeave(id) {
        return this.delete(`/leave/${id}`)
    },
    //請假審核列表
    getAuditLeave(params) {
        return this.get("/auditLeave", { params })
    },
    //本人可審核請假列表
    getWaitApproveLeaves(params) {
        return this.get("/waitApproveLeave", { params })
    },
    //請假審核列表匯出
    getAuditLeaveFile(params) {
        const fullPath = `${this.url}/_/auditLeaveFile`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "Record.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    //外出
    getVisit(params) {
        return this.get("/visit", { params })
    },
    putVisit(id, params) {
        return this.put(`/visit/${id}`, params)
    },
    deleteVisit(id) {
        return this.delete(`/visit/${id}`)
    },
    //外出審核列表
    getAuditVisit(params) {
        return this.get("/auditVisit", { params })
    },
    //本人可審核外出列表
    getWaitApproveVisits(params) {
        return this.get("/waitApproveVisit", { params })
    },
    //外出審核列表匯出
    getAuditVisitFile(params) {
        const fullPath = `${this.url}/_/auditVisitFile`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "Record.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    // 出勤統計
    getAttendances(params) {
        return this.get("/attendances", { params })
    },
    // 新增出勤紀錄
    createAttendance(params) {
        return this.patch(`/attendances/new`, params)
    },
    // 修改出勤紀錄
    updateAttendance(params) {
        return this.patch(`/attendances`, params)
    },
    //請假審核列表匯出
    getAttendanceFile(params) {
        const fullPath = `${this.url}/_/attendanceFiles`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "Record.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    //職務代理
    getJobAgent(params) {
        return this.get("/jobagent", { params })
    },
    //人員
    putUser(id, params) {
        return this.put(`/user/${id}`, params)
    },
    patchUser(id, params, config) {
        return this.patch(`/user/${id}`, params, config)
    },
    //訂單
    getOrderNotShippedTotal(params) {
        return this.get("/orderNotShippedTotal", { params })
    },
    //工作
    getJobList(params) {
        return this.get("/jobList", { params })
    },
    getJobs(params) {
        return this.get("/job", { params })
    },
    getJobCategories(params) {
        return this.get("/jobcategory", { params })
    },
    postJob(params) {
        return this.post("/job", params)
    },
    putJob(id, params) {
        return this.put(`/job/${id}`, params)
    },
    postJobReport(params) {
        return this.post("/jobreport", params)
    },
    getJobProcess({ offset = 0, limit = Number.MAX_SAFE_INTEGER } = { offset: 0, limit: Number.MAX_SAFE_INTEGER }) {
        const params = { offset, limit }
        return this.get("/jobprocess", { params })
    },
    //任務
    putTask(id, params) {
        return this.put(`/task/${id}`, params)
    },
    deleteTask(id) {
        return this.delete(`/task/${id}`)
    },
    //任務回報
    postTaskReport(params) {
        return this.post("/taskreport", params)
    },
    //客戶月銷統計
    customerMonthlySales(params) {
        return this.get("/customerMonthlySales", { params })
    },
    //代理月銷統計
    agentCustomerMonthlySales(params) {
        return this.get("/agentCustomerMonthlySales", { params })
    },
    //營收表
    monthlyRevenuesStatistics(params) {
        return this.get("/monthlyRevenuesStatistics", { params })
    },
    //月營收明細
    monthlyRevenues(params) {
        return this.get("/monthlyRevenues", { params })
    },
    //委外測試報告
    postTest(params) {
        return this.post("/test", params)
    },
    //文件管理
    getDocumentTree(params) {
        return this.get("/documentTree", { params })
    },
    getDocuments(params) {
        return this.get(`/document`, { params })
    },
    getDocument(id) {
        return this.get(`/document/${id}`)
    },
    postDocument(params) {
        return this.post("/document", params)
    },
    putDocument(id, params) {
        return this.put(`/document/${id}`, params)
    },
    deleteDocument(id) {
        return this.delete(`/document/${id}`)
    },
    postDocumentTag(params) {
        return this.post("/documentTag", params)
    },
    postDirectory(params) {
        return this.post("/directory", params)
    },
    patchDirectory(id, params) {
        return this.patch(`/directory/${id}`, params)
    },
    deleteDirectory(id) {
        return this.delete(`/directory/${id}`)
    },
    //簽核中的文件
    getDocumentApproving(params) {
        return this.get("/documentApproving", { params })
    },
    getTags(params) {
        return this.get(`/tag`, { params })
    },
    postTag(params) {
        return this.post(`/tag`, params)
    },
    patchTag(id, params) {
        return this.patch(`/tag/${id}`, params)
    },
    deleteTag(id) {
        return this.delete(`/tag/${id}`)
    },
    getDocumentLogs(params) {
        return this.get(`/documentRecords`, { params })
    },
    //網站客戶詢價
    getWebContactDatas(params) {
        return this.get(`/webContactDatas`, { params })
    },
    //網站客戶詢價簽核
    getWebContactDatasApproving(params) {
        return this.get(`/webContactDatasApproving`, { params })
    },
    //轉移部門
    getTransferDep(params) {
        return this.get(`/webContactDatas/transferDep`, { params })
    },
    //費用登錄
    getFeeReports(params) {
        return this.get(`/expense`, { params })
    },
    getFeeReport(id) {
        return this.get(`/expense/${id}`)
    },
    postFeeReport(params) {
        return this.post(`/expense`, params)
    },
    putFeeReport(id, params) {
        return this.put(`/expense/${id}`, params)
    },
    patchFeeReport(id, params) {
        return this.patch(`/expense/${id}`, params)
    },
    deleteFeeReport(id) {
        return this.delete(`/expense/${id}`)
    },
    getFeeStatis(params) {
        return this.get(`/expenseStatis`, { params })
    },
    getFeeStatisTotal(params) {
        return this.get(`/expenseTotalStatis`, { params })
    },
    getFeeZip(path, fileName = "data.zip") {
        const fullPath = `${this.url}${path}`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
    },
    downloadDocument(path, fileName = "data.zip") {
        const fullPath = `${this.url}${path}?t=${store.getters.getUserToken()}`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
    },
    // 訂單特殊列表設定
    getGroups(params) {
        return this.get(`/group`, { params })
    },
    postGroup(params) {
        return this.post(`/group`, params)
    },
    patchGroups(id, params) {
        return this.patch(`/group/${id}`, params)
    },
    deleteGroup(id) {
        return this.delete(`/group/${id}`)
    },
    // 會議
    getMeeting(id) {
        return this.get(`/meeting/${id}`)
    },
    getMeetings(params) {
        return this.get(`/meeting`, { params })
    },
    postMeeting(params) {
        return this.post(`/meeting`, params)
    },
    putMeeting(id, params) {
        return this.put(`/meeting/${id}`, params)
    },
    deleteMeeting(id) {
        return this.delete(`/meeting/${id}`)
    },
    getValidMeetingTimes(params) {
        return this.get(`/meetingtimes`, { params })
    },
    getUsedMeetingTimes(params) {
        return this.get(`/usedMeetingTimes`, { params })
    },
    getMeetingCategories() {
        return this.get(`/meetingCategory`)
    },
    postMeetingCategory(params) {
        return this.post("/meetingCategory", params)
    },
    putMeetingCategory(id, params) {
        return this.put(`/meetingCategory/${id}`, params)
    },
    deleteMeetingCategory(id) {
        return this.delete(`/meetingCategory/${id}`)
    },
    getSelections(params) {
        return this.get(`/selection`, { params })
    },
    postTga(params) {
        return this.post(`/tga`, params)
    },
    putTga(id, params) {
        return this.put(`/tga/${id}`, params)
    },
    postDsc(params) {
        return this.post(`/dsc`, params)
    },
    putDsc(id, params) {
        return this.put(`/dsc/${id}`, params)
    },
    getYearRevenuesStatistics(params) {
        return this.get(`/year_revenues_statistics`, { params })
    },
    getSalesRevenuesStatistics(params) {
        return this.get(`/sales_revenues_statistics`, { params })
    },
    getRevenuesStatisticsValidSales(params) {
        return this.get(`/revenues_statistics_valid_sales`, { params })
    },
    getRevenuesStatisticsDepartments(params) {
        return this.get(`/revenues_statistics_departments`, { params })
    },
    postRevenuesStatisticsDepartments(params) {
        return this.post(`/revenues_statistics_departments`, params)
    },
    putRevenuesStatisticsDepartments(id, params) {
        return this.put(`/revenues_statistics_departments/${id}`, params)
    },
    deleteRevenuesStatisticsDepartments(id) {
        return this.delete(`/revenues_statistics_departments/${id}`)
    },
    getBreakevens(params) {
        return this.get(`/breakevens`, { params })
    },
    getSalesAttendances(params) {
        return this.get(`/salesAttendance`, { params })
    },
    deleteAttendance(userId) {
        return this.delete(`/cancelAttendance/${userId}`)
    },
    getNytexIPs(params) {
        return this.get("/nytexIPs", { params })
    },
    postNytexIP(params) {
        return this.post("/nytexIPs", params)
    },
    patchNytexIP(id, params) {
        return this.patch(`/nytexIPs/${id}`, params)
    },
    deleteNytexIP(id) {
        return this.delete(`/nytexIPs/${id}`)
    },
    getSystem(params) {
        return this.get(`/system/${params.name}`, { params })
    },
    patchSystem(params) {
        return this.patch(`/system`, params)
    },
    //安全庫存
    getSafetyStocks(params) {
        return this.get(`/safetyStocks`, { params })
    },
    //專案管理
    getProjects(params) {
        return this.get(`/project`, { params })
    },
    //個人設定
    getUserSetting(params) {
        return this.get(`/userSetting`, { params })
    },
    updateUserSetting(params) {
        return this.patch(`/userSetting`, params)
    },
    // 部門預算表
    getBudgets(params) {
        return this.get(`/budget`, { params })
    },
    getUserBudget(params) {
        return this.get(`/userBudget`, { params })
    },
    postBudget(params) {
        return this.post("/budget", params)
    },
    patchBudget(id, params) {
        return this.patch(`/budget/${id}`, params)
    },
    deleteBudget(id) {
        return this.delete(`/budget/${id}`)
    },
    // 職訓
    getTrainings(params) {
        return this.get(`/training`, { params })
    },
    getTraining(trainingId) {
        return this.get(`/training/${trainingId}`)
    },
    postTraining(params) {
        return this.post("/training", params)
    },
    patchTraining(id, params) {
        return this.patch(`/training/${id}`, params)
    },
    //本人可審核外出列表
    getWaitApproveTrainings(params) {
        return this.get("/waitApproveTraining", { params })
    },
    trainingAudit({ id, status }) {
        return this.put(`/trainingAudit/${id}/${status}`)
    },
    trainingReport(id, params) {
        return this.post(`/trainingReport/${id}`, params)
    },
    //職訓列表匯出
    getTrainingFile(params) {
        const fullPath = `${this.url}/_/trainingExportFile`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "training.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    deleteTrainingReport(id) {
        return this.delete(`/trainingReport/${id}`)
    },
    getCertificates(params) {
        return this.get(`/certificate`, { params })
    },
    getCertificate(certificateId) {
        return this.get(`/certificate/${certificateId}`)
    },
    postCertificate(params) {
        return this.post("/certificate", params)
    },
    patchCertificate(id, params) {
        return this.patch(`/certificate/${id}`, params)
    },
    deleteCertificate(id) {
        return this.delete(`/certificate/${id}`)
    },
    //證照匯出
    getCertificateFile(params) {
        const fullPath = `${this.url}/_/certificateExportFile`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "certificate.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    getCertificateCategories() {
        return this.get(`/certificateCategory`)
    },
    postCertificateCategory(params) {
        return this.post("/certificateCategory", params)
    },
    patchCertificateCategory(id, params) {
        return this.patch(`/certificateCategory/${id}`, params)
    },
    deleteCertificateCategory(id) {
        return this.delete(`/certificateCategory/${id}`)
    },
    getContracts(params) {
        return this.get(`/contract`, { params })
    },
    getContract(contractId) {
        return this.get(`/contract/${contractId}`)
    },
    postContract(params) {
        return this.post("/contract", params)
    },
    patchContract(id, params) {
        return this.patch(`/contract/${id}`, params)
    },
    deleteContract(id) {
        return this.delete(`/contract/${id}`)
    },
    getContractCategories() {
        return this.get(`/contractCategory`)
    },
    postContractCategory(params) {
        return this.post("/contractCategory", params)
    },
    patchContractCategory(id, params) {
        return this.patch(`/contractCategory/${id}`, params)
    },
    deleteContractCategory(id) {
        return this.delete(`/contractCategory/${id}`)
    },
    //合約匯出
    getContractFile(params) {
        const fullPath = `${this.url}/_/contractExportFile`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "contract.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    //EZ-Sales
    getCustomers(params) {
        return this.get(`/customer`, { params })
    },
    getCustomer(customerId) {
        return this.get(`/customerDetail/${customerId}`)
    },
    postCustomer(params) {
        return this.post("/customer", params)
    },
    patchCustomer(id, params) {
        return this.patch(`/customer/${id}`, params)
    },
    deleteCustomer(id) {
        return this.delete(`/customer/${id}`)
    },
    postCustomerShareholders(id, params) {
        return this.post(`/customerShareholder/${id}`, params)
    },
    putCustomerPotential(id, params) {
        return this.put(`/customersPotential/${id}`, params)
    },
    putCustomerProcurement(id, params) {
        return this.put(`/customersProcurement/${id}`, params)
    },
    putCustomerPurchasing(id, params) {
        return this.put(`/customersPurchasing/${id}`, params)
    },
    putCustomerSpwRating(id, params) {
        return this.put(`/customerSpwRatings/${id}`, params)
    },
    putCustomerCompetitiveSituations(id, params) {
        return this.put(`/customerCompetitiveSituations/${id}`, params)
    },
    putCustomerOthers(id, params) {
        return this.put(`/customerOthers/${id}`, params)
    },
    getCharacters(params) {
        return this.get(`/character`, { params })
    },
    getCharacter(characterId) {
        return this.get(`/characterDetail/${characterId}`)
    },
    postCharacter(params) {
        return this.post("/character", params)
    },
    putCharacter(characterId, params) {
        return this.put(`/character/${characterId}`, params)
    },
    deleteCharacter(characterId) {
        return this.delete(`/character/${characterId}`)
    },
    putCharacterWork(characterId, params) {
        return this.put(`/characterWork/${characterId}`, params)
    },
    postCharacterFamily(id, params) {
        return this.post(`/characterFamily/${id}`, params)
    },
    postCharacterHobby(id, params) {
        return this.post(`/characterHobby/${id}`, params)
    },
    postCharacterSport(id, params) {
        return this.post(`/characterSport/${id}`, params)
    },
    postCharacterFood(id, params) {
        return this.post(`/characterFood/${id}`, params)
    },
    postCharacterPet(id, params) {
        return this.post(`/characterPet/${id}`, params)
    },
    putCharacterPosition(characterId, params) {
        return this.put(`/characterPosition/${characterId}`, params)
    },
    postCharacterEducational(characterId, params) {
        return this.post(`/characterEducational/${characterId}`, params)
    },
    putCharacterOthers(characterId, params) {
        return this.put(`/characterOthers/${characterId}`, params)
    },
    getEzProjects(params) {
        return this.get(`/ezProject`, { params })
    },
    getEzProject(projectId) {
        return this.get(`/ezProject/${projectId}`)
    },
    postEzProject(params) {
        return this.post("/ezProject", params)
    },
    putEzProject(projectId, params) {
        return this.put(`/ezProject/${projectId}`, params)
    },
    deleteEzProject(projectId) {
        return this.delete(`/ezProject/${projectId}`)
    },
    getEzAnalysis(projectId) {
        return this.get(`/ezAnalysis/${projectId}`)
    },
    patchEzAnalysis(id, params) {
        return this.patch(`/ezAnalysis/${id}`, params)
    },
    getEzPlan(planId) {
        return this.get(`/ezStrategic/${planId}`)
    },
    postEzPlan(projectId, params) {
        return this.post(`/ezStrategic/${projectId}`, params)
    },
    putEzPlan(planId, params) {
        return this.put(`/ezStrategic/${planId}`, params)
    },
    deleteEzPlan(planId) {
        return this.delete(`/ezStrategic/${planId}`)
    },
    patchEzFocusStop(projectId, params) {
        return this.patch(`/ezFocusStop/${projectId}`, params)
    },
    getEzFocusStop(projectId) {
        return this.get(`/ezFocusStop/${projectId}`)
    },
    getEzProcurementPaths(projectId) {
        return this.get(`/ezProcurementRouting/${projectId}`)
    },
    postEzProcurementPath(projectId, params) {
        return this.post(`/ezProcurementRouting/${projectId}`, params)
    },
    putEzProcurementPath(pathId, params) {
        return this.put(`/ezProcurementRouting/${pathId}`, params)
    },
    deleteEzProcurementPath(pathId) {
        return this.delete(`/ezProcurementRouting/${pathId}`)
    },
    postEzProcurementPathPeople(pathId, params) {
        return this.post(`/ezProcurementRoutingPeople/${pathId}`, params)
    },
    getEzPeoples(projectId) {
        return this.get(`/ezPeople/${projectId}`)
    },
    postEzPeople(projectId, characterId) {
        return this.post(`/ezPeople/${projectId}/${characterId}`)
    },
    putEzPeople(peopleId, params) {
        return this.put(`/ezPeople/${peopleId}`, params)
    },
    deleteEzPeople(peopleId) {
        return this.delete(`/ezPeople/${peopleId}`)
    },
    getEzRelationshipPlans(projectId, params) {
        return this.get(`/ezRelationshipPlan/${projectId}`, { params })
    },
    postEzRelationshipPlan(projectId, params) {
        return this.post(`/ezRelationshipPlan/${projectId}`, params)
    },
    putEzRelationshipPlan(planId, params) {
        return this.put(`/ezRelationshipPlan/${planId}`, params)
    },
    deleteEzRelationshipPlan(planId) {
        return this.delete(`/ezRelationshipPlan/${planId}`)
    },
    postEzRelationshipPlanPeople(planId, params) {
        return this.post(`/ezRelationshipPlanPeople/${planId}`, params)
    },
    postEzRelationshipGift(planId, params) {
        return this.post(`/ezRelationshipGift/${planId}`, params)
    },
    deleteEzRelationshipGift(GiftId) {
        return this.delete(`/ezRelationshipGift/${GiftId}`)
    },
    getEzConcepts(planId, params) {
        return this.get(`/ezConceptSelling/${planId}`, { params })
    },
    postEzConcept(planId, params) {
        return this.post(`/ezConceptSelling/${planId}`, params)
    },
    putEzConcept(conceptId, params) {
        return this.put(`/ezConceptSelling/${conceptId}`, params)
    },
    deleteEzConcept(conceptId) {
        return this.delete(`/ezConceptSelling/${conceptId}`)
    },
    //匯出
    getEzProjectReportFile(params) {
        const fullPath = `${this.url}/_/ezProjectReport`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "projects.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    getWeeklyReviews(params) {
        return this.get(`/weeklyReview`, { params })
    },
    getWeeklyReview({ reviewId, version = 0, ...params }) {
        return this.get(`/weeklyReviewDetail/${reviewId}/${version}`, { params })
    },
    getWeeklyReviewVersion({ reviewId, ...params }) {
        return this.get(`/weeklyReviewVersion/${reviewId}`, { params })
    },
    postWeeklyReview(params, { year, month, area, reviewId = null }) {
        if (!reviewId) return this.post(`/weeklyReview/${year}/${month}/${area}`, params)
        return this.post(`/weeklyReview/${year}/${month}/${area}?weekly_review_id=${reviewId}`, params)
    },
    patchWeeklyReviewLock(params) {
        return this.patch(`/weeklyReviewLock`, params)
    },
    getSalesFunnels(params) {
        return this.get(`/salesFunnel`, { params })
    },
    getSalesFunnel({ funnelId, version = 0, ...params }) {
        return this.get(`/salesFunnelDetail/${funnelId}/${version}`, { params })
    },
    getSalesFunnelVersion({ funnelId, ...params }) {
        return this.get(`/salesFunnelVersion/${funnelId}`, { params })
    },
    postSalesFunnel(params, { year, month, area }) {
        return this.post(`/salesFunnel/${year}/${month}/${area}`, params)
    },
    getPredictionAccuracics(params) {
        return this.get(`/predictionAccuracy`, { params })
    },
    getPredictionAccuracy(reviewId, params) {
        return this.get(`/predictionAccuracy/${reviewId}`, { params })
    },
    setPredictionAccuracy(params) {
        return this.post(`/predictionAccuracy`, params)
    },
    getAccuracyRevenues(params) {
        return this.get(`/accuracyRevenue`, { params })
    },
    // 忘卡
    getForgotPunchIns(params) {
        return this.get(`/forgotPunchIn`, { params })
    },
    getForgotPunchIn(forgotPunchInId) {
        return this.get(`/forgotPunchIn/${forgotPunchInId}`)
    },
    postForgotPunchIn(params) {
        return this.post("/forgotPunchIn", params)
    },
    patchForgotPunchIn(forgotPunchInId, params) {
        return this.patch(`/forgotPunchIn/${forgotPunchInId}`, params)
    },
    //本人可審核外出列表
    getWaitApproveforgotPunchIns(params) {
        return this.get("/waitApproveforgotPunchIn", { params })
    },
    // 商標專利
    getTrademarks(params) {
        return this.get(`/trademark`, { params })
    },
    putTrademark(trademarkId, params) {
        return this.put(`/trademark/${trademarkId}`, params)
    },
    deleteTrademark(trademarkId) {
        return this.delete(`/trademark/${trademarkId}`)
    },
    // 問卷調查
    getQuestionnaireMeta(params) {
        return this.get(`/questionnaireType`, { params })
    },
    getQuestionnaires(params) {
        return this.get(`/questionnaire`, { params })
    },
    getQuestionnaireFormIds(params) {
        return this.get(`/questionnaireFormId`, { params })
    },
    //問卷調查匯出
    getQuestionnaireFile(params) {
        const fullPath = `${this.url}/_/questionnaireExport`
        const config = {
            headers: {
                token: store.getters.getUserToken(),
            },
            responseType: "blob",
            params,
        }
        return axios.get(fullPath, config).then((res) => {
            const blb = new Blob([res.data])
            const url = window.URL.createObjectURL(blb)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "questionnaire.xlsx")
            document.body.appendChild(link)
            link.click()
        })
    },
    // 權限管理
    getPermissions(params) {
        const { offset = 0, limit = Number.MAX_SAFE_INTEGER } = params
        const paramsWithPager = { offset, limit, ...params }
        return this.get(`/permission`, { params: paramsWithPager })
    },
    getPermissionsByUser(userId) {
        return this.get(`/permissionByUser/${userId}`)
    },
    postPermissions(params) {
        return this.post(`/permission`, params)
    },
    patchPermission(id, params) {
        return this.patch(`/permission/${id}`, params)
    },
    deletePermission(id) {
        return this.delete(`/permission/${id}`)
    },
    deletePermissions(params) {
        return this.delete(`/permission`, { data: params })
    },
    // 試料管理
    getSampleDistricts(params) {
        return this.get(`/sampleDistrict`, { params })
    },
    //額外通知對象
    getExtraTargets(userId) {
        return this.get(`/mail/extra_targets/${userId}`)
    },
    /// 供應商平台
    // 供應商列表
    getSuppliers(params) {
        return this.get(`/supplier`, { params })
    },
    postSupplier(params) {
        return this.post(`/supplier`, params)
    },
    patchSupplier(id, params) {
        return this.patch(`/supplier/${id}`, params)
    },
    getInspections(params) {
        return this.get(`/supplierInspection`, { params })
    },
    getInspectionsBySupplier(id, params) {
        return this.get(`/supplierInspection/${id}`, { params })
    },
    postInspection(params) {
        return this.post(`/supplierInspection`, params)
    },
    patchInspection(id, params) {
        return this.patch(`/supplierInspection/${id}`, params)
    },
    getSupplierProducts(id, params) {
        return this.get(`/supplierProduct/${id}`, { params })
    },
    postSupplierProduct(id, params) {
        return this.post(`/supplierProduct/${id}`, params)
    },
    patchSupplierProduct(id, params) {
        return this.patch(`/supplierProduct/${id}`, params)
    },
}
