const constants = {
  JOBREPORTTYPE_WORKSHOW: "工作指示", //'1', // 工作指示
  JOBREPORTTYPE_PAUSEWORK: "暫停工作", //'2', // 暫停工作
  JOBREPORTTYPE_RESTOREWORK: "工作恢復",
  JOBREPORTTYPE_CONTINUEJOB: "繼續工作", //'3', // 繼續工作
  JOBREPORTTYPE_JOBMODIFY: "工作修改", //'4', // 工作修改
  JOBREPORTTYPE_REPORTWORK: "回報工作進度", //'5', // 回報工作進度
  JOBREPORTTYPE_WORKOVER: "工作結束", //'6', // 工作結束
};

export default constants;
