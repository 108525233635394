<template>
  <div>
    <div>value:{{ value }}</div>
    <!-- TODO: confirm use wht kind of select -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "UserSelectModal",
  props: ["name", "value"],
  computed: {
    ...mapState(["usersTree"]),
    // tree: function() {
    //   return this.toTreeselectNode(this.usersTree.root).children;
    // }
  },
  methods: {
    handleChange: function(listName, e) {
      // this.approverList;
      // console.log(listName, e);
    },
    // toTreeselectNode: function(node) {
    //   if (!node) {
    //     return {};
    //   } else {
    //     const type = node.departments ? "department" : "user";
    //     let children;
    //     if (type === "department") {
    //       children =
    //         _.isEmpty(node.departments) && !node.users.length
    //           ? null
    //           : _.flatten([Object.values(node.departments), node.users]).map(
    //               n => this.toTreeselectNode(n)
    //             );
    //     }
    //     if (type === "user") {
    //       children = undefined;
    //     }
    //     return {
    //       ...node,
    //       label: node.name || node.chinese_name,
    //       children
    //     };
    //   }
    // },
  },
  data() {
    return { inputVal: this.value };
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
    },
    value(v) {
      this.inputVal = v;
    },
  },
};
</script>

<style></style>
