<template>
  <oa-modal
    :id="modalName"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header color1">
          <button type="button" class="close" data-dismiss="modal">
            <span class="close_COLOR" aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <h2 class="modal-title">新增機會</h2>
        </div>
        <div class="modal-body">
          <form class="form-horizontal" role="form">
            <div class="form-group">
              <label class="col-sm-5 control-label">材料大類</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入材料大類"
                  v-model="new_chance.materialBig"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">材料小類</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入材料小類"
                  v-model="new_chance.materialSmall"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">產品名稱</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入產品名稱"
                  v-model="new_chance.productName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">物性需求</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入物性需求"
                  v-model="new_chance.physicalNeed"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">原廠規格</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="原廠規格"
                  v-model="new_chance.specification"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">數量(KG)/月</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入數量"
                  v-model="new_chance.amount"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">價格/KG</label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入價格"
                  v-model="new_chance.price"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-5 control-label">{{
                $t("misc.currency")
              }}</label>
              <div class="col-sm-7">
                <select class="form-control" v-model="new_chance.currency">
                  <option disabled value="">{{
                    $t("placeholder.select")
                  }}</option>
                  <option v-for="c in currencies" :value="c" :key="c">
                    {{ c }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <!-- /.modal-body -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="addNewChance"
          >
            新增
          </button>
          <button type="button" class="btn btn-default" data-dismiss="modal">
            關閉
          </button>
        </div>
        <!-- /.modal-footer -->
      </div>
      <!-- /.modal-content -->
    </div>
  </oa-modal>
  <!-- /.modal -->
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import api from "@/Api/Api";
import * as moment from "moment";
import * as _ from "lodash";

export default {
  data() {
    return {
      modalName: "createChance",
      new_chance: {
        currency:""
      },
      interview: {},
      addCallback:null
    };
  },
  computed: {
    ...mapState(["ready", "user", "userId", "currencies"]),
  },
  async created() {
    if (this.ready) {
      this.init();
    } else {
      this.$bus.$on("ready", (e) => {
        this.init();
      });
    }
  },
  beforeDestroy() {
    this.$bus.$off("ready");
  },
  methods: {
    init() {},
    beforeOpen(e) {
      this.interview = _.cloneDeep(e.interview) || {};
      this.addCallback = e.addChance;
      this.new_chance = {
        currency:""
      }
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeClose() {},

    addNewChance() {
      this.new_chance.interviewId = this.interview.interviewId;
      this.addCallback(_.cloneDeep(this.new_chance));
      this.close();
    },
  },
};
</script>

<style></style>
