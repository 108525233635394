<template>
    <!-- 修改公佈欄 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" data-dismiss="modal">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ bulletin.title }}</h2>
                </div>
                <div class="modal-body">
                    <ul class="modal_ul">
                        <li>
                            <span class="span_name">{{ $t("misc.title") }}：</span>
                            <span>{{ bulletin.title }}</span>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("misc.startDate") }}：</span>
                            <span>{{ bulletin.startDate | formatDateUTC("YYYY-MM-DD") }}</span>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("misc.endDate") }}：</span>
                            <span>{{ bulletin.endDate | formatDateUTC("YYYY-MM-DD") }}</span>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("misc.priority") }}：</span>
                            <span>{{ bulletinImportanceMap[bulletin.importance] }}</span>
                        </li>
                        <li>
                            <div class="flex">
                                <span class="span_name">{{ $t("misc.content") }}：</span>
                                <p class="">{{ bulletin.content }}</p>
                            </div>
                        </li>
                        <li>
                            <span class="span_name">{{ $t("page.outsourcingTest0101.file") }}：</span>
                            <button
                                class="btn btn-success mr-1"
                                v-for="(file, i) in bulletin.files"
                                :key="i"
                                type="button"
                                @click="openFile(file.path)"
                            >
                                {{ file.fileName }}
                            </button>
                        </li>
                    </ul>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button v-if="isApprover && $router.currentRoute.path === '/bulletin0101'" type="button" class="btn btn-primary" @click="handleTakeDown()">
                        {{ $t("page.bulletin.close") }}
                    </button>
                    <button v-if="isApprover && bulletin.approvalStatus === '未簽核'" type="button" class="btn btn-primary" @click="updateStatus('已簽核')">
                        {{ $t("page.project0101.approve2") }}
                    </button>
                    <button v-if="isApprover && bulletin.approvalStatus === '未簽核'" type="button" class="btn btn-danger" @click="updateStatus('否決')">
                        {{ $t("misc.approveReject2") }}
                    </button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import api from "@/Api/Api"
import { mapState, mapGetters } from "vuex"
import moment from "moment"
export default {
    data() {
        return {
            modalName: "showBulletin",
            callback: () => {},
            bulletin: {},
        }
    },
    computed: {
        ...mapState(["userId", "bulletinImportanceMap"]),
        ...mapGetters(["hasPermission"]),
        isApprover() {
            return this.hasPermission(this.PERMISSION_BULLETIN_APPROVE)
        },
    },
    methods: {
        beforeOpen(e = {}) {
            this.bulletin = e.bulletin
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        openFile(filePath) {
            window.open(api.imageUrl + filePath)
        },
        handleTakeDown() {
            const params = {
                approvalStatus: "已下架",
            }
            api.putBulletin(this.bulletin.bulletinId, params).then(() => {
                this.callback()
                this.close()
            })
        },
        updateStatus(status) {
            const params = {
                approvalStatus: status,
                approvingUser: this.userId,
            }
            api.putBulletin(this.bulletin.bulletinId, params).then(() => {
                this.callback()
                this.close()
            })
        },
    },
}
</script>

<style scoped>
a.delete {
    position: absolute;
    right: 1em;
    font-size: 1.5em;
    font-weight: bold;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    color: #fff;
    padding: 8px 8px;
    background-color: #0078d7;
    border-radius: 4px;
}
a.delete:hover {
    text-decoration: none;
    background-color: #2badaf;
}
</style>
