<template>
    <!-- 今日重要事項 -->
    <oa-modal :id="modalName" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header color1">
                    <button type="button" class="close" @click="close">
                        <span class="close_COLOR" aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <h2 class="modal-title">{{ $t("page.home.importantMatters.viewEventsSetting") }}</h2>
                </div>
                <div class="modal-body">
                    <div>
                        <input type="checkbox" value="BULLETIN" v-model="showEvents" />
                        <span class="ml-2">{{ $t("header.billboard") }}</span>
                    </div>
                    <div>
                        <input type="checkbox" value="ACCOUNT" v-model="showEvents" disabled />
                        <span class="ml-2"
                            >{{ $t("sideMenu.graveAffair.graveAffair0101") }}/{{
                                $t("page.graveAffair0101.accountOverdue")
                            }}</span
                        >
                    </div>
                    <div>
                        <input type="checkbox" value="ORDER" v-model="showEvents" />
                        <span class="ml-2"
                            >{{ $t("sideMenu.graveAffair.graveAffair0101") }}/{{
                                $t("page.graveAffair0101.qualityAbnormity")
                            }}</span
                        >
                    </div>
                    <div>
                        <input type="checkbox" value="FLUCTUATION" v-model="showEvents" />
                        <span class="ml-2"
                            >{{ $t("sideMenu.graveAffair.graveAffair0101") }}/{{
                                $t("page.graveAffair0101.faild35")
                            }}</span
                        >
                    </div>
                    <div>
                        <input type="checkbox" value="STOCK" v-model="showEvents" />
                        <span class="ml-2"
                            >{{ $t("sideMenu.graveAffair.graveAffair0101") }}/{{
                                $t("page.graveAffair0101.safeStock")
                            }}</span
                        >
                    </div>
                    <div>
                        <input type="checkbox" value="JOB" v-model="showEvents" disabled />
                        <span class="ml-2">{{ $t("sideMenu.joblist.title") }}</span>
                    </div>
                    <div>
                        <input type="checkbox" value="PROJECT" v-model="showEvents" disabled />
                        <span class="ml-2">{{ $t("sideMenu.project0101") }}</span>
                    </div>
                    <div>
                        <input type="checkbox" value="MEETING" v-model="showEvents" />
                        <span class="ml-2">{{ $t("sideMenu.conferenceList.title") }}</span>
                    </div>
                </div>
                <!-- /.modal-body -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="handleUpdate">
                        {{ $t("misc.confirm") }}
                    </button>
                    <button type="button" class="btn btn-default" @click="close">
                        {{ $t("misc.close") }}
                    </button>
                </div>
                <!-- /.modal-footer -->
            </div>
            <!-- /.modal-content -->
        </div>
    </oa-modal>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
    data() {
        return {
            modalName: "todayEventOptions",
            callback: () => {},
            showEvents: [],
        }
    },
    computed: {
        ...mapState("userStore", ["userSettings"]),
    },
    methods: {
        ...mapActions("userStore", ["actionUpdateUserSetting"]),
        beforeOpen(e = {}) {
            this.showEvents = this.userSettings.importantMatters.split(",")
            this.callback = e.callback || this.callback
        },
        close() {
            this.$modal.hide(this.modalName)
        },
        beforeClose() {},
        handleUpdate() {
            const params = {
                importantMatters: this.showEvents.join(","),
            }
            this.actionUpdateUserSetting(params).then(() => {
                this.close()
                this.callback()
            })
        },
    },
}
</script>

<style scoped>
a.delete {
    position: absolute;
    right: 1em;
    font-size: 1.5em;
    font-weight: bold;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    color: #fff;
    padding: 8px 8px;
    background-color: #0078d7;
    border-radius: 4px;
}
a.delete:hover {
    text-decoration: none;
    background-color: #2badaf;
}
</style>
