<template>
    <div>
        <multiselect
            v-model="clientTargets"
            label="label"
            track-by="clientId"
            :placeholder="$t('placeholder.searchClient')"
            open-direction="bottom"
            :options="clientlist"
            :multiple="multiple"
            :searchable="true"
            :loading="isClientLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="multiple ? false : true"
            :options-limit="20"
            :limit="100"
            :max="multiple ? 100 : false"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            :preserve-search="true"
            @search-change="debouncedSearchClient"
            @input="$emit('change')"
            :select-label="''"
            :deselect-label="''"
        >
            <template slot="maxElements">
                <span>{{ $t("page.attendance0201.maxTenClient") }}</span>
            </template>
            <template slot="noOptions">
                <span>{{ $t("misc.noResult") }}</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import api from "@/Api/Api"
export default {
    name: "ClientSelect",
    // props: ["value", "multiple", "includeTipTop"],
    props: {
        value: [Array, Object],
        multiple: {
            type: Boolean,
            default: false,
        },
        allClients: {
            type: Boolean,
            default: false,
        },
        userIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            clientlist: [],
            debouncedSearchClient: null,
            isClientLoading: false,
            clientTargets: [],
        }
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    created() {
        this.debouncedSearchClient = _.debounce(this.searchClient, 400)
    },
    beforeDestroy() {},
    methods: {
        async searchClient(query) {
            if (query.length < 2) {
                this.clientlist = []
                return
            }
            this.isClientLoading = true
            await this.getclient(query)
            this.isClientLoading = false
        },
        getclient(query = "") {
            if (query.length < 2) {
                this.clientlist = []
                return
            }
            api.get(`/client`, {
                params: {
                    q: query,
                    ...(this.allClients && { allClients: this.allClients }),
                    ...(this.userIds.length && { userId: this.userIds.join(",") }),
                    limit: 20,
                    offset: 0,
                },
            }).then((res) => {
                this.clientlist = res.data.map((client) => {
                    let isTiptop = ""
                    if (!!client.dbSerialNumber && this.allClients == false) {
                        isTiptop = "(tiptop)"
                    }
                    return {
                        ...client,
                        label: `${client.name} [${
                            this.getUser(client.user_id).chinese_name || client.user_tiptop_id
                        }]${isTiptop}`,
                    }
                })
            })
        },
    },
    watch: {
        clientTargets(value) {
            this.$emit("input", value)
        },
        value(value) {
            this.clientTargets = value
        },
    },
}
</script>

<style lang="scss" scoped>
.multiselect-section {
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 1em;
    margin-top: 0.3em;
}
.sol-quick-delete {
    user-select: none;
}
</style>
