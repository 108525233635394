import permissions from "../domain/permission";
import * as _ from "lodash";

export default {
  install(Vue) {
    _.each(permissions, (permission, k) => {
      Vue.prototype[k] = permission;
    });
  },
};
