<template>
    <treeselect
        @input="handleChange(name, $event)"
        :options="tree"
        :searchable="true"
        :multiple="false"
        :default-expand-level="0"
        :show-count="true"
        placeholder
        v-model="inputVal"
        :load-options="loadOptions"
        search-nested
    >
        <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
            :class="labelClassName"
        >
            {{ node.label }}
            <span v-if="shouldShowCount && count" :class="countClassName">({{ count }})</span>
        </label>
    </treeselect>
</template>

<script>
import { mapState } from "vuex"
import _ from "lodash"

export default {
    name: "UserSelectSingleValue",
    props: ["name", "value"],
    computed: {
        ...mapState(["departmentTree"]),
        tree: function () {
            return this.toTreeselectNode(this.departmentTree["dep-root"]).children
        },
    },
    methods: {
        handleChange: function (listName, e) {
            // this.approverList;
            // console.log(listName, e);
        },
        toTreeselectNode: function (node) {
            if (!node) {
                return {}
            } else {
                return {
                    ...node,
                    label: node.name === "root" ? "公司" : node.name,
                    ...(!_.isEmpty(node.departments) && {
                        children: Object.values(node.departments).map((n) => this.toTreeselectNode(n)),
                    }),
                }
            }
        },
        loadOptions({ action, parentNode, callback, instanceId }) {
            callback()
        },
    },
    data() {
        return { inputVal: this.value }
    },
    watch: {
        inputVal(val) {
            this.$emit("input", val)
        },
    },
}
</script>

<style></style>
