const config = {
  default: {},
  development: {
    environment: "development",

    // api: "http://localhost:8034",
    // fileApi: "http://localhost:8081",

    // api: 'https://oa-api.uilgniw.com',
    // fileApi: 'https://oa-file-api.uilgniw.com',

    api: "http://oaback.ibpasia.com",
    fileApi: "http://oafile.ibpasia.com",
  },
  production: {
    environment: "production",
    api: "https://oaback.ibpeip.com",
    fileApi: "https://oafile.ibpeip.com",
  },
  // test: {
  //   environment: "test",
  //   api: "https://oa-api.uilgniw.com",
  //   fileApi: "https://oa-file-api.uilgniw.com",
  // },
    test: {
    environment: "test",
    api: "http://oaback.ibpasia.com",
    fileApi: "http://oafile.ibpasia.com",
  },
};

export default {
  ...config.default,
  ...config[process.env.VUE_APP_MODE || "development"],
};
