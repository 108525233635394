import oaConstants from "../domain/oaConstants";
import eventBus from "@/lib/eventBus";

import _ from "lodash";
import moment from "moment";
export default {
  install(Vue) {
    // utility library
    Vue.prototype.$_ = _;
    Vue.prototype.$moment = moment;
    Vue.prototype.$poundToKg = (pound) => {
      return pound / 2.2046226218;
    };

    // debug and test
    Vue.prototype.$debug = (v) => {
      if (localStorage.getItem("debug") === "true") {
        return v;
      }
    };
    Vue.prototype.$test = (v) => {
      return localStorage.getItem("test") === "true";
    };
    window.setDebug = (v) => window.localStorage.setItem("debug", !!v);
    window.setTest = (v) => window.localStorage.setItem("test", !!v);
    if (window.location.search.includes("debug=1")) {
      window.setDebug(true);
    }
    if (window.location.search.includes("debug=0")) {
      window.setDebug(false);
    }

    if (window.location.search.includes("test=1")) {
      window.setTest(true);
    }
    if (window.location.search.includes("test=0")) {
      window.setTest(false);
    }

    // modal
    Vue.prototype.$modal = {
      show: (id, params) => {
        window["modal-temp-data-" + id] = params;
        $("#" + id).modal("show");
      },
      hide: (id) => {
        $("#" + id).modal("hide");
      },
    };
    $(window).on("show.bs.modal", (e) => {
      const id = e.target.id;
      const params = _.cloneDeep(window["modal-temp-data-" + id]);
      eventBus.$emit("show.bs.modal." + id, params);
      delete window["modal-temp-data-" + id];
    });

    $(window).on("hide.bs.modal", (e) => {
      const id = e.target.id;
      eventBus.$emit("hide.bs.modal." + id);
    });

    _.each(oaConstants, (o, k) => {
      Vue.prototype[k] = o;
    });
  },
};
