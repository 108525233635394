import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./assets/css/bootstrap.min.css"
import "./assets/css/bootstrap-theme.min.css"
import "./assets/css/bootstrap-multiselect.css"
import "./assets/css/sol.css"
import "./assets/css/style_rwdoa.css"
import VCharts from "v-charts"
import VeLine from "v-charts/lib/line.common"
import VeRing from "v-charts/lib/ring.common"
import VeHistogram from "v-charts/lib/histogram.common"
import LiquorTree from "liquor-tree"
import htmlToPdf from "@/components/htmlToPdf"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import UserSelect from "@/components/UserSelect"
import UserSelectModal from "@/components/UserSelectModal"
import DepartmentSelect from "@/components/DepartmentSelect"
import UserSelectSingleValue from "@/components/UserSelectSingleValue"
import DepartmentSelectSingleValue from "@/components/DepartmentSelectSingleValue"
import VueI18n from "vue-i18n"
import VueSimpleContextMenu from "vue-simple-context-menu"
import "vue-simple-context-menu/dist/vue-simple-context-menu.css"
import SuiVue from "semantic-ui-vue"
import "semantic-ui-css/semantic.min.css"
import Snackbar from "vuejs-snackbar"
import Multiselect from "vue-multiselect"
import "vue-multiselect/dist/vue-multiselect.min.css"
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker"
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css"
import VTooltip from "v-tooltip"
import AsyncComputed from "vue-async-computed"

import VueMaterialIcon from "@/components/VueMaterialIcon"
import TagIcon from "@/components/TagIcon"
import Pager from "@/components/Pager"
import OaModal from "@/components/OaModal"
import SortCaret from "@/components/SortCaret"
import messages from "@/i18n"
import utilityPlugin from "@/plugin/utility"
import permissionPlugin from "@/plugin/permission"
import config from "@/config"
import "@/filters/date"
import "@/filters/currency"
import "@/filters/thousandth"
import eventBus from "@/lib/eventBus"
// import pager from 'vue-simple-pager'
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
import VueTreeList from "vue-tree-list"
import Paginate from "vuejs-paginate"
import { MediaQueries, CommonBands } from "vue-media-queries"

import CreateVisitModal from "@/components/modal/CreateVisit"
import CreateInterviewModal from "@/components/modal/CreateInterview"
import CreateChanceModal from "@/components/modal/CreateChance"
import EditChanceModal from "@/components/modal/EditChance"
import VisitModal from "@/components/modal/ShowVisit"
import ToggleButton from "vue-js-toggle-button"
Vue.use(ToggleButton)
import { BootstrapVue } from "bootstrap-vue"
Vue.use(BootstrapVue)
console.log("OA running:", { environment: config.environment })

const mediaQueries = new MediaQueries({
    bands: CommonBands.Bootstrap4,
})

Vue.use(mediaQueries)

Vue.use(VueTreeList)
// Vue.use(VCharts);
Vue.use(AsyncComputed)
Vue.component(VeLine.name, VeLine)
Vue.component(VeRing.name, VeRing)
Vue.component(VeHistogram.name, VeHistogram)
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
    console.error(err, vm, info)
}
Vue.component(LiquorTree.name, LiquorTree) //<tree>
Vue.use(htmlToPdf)
Vue.component("treeselect", Treeselect)
Vue.component("userselect", UserSelect)
Vue.component("user-select-modal", UserSelectModal)
Vue.component("departmentselect", DepartmentSelect)
Vue.component("userselectsinglevalue", UserSelectSingleValue)
Vue.component("departmentselectsinglevalue", DepartmentSelectSingleValue)
Vue.component("paginate", Paginate)
Vue.use(VueI18n)
Vue.use(SuiVue)
Vue.use(utilityPlugin)
Vue.use(permissionPlugin)
Vue.use(AirbnbStyleDatepicker, {})
Vue.use(VTooltip)
Vue.use(Loading)
Vue.use(Datetime)

Vue.component("datetime", Datetime)

Vue.component("multiselect", Multiselect)

Vue.component("VueIcon", VueMaterialIcon)
Vue.component("TagIcon", TagIcon)
Vue.component("oa-modal", OaModal)
Vue.component("Pager", Pager)
Vue.component("SortCaret", SortCaret)

Vue.component("vue-simple-context-menu", VueSimpleContextMenu)
Vue.component("snackbar", Snackbar)

// modals
Vue.component("create-visit-modal", CreateVisitModal)
Vue.component("create-interview-modal", CreateInterviewModal)
Vue.component("create-chance-modal", CreateChanceModal)
Vue.component("edit-chance-modal", EditChanceModal)
Vue.component("visit-modal", VisitModal)

const i18n = new VueI18n({
    locale: "zh-TW",
    fallbackLocale: "zh-TW",
    messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
})

if (module.hot) {
    module.hot.accept()

    window.addEventListener("message", (e) => {
        if (
            typeof e.data === "string" &&
            e.data.startsWith("webpackHotUpdate") &&
            "production" !== process.env.NODE_ENV
        ) {
            console.clear()
        } else {
            // console.log('window.message', e);
        }
    })
    setTimeout(() => {
        if (window.$(".modal-backdrop.fade.in").is(":empty")) {
            window.$(".modal-backdrop.fade.in").remove()
        }
    }, 1000)
}
// console.log('test')
// let myApp
// store.dispatch("acionGetUserId")
// store.dispatch("getPermissions").then(()=>{
//   myApp = new Vue({
//     i18n,
//     router,
//     store,
//     data() {
//       return {
//         bus: eventBus,
//       };
//     },
//     mediaQueries,
//     mixins: [CommonBands.Bootstrap4.mixin],
//     render: (h) => h(App),
//   }).$mount("#app");
// })
// async function startApp(){
//   store.dispatch("acionGetUserId")
//   await store.dispatch("getPermissions")
//   return window.app = new Vue({
//     i18n,
//     router,
//     store,
//     data() {
//       return {
//         bus: eventBus,
//       };
//     },
//     mediaQueries,
//     mixins: [CommonBands.Bootstrap4.mixin],
//     render: (h) => h(App),
//   }).$mount("#app");
// }
// export const app = startApp();
// export const app = myApp;
export const app = new Vue({
    i18n,
    router,
    store,
    data() {
        return {
            bus: eventBus,
        }
    },
    mediaQueries,
    mixins: [CommonBands.Bootstrap4.mixin],
    render: (h) => h(App),
}).$mount("#app")

// router.onReady(()=>{
//   app.$mount("#app");
// })
