import _ from "lodash"
import Vue from "vue"
import Router from "vue-router"
import Home from "./views/Home.vue"
import store from "./store"
import permissions from "@/domain/permission"
// import Login from './views/Login/login.vue'

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "login",
            meta: { public: true },
            component: () => import("./views/Login/login.vue"),
        },
        // {
        //   path:'/logout',
        //   name:'logout',
        //   meta: {public:true},

        // },
        {
            path: "/home",
            name: "home",
            component: Home,
        },
        {
            path: "/about",
            name: "about",
            component: () => import("./views/About.vue"),
        },
        // {
        //   path: '/login',
        //   name: 'login',
        //   component: Login
        //   component: ()=>import('./views/Login/login.vue')
        // },
        {
            path: "/achievingRate0101",
            name: "achievingRate0101",

            component: () => import("./views/achievingRate/achievingRate0101.vue"),
        },
        {
            path: "/achievingRate0102",
            name: "achievingRate0102",
            component: () => import("./views/achievingRate/achievingRate0102.vue"),
        },
        {
            path: "/achievingRate0201",
            name: "achievingRate0201",
            component: () => import("./views/achievingRate/achievingRate0201.vue"),
        },
        {
            path: "/achievingRate0202",
            name: "achievingRate0202",
            component: () => import("./views/achievingRate/achievingRate0202.vue"),
        },
        {
            path: "/achievingRate0203",
            name: "achievingRate0203",
            component: () => import("./views/achievingRate/achievingRate0203.vue"),
        },
        {
            path: "/achievingRate0204",
            name: "achievingRate0204",
            component: () => import("./views/achievingRate/achievingRate0204.vue"),
        },
        {
            path: "/achievingRate0205",
            name: "achievingRate0205",
            component: () => import("./views/achievingRate/achievingRate0205.vue"),
        },
        {
            path: "/attendance0101",
            name: "attendance0101",
            component: () => import("./views/attendance/attendance0101.vue"),
        },
        {
            path: "/attendance0102",
            name: "attendance0102",
            component: () => import("./views/attendance/attendance0102.vue"),
        },
        {
            path: "/attendance0103",
            name: "attendance0103",
            component: () => import("./views/attendance/attendance0103.vue"),
        },
        {
            path: "/attendance0104",
            name: "attendance0104",
            component: () => import("./views/attendance/attendance0104.vue"),
        },
        {
            path: "/attendance02permission",
            name: "attendance02permission",
            component: () => import("./views/attendance/attendance02permission.vue"),
        },
        {
            path: "/attendance0201",
            name: "attendance0201",
            component: () => import("./views/attendance/attendance0201.vue"),
        },
        {
            path: "/attendance0202",
            name: "attendance0202",
            component: () => import("./views/attendance/attendance0202.vue"),
        },
        {
            path: "/attendance0203",
            name: "attendance0203",
            component: () => import("./views/attendance/attendance0203.vue"),
        },
        {
            path: "/attendance0204",
            name: "attendance0204",
            component: () => import("./views/attendance/attendance0204.vue"),
        },
        {
            path: "/attendance0205",
            name: "attendance0205",
            component: () => import("./views/attendance/attendance0205.vue"),
        },
        {
            path: "/attendance0206",
            name: "attendance0206",
            component: () => import("./views/attendance/attendance0206.vue"),
        },
        {
            path: "/carApplication",
            name: "carApplication",
            component: () => import("./views/attendance/carApplication.vue"),
            props: true,
        },
        {
            path: "/attendance0301",
            name: "attendance0301",
            component: () => import("./views/attendance/attendance0301.vue"),
        },
        {
            path: "/attendance0302",
            name: "attendance0302",
            component: () => import("./views/attendance/attendance0302.vue"),
        },
        {
            path: "/attendance0401",
            name: "attendance0401",
            component: () => import("./views/attendance/attendance0401.vue"),
        },
        {
            path: "/attendance0402",
            name: "attendance0402",
            component: () => import("./views/attendance/attendance0402.vue"),
        },
        {
            path: "/attendance0501",
            name: "attendance0501",
            component: () => import("./views/attendance/attendance0501.vue"),
        },
        {
            path: "/attendance0502",
            name: "attendance0502",
            component: () => import("./views/attendance/attendance0502.vue"),
        },
        {
            path: "/attendance0601",
            name: "attendance0601",
            component: () => import("./views/attendance/attendance0601.vue"),
        },
        {
            path: "/attendance0602",
            name: "attendance0602",
            component: () => import("./views/attendance/attendance0602.vue"),
        },
        {
            path: "/attendance0701",
            name: "attendance0701",
            component: () => import("./views/attendance/attendance0701.vue"),
        },
        {
            path: "/attendance0702",
            name: "attendance0702",
            component: () => import("./views/attendance/attendance0702.vue"),
        },
        {
            path: "/graveAffairPermission",
            name: "graveAffairPermission",
            component: () => import("./views/graveAffair/graveAffairPermission.vue"),
        },
        {
            path: "/graveAffair0101",
            name: "graveAffair0101",
            component: () => import("./views/graveAffair/graveAffair0101.vue"),
        },
        {
            path: "/graveAffair0102",
            name: "graveAffair0102",
            component: () => import("./views/graveAffair/graveAffair0102.vue"),
        },
        {
            path: "/graveAffair0102event/:id",
            name: "graveAffair0102event",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0102event.vue"),
        },
        {
            path: "/graveAffair0103",
            name: "graveAffair0103",
            component: () => import("./views/graveAffair/graveAffair0103.vue"),
        },
        {
            path: "/graveAffair0103event/:id",
            name: "graveAffair0103event",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0103event.vue"),
        },
        {
            path: "/graveAffair0104",
            name: "graveAffair0104",
            component: () => import("./views/graveAffair/graveAffair0104.vue"),
        },
        {
            path: "/graveAffair0104event1/:id",
            name: "graveAffair0104event1",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0104event1.vue"),
        },
        {
            path: "/graveAffair0104event2/:id",
            name: "graveAffair0104event2",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0104event2.vue"),
        },
        {
            path: "/graveAffair0104event3/:id",
            name: "graveAffair0104event3",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0104event3.vue"),
        },
        {
            path: "/graveAffair0104event4/:id",
            name: "graveAffair0104event4",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0104event4.vue"),
        },
        {
            path: "/graveAffair0105",
            name: "graveAffair0105",
            component: () => import("./views/graveAffair/graveAffair0105.vue"),
        },
        {
            path: "/graveAffair0107",
            name: "graveAffair0107",
            component: () => import("./views/graveAffair/graveAffair0105.vue"),
        },
        {
            path: "/graveAffair0105event/:id",
            name: "graveAffair0105event",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0105event.vue"),
        },
        {
            path: "/graveAffair0106",
            name: "graveAffair0106",
            component: () => import("./views/graveAffair/graveAffair0106.vue"),
        },
        {
            path: "/graveAffair0106event/:id",
            name: "graveAffair0106event",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0106event.vue"),
        },
        {
            path: "/graveAffair0201",
            redirect: "/graveAffair0201/NT1",
        },
        {
            path: "/graveAffair0201/NT1",
            redirect: "/graveAffair0201/NT1/RM-100-50BW-2",
        },
        {
            path: "/graveAffair0201/SH1",
            redirect: "/graveAffair0201/SH1/RN-100-C20",
        },
        {
            path: "/graveAffair0201/:district/:material",
            name: "graveAffair0201",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0201.vue"),
        },
        {
            path: "/graveAffair0201permission",
            name: "graveAffair0201permission",
            component: () => import("./views/graveAffair/graveAffair0201permission.vue"),
        },
        {
            path: "/graveAffair0301",
            name: "graveAffair0301",
            component: () => import("./views/graveAffair/graveAffair0301.vue"),
        },
        {
            path: "/graveAffair0401",
            name: "graveAffair0401",
            component: () => import("./views/graveAffair/graveAffair0401.vue"),
        },
        {
            path: "/graveAffair0501",
            name: "graveAffair0501",
            component: () => import("./views/graveAffair/graveAffair0501.vue"),
        },
        {
            path: "/graveAffair0502",
            name: "graveAffair0502",
            component: () => import("./views/graveAffair/graveAffair0502.vue"),
        },
        {
            path: "/graveAffair0503/:district",
            name: "graveAffair0503",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0503.vue"),
        },
        {
            path: "/graveAffair0503detail/:district",
            name: "graveAffair0503detail",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0503detail.vue"),
        },
        {
            path: "/graveAffair0504/:district",
            name: "graveAffair0504",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0504.vue"),
        },
        {
            path: "/graveAffair0504detail/:district",
            name: "graveAffair0504detail",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0504detail.vue"),
        },
        {
            path: "/graveAffair0505/:district",
            name: "graveAffair0505",
            props: true,
            component: () => import("./views/graveAffair/graveAffair0505.vue"),
        },
        {
            path: "/interview01permission",
            name: "interview01permission",
            component: () => import("./views/interview/interview01permission.vue"),
        },
        {
            path: "/interview0101",
            name: "interview0101",
            component: () => import("./views/interview/interview0101.vue"),
        },
        {
            path: "/interview0102/:id",
            name: "interview0102",
            props: true,
            component: () => import("./views/interview/interview0102.vue"),
        },
        {
            path: "/interview0105/:id",
            name: "interview0105",
            component: () => import("./views/interview/interview0105.vue"),
        },
        {
            path: "/interview0106",
            name: "interview0106",
            component: () => import("./views/interview/interview0106.vue"),
        },
        {
            path: "/interview0201",
            name: "interview0201",
            component: () => import("./views/interview/interview0201.vue"),
        },
        {
            path: "/interview0301",
            name: "interview0301",
            component: () => import("./views/interview/interview0301.vue"),
        },
        {
            path: "/joblist0101",
            name: "joblist0101",
            component: () => import("./views/joblist/joblist0101.vue"),
        },
        {
            path: "/joblist0102",
            name: "joblist0102",
            component: () => import("./views/joblist/joblist0101.vue"),
        },
        {
            path: "/joblist0103",
            name: "joblist0103",
            component: () => import("./views/joblist/joblist0101.vue"),
        },
        {
            path: "/joblist0104/:jobId",
            name: "joblist0104",
            props: true,
            component: () => import("./views/joblist/joblist0104.vue"),
        },
        {
            path: "/joblist0201",
            name: "joblist0201",
            component: () => import("./views/joblist/joblist0203.vue"),
        },
        {
            path: "/joblist0202",
            name: "joblist0202",
            component: () => import("./views/joblist/joblist0203.vue"),
        },
        {
            path: "/joblist0203",
            name: "joblist0203",
            component: () => import("./views/joblist/joblist0203.vue"),
        },
        {
            path: "/joblist0301",
            name: "joblist0301",
            component: () => import("./views/joblist/joblist0301.vue"),
        },
        {
            path: "/joblist0401",
            name: "joblist0401",
            component: () => import("./views/joblist/joblist0401.vue"),
        },
        {
            path: "/joblist0501",
            name: "joblist0501",
            component: () => import("./views/joblist/joblist0501.vue"),
        },
        {
            path: "/largeExpenditure0101",
            name: "largeExpenditure0101",
            component: () => import("./views/largeExpenditure/largeExpenditure0101.vue"),
        },
        {
            path: "/machineManagement0101permission",
            name: "machineManagement0101permission",
            component: () => import("./views/machineManagement/machineManagement0101permission.vue"),
        },
        {
            path: "/machineManagement0101",
            name: "machineManagement0101",
            component: () => import("./views/machineManagement/machineManagement0101.vue"),
        },
        {
            path: "/machineManagement0201/:district",
            name: "machineManagement0201",
            props: true,
            component: () => import("./views/machineManagement/machineManagement0201.vue"),
        },
        {
            path: "/machineManagement0202/:district/:machine",
            name: "machineManagement0202",
            props: true,
            component: () => import("./views/machineManagement/machineManagement0202.vue"),
        },
        {
            path: "/machineManagement0203/:district/:machine",
            name: "machineManagement0203",
            props: true,
            component: () => import("./views/machineManagement/machineManagement0203.vue"),
        },
        {
            path: "/machineManagement0102",
            name: "machineManagement0102",
            component: () => import("./views/machineManagement/machineManagement0102.vue"),
        },
        {
            path: "/machineManagement0103/:district/:machine",
            name: "machineManagement0103",
            props: true,
            component: () => import("./views/machineManagement/machineManagement0103.vue"),
        },
        {
            path: "/machineManagement0301/:district/:machine/:maintenanceItem",
            name: "machineManagement0301",
            props: true,
            component: () => import("./views/machineManagement/machineManagement0301.vue"),
        },
        {
            path: "/material0101",
            name: "material0101",
            props: true,
            component: () => import("./views/material/material0101.vue"),
        },
        {
            path: "/material0102",
            name: "material0102",
            props: true,
            component: () => import("./views/material/material0102.vue"),
        },
        {
            path: "/material0103",
            name: "material0103",
            component: () => import("./views/material/material0103.vue"),
        },
        {
            path: "/material0201",
            name: "material0201",
            component: () => import("./views/material/material0201.vue"),
        },
        {
            path: "/material0301",
            name: "material0301",
            component: () => import("./views/material/material0301.vue"),
        },
        {
            path: "/orders0101",
            name: "orders0101",
            component: () => import("./views/orders/orders0101.vue"),
        },
        {
            path: "/orders0102",
            name: "orders0102",
            component: () => import("./views/orders/orders0102.vue"),
        },
        {
            path: "/orders0103",
            name: "orders0103",
            component: () => import("./views/orders/orders0103.vue"),
        },
        {
            path: "/orders0104",
            name: "orders0104",
            component: () => import("./views/orders/orders0104.vue"),
        },
        {
            path: "/orders0105",
            name: "orders0105",
            component: () => import("./views/orders/orders0105.vue"),
        },
        {
            path: "/orders0201",
            name: "orders0201",
            component: () => import("./views/orders/orders0201.vue"),
        },
        {
            path: "/orders0202/:orderId/:shipmentNumber/:lineItem",
            name: "orders0202",
            props: true,
            component: () => import("./views/orders/orders0202.vue"),
        },
        {
            path: "/orders0202report/:orderId/:shipmentNumber/:lineItem",
            name: "orders0202report",
            props: true,
            component: () => import("./views/orders/orders0202report.vue"),
        },
        {
            path: "/orders0203/:id",
            name: "orders0203",
            props: true,
            component: () => import("./views/orders/orders0203.vue"),
        },
        // {
        //   path: '/outsourcingTest0101',
        //   name: 'outsourcingTest0101',
        //   component: () => import('./views/outsourcingTest/outsourcingTest0101.vue')
        // },
        {
            path: "/outsourcingTest0102",
            name: "outsourcingTest0102",
            component: () => import("./views/outsourcingTest/outsourcingTest0102.vue"),
        },
        {
            path: "/project0101",
            name: "project0101",
            component: () => import("./views/project/project0101.vue"),
        },
        {
            path: "/project0102/:id",
            name: "project0102",
            props: true,
            component: () => import("./views/project/project0102.vue"),
        },
        {
            path: "/project0103/:id",
            name: "project0103",
            props: true,
            component: () => import("./views/project/project0103.vue"),
        },
        {
            path: "/project0201",
            name: "project0201",
            props: true,
            component: () => import("./views/project/project0201.vue"),
        },
        {
            path: "/purchasingEstimates0101",
            name: "purchasingEstimates0101",
            component: () => import("./views/purchasingEstimates/purchasingEstimates0101.vue"),
        },
        {
            path: "/purchasingEstimates0201",
            name: "purchasingEstimates0201",
            component: () => import("./views/purchasingEstimates/purchasingEstimates0201.vue"),
        },
        {
            path: "/purchasingEstimates0201permission",
            name: "purchasingEstimates0201permission",
            component: () => import("./views/purchasingEstimates/purchasingEstimates0201permission.vue"),
        },
        {
            path: "/supplierPlatform0101",
            name: "supplierPlatform0101",
            component: () => import("./views/supplierPlatform/supplierPlatform0101.vue"),
        },
        {
            path: "/supplierPlatform0102",
            name: "supplierPlatform0102",
            component: () => import("./views/supplierPlatform/supplierPlatform0102.vue"),
        },
        {
            path: "/supplierPlatform0103",
            name: "supplierPlatform0103",
            component: () => import("./views/supplierPlatform/supplierPlatform0103.vue"),
        },
        {
            path: "/supplierPlatform0104",
            name: "supplierPlatform0104",
            component: () => import("./views/supplierPlatform/supplierPlatform0104.vue"),
        },
        // {
        //   path: '/purchasingEstimates0202',
        //   name: 'purchasingEstimates0202',
        //   component: ()=>import('./views/purchasingEstimates/purchasingEstimates0202.vue')
        // },
        // {
        //   path: '/purchasingEstimates0203',
        //   name: 'purchasingEstimates0203',
        //   component: ()=>import('./views/purchasingEstimates/purchasingEstimates0203.vue')
        // }, {
        //   path: '/purchasingEstimates0204',
        //   name: 'purchasingEstimates0204',
        //   component: ()=>import('./views/purchasingEstimates/purchasingEstimates0204.vue')
        // },
        {
            path: "/technicalEvaluation0101",
            name: "technicalEvaluation0101",
            component: () => import("./views/technicalEvaluation/technicalEvaluation0101.vue"),
        },
        {
            path: "/technicalEvaluation0201",
            name: "technicalEvaluation0201",
            component: () => import("./views/technicalEvaluation/technicalEvaluation0201.vue"),
        },
        {
            path: "/technicalEvaluation0301",
            name: "technicalEvaluation0301",
            component: () => import("./views/technicalEvaluation/technicalEvaluation0301.vue"),
        },
        {
            path: "/competition0101",
            name: "competition0101",
            component: () => import("./views/competition/competition0101.vue"),
        },
        {
            path: "/competition0201",
            name: "competition0201",
            component: () => import("./views/competition/competition0201.vue"),
        },
        {
            path: "/competition0301",
            name: "competition0301",
            component: () => import("./views/competition/competition0301.vue"),
        },
        {
            path: "/compare/:path1/:path2",
            name: "compare",
            component: () => import("./views/competition/compare.vue"),
            props: true,
        },
        {
            path: "/technicalEvaluation0101permission",
            name: "technicalEvaluation0101permission",
            component: () => import("./views/technicalEvaluation/technicalEvaluation0101permission.vue"),
        },
        {
            path: "/testmaterial0101",
            name: "testmaterial0101",
            component: () => import("./views/testmaterial/testmaterial0101.vue"),
        },
        {
            path: "/testmaterial0102",
            name: "testmaterial0102",
            component: () => import("./views/testmaterial/testmaterial0102.vue"),
        },
        {
            path: "/testmaterial0103",
            name: "testmaterial0103",
            component: () => import("./views/testmaterial/testmaterial0103.vue"),
        },
        {
            path: "/testmaterial0201",
            name: "testmaterial0201",
            component: () => import("./views/testmaterial/testmaterial0201.vue"),
        },
        {
            path: "/testmaterial0201/:id",
            name: "testmaterial02012",
            props: true,
            component: () => import("./views/testmaterial/testmaterial0201.vue"),
        },
        {
            path: "/testmaterial0202",
            name: "testmaterial0202",
            component: () => import("./views/testmaterial/testmaterial0202.vue"),
        },
        {
            path: "/testmaterial0203",
            name: "testmaterial0203",
            component: () => import("./views/testmaterial/testmaterial0203.vue"),
        },
        {
            path: "/testmaterial0204",
            name: "testmaterial0204",
            component: () => import("./views/testmaterial/testmaterial0204.vue"),
        },
        {
            path: "/testmaterial0205",
            name: "testmaterial0205",
            component: () => import("./views/testmaterial/testmaterial0205.vue"),
        },
        {
            path: "/testmaterial0206",
            name: "testmaterial0206",
            component: () => import("./views/testmaterial/testmaterial0206.vue"),
        },
        {
            path: "/testmaterial0301",
            name: "testmaterial0301",
            component: () => import("./views/testmaterial/testmaterial0301.vue"),
        },
        {
            path: "/testmaterial0302",
            name: "testmaterial0302",
            component: () => import("./views/testmaterial/testmaterial0302.vue"),
        },
        {
            path: "/testmaterial0303",
            name: "testmaterial0303",
            component: () => import("./views/testmaterial/testmaterial0303.vue"),
        },
        {
            path: "/testmaterial0304",
            name: "testmaterial0304",
            component: () => import("./views/testmaterial/testmaterial0304.vue"),
        },
        {
            path: "/testmaterial0305",
            name: "testmaterial0305",
            component: () => import("./views/testmaterial/testmaterial0305.vue"),
        },
        {
            path: "/testmaterial0401",
            name: "testmaterial0401",
            component: () => import("./views/testmaterial/testmaterial0401.vue"),
        },
        {
            path: "/TGA_DSC0101",
            name: "TGA_DSC0101",
            component: () => import("./views/TGA_DSC/TGA_DSC0101.vue"),
        },
        {
            path: "/TGA_DSC0101permission",
            name: "TGA_DSC0101permission",
            component: () => import("./views/TGA_DSC/TGA_DSC0101permission.vue"),
        },
        {
            path: "/TGA_DSC0102",
            name: "TGA_DSC0102",
            component: () => import("./views/TGA_DSC/TGA_DSC0102.vue"),
        },
        {
            path: "/TGA_DSC0103",
            name: "TGA_DSC0103",
            component: () => import("./views/TGA_DSC/TGA_DSC0103.vue"),
        },
        {
            path: "/TGA_DSC0201",
            name: "TGA_DSC0201",
            component: () => import("./views/TGA_DSC/TGA_DSC0101.vue"),
        },
        {
            path: "/TGA_DSC0202",
            name: "TGA_DSC0202",
            component: () => import("./views/TGA_DSC/TGA_DSC0102.vue"),
        },
        {
            path: "/myProfile0101",
            name: "myProfile0101",
            component: () => import("./views/myProfile/myProfile0101.vue"),
        },
        {
            path: "/schedule",
            name: "schedule",
            component: () => import("./views/schedule/schedule.vue"),
        },
        {
            path: "/schedule0101",
            name: "schedule0101",
            component: () => import("./views/schedule/schedule0101.vue"),
        },
        {
            path: "/schedule_review",
            name: "schedule_review",
            component: () => import("./views/schedule/schedule_review.vue"),
        },
        {
            path: "/permission",
            name: "permission",
            component: () => import("./views/permission/permission.vue"),
            children: store.state.permissionList.map((permission) => {
                return {
                    path: permission.path,
                    name: "permission" + permission.path.charAt(0).toUpperCase() + permission.path.slice(1),
                    component: () => import(`./views/permission/${permission.path}.vue`),
                }
            }),
        },
        {
            path: "/permission0101",
            name: "permission0101",
            component: () => import("./views/permission/permission0101.vue"),
        },
        {
            path: "/permission0201",
            name: "permission0201",
            component: () => import("./views/permission/permission0201.vue"),
        },
        {
            path: "/template",
            name: "template",
            component: () => import("./views/template.vue"),
        },
        {
            path: "/bulletin0101",
            name: "bulletin0101",
            component: () => import("./views/bulletin/bulletin0101.vue"),
        },
        {
            path: "/bulletin0102",
            name: "bulletin0102",
            component: () => import("./views/bulletin/bulletin0101.vue"),
        },
        {
            path: "/bulletin0103",
            name: "bulletin0103",
            component: () => import("./views/bulletin/bulletin0101.vue"),
        },
        {
            path: "/bulletin0104",
            name: "bulletin0104",
            component: () => import("./views/bulletin/bulletin0101.vue"),
        },
        // {
        //   path: '/officialdocument0101',
        //   name: 'officialdocument0101',
        //   component: ()=>import('./views/officialDocument/officialdocument0101.vue')
        // },
        // {
        //   path: '/officialdocument0201/:id',
        //   name: 'officialdocument0201',
        //   props: true,
        //   component: ()=>import('./views/officialDocument/officialdocument0201.vue')
        // },
        // {
        //   path: '/officialdocument0102',
        //   name: 'officialdocument0102',
        //   component: ()=>import('./views/officialDocument/officialdocument0102.vue')
        // },
        {
            path: "/conferenceList0101",
            name: "conferenceList0101",
            component: () => import("./views/conferenceList/conferenceList0101.vue"),
        },
        {
            path: "/conferenceList0102",
            name: "conferenceList0102",
            component: () => import("./views/conferenceList/conferenceList0101.vue"),
        },
        // {
        //   path: '/conferenceList0103',
        //   name: 'conferenceList0103',
        //   component: () => import('./views/conferenceList/conferenceList0103.vue')
        // },
        {
            path: "/conferenceList0104",
            name: "conferenceList0104",
            component: () => import("./views/conferenceList/conferenceList0104.vue"),
        },
        {
            path: "/conferenceList0105",
            name: "conferenceList0105",
            component: () => import("./views/conferenceList/conferenceList0105.vue"),
        },
        {
            path: "/conferenceList0106",
            name: "conferenceList0106",
            component: () => import("./views/conferenceList/conferenceList0106.vue"),
        },
        {
            path: "/fileManager",
            name: "fileManager",
            component: () => import("./views/fileManager/fileManager.vue"),
            beforeEnter: (to, from, next) => {
                if (store.getters.hasPermission(permissions.PERMISSION_DOCUMENT_LIST, { noAdmin: true, effect: "deny" })) {
                    next({ name: "home" })
                } else {
                    next()
                }
            },
        },
        {
            path: "/fileManager0101",
            name: "fileManager0101",
            component: () => import("./views/fileManager/fileManager0101.vue"),
            beforeEnter: (to, from, next) => {
                if (store.getters.hasPermission(permissions.PERMISSION_DOCUMENT_LIST, { noAdmin: true, effect: "deny" })) {
                    next({ name: "home" })
                } else {
                    next()
                }
            },
        },
        {
            path: "/fileManager0102",
            name: "fileManager0102",
            component: () => import("./views/fileManager/fileManager0102.vue"),
        },
        {
            path: "/fileManager0103",
            name: "fileManager0103",
            component: () => import("./views/fileManager/fileManager0103.vue"),
        },
        {
            path: "/fileManager0104",
            name: "fileManager0104",
            component: () => import("./views/fileManager/fileManager0104.vue"),
        },
        {
            path: "/displayDocumentNew0101",
            name: "displayDocumentNew0101",
            component: () => import("./views/displayDocumentNew/displayDocumentNew0101.vue"),
        },
        {
            path: "/displayDocumentNew0201/:secId",
            name: "displayDocumentNew0201",
            props: true,
            component: () => import("./views/displayDocumentNew/displayDocumentNew0201.vue"),
        },
        {
            path: "/displayDocumentNew0102",
            name: "displayDocumentNew0102",
            component: () => import("./views/displayDocumentNew/displayDocumentNew0102.vue"),
        },
        {
            path: "/displayDocumentNew0103",
            name: "displayDocumentNew0103",
            component: () => import("./views/displayDocumentNew/displayDocumentNew0103.vue"),
        },
        {
            path: "/displayDocumentNew0104",
            name: "displayDocumentNew0104",
            component: () => import("./views/displayDocumentNew/displayDocumentNew0104.vue"),
        },
        {
            path: "/displayDocumentNew0204/:id",
            name: "displayDocumentNew0204",
            props: true,
            component: () => import("./views/displayDocumentNew/displayDocumentNew0204.vue"),
        },
        {
            path: "/companySales0101",
            name: "companySales0101",
            component: () => import("./views/companySales/companySales0101.vue"),
        },
        {
            path: "/companySales0102",
            name: "companySales0102",
            component: () => import("./views/companySales/companySales0102.vue"),
        },
        {
            path: "/companySales0103",
            name: "companySales0103",
            component: () => import("./views/companySales/companySales0103.vue"),
        },
        {
            path: "/companySales0104",
            name: "companySales0104",
            component: () => import("./views/companySales/companySales0104.vue"),
        },
        {
            path: "/companySales0105",
            name: "companySales0105",
            component: () => import("./views/companySales/companySales0105.vue"),
        },
        {
            path: "/weeklyReview0101",
            name: "weeklyReview0101",
            component: () => import("./views/weeklyReview/weeklyReview0101.vue"),
        },
        {
            path: "/weeklyReview0102",
            name: "weeklyReview0102",
            component: () => import("./views/weeklyReview/weeklyReview0102.vue"),
        },
        {
            path: "/weeklyReview0103",
            name: "weeklyReview0103",
            component: () => import("./views/weeklyReview/weeklyReview0103.vue"),
        },
        {
            path: "/weeklyReview0104",
            name: "weeklyReview0104",
            component: () => import("./views/weeklyReview/weeklyReview0103.vue"),
        },
        {
            path: "/weeklyReview0105",
            name: "weeklyReview0105",
            component: () => import("./views/weeklyReview/weeklyReview0105.vue"),
        },
        {
            path: "/weeklyReview0106",
            name: "weeklyReview0106",
            component: () => import("./views/weeklyReview/weeklyReview0106.vue"),
        },
        {
            path: "/weeklyReview0107",
            name: "weeklyReview0107",
            component: () => import("./views/weeklyReview/weeklyReview0107.vue"),
        },
        {
            path: "/weeklyReview0108",
            name: "weeklyReview0108",
            component: () => import("./views/weeklyReview/weeklyReview0108.vue"),
        },
        {
            path: "/weeklyReview0109",
            name: "weeklyReview0109",
            component: () => import("./views/weeklyReview/weeklyReview0109.vue"),
        },
        {
            path: "/breakeven0101",
            name: "breakeven0101",
            component: () => import("./views/breakeven/breakeven0101.vue"),
        },
        {
            path: "/breakeven0102",
            name: "breakeven0102",
            component: () => import("./views/breakeven/breakeven0102.vue"),
        },
        {
            path: "/transaction0101",
            name: "transaction0101",
            component: () => import("./views/interview/interview0201.vue"),
        },
        {
            path: "/transaction0201",
            name: "transaction0201",
            component: () => import("./views/transaction/transaction0201.vue"),
        },
        {
            path: "/transaction0202/:id",
            name: "transaction0202",
            props: true,
            component: () => import("./views/transaction/transaction0202.vue"),
        },
        {
            path: "/inquiryAction0101",
            name: "inquiryAction0101",
            component: () => import("./views/inquiryAction/inquiryAction0101.vue"),
        },
        {
            path: "/inquiryAction0101/:id",
            name: "inquiryAction01012",
            props: true,
            component: () => import("./views/inquiryAction/inquiryAction0101.vue"),
        },
        {
            path: "/inquiryAction0102",
            name: "inquiryAction0102",
            props: true,
            component: () => import("./views/inquiryAction/inquiryAction0102.vue"),
        },
        {
            path: "/questionnaire0101",
            name: "questionnaire0101",
            component: () => import("./views/questionnaire/questionnaire0101.vue"),
        },
        {
            path: "/questionnaire0102",
            name: "questionnaire0102",
            component: () => import("./views/questionnaire/questionnaire0102.vue"),
        },
        {
            path: "/top20customer0101",
            name: "top20customer0101",
            props: true,
            component: () => import("./views/top20customer/top20customer0101.vue"),
        },
        {
            path: "/trademark0101/:category",
            name: "trademark0101",
            props: true,
            component: () => import("./views/trademark/trademark0101.vue"),
        },
        {
            path: "/certificate0101",
            name: "certificate0101",
            props: true,
            component: () => import("./views/certificate/certificate0101.vue"),
        },
        {
            path: "/certificate0102",
            name: "certificate0102",
            props: true,
            component: () => import("./views/certificate/certificate0102.vue"),
        },
        {
            path: "/certificate0103",
            name: "certificate0103",
            props: true,
            component: () => import("./views/certificate/certificate0103.vue"),
        },
        {
            path: "/ezSales/clientManagement0101",
            name: "ezSalesClientManagement0101",
            component: () => import("./views/ezSales/clientManagement0101.vue"),
        },
        {
            path: "/ezSales/clientManagement0102/:customerId",
            name: "ezSalesClientManagement0102",
            component: () => import("./views/ezSales/clientManagement0102.vue"),
        },
        {
            path: "/ezSales/clientManagement0103/:customerId",
            name: "ezSalesClientManagement0103",
            component: () => import("./views/ezSales/clientManagement0103.vue"),
        },
        {
            path: "/ezSales/characterManagement0101",
            name: "ezSalesCharacterManagement0101",
            component: () => import("./views/ezSales/characterManagement0101.vue"),
        },
        {
            path: "/ezSales/characterManagement0102/:characterId",
            name: "ezSalesCharacterManagement0102",
            component: () => import("./views/ezSales/characterManagement0102.vue"),
        },
        {
            path: "/ezSales/characterManagement0103/:characterId",
            name: "ezSalesCharacterManagement0103",
            component: () => import("./views/ezSales/characterManagement0103.vue"),
        },
        {
            path: "/ezSales/projectManagement0101",
            name: "ezSalesProjectManagement0101",
            component: () => import("./views/ezSales/projectManagement0101.vue"),
        },
        {
            path: "/ezSales/projectManagement0102/:projectId",
            name: "ezSalesProjectManagement0102",
            component: () => import("./views/ezSales/projectManagement0102.vue"),
        },
        {
            path: "/ezSales/projectManagement0103/:projectId",
            name: "ezSalesProjectManagement0103",
            component: () => import("./views/ezSales/projectManagement0103.vue"),
        },
        {
            path: "/ezSales/projectManagement0104/:projectId",
            name: "ezSalesProjectManagement0104",
            component: () => import("./views/ezSales/projectManagement0104.vue"),
        },
        {
            path: "/ezSales/projectManagement0105/:projectId",
            name: "ezSalesProjectManagement0105",
            component: () => import("./views/ezSales/projectManagement0105.vue"),
        },
        {
            path: "/ezSales/permission",
            name: "ezSalesPermission",
            component: () => import("./views/ezSales/ezSales0401.vue"),
        },
        {
            path: "/ezSales/selection",
            name: "ezSalesSelection",
            component: () => import("./views/ezSales/ezSales0402.vue"),
        },
        {
            path: "/trademark0201",
            name: "trademark0201",
            props: true,
            component: () => import("./views/trademark/trademark0201.vue"),
        },
        {
            path: "/contract0101",
            redirect: "/contract0101/NT1",
        },
        {
            path: "/contract0101/:district",
            name: "contract0101",
            props: true,
            component: () => import("./views/contract/contract0101.vue"),
        },
        {
            path: "/contract0102",
            name: "contract0102",
            props: true,
            component: () => import("./views/contract/contract0102.vue"),
        },
        {
            path: "/contract0103",
            name: "contract0103",
            props: true,
            component: () => import("./views/contract/contract0103.vue"),
        },
        {
            path: "/regulationList0101",
            name: "regulationList0101",
            component: () => import("./views/regulationList/regulationList0101.vue"),
        },
        {
            path: "/regulationList0201",
            name: "regulationList0201",
            component: () => import("./views/regulationList/regulationList0201.vue"),
        },
        {
            path: "/regulationList0301",
            name: "regulationList0301",
            component: () => import("./views/regulationList/regulationList0301.vue"),
        },
        {
            path: "/jobRule0101",
            name: "jobRule0101",
            component: () => import("./views/jobRule/jobRule0101.vue"),
        },
        {
            path: "/jobTraining0101",
            name: "jobTraining0101",
            component: () => import("./views/jobTraining/jobTraining0101.vue"),
        },
        {
            path: "/jobTraining0102",
            name: "jobTraining0102",
            component: () => import("./views/jobTraining/jobTraining0102.vue"),
        },
        {
            path: "/jobTraining0103",
            name: "jobTraining0103",
            component: () => import("./views/jobTraining/jobTraining0103.vue"),
        },
        {
            path: "/fee0101",
            name: "fee0101",
            component: () => import("./views/fee/fee0101.vue"),
        },
        {
            path: "/fee0102",
            name: "fee0102",
            component: () => import("./views/fee/fee0102.vue"),
        },
        {
            path: "/client0101",
            name: "client0101",
            component: () => import("./views/client/client0101.vue"),
        },
        {
            path: "/client0102",
            name: "client0102",
            component: () => import("./views/client/client0102.vue"),
        },
        {
            path: "/client0201",
            name: "client0201",
            component: () => import("./views/client/client0201.vue"),
        },
        {
            path: "/client0301",
            name: "client0301",
            component: () => import("./views/client/client0301.vue"),
        },
        {
            path: "/client0401",
            name: "client0401",
            component: () => import("./views/client/client0401.vue"),
        },
        {
            path: "/interviewCount0101",
            name: "interviewCount0101",
            component: () => import("./views/interviewCount/interviewCount0101.vue"),
        },
        {
            path: "/interviewCount0102",
            name: "interviewCount0102",
            component: () => import("./views/interviewCount/interviewCount0102.vue"),
        },
        {
            path: "/interviewCount0103",
            name: "interviewCount0103",
            component: () => import("./views/interviewCount/interviewCount0103.vue"),
        },
        {
            path: "/interviewCount0104",
            name: "interviewCount0104",
            component: () => import("./views/interviewCount/interviewCount0104.vue"),
        },
        {
            path: "/member0101",
            name: "member0101",
            component: () => import("./views/member/member0101.vue"),
        },
        {
            path: "/member0102",
            name: "member0102",
            component: () => import("./views/member/member0101.vue"),
        },
        {
            path: "/member0103",
            name: "member0103",
            component: () => import("./views/member/member0103.vue"),
        },
        {
            path: "/member0104",
            name: "member0104",
            component: () => import("./views/member/member0104.vue"),
        },
        {
            path: "/member0105",
            name: "member0105",
            component: () => import("./views/member/member0105.vue"),
        },
        {
            path: "/member0106",
            name: "member0106",
            component: () => import("./views/member/member0106.vue"),
        },
        {
            path: "/user/:id",
            name: "user",
            component: () => import("./views/user/user.vue"),
        },
        {
            path: "/department0101",
            name: "department0101",
            component: () => import("./views/department/department0101.vue"),
        },
        {
            path: "/department0102",
            name: "department0102",
            component: () => import("./views/department/department0102.vue"),
        },
        {
            path: "/department0103",
            name: "department0103",
            component: () => import("./views/department/department0103.vue"),
        },
        {
            path: "/department0104",
            name: "department0104",
            component: () => import("./views/department/department0104.vue"),
        },
        {
            path: "/email/extra_target",
            name: "emailExtraTarget_backup",
            component: () => import("./views/email/extra_target.vue"),
        },
        {
            path: "/email/email_management",
            name: "emailExtraTarget",
            component: () => import("./views/email/emailManagement.vue"),
        },
        {
            path: "/system_security/ip_block",
            name: "systemSecurity",
            component: () => import("./views/systemSecurity/ipBlock.vue"),
        },
        {
            path: "/system_security/password",
            name: "systemSecurityPassword",
            component: () => import("./views/systemSecurity/password.vue"),
        },
        {
            path: "/system_security/nytexIPs",
            name: "nytexIPs",
            component: () => import("./views/systemSecurity/nytexIPs.vue"),
        },
        // {
        //   path: '/user',
        //   name: 'user',
        //   component: () => import('./views/user/user.vue')
        // },
    ],
})

router.beforeEach((to, from, next) => {
    const userToken = localStorage.getItem("login_Usertoken")

    if (to.name === "login") {
        if (userToken) {
            next({
                path: "/home",
            })
        } else {
            next()
        }
    } else {
        //protected
        if (!userToken) {
            next({
                path: "/",
            })
        } else {
            next()
        }
    }
})

export default router
