import Vue from "vue"

Vue.filter("thousandth", function (value, format) {
    if (value === 0 || value) {
        const number = String(value).split(".")
        const thousandthNumber = number[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if (number.length === 2) {
            return `${thousandthNumber}.${number[1]}`
        }
        return thousandthNumber
    }
})
